import { get, post, deleteReq, put } from "src/services/restService";

export const addPaymentTermsApi = (data) => {
  return post("payment_term/add", data);
};
export const getAllPaymentTermsApi = () => {
  return get("payment_term/getAll");
};
export const deletePaymentTermsApi = (id) => {
  return deleteReq(`payment_term/delete/${id}`);
};
export const updatePaymentTermsApi = (data) => {
  return put(`payment_term/update/${data._id}`, data);
};

import { createSlice } from "@reduxjs/toolkit";
import {
  addQuotationApi,
  getAllQuotationApi,
  getQuotationByIdApi,
  deleteQuotationApi,
  updateQuotationApi,
} from "src/api/customer/quotationApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  quotations: [],
  singleQuotation: {},
};

const slice = createSlice({
  name: "quotation",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addQuotation(state, { payload }) {
      state.quotations.push(payload);
    },
    _getAllQuotation(state, { payload }) {
      state.quotations = payload;
    },
    _getSingleQuotation(state, { payload }) {
      state.singleQuotation = payload;
    },
    _deleteQuotation(state, { payload }) {
      const index = state.quotations.findIndex((item) => item._id === payload);
      if (index !== -1) {
        state.quotations.splice(index, 1);
      }
    },
    _updateQuotation(state, { payload }) {
      const index = state.quotations.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.newCustomers[index] = payload;
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addQuotation,
  _getAllQuotation,
  _getSingleQuotation,
  _deleteQuotation,
  _updateQuotation,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addQuotation = (data, userId) => async (dispatch) => {
  try {

    const response = await addQuotationApi({ ...data, userId });

    const { status, newquotation } = response.data;
    if (status === "success") {
      dispatch(_addQuotation(newquotation));
      return newquotation.id;
    } else {
      throw new Error(response.data.message || "Failed to add quotation");
    }
  } catch (error) {
    throw new Error(error.message || "Failed to add quotation");
  }
};

export const getAllQuotations = (userId) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getAllQuotationApi(userId);

    const { status, newquotations } = response.data;
    if (status === "success") {
      dispatch(_getAllQuotation(newquotations));
    }
  } catch (error) {}
};

export const deleteQuotations = (id) => async (dispatch) => {
  try {
    const response = await deleteQuotationApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteQuotation(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateQuotations = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateQuotationApi(data);
    const { status, newquotations } = response.data;
    if (status === "success") {
      dispatch(_updateQuotation(newquotations));
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const getQuotationById = (id) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getQuotationByIdApi(id);

    const { status, quotation } = response.data;
    if (status === "success") {
      dispatch(_getSingleQuotation(quotation));
    }
  } catch (error) {
    console.log(error);
    // dispatch(hasError(error));
  }
};

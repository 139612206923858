import sum from "lodash/sum";
// @mui
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Card,
  Table,
  Divider,
  TableRow,
  Container,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "src/routes/paths";
// utils
import { fCurrency } from "src/utils/formatNumber";
// _mock_
import { _invoice } from "src/_mock";
// hooks
import useSettings from "src/hooks/useSettings";
// components
import Page from "src/components/Page";
import Label from "src/components/Label";
import Image from "src/components/Image";
import Scrollbar from "src/components/Scrollbar";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import comLogo from "src/assets/logos/comLogo.jpeg";
import { useParams } from "react-router-dom";
// sections
import {
  getAllPurchaseOrders,
  getPurchaseOrderById,
} from "src/redux/slices/supplier/purchaseOrder";
import {
  getAllGRNReceiveds,
  getGRNReceivedById,
} from "src/redux/slices/supplier/grnReceived";
import { Link } from "react-router-dom";
import InvoiceToolbar from "src/pages/dashboard/components/customers/Reports/invoice/InvoiceToolbar";

// ----------------------------------------------------------------------

const RowResultStyle = styled(TableRow)(({ theme }) => ({
  "& td": {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

export default function PurchaseOrderDetails() {
  const { id } = useParams();
  const purchaseOrder = useSelector((state) => state.purchaseOrder);
  const { purchaseOrders, singlePurchaseOrder } = purchaseOrder;

 
  const { themeStretch } = useSettings();
  const [singlePurchaseOrderObj, setsinglePurchaseOrderObj] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    getQuotations();
  }, []);
  useEffect(() => {
    setsinglePurchaseOrderObj(singlePurchaseOrder);
  }, [singlePurchaseOrder]);

  const getQuotations = async () => {
    console.log("single Quotation >>>>>", singlePurchaseOrderObj);

    await dispatch(getPurchaseOrderById(id));
    // await dispatch(getAllQuotations());

  };
  ///////////////////// GRN DETAILS /////////////////////////////////////

  const grnReceived = useSelector((state) => state.grnReceived);
  const { grnReceiveds } = grnReceived;

  const [GRNItems, setGRNItems] = useState([]);


  useEffect(() => {
    getGrnReceiveds();
  }, []);

  const getGrnReceiveds = async () => {
    await dispatch(getAllGRNReceiveds());
  };
  useEffect(() => {
    if (grnReceiveds) {
      setGRNItems(grnReceiveds);
    }
  }, [grnReceiveds]);
  return (
    <Page title="suppliers: Purchase Order">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="Purchase Order Detials"
          links={[
            { href: PATH_DASHBOARD.root },
            {
              href: PATH_DASHBOARD.suppliers.root,
            },
          ]}
        />

        {/* <InvoiceToolbar singlePurchaseOrderObj={singlePurchaseOrderObj} /> */}

        <Card sx={{ pt: 5, px: 5 }}>
          <Grid container>
            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Image
                disabledEffect
                visibleByDefault
                alt="logo"
                src="/favicon/Overseas.jpeg"
                sx={{ maxWidth: 200 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Box sx={{ textAlign: { sm: "right" } }}>
                <Label
                  color="success"
                  sx={{ textTransform: "uppercase", mb: 1 }}
                >
                  {singlePurchaseOrderObj.supplierName}
                  {console.log(
                    "single che>>>>",
                    singlePurchaseOrderObj.supplierCurrency
                  )}
            
                </Label>
                <Typography variant="h6">PO00550{id}</Typography>
              </Box>
            </Grid>

       
          </Grid>

          <TableContainer sx={{ minWidth: 960 }}>
            <Table
            // sx={{
            //   border: (theme) => `solid 2px ${theme.palette.divider}`,
            //   "& th": { backgroundColor: "transparent" },
            // }}
            >
              <TableHead
                sx={{
                  border: 2,
                  borderColor: "grey.500",
                  backgroundColor: "orange",
                }}
              >
                <TableRow sx={{ fontWeight: "Bold" }}>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    width={40}
                  >
                    Suppliers
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    Delivery Into Location
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    Order Date
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    PAYMENT TERMS
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    Order Currency
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    {singlePurchaseOrderObj.supplierName}
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    {singlePurchaseOrderObj.receiveinto}
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    {singlePurchaseOrderObj.orderDate}
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    {singlePurchaseOrderObj.paymentTerms}
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    {singlePurchaseOrderObj.supplierCurrency}
                  </TableCell>
                </TableRow>
                <TableRow sx={{ border: 1, borderColor: "gray" }}>
                  <b> DELIVERY ADDRESS -- </b>
                  {singlePurchaseOrderObj.deliverTo}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 960 }}>
              <Table>
                <TableHead
                  sx={{
                    borderBottom: (theme) =>
                      `solid 1px ${theme.palette.divider}`,
                    "& th": { backgroundColor: "transparent" },
                  }}
                >
                  <h1>Line Details</h1>
                  <TableRow>
                    <TableCell>ITEM CODE</TableCell>
                    <TableCell align="left">DESCRIPTION </TableCell>
                    <TableCell align="left">QTY</TableCell>
                    <TableCell align="left">UNIT PRICE</TableCell>
                    <TableCell align="right">TOTAL</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {singlePurchaseOrderObj.orderItemDetails?.items.map(
                    (item, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          borderBottom: (theme) =>
                            `solid 1px ${theme.palette.divider}`,
                        }}
                      >
                        <TableCell align="left">
                          <Box>
                            <Typography variant="subtitle2">
                              {item.itemCode}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="left">
                          {item.itemDescription}
                        </TableCell>
                        <TableCell align="left">{item.quantity}</TableCell>

                        <TableCell align="left">
                          {item.priceBeforeTax}
                        </TableCell>
                        <TableCell align="right">{item.total}</TableCell>
                      </TableRow>
                    )
                  )}

                  <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Box sx={{ mt: 2 }} />
                      <Typography>Subtotal</Typography>
                    </TableCell>
                    <TableCell align="right" width={120}>
                      <Box sx={{ mt: 2 }} />
                      <Typography>
                        {" "}
                        {console.log("single PO", singlePurchaseOrderObj)}
                        {singlePurchaseOrderObj.orderItemDetails?.total}
                      </Typography>
                    </TableCell>
                  </RowResultStyle>
                  {/* <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Typography>Shipping </Typography>
                    </TableCell>
                    <TableCell align="right" width={120}>
                      <Typography sx={{ color: "error.main" }}>
                        {singlePurchaseOrderObj.shippingCost}
                      </Typography>
                    </TableCell>
                  </RowResultStyle> */}
                  <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Typography>Taxes</Typography>
                      <Typography>{singlePurchaseOrderObj.taxGroup}</Typography>
                    </TableCell>
                    <TableCell align="right" width={120}>
                      <Typography>{singlePurchaseOrderObj.taxCost}</Typography>
                    </TableCell>
                  </RowResultStyle>
                  <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Typography variant="h6">Total</Typography>
                    </TableCell>
                    <TableCell align="right" width={140}>
                      <Typography variant="h6">
                        {singlePurchaseOrderObj.afterTax}
                      </Typography>
                    </TableCell>
                  </RowResultStyle>
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Divider sx={{ mt: 5 }} />

          {/* <Grid container>
            <Grid item xs={12} md={9} sx={{ py: 3 }}>
              <Typography variant="subtitle2">NOTES</Typography>
              <Typography variant="body2">
                We appreciate your business. Should you need us to add VAT or
                extra notes let us know!
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} sx={{ py: 3, textAlign: "right" }}>
              <Typography variant="subtitle2">Have a Question?</Typography>
              <Typography variant="body2">info@oe.com.pk</Typography>
            </Grid>
          </Grid> */}
        </Card>
      </Container>

      <Container>
        <Grid item xs={12} sm={6}>
          <Card>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>GRN ID </TableCell>
                  <TableCell>Supplier Name</TableCell>
                  <TableCell>Delivered On</TableCell>
                </TableRow>
              </TableHead>
              {console.log("orderDate ----->>>>", GRNItems)}
              <TableBody>
                {GRNItems.map((item, index) => {
                  if (item.purchaseOrderID === id) {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          borderBottom: (theme) =>
                            `solid 1px ${theme.palette.divider}`,
                        }}
                      >
                        <TableCell align="left">
                          <Link to={`/dashboard/suppliers/report/${item.id}`}>
                            GRN550{item.id}
                          </Link>
                        </TableCell>
                        <TableCell align="left">
                          <Box sx={{ maxWidth: 560 }}>
                            <Typography variant="subtitle2">
                              {item.supplierName}
                            </Typography>
                          </Box>
                        </TableCell>

                        <TableCell align="center">{item.orderDate}</TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </Card>
        </Grid>
      </Container>
    </Page>
  );
}

import PropTypes from "prop-types";
import sum from "lodash/sum";
import { Page, View, Text, Image, Document } from "@react-pdf/renderer";
// utils
// import Overseas from "../Overseas.jpeg";

import { fCurrency } from "src/utils/formatNumber";
//
import styles from "./InvoiceStyle";
import { Divider } from "@mui/material";

// ----------------------------------------------------------------------

InvoicePDF.propTypes = {
  deliveryNotePdf: PropTypes.object.isRequired,
};

export default function InvoicePDF({ deliveryNotePdf }) {
  const { invoice = {} } = deliveryNotePdf;
  console.log("deliveryNotePdf///<<<<", deliveryNotePdf?.id);
  const { id } = invoice;

  function splitItemsIntoPages(items) {
    const rowsPerPage = 10;
    const pages = [];

    // add first page with first two items
    const firstPageItems = items.slice(0, 2);
    pages.push({
      title: "Quotation Details (Page 1)",
      items: firstPageItems,
      startIndex: 1,
    });

    // add remaining pages
    const remainingItems = items.slice(2);
    const totalPages = Math.ceil(remainingItems.length / rowsPerPage);

    let currentIndex = 3;
    for (let i = 0; i < totalPages; i++) {
      const start = i * rowsPerPage;
      const end = start + rowsPerPage;
      const pageItems = remainingItems.slice(start, end);
      const pageNumber = i + 2; // start page numbering from 2
      const startIndex = pages[i].startIndex + pages[i].items.length;
      const page = {
        title: `Delivery Note (Page ${pageNumber})`,
        startIndex: startIndex,
        items: pageItems,
      };
      pages.push(page);
    }

    return pages;
  }
  const items = deliveryNotePdf?.items || [];
  const pages = splitItemsIntoPages(items);
  const itemsPerPage = 10;
  const totalPage = Math.ceil(items.length / itemsPerPage);
  let previousDiscount = 0;

  return (
    <Document>
      {pages.map(({ title, items, startIndex }, pageIndex) => (
        <Page size="A4" style={styles.page} key={pageIndex}>
          <View style={[styles.gridContainer, styles.bottomline]}>
            <Image
              disabledEffect
              visibleByDefault
              alt="logo"
              src="/favicon/Overseas.jpeg"
              style={{ width: 300, height: 100, marginTop: 0 }}
            />

            <View
              style={[
                { alignItems: "flex-end", flexDirection: "column" },
                styles.overline,
              ]}
            >
              <Text>
                Page {pageIndex + 1} of {pages.length}
              </Text>
              <Text style={styles.h1}>
                Delivery Note : DN500-{deliveryNotePdf?.id}
              </Text>
            </View>
          </View>

          {pageIndex === 0 && (
            <View style={[styles.gridContainer, styles.mb40]}>
              <View style={styles.col6}>
                <Text style={[styles.overline, styles.mb80]}>Buyer</Text>
                <Text style={[styles.cell01, { fontWeight: "bold" }]}>
                  {deliveryNotePdf.customer}
                </Text>

                <Text style={[styles.cell01]}>
                  {deliveryNotePdf.customerAddress}
                </Text>

                <Text style={[styles.cell01]}>
                  <Text style={[styles.boldText]}>PH :</Text>{" "}
                  {deliveryNotePdf.PhoneNumber}
                </Text>
                <Text style={[styles.mb80]}>
                  <Text style={[styles.boldText]}>STR :</Text>{" "}
                  {deliveryNotePdf.gstNum}
                </Text>
                <Text style={[styles.mb80]}>
                  <Text style={[styles.boldText]}>NTN:</Text>{" "}
                  {deliveryNotePdf.ntnNum}
                </Text>
                <Text style={[styles.cell01, { fontWeight: "bold" }]}>
                  Delivery Address
                </Text>
                <Text
                  style={[styles.body1, styles.cell01, { fontWeight: "bold" }]}
                >
                  {deliveryNotePdf.branch}
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  {deliveryNotePdf.branchAddress}
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  PH: {deliveryNotePdf.PhoneNumber}
                </Text>
              </View>
              <View style={styles.col7}>
                <View style={[styles.col10]}>
                  <Text style={[{ textAlign: "center", fontSize: 10 }]}>
                    DELIVERY NOTE
                  </Text>
                </View>
                <View style={[styles.col101]}>
                  <Text style={[styles.body1, styles.cell01]}>
                    {"\n"}
                    Number / Date
                    <Text style={[styles.body1, { fontWeight: "bold" }]}>
                      {"\n"}
                      DN500{deliveryNotePdf?.id}
                    </Text>{" "}
                    / {deliveryNotePdf.date}
                  </Text>
                  <Text style={[styles.body1, styles.cell01]}>
                    {"\n"}
                    Responsible for Order processing: {"\n"}
                    {deliveryNotePdf.salesPerson}
                  </Text>
                  <Text style={[styles.body1, styles.cell01]}>
                    {"\n"} Tel: +92 42 35922681-85 ,{" "}
                    {deliveryNotePdf.salespersonPhone}
                  </Text>
                  <Text style={[styles.body1, styles.cell01]}>
                    <Text
                      style={[styles.subtitle20, styles.cell0, styles.overline]}
                    >
                      {"\n"}
                      {"\n"}
                      STR Number :{"    "}
                    </Text>
                    12-02-9999-456-28
                  </Text>
                  <Text style={[styles.body1, styles.cell01]}>
                    <Text
                      style={[styles.subtitle20, styles.cell0, styles.overline]}
                    >
                      {"\n"} Our NTN NO :{"     "}
                    </Text>
                    27313110-7
                  </Text>
                  <Text style={[styles.body1, styles.cell01]}>
                    <Text
                      style={[styles.subtitle20, styles.cell0, styles.overline]}
                    >
                      CNIC NO. :{"          "}
                    </Text>
                    42301-7700217-7
                  </Text>
                </View>
              </View>
            </View>
          )}
          <View style={[styles.gridContainer_2]}>
            <View>
              <Text
                style={[styles.subtitle20, styles.cel1l01, styles.overline]}
              >
                Client Reference :
              </Text>
            </View>
            <View style={[styles.top]}>
              <Text
                style={[styles.subtitle20, styles.cel1l01, styles.overline]}
              >
                Shipping Details:
              </Text>
            </View>
          </View>
          <View style={[styles.gridContainer, styles.mb40]}>
            <View
              style={[styles.table0, { border: 1, borderColor: "#000000" }]}
            >
              <View style={[styles.tableRow0111]}>
                <View style={[styles.tableCell_401]}>
                  <Text>Purchase Order No.</Text>
                </View>
                <View style={[styles.tableCell_4011]}>
                  <Text>
                    {" "}
                    {"   "}
                    {deliveryNotePdf?.purchaseOrder}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.tableRow0111,
                  { borderTop: 1, borderColor: "#000000" },
                ]}
              >
                <View style={[styles.tableCell_401]}>
                  <Text> P.O. Date :</Text>
                </View>
                <View style={[styles.tableCell_4011]}>
                  <Text>
                    {"   "} {deliveryNotePdf?.purchaseOrderDate}
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={[styles.table01, { border: 1, borderColor: "#000000" }]}
            >
              <View style={[styles.tableRow01111]}>
                <View style={[styles.tableCell_401]}>
                  <Text> Delivery Note No./Date</Text>
                </View>
                <View style={[styles.tableCell_4011]}>
                  <Text>
                    {"   "} {deliveryNotePdf?.date}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.tableRow01111,
                  { borderTop: 1, borderColor: "#000000" },
                ]}
              >
                <View style={[styles.tableCell_401]}>
                  <Text> Carrier</Text>
                </View>
                <View style={[styles.tableCell_4011]}>
                  <Text>
                    {"   "} {deliveryNotePdf?.shippingCom}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.tableRow01111,
                  { borderTop: 1, borderColor: "#000000" },
                ]}
              >
                <View style={[styles.tableCell_401]}>
                  <Text> Carrier Receipt No.</Text>
                </View>
                <View style={[styles.tableCell_4011]}>
                  <Text>
                    {"   "}
                    {deliveryNotePdf?.carrierReceipt}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.tableRow01111,
                  { borderTop: 1, borderColor: "#000000" },
                ]}
              >
                <View style={[styles.tableCell_401]}>
                  <Text>T.R Date</Text>
                </View>
                <View style={[styles.tableCell_4011]}>
                  <Text>
                    {"   "}
                    {deliveryNotePdf?.trDate}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={[styles.subtotal, styles.tableContainer]}>
            <View style={[styles.tableLatest]}>
              <View style={styles.table}>
                <View style={[styles.tableHeader0]}>
                  <View style={[styles.tableCell_10]}>
                    <Text style={[styles.cell01]}>ITEM</Text>
                  </View>

                  <View style={[styles.tableCell_110, styles.alignCenter]}>
                    <Text style={[styles.cell01]}>Quantity</Text>
                  </View>
                  <View style={[styles.tableCell_10, styles.alignCenter]}>
                    <Text style={[styles.cell01]}>UOM</Text>
                  </View>
                  <View style={[styles.tableCell_30, styles.alignCenter]}>
                    <Text style={[styles.cell01]}>
                      {" "}
                      Product / Part No. Description
                    </Text>
                  </View>
                </View>
              </View>

              <View style={[styles.tableBody]}>
                {items.map((item, index) => {
                  const currentPage = Math.floor(index / itemsPerPage) + 1;
                  const isLastPage = currentPage === totalPage;
                  const tableBodyHeight = isLastPage ? "auto" : 300;

                  // Check if previous item exists and has a 3-line description
                  const previousItem = index > 0 ? items[index - 1] : null;
                  const isPreviousItemThreeLines =
                    previousItem &&
                    previousItem.longDescription &&
                    previousItem.longDescription.split("\n").length === 3;
                  const hasPreviousDiscount = items
                    .slice(0, index)
                    .some((prevItem) => prevItem.discount > 0);
                  const descriptionLines = item.longDescription
                    ? item.longDescription.split("\n").length
                    : 0;
                  const maxDescriptionLines = isPreviousItemThreeLines ? 5 : 3;
                  const maxTotalLines = isPreviousItemThreeLines ? 6 : 4;

                  let mergedDescription = item.longDescription;
                  if (hasPreviousDiscount && descriptionLines > 2) {
                    // Merge description with previous item's description
                    const previousItems = items.slice(0, index);
                    const mergedDescriptions = previousItems.map(
                      (prevItem) => prevItem.longDescription
                    );
                    mergedDescriptions.push(item.longDescription);
                    mergedDescription = mergedDescriptions.join("\n");
                  }

                  return (
                    <View
                      style={[
                        styles.tableRowLatest,
                        {
                          minHeight: maxTotalLines * 20,
                          height: tableBodyHeight,
                        },
                      ]}
                      key={index}
                    >
                      <View style={[styles.tableCell_10]}>
                        <Text style={[styles.cell01]}>
                          {startIndex + index}
                        </Text>
                      </View>
                      <View style={[styles.tableCell_110, styles.alignCenter]}>
                        <Text style={[styles.cell01]}>{item.delivery}</Text>
                      </View>
                      <View style={[styles.tableCell_10, styles.alignCenter]}>
                        <Text style={[styles.cell01]}>{item.unit}</Text>
                      </View>
                      <View style={[styles.tableCell_30]}>
                        <Text
                          style={[styles.cell01, styles.Dlong]}
                          numberOfLines={maxDescriptionLines}
                        >
                          {item.itemDescription}
                          {"\n"} {"\n"}
                          {mergedDescription}
                        </Text>
                      </View>
                    </View>
                  );
                })}

                {pageIndex === pages.length - 1 && <View></View>}
              </View>
            </View>
          </View>

          {pageIndex === pages.length - 1 && (
            <View style={styles.endPage}>
              <View style={[styles.Note4]}>
                <View style={[styles.Note10]}>
                  <Text>Packaging</Text>
                </View>
                <View style={[styles.Note102]}>
                  <View style={[styles.tableLatest10]}>
                    <View style={[styles.tableCell_4012]}>
                      <Text style={[styles.tableCell_401222]}>
                        {"      "}Quantity
                      </Text>
                    </View>

                    <View
                      style={[
                        styles.tableRow0111,
                        { borderTop: 1, borderColor: "#000000" },
                      ]}
                    >
                      <View></View>
                    </View>
                  </View>
                </View>
                <View style={[styles.Note101]}>
                  <View style={[styles.tableLatest101]}>
                    <View style={[styles.tableCell_40122]}>
                      <Text>{"      "}Type Of Packaging </Text>
                    </View>

                    <View
                      style={[
                        styles.tableRow0111,
                        { borderTop: 1, borderColor: "#000000" },
                      ]}
                    >
                      <View></View>
                    </View>
                  </View>
                </View>
              </View>

              <View style={[styles.gridContainer, styles.footer]}>
                <View style={[styles.Note]}>
                  <Text style={[styles.textStyle2]}>Note </Text>
                  <Text style={[styles.textStyle]}>
                    Good Received in good condition
                  </Text>
                </View>
                <View style={[styles.Note]}>
                  <Text style={[styles.textStyle2]}>
                    For OVERSEAS ENTERPRISES{" "}
                  </Text>
                  <Text style={[styles.textStyle]}>Signature</Text>
                </View>
              </View>
            </View>
          )}
        </Page>
      ))}
    </Document>
  );
}

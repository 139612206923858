import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
import { PATH_AUTH } from "./paths";
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";
import { QuotationInvoice } from "src/pages/dashboard/components/customers/Reports";
import SalesOrderPrint from "src/pages/dashboard/components/customers/transactions/SalesOrderPrint";
import PurchaseOrderDetails from "src/pages/dashboard/components/suppliers/transaction/PurchaseOrderDetails";
import GrnReceived from "src/pages/dashboard/components/suppliers/transaction/GrnReceived";
import GrnItemsDetailsView from "src/pages/dashboard/components/suppliers/report/GrnItemsDetailsView";
import DeliveryNote from "src/pages/dashboard/components/customers/transactions/DeliveryNote";
import DeliveryNotePrint from "src/pages/dashboard/components/customers/transactions/DeliveryNotePrint";
import CreditInvoiceProcess from "src/pages/dashboard/components/customers/transactions/CreditInvoiceProcess";
import CreditInvoicePrintView from "src/pages/dashboard/components/customers/transactions/CreditInvoicePrintView";
import CashInvoicePrintView from "src/pages/dashboard/components/customers/transactions/CashInvoicePrintView";
import {
  Quotations,
  SalesOrder,
} from "src/pages/dashboard/components/customers/transactions";
// ----------------------------------------------------------------------
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: [
        { element: <Navigate to={"auth"} replace />, index: true },
        {
          path: "auth",
          children: [
            { element: <Navigate to={"login"} replace />, index: true },
            {
              path: "login",
              element: (
                <GuestGuard>
                  <Login />
                </GuestGuard>
              ),
              // element: <Login />
            },
            // {
            //   path: 'register',
            //   // element: (
            //   //   <GuestGuard>
            //   //     <Register />
            //   //   </GuestGuard>
            //   // ),
            //   element: <Register />
            // },
            // { path: 'login-unprotected', element: <Login /> },
            // { path: 'register-unprotected', element: <Register /> },
            // { path: 'reset-password', element: <ResetPassword /> },
            // { path: 'verify', element: <VerifyCode /> },
          ],
        },
      ],
    },

    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "app", element: <GeneralApp /> },
        // { path: 'ecommerce', element: <GeneralEcommerce /> },
        // { path: 'analytics', element: <GeneralAnalytics /> },
        // { path: 'banking', element: <GeneralBanking /> },
        // { path: 'booking', element: <GeneralBooking /> },

        // {
        //   path: "e-commerce",
        //   children: [
        //     {
        //       element: <Navigate to="/dashboard/e-commerce/shop" replace />,
        //       index: true,
        //     },
        //     { path: "shop", element: <EcommerceShop /> },
        //     { path: "product/:name", element: <EcommerceProductDetails /> },
        //     { path: "list", element: <EcommerceProductList /> },
        //     { path: "product/new", element: <EcommerceProductCreate /> },
        //     { path: "product/:name/edit", element: <EcommerceProductCreate /> },
        //     { path: "checkout", element: <EcommerceCheckout /> },
        //     { path: "invoice", element: <EcommerceInvoice /> },
        //   ],
        // },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: "profile", element: <UserProfile /> },
            { path: "cards", element: <UserCards /> },
            { path: "list", element: <UserList /> },
            { path: "new", element: <UserCreate /> },
            { path: ":name/edit", element: <UserCreate /> },
            { path: "account", element: <UserAccount /> },
          ],
        },
        {
          path: "customers",
          children: [
            {
              element: (
                <Navigate to="/dashboard/customers/transactions" replace />
              ),
              index: true,
            },
            { path: "transactions", element: <Transactions /> },
            { path: "transactions/:id", element: <SalesOrderPrint /> },
            {
              path: "transactions/:id/deliveryNote",
              element: <DeliveryNote />,
            },
            { path: "edit-deliveryNote/:id", element: <DeliveryNote /> },
            {
              path: "/dashboard/customers/transactions/:id/cashInvoice",
              element: <CashInvoicePrintView />,
            },
            {
              path: "transactions/:salesOrderID/deliveryNote/:deliveryNoteId/creditInvoice",
              element: <CreditInvoiceProcess />,
            },
            {
              path: "transactions/:salesOrderID/deliveryNote/:deliveryNoteId/creditInvoice/:creditInvoiceId",
              element: <CreditInvoicePrintView />,
            },

            {
              path: "transactions/:salesOrderID/deliveryNote/:deliveryNoteId",
              element: <DeliveryNotePrint />,
            },

            // { path: "edit-quotation/:id", element: <Transactions /> },
            { path: "edit-quotation/:id", element: <Quotations /> },
            { path: "edit-salesOrder/:id", element: <SalesOrder /> },
            { path: "reports", element: <Reports /> },
            { path: "reports/:id", element: <QuotationInvoice /> },
            { path: "setup", element: <Setup /> },
          ],
        },
        {
          path: "suppliers",
          children: [
            {
              element: (
                <Navigate to="/dashboard/suppliers/transaction" replace />
              ),
              index: true,
            },
            { path: "transaction", element: <Transaction /> },
            { path: "transaction/:id", element: <PurchaseOrderDetails /> },
            { path: "transaction/:id/received", element: <GrnReceived /> },

            { path: "report", element: <Report /> },
            { path: "report/:id", element: <GrnItemsDetailsView /> },
            { path: "suppliersetup", element: <SupplierSetup /> },
          ],
        },
        {
          path: "Inventory",
          children: [
            {
              element: (
                <Navigate
                  to="/dashboard/Inventory/Inventorytransaction"
                  replace
                />
              ),
              index: true,
            },
            { path: "Inventorytransaction", element: <InventoryTransaction /> },
            { path: "Inventoryreport", element: <InventoryReport /> },
            { path: "Inventorysetup", element: <InventorySetup /> },
          ],
        },
        {
          path: "GeneralLedger",
          children: [
            {
              element: (
                <Navigate
                  to="/dashboard/GeneralLedger/Generaltransactions"
                  replace
                />
              ),
              index: true,
            },
            { path: "Generaltransactions", element: <GeneralTransactions /> },
            { path: "Generalreport", element: <GeneralReport /> },
            { path: "Generalsetup", element: <GeneralSetup /> },
          ],
        },
        {
          path: "Settings",
          children: [
            {
              element: (
                <Navigate to="/dashboard/Settings/CompanySetup" replace />
              ),
              index: true,
            },
            { path: "CompanySetup", element: <CompanySetup /> },
            { path: "Miscellaneous", element: <Miscellaneous /> },
            { path: "SettingMaintenance", element: <SettingMaintenance /> },
          ],
        },
        // {
        //   path: 'blog',
        //   children: [
        //     { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
        //     { path: 'posts', element: <BlogPosts /> },
        //     { path: 'post/:title', element: <BlogPost /> },
        //     { path: 'new-post', element: <BlogNewPost /> },
        //   ],
        // },
        {
          path: "mail",
          children: [
            {
              element: <Navigate to="/dashboard/mail/all" replace />,
              index: true,
            },
            { path: "label/:customLabel", element: <Mail /> },
            { path: "label/:customLabel/:mailId", element: <Mail /> },
            { path: ":systemLabel", element: <Mail /> },
            { path: ":systemLabel/:mailId", element: <Mail /> },
          ],
        },
        {
          path: "chat",
          children: [
            { element: <Chat />, index: true },
            { path: "new", element: <Chat /> },
            { path: ":conversationKey", element: <Chat /> },
          ],
        },
        { path: "calendar", element: <Calendar /> },
        { path: "kanban", element: <Kanban /> },
      ],
    },

    // Main Routes
    // {
    //   path: '*',
    //   element:  <LogoOnlyLayout />,
    //   children: [
    //     { path: 'coming-soon', element: <ComingSoon /> },
    //     { path: 'maintenance', element: <Maintenance /> },
    //     { path: 'pricing', element: <Pricing /> },
    //     { path: 'payment', element: <Payment /> },
    //     { path: '500', element: <Page500 /> },
    //     { path: '404', element: <NotFound /> },
    //     { path: '*', element: <Navigate to="/404" replace /> },
    //   ],
    // },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <About /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <Faqs /> },
    //   ],
    // },
    // { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));
// Dashboard
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);
const GeneralEcommerce = Loadable(
  lazy(() => import("../pages/dashboard/GeneralEcommerce"))
);
const GeneralAnalytics = Loadable(
  lazy(() => import("../pages/dashboard/GeneralAnalytics"))
);
const GeneralBanking = Loadable(
  lazy(() => import("../pages/dashboard/GeneralBanking"))
);
const GeneralBooking = Loadable(
  lazy(() => import("../pages/dashboard/GeneralBooking"))
);
const EcommerceShop = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceShop"))
);
const EcommerceProductDetails = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceProductDetails"))
);
const EcommerceProductList = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceProductList"))
);
const EcommerceProductCreate = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceProductCreate"))
);
const EcommerceCheckout = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceCheckout"))
);
const EcommerceInvoice = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceInvoice"))
);
const BlogPosts = Loadable(lazy(() => import("../pages/dashboard/BlogPosts")));
const BlogPost = Loadable(lazy(() => import("../pages/dashboard/BlogPost")));
const BlogNewPost = Loadable(
  lazy(() => import("../pages/dashboard/BlogNewPost"))
);
const UserProfile = Loadable(
  lazy(() => import("../pages/dashboard/UserProfile"))
);
const UserCards = Loadable(lazy(() => import("../pages/dashboard/UserCards")));
const UserList = Loadable(lazy(() => import("../pages/dashboard/UserList")));
const UserAccount = Loadable(
  lazy(() => import("../pages/dashboard/UserAccount"))
);
const UserCreate = Loadable(
  lazy(() => import("../pages/dashboard/UserCreate"))
);
const Chat = Loadable(lazy(() => import("../pages/dashboard/Chat")));
const Mail = Loadable(lazy(() => import("../pages/dashboard/Mail")));
const Calendar = Loadable(lazy(() => import("../pages/dashboard/Calendar")));
const Kanban = Loadable(lazy(() => import("../pages/dashboard/Kanban")));
// --------------------- customers -----------------------
const Transactions = Loadable(
  lazy(() => import("../pages/dashboard/Customers/Transactions"))
);
const Reports = Loadable(
  lazy(() => import("../pages/dashboard/Customers/Reports"))
);
const Setup = Loadable(
  lazy(() => import("../pages/dashboard/Customers/Setup"))
);
//----------------------Suppliers-------------------------
const Transaction = Loadable(
  lazy(() => import("../pages/dashboard/Suppliers/Transaction"))
);
const Report = Loadable(
  lazy(() => import("../pages/dashboard/Suppliers/Report"))
);
const SupplierSetup = Loadable(
  lazy(() => import("../pages/dashboard/Suppliers/SupplierSetup"))
);
//......................Inventory------------------------
const InventoryTransaction = Loadable(
  lazy(() => import("../pages/dashboard/Inventory/InventoryTransaction"))
);
const InventoryReport = Loadable(
  lazy(() => import("../pages/dashboard/Inventory/InventoryReport"))
);
const InventorySetup = Loadable(
  lazy(() => import("../pages/dashboard/Inventory/InventorySetup"))
);
//......................General Ledger------------------------
const GeneralTransactions = Loadable(
  lazy(() => import("../pages/dashboard/GeneralLedger/GeneralTransactions"))
);
const GeneralReport = Loadable(
  lazy(() => import("../pages/dashboard/GeneralLedger/GeneralReport"))
);
const GeneralSetup = Loadable(
  lazy(() => import("../pages/dashboard/GeneralLedger/GeneralSetup"))
);
//--------------------------Settings------------------------
const CompanySetup = Loadable(
  lazy(() => import("../pages/dashboard/Settings/CompanySetup"))
);
const Miscellaneous = Loadable(
  lazy(() => import("../pages/dashboard/Settings/Miscellaneous"))
);
const SettingMaintenance = Loadable(
  lazy(() => import("../pages/dashboard/Settings/SettingMaintenance"))
);
// Main

const HomePage = Loadable(lazy(() => import("../pages/Home")));
const About = Loadable(lazy(() => import("../pages/About")));
const Contact = Loadable(lazy(() => import("../pages/Contact")));
const Faqs = Loadable(lazy(() => import("../pages/Faqs")));
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));
const Pricing = Loadable(lazy(() => import("../pages/Pricing")));
const Payment = Loadable(lazy(() => import("../pages/Payment")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));

import { createSlice } from "@reduxjs/toolkit";
import {
  addCashInvoiceApi,
  getAllCashInvoiceApi,
  getCashInvoiceByIdApi,
  deleteCashInvoiceApi,
  updateCashInvoiceApi,
} from "src/api/customer/cashInvoiceApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  cashInvoices: [],
  singlecashInvoice: {},
};

const slice = createSlice({
  name: "cashInvoice",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addcashInvoice(state, { payload }) {
      state.cashInvoices.push(payload);
    },
    _getAllcashInvoice(state, { payload }) {
      state.cashInvoices = payload;
    },
    _getSinglecashInvoice(state, { payload }) {
      state.singlecashInvoice = payload;
    },
    _deletecashInvoice(state, { payload }) {
      const index = state.cashInvoices.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.cashInvoices.splice(index, 1);
      }
    },
    _updatecashInvoice(state, { payload }) {
      const index = state.cashInvoices.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.newCustomers[index] = payload;
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addcashInvoice,
  _getAllcashInvoice,
  _getSinglecashInvoice,
  _deletecashInvoice,
  _updatecashInvoice,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addCashInvoice = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addCashInvoiceApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, newcashinvoice } = response.data;
    if (status === "success") {
      dispatch(_addcashInvoice(newcashinvoice));
      return newcashinvoice.id;
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllCashInvoices = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllCashInvoiceApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, newcashinvoices } = response.data;
    if (status === "success") {
      dispatch(_getAllcashInvoice(newcashinvoices));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const deleteCashInvoices = (id) => async (dispatch) => {
  try {
    const response = await deleteCashInvoiceApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deletecashInvoice(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateCashInvoices = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateCashInvoiceApi(data);
    const { status, newcashinvoices } = response.data;
    if (status === "success") {
      dispatch(_updatecashInvoice(newcashinvoices));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const getCashInvoiceById = (id) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getCashInvoiceByIdApi(id);
    console.log("get getCashInvoiceByIdApi====", response);
    const { status, cashinvoice } = response.data;
    if (status === "success") {
      dispatch(_getSinglecashInvoice(cashinvoice));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get _getSinglecashInvoice response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    console.log(error);
    // dispatch(hasError(error));
  }
};

import { createSlice } from "@reduxjs/toolkit";
import {
  addCurrencyItemsApi,
  getAllCurrencyItemsApi,
  deleteCurrencyItemsApi,
  updateCurrencyItemsApi,
} from "src/api/generalledger/currencyItemApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  currencyItems: [],
};

const slice = createSlice({
  name: "currencyItem",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addCurrencyItem(state, { payload }) {
      state.currencyItems.push(payload);
    },
    _getAllCurrencyItem(state, { payload }) {
      state.currencyItems = payload;
    },
    _deleteCurrencyItem(state, { payload }) {
      const index = state.currencyItems.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.currencyItems.splice(index, 1);
      }
    },
    _updateCurrencyItem(state, { payload }) {
      const index = state.currencyItems.findIndex(
        (currency) => currency._id === payload._id
      );
      if (index !== -1) {
        state.currencyItems[index] = payload;
      }
    },

    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addCurrencyItem,
  _getAllCurrencyItem,
  _deleteCurrencyItem,
  _updateCurrencyItem,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addCurrencyItem = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addCurrencyItemsApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, currencyitem } = response.data;
    if (status === "success") {
      dispatch(_addCurrencyItem(currencyitem));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllCurrencyItems = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllCurrencyItemsApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, currencyitems } = response.data;
    if (status === "success") {
      dispatch(_getAllCurrencyItem(currencyitems));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};
export const updateCurrencyItems = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateCurrencyItemsApi(data);
    const { status, currencyitems } = response.data;
    if (status === "success") {
      dispatch(_updateCurrencyItem(currencyitems));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};

export const deleteCurrencyItems = (id) => async (dispatch) => {
  try {
    const response = await deleteCurrencyItemsApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteCurrencyItem(id));
    }
  } catch (error) {
    console.log(error);
  }
};

import { get, post, deleteReq, put } from "../../../src/services/restService";

export const addReceiptVoucherApi = (data) => {
  return post("receipt_voucher/add", data);
};
export const getAllReceiptVoucherApi = () => {
  return get("receipt_voucher/getAll");
};
export const getReceiptVoucherByIdApi = (id) => {
  return get(`receipt_voucher/getById/${id}`);
};

export const deleteReceiptVoucherApi = (id) => {
  return deleteReq(`receipt_voucher/delete/${id}`);
};

export const updateReceiptVoucherApi = (data) => {
  return put(`receipt_voucher/update/${data._id}`, data);
};

import { createSlice } from "@reduxjs/toolkit";
import { addgstDataApi, getAllgstDataApi,deletegstDataApi ,updategstDataApi} from "src/api/setting/gstDataApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  gstDatas: [],
};

const slice = createSlice({
  name: "gstData",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addgstData(state, { payload }) {
      state.gstDatas.push(payload);
    },
    _getAllgstdata(state, { payload }) {
      state.gstDatas = payload;
    },
    _updategstdata(state, { payload }) {
      const index = state.gstDatas.findIndex(
        (term) => term._id === payload._id
      );
      if (index !== -1) {
        state.itemTaxs[index] = payload;
      }
    },
    _deletegstdata(state, { payload }) {
      const index = state.gstDatas.findIndex((item) => item._id === payload);
      if (index !== -1) {
        state.gstDatas.splice(index, 1);
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addgstData,
  _getAllgstdata,
  _updategstdata,
  _deletegstdata,
  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addGstData = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addgstDataApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, gstData } = response.data;
    if (status === "success") {
      dispatch(_addgstData(gstData));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllGstDatas = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllgstDataApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, gstDatas } = response.data;
    if (status === "success") {
      dispatch(_getAllgstdata(gstDatas));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};
export const updateGstDatas  = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updategstDataApi(data);
    const { status, gstDatas } = response.data;
    if (status === "success") {
      dispatch(_updategstdata(gstDatas));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const deleteGstDatas = (id) => async (dispatch) => {
  try {
    const response = await deletegstDataApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deletegstdata(id));
    }
  } catch (error) {
    console.log(error);
  }
};
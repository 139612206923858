import { createSlice } from "@reduxjs/toolkit";
import {
  additemTaxApi,
  getAllitemTaxApi,
  deleteitemTaxApi,
  updateitemTaxApi,
} from "src/api/setting/itemTaxApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  itemTaxs: [],
};

const slice = createSlice({
  name: "itemTax",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addItemTax(state, { payload }) {
      state.itemTaxs.push(payload);
    },
    _getAllItemTax(state, { payload }) {
      state.itemTaxs = payload;
    },
    _updateItemTax(state, { payload }) {
      const index = state.itemTaxs.findIndex(
        (term) => term._id === payload._id
      );
      if (index !== -1) {
        state.itemTaxs[index] = payload;
      }
    },
    _deleteItemTax(state, { payload }) {
      const index = state.itemTaxs.findIndex((item) => item._id === payload);
      if (index !== -1) {
        state.itemTaxs.splice(index, 1);
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addItemTax,
  _getAllItemTax,
  _updateItemTax,
  _deleteItemTax,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addItemTax = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await additemTaxApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, itemtax } = response.data;
    if (status === "success") {
      dispatch(_addItemTax(itemtax));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllItemTaxs = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllitemTaxApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, itemtaxs } = response.data;
    if (status === "success") {
      dispatch(_getAllItemTax(itemtaxs));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};
export const updateItemTaxs = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateitemTaxApi(data);
    const { status, itemtax } = response.data;
    if (status === "success") {
      dispatch(_updateItemTax(itemtax));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const deleteItemTaxs = (id) => async (dispatch) => {
  try {
    const response = await deleteitemTaxApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteItemTax(id));
    }
  } catch (error) {
    console.log(error);
  }
};

import { get, post, deleteReq, put } from "src/services/restService";

export const addInventoryMovementApi = (data) => {
  return post("inventory_movement/add", data);
};
export const getAllInventoryMovementApi = () => {
  return get("inventory_movement/getAll");
};
export const deleteInventoryMovementApi = (id) => {
  return deleteReq(`inventory_movement/delete/${id}`);
};

export const updateInventoryMovementApi = ( data) => {
  return put(`inventory_movement/update/${data._id}`, data);
};

import { get, post, deleteReq, put } from "src/services/restService";

export const addExchangeRateApi = (data) => {
  return post("exchange_rate/add", data);
};
export const getAllExchangeRateApi = () => {
  return get("exchange_rate/getAll");
};
export const deleteExchangeRateApi = (id) => {
  return deleteReq(`exchange_rate/delete/${id}`);
};

export const updateExchangeRateApi = (data) => {
  return put(`exchange_rate/update/${data._id}`, data);
};

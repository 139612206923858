import PropTypes from "prop-types";
import sum from "lodash/sum";
import { Page, View, Text, Image, Document } from "@react-pdf/renderer";
// utils
// import Overseas from "../Overseas.jpeg";

import { fCurrency } from "src/utils/formatNumber";
//
import styles from "./InvoiceStyle";

// ----------------------------------------------------------------------

InvoicePDF.propTypes = {
  saleOrderPdf: PropTypes.object.isRequired,
};

export default function InvoicePDF({ saleOrderPdf }) {
  const { invoice = {} } = saleOrderPdf;
  console.log("saleOrderPdf///<<<<", saleOrderPdf?.id);
  console.log("invoice///<<<", invoice);
  const { id } = invoice;

  function splitItemsIntoPages(items) {
    const rowsPerPage = 10;
    const pages = [];

    // add first page with first two items
    const firstPageItems = items.slice(0, 2);
    pages.push({
      title: "Sales Order  (Page 1)",
      items: firstPageItems,
      startIndex: 1,
    });

    // add remaining pages
    const remainingItems = items.slice(2);
    const totalPages = Math.ceil(remainingItems.length / rowsPerPage);
    let currentIndex = 3;
    for (let i = 0; i < totalPages; i++) {
      const start = i * rowsPerPage;
      const end = start + rowsPerPage;
      const pageItems = remainingItems.slice(start, end);
      const pageNumber = i + 2; // start page numbering from 2
      const startIndex = pages[i].startIndex + pages[i].items.length;
      const page = {
        title: `Sales Order (Page ${pageNumber})`,
        startIndex: startIndex,
        items: pageItems,
      };
      pages.push(page);
    }

    return pages;
  }
  const items = saleOrderPdf.salesOrderDetails?.items || [];
  const pages = splitItemsIntoPages(items);
  // Set the desired font size
  const lineHeight = 2;
  return (
    <Document>
      {pages.map(({ title, items, startIndex }, pageIndex) => (
        <Page size="A4" style={styles.page}>
          {/* {pageIndex === 0 && ( */}
          <View style={[styles.gridContainer]}>
            <Image
              disabledEffect
              visibleByDefault
              alt="logo"
              src="/favicon/Overseas.jpeg"
              style={{ width: 150, height: 100, marginTop: 5 }}
            />
            <View
              style={[
                { alignItems: "flex-end", flexDirection: "column" },
                styles.overline,
              ]}
            >
              <Text>
                Page {pageIndex + 1} of {pages.length}
              </Text>
              <Text style={styles.h1}>
                SALES ORDER : SO500-{saleOrderPdf?.id}
              </Text>
            </View>
          </View>
          {/* )} */}
          {pageIndex === 0 && (
            <View style={[styles.gridContainer, styles.mb40]}>
              <View style={styles.col6}>
                <Text style={[styles.overline, styles.mb80]}>
                  Sales Order From
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  Overseas Enterprises (Automation & Control Division)
                </Text>

                <Text style={[styles.body1, styles.cell01]}>
                  Plot No. 28, Street No. 01, 18th KM Ferozepur Road,{" "}
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  Usman Colony, Lahore, Punjab 54600, Pakistan
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  Ph: +92 42 3718 6381-85,0300-8245084-94
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  Fax : +92 42 3718 6380
                </Text>
                <Text style={[styles.body1, styles.mb80]}>
                  Email : inquiry@oe.com.pk, Web : www.oe.com.pk
                </Text>
              </View>
              <View style={styles.col6}>
                <Text style={[styles.overline, styles.mb80]}> To</Text>
                <Text style={[styles.body1, styles.cell01]}>
                  {" "}
                  {/* <Text style={[styles.bold, styles.overline, styles.mb8]}>
                    Customer:
                  </Text> */}
                  {saleOrderPdf.customer}
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  {" "}
                  {/* <Text style={[styles.bold, styles.overline, styles.mb8]}>
                    Address:
                  </Text> */}
                  {saleOrderPdf.address}
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  {/* <Text style={[styles.bold, styles.overline, styles.mb8]}>
                    Phone:
                  </Text> */}
                  {saleOrderPdf.PhoneNumber}
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  <Text
                    style={[styles.subtitle20, styles.cell0, styles.overline]}
                  >
                    ATTN:
                  </Text>
                  {saleOrderPdf.atn}
                </Text>
              </View>
            </View>
          )}
          <View style={styles.table0}>
            <View style={[styles.tableHeader0, styles.tableRow0]}>
              <View style={[styles.tableCell_100, styles.border0]}>
                <Text style={[styles.subtitle20, styles.cell0]}>
                  Customer's Reference
                </Text>
              </View>
              <View
                style={[
                  styles.tableCell_70,
                  styles.alignCenter0,
                  styles.border0,
                ]}
              >
                <Text style={[styles.subtitle20, styles.cell0]}>
                  Sales Person
                </Text>
              </View>
              <View style={[styles.tableCell_100, styles.border0]}>
                <Text style={[styles.subtitle20, styles.cell0]}>
                  {" "}
                  Your Gst No.
                </Text>
              </View>
              <View style={[styles.tableCell_100, styles.border0]}>
                <Text style={[styles.subtitle20, styles.cell0]}>
                  Our Order No.
                </Text>
              </View>
              <View style={[styles.tableCell_1100, styles.alignRight0]}>
                <Text style={[styles.subtitle20, styles.cell0]}>
                  Delivery Date.
                </Text>
              </View>
            </View>
            <View style={[styles.tableRow0]}>
              <View style={[styles.tableCell_100, styles.border0]}>
                <Text style={[styles.subtitle20, styles.cell0]}>
                  {saleOrderPdf?.customerReference}
                </Text>
              </View>
              <View
                style={[
                  styles.tableCell_70,
                  styles.alignCenter0,
                  styles.border0,
                ]}
              >
                <Text style={[styles.subtitle20, styles.cell0]}>
                  {saleOrderPdf?.salesPerson}
                </Text>
              </View>
              <View style={[styles.tableCell_100, styles.border0]}>
                {saleOrderPdf.taxCost !== 0 && (
                  <Text style={[styles.subtitle20, styles.cell0]}>
                    {" "}
                    {saleOrderPdf?.gstNum}
                  </Text>
                )}
              </View>
              <View style={[styles.tableCell_100, styles.border0]}>
                <Text style={[styles.subtitle20, styles.cell0]}>
                  SO500-{saleOrderPdf?.id}
                </Text>
              </View>
              <View style={[styles.tableCell_100, styles.alignRight0]}>
                <Text style={[styles.subtitle20, styles.cell0]}>
                  {" "}
                  {saleOrderPdf?.deliveryDate}
                </Text>
              </View>
            </View>

            <View style={styles.tableRow0}>
              <View>
                <Text style={[styles.subtitle20, styles.cell0]}>
                  <b> PAYMENT TERMS : {saleOrderPdf?.payment}</b>
                </Text>
              </View>
            </View>
          </View>
          {pageIndex === 0 && (
            <View>
              <Text style={[styles.overline, styles.mb8]}>
                SALES ORDER DETAILS
              </Text>
            </View>
          )}
          {/* <View style={[styles.table0, styles.row]}> */}
          <View style={[styles.subtotal]}>
            <View style={styles.table}>
              <View style={[styles.tableHeader0]}>
                <View style={[styles.tableCell_10]}>
                  <Text style={[styles.cell01]}>ITEM</Text>
                </View>
                <View style={[styles.tableCell_30, styles.alignCenter]}>
                  <Text style={[styles.cell01]}>Item Code /Description</Text>
                </View>
                <View style={[styles.tableCell_10, styles.alignCenter]}>
                  <Text style={[styles.cell01]}>Qty</Text>
                </View>
                <View style={[styles.tableCell_110, styles.alignCenter]}>
                  <Text style={[styles.cell01]}>Unit price</Text>
                </View>
                <View style={[styles.tableCell_10, styles.alignCenter]}>
                  <Text style={[styles.cell01]}>Discount %</Text>
                </View>
                <View style={[styles.tableCell_50, styles.alignRight]}>
                  <Text style={[styles.cell01]}>Total</Text>
                </View>
              </View>
            </View>

            <View style={[styles.tableBody]}>
              {items.map((item, index) => (
                <View
                  style={[styles.tableRow0, { minHeight: 3 * 20 }]}
                  key={index}
                >
                  <View style={[styles.tableCell_10]}>
                    <Text style={[styles.cell01]}>{startIndex + index}</Text>
                  </View>
                  <View style={[styles.tableCell_30, styles.alignCenter]}>
                    <Text style={[styles.cell01]}> {item.itemDescription}</Text>
                  </View>
                  <View style={[styles.tableCell_10, styles.alignCenter]}>
                    <Text style={[styles.cell01]}>{item.quantity}</Text>
                  </View>
                  <View style={[styles.tableCell_110, styles.alignCenter]}>
                    <Text style={[styles.cell01]}> {item.priceBeforeTax}</Text>
                  </View>
                  <View style={[styles.tableCell_10, styles.alignCenter]}>
                    <Text style={[styles.cell01]}> {item.discount}</Text>
                  </View>
                  <View style={[styles.tableCell_50, styles.alignRight]}>
                    <Text style={[styles.cell01]}>{item.total}</Text>
                  </View>
                </View>
              ))}
            </View>
            {pageIndex === pages.length - 1 && (
              <View
                style={{
                  marginTop: items.length === 2 ? 150 : 200,
                  borderTopWidth: 1,
                  borderTopColor: "#C1C0B9",
                }}
              >
                <View style={styles.tableRow000}>
                  <View style={[styles.tableCell_10s]}>
                    <Text style={[styles.cell01]}></Text>
                  </View>
                  <View
                    style={[styles.tableCell_30, styles.alignCenter]}
                  ></View>
                  <View style={[styles.tableCell_10s]}>
                    <Text style={[styles.cell01]}> SUB TOTAL</Text>
                  </View>
                  <View style={[styles.tableCell_110, styles.alignCenter]}>
                    <Text style={[styles.cell01]}></Text>
                  </View>
                  <View
                    style={[styles.tableCell_101, styles.alignCenter]}
                  ></View>
                  <View style={[styles.tableCell_50, styles.alignRight]}>
                    <Text style={[styles.cell01]}>
                      {saleOrderPdf.salesOrderDetails?.total ?? "N/A"}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow000}>
                  <View style={[styles.tableCell_10s]}>
                    <Text style={[styles.cell01]}></Text>
                  </View>
                  <View
                    style={[styles.tableCell_30, styles.alignCenter]}
                  ></View>
                  <View style={[styles.tableCell_10s]}>
                    <Text style={[styles.cell01]}>SHIPPING </Text>
                  </View>
                  <View style={[styles.tableCell_110]}>
                    <Text style={[styles.cell01]}> </Text>
                  </View>
                  <View
                    style={[styles.tableCell_101, styles.alignCenter]}
                  ></View>
                  <View style={[styles.tableCell_50, styles.alignRight]}>
                    <Text style={[styles.cell01]}>
                      {saleOrderPdf.shippingCost}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow000}>
                  <View style={[styles.tableCell_10s]}>
                    <Text style={[styles.cell01]}></Text>
                  </View>
                  <View
                    style={[styles.tableCell_30, styles.alignCenter]}
                  ></View>
                  <View style={[styles.tableCell_10s]}>
                    <Text style={[styles.cell01]}> TAX</Text>
                  </View>
                  <View style={[styles.tableCell_110, styles.alignCenter]}>
                    {saleOrderPdf.taxCost !== 0 && (
                      <Text style={[styles.cell01]}>
                        {" "}
                        {saleOrderPdf.taxGroup}
                      </Text>
                    )}
                  </View>
                  <View
                    style={[styles.tableCell_101, styles.alignCenter]}
                  ></View>
                  <View style={[styles.tableCell_50, styles.alignRight]}>
                    <Text style={[styles.cell01]}>{saleOrderPdf.taxCost}</Text>
                  </View>
                </View>

                <View style={styles.tableRow000}>
                  <View style={[styles.tableCell_10s]}>
                    <Text style={[styles.cell01]}></Text>
                  </View>
                  <View
                    style={[styles.tableCell_30, styles.alignCenter]}
                  ></View>
                  <View style={[styles.tableCell_10s]}>
                    <Text style={[styles.cell01]}>
                      {" "}
                      <b> TOTAL AMOUNT</b>
                    </Text>
                  </View>
                  <View style={[styles.tableCell_110, styles.alignCenter]}>
                    <Text style={[styles.cell01]}></Text>
                  </View>
                  <View
                    style={[styles.tableCell_101, styles.alignCenter]}
                  ></View>
                  <View style={[styles.tableCell_50, styles.alignRight]}>
                    <Text style={[styles.cell01]}>{saleOrderPdf.afterTax}</Text>
                  </View>
                </View>
              </View>
            )}
          </View>
          {/* {pageIndex === pages.length - 1 && ( */}
          {/* <View style={styles.endPage}>
            <View style={[styles.gridContainer, styles.footer]}>
              <View style={styles.col8}>
                <Text style={[styles.subtitle2, styles.bor]}>Principal</Text>
                <Text> </Text>
                <Text style={[styles.bor, styles.space3, styles.cell01]}>
                  {" "}
                  WE HOPE YOU WILL FIND OUR QUOTATION SATISFACTORY IN ALL
                  RESPECT, AND LOOK TO RECEIVING YOUR VALUABLE ORDER.
                </Text>
              </View>
              <View style={styles.col6}>
                <Text
                  style={[styles.subtitle2, styles.bor, styles.alignCenter]}
                >
                  Have a Question?
                </Text>
                <Text style={[styles.alignCenter]}> inquiry@oe.com.pk</Text>
                <Text></Text>
              </View>
              <View style={[styles.col8, styles.alignCenter]}>
                <Text style={[styles.subtitle2, styles.bor]}>
                  Yours sincerely
                </Text>
                <Text>
                  For OVERSEAS ENTERPRISES{" "}
                  <Text style={[styles.bolds]}>
                    :{saleOrderPdf.salesPerson}
                  </Text>
                </Text>
                <Text style={[styles.bor, styles.space3, styles.cell01]}>
                  THIS SALES ORDER IS VALID FOR THE PERIOD INDICATED AND SUBJECT
                  TO THE TERMS AND CONDITIONS ON REVERSE.
                </Text>
              </View>
            </View>
          </View> */}
          {/* )} */}
        </Page>
      ))}
    </Document>
  );
}

import { get, post, deleteReq, put } from "../../../src/services/restService";

export const addQuotationApi = (data) => {
  return post("quoatation_item/add", data);
};
export const getAllQuotationApi = () => {
  return get("quoatation_item/getAll");
};
export const getQuotationByIdApi = (id) => {
  return get(`quoatation_item/getById/${id}`);
};

export const deleteQuotationApi = (id) => {
  return deleteReq(`quoatation_item/delete/${id}`);
};

export const updateQuotationApi = (data) => {
  return put(`quoatation_item/update/${data._id}`, data);
};

import { get, post, deleteReq, put } from "../../../src/services/restService";

export const addSalesOrderApi = (data) => {
  return post("sale_order/add", data);
};
export const getAllSalesOrderApi = () => {
  return get("sale_order/getAll");
};
export const getSalesOrderByIdApi = (id) => {
  return get(`sale_order/getById/${id}`);
};

export const deleteSalesOrderApi = (id) => {
  return deleteReq(`sale_order/delete/${id}`);
};

export const updateSalesOrderApi = (data) => {
  return put(`sale_order/update/${data._id}`, data);
};

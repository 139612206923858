import { createSlice } from "@reduxjs/toolkit";
import {
  addSalesReturnsApi,
  getAllSalesReturnsApi,
  getSalesReturnsByIdApi,
  deleteSalesReturnsApi,
  updateSalesReturnsApi,
} from "src/api/customer/salesReturnApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  salesReturns: [],
  singleSalesReturn: {},
};

const slice = createSlice({
  name: "salesReturn",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addSalesReutrn(state, { payload }) {
      state.salesReturns.push(payload);
    },
    _getAllSalesReutrn(state, { payload }) {
      state.salesReturns = payload;
    },
    _getSingleSalesReutrn(state, { payload }) {
      state.singleSalesReturn = payload;
    },
    _deleteSalesReutrn(state, { payload }) {
      const index = state.salesReturns.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.salesReturns.splice(index, 1);
      }
    },
    _updateSalesReutrn(state, { payload }) {
      const index = state.salesReturns.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.newCustomers[index] = payload;
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addSalesReutrn,
  _getAllSalesReutrn,
  _getSingleSalesReutrn,
  _deleteSalesReutrn,
  _updateSalesReutrn,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addSalesReturn = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addSalesReturnsApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, newsalesReturn } = response.data;
    if (status === "success") {
      dispatch(_addSalesReutrn(newsalesReturn));
      return newsalesReturn.id;
    }
    // dispatch(_addSalesPerso(response))
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllSalesReturns = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllSalesReturnsApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, newsalesReturns } = response.data;
    if (status === "success") {
      dispatch(_getAllSalesReutrn(newsalesReturns));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const deleteSalesReturns = (id) => async (dispatch) => {
  try {
    const response = await deleteSalesReturnsApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteSalesReutrn(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateSalesReturns = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateSalesReturnsApi(data);
    const { status, newsalesReturns } = response.data;
    if (status === "success") {
      dispatch(_updateSalesReutrn(newsalesReturns));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const getSalesReturnnById = (id) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getSalesReturnsByIdApi(id);
    console.log("get getQuotationByIdApi====", response);
    const { status, newsalesReturns } = response.data;
    if (status === "success") {
      dispatch(_getSingleSalesReutrn(newsalesReturns));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    console.log(error);
    // dispatch(hasError(error));
  }
};

import sum from "lodash/sum";
// @mui
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Card,
  Table,
  Divider,
  TableRow,
  Container,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "src/routes/paths";

import useSettings from "src/hooks/useSettings";
// components
import Page from "src/components/Page";
import Label from "src/components/Label";
import Image from "src/components/Image";
import Scrollbar from "src/components/Scrollbar";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// sections
import {
  getDeliveryNoteById,

} from "src/redux/slices/customer/deliveryNote";
import InvoiceToolbar from "src/pages/dashboard/components/customers/transactions/DNPrint/InvoiceToolbar";

const RowResultStyle = styled(TableRow)(({ theme }) => ({
  "& td": {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

// import "./styles.css"

export default function DeliveryNotePrint() {
  const { id, deliveryNoteId } = useParams();
  const deliveryNote = useSelector((state) => state.deliveryNote);
  const { singleDelivery } = deliveryNote;
  console.log("salesOrders>>>> ", singleDelivery);
  const { themeStretch } = useSettings();
  const [singledeliveryNoteObj, setsingledeliveryNoteObj] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    getDeliveryNotes();
  }, []);

  useEffect(() => {
    setsingledeliveryNoteObj(singleDelivery);
  }, [singleDelivery]);

  const getDeliveryNotes = async () => {
    await dispatch(getDeliveryNoteById(deliveryNoteId));
  };

  console.log("ID:<<<", deliveryNoteId);
  console.log("Customer:<<<", singledeliveryNoteObj.customer);
  return (
    <Page title="customers: SalesOrder">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="Delivery Note Details"
          links={[
            { key: "dashboard", href: PATH_DASHBOARD.root },
            { key: "customers", href: PATH_DASHBOARD.customers.root },
          ]}
        />

        <InvoiceToolbar singledeliveryNoteObj={singledeliveryNoteObj} />

        <Card sx={{ pt: 5, px: 5 }}>
          <Grid container>
            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Image
                disabledEffect
                visibleByDefault
                alt="logo"
                src="/favicon/Overseas.jpeg"
                sx={{ maxWidth: 200 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Box sx={{ textAlign: { sm: "right" } }}>
                <Label
                  color="success"
                  sx={{ textTransform: "uppercase", mb: 1 }}
                >
                  {singledeliveryNoteObj &&
                    singledeliveryNoteObj.length > 0 &&
                    singledeliveryNoteObj[0].customer}
                  {console.log(
                    "single che>>>>",
                    singledeliveryNoteObj.customer
                  )}
                </Label>
                <Typography variant="h6">DN500{deliveryNoteId}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Typography
                paragraph
                variant="overline"
                sx={{ color: "text.disabled" }}
              >
                <h1>Delivery Note From</h1>
              </Typography>
              <Typography variant="body1">
                <b> Customer Details </b>
              </Typography>
              <Typography variant="body2">
                Customer Name :<b> {singledeliveryNoteObj.customer}</b>
                <br />
                Deliver To Branch : <b>{singledeliveryNoteObj.branch}</b>
              </Typography>
              <Typography variant="body2">
                Ph: +92 42 3718 6381-85,0300-8245084-94
              </Typography>
              <Typography variant="body2">
                <b> KARACHI OFFICE</b>
              </Typography>
              <Typography variant="body2">
                Plot # RS-7, ST-13, Sector 31-B, K.D.A.,
                <br />
                Employee Society, Korangi Township, Korangi
                <br />
                Karachi-74900 Pakistan
              </Typography>
              <Typography variant="body2">
                Ph: +92 21 35159011-12 FAX : +92-21-35159016
              </Typography>
              <Typography variant="body2">
                Email : inquiry@oe.com.pk, Web : www.oe.com.pk
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Typography
                paragraph
                variant="overline"
                sx={{ color: "text.disabled" }}
              ></Typography>
              <Typography variant="body1">
                <b> {singledeliveryNoteObj.customer}</b>
              </Typography>
              <Typography variant="body2">
                {singledeliveryNoteObj.address}
              </Typography>
              <Typography variant="body2">
                Phone: {singledeliveryNoteObj?.PhoneNumber}
              </Typography>
              <Typography variant="body1">
                ATTN:<b> {singledeliveryNoteObj.atn}</b>
              </Typography>
            </Grid>
          </Grid>

          <TableContainer sx={{ minWidth: 960 }}>
            <Table
            // sx={{
            //   border: (theme) => `solid 2px ${theme.palette.divider}`,
            //   "& th": { backgroundColor: "transparent" },
            // }}
            >
              <TableHead
                sx={{
                  border: 2,
                  borderColor: "grey.500",
                  backgroundColor: "orange",
                }}
              >
                <TableRow sx={{ fontWeight: "Bold" }}>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    width={40}
                  >
                    Customer's Reference
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    Sales Person
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    Your Gst No
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    Our Order No
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    Delivery Date
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    {singledeliveryNoteObj.customerReference}
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    {singledeliveryNoteObj.salesPerson}
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    {singledeliveryNoteObj.gstNum}
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    {singledeliveryNoteObj.payment}
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    {singledeliveryNoteObj.deliveryDate}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={2}
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    DELIVERY : {singledeliveryNoteObj.deliveryTerms}
                  </TableCell>
                  {/* <TableCell
                    colSpan={2}
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    G.S.T # 12-02-9999-456-28 <br /> NTN # 2731310-7
                    <br />
                    CNIC # 42301-7700217-7
                  </TableCell> */}
                  {/* <TableCell
                    colSpan={2}
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    Currency : {singlesalesOrderObj.currency}
                  </TableCell> */}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 960 }}>
              <Table>
                <TableHead
                  sx={{
                    borderBottom: (theme) =>
                      `solid 1px ${theme.palette.divider}`,
                    "& th": { backgroundColor: "transparent" },
                  }}
                >
                  <TableRow>
                    <TableCell width={40}>ITEM</TableCell>
                    <TableCell align="left">DESCRIPTION </TableCell>
                    <TableCell align="left">QTY</TableCell>
                    <TableCell align="right">UNIT PRICE</TableCell>
                    <TableCell align="right">DISCOUNT</TableCell>
                    <TableCell align="right">TOTAL</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {singledeliveryNoteObj.salesOrderDetails?.items.map(
                    (item, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          borderBottom: (theme) =>
                            `solid 1px ${theme.palette.divider}`,
                        }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell align="left">
                          <Box sx={{ maxWidth: 560 }}>
                            <Typography variant="subtitle2">
                              {item.itemDescription}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">{item.quantity}</TableCell>
                        <TableCell align="center">
                          {item.priceBeforeTax}
                        </TableCell>

                        <TableCell align="center">{item.discount}%</TableCell>
                        <TableCell align="right">{item.total}</TableCell>
                      </TableRow>
                    )
                  )}

                  <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Box sx={{ mt: 2 }} />
                      <Typography>Subtotal</Typography>
                    </TableCell>
                    <TableCell align="right" width={120}>
                      <Box sx={{ mt: 2 }} />
                      <Typography>
                        {" "}
                        {console.log("single quotation", singledeliveryNoteObj)}
                        {singledeliveryNoteObj.salesOrderDetails?.total}
                      </Typography>
                    </TableCell>
                  </RowResultStyle>
                  <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Typography>Shipping </Typography>
                    </TableCell>
                    <TableCell align="right" width={120}>
                      <Typography sx={{ color: "error.main" }}>
                        {singledeliveryNoteObj.shippingCost}
                      </Typography>
                    </TableCell>
                  </RowResultStyle>
                  <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Typography>Taxes</Typography>
                    </TableCell>
                    <TableCell align="right" width={120}>
                      <Typography>{singledeliveryNoteObj.taxCost}</Typography>
                    </TableCell>
                  </RowResultStyle>
                  <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Typography variant="h6">Total</Typography>
                    </TableCell>
                    <TableCell align="right" width={140}>
                      <Typography variant="h6">
                        {singledeliveryNoteObj.afterTax}
                      </Typography>
                    </TableCell>
                  </RowResultStyle>
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Divider sx={{ mt: 5 }} />

          {/* <Grid container>
            <Grid item xs={12} md={9} sx={{ py: 3 }}>
              <Typography variant="subtitle2">NOTES</Typography>
              <Typography variant="body2">
                We appreciate your business. Should you need us to add VAT or
                extra notes let us know!
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} sx={{ py: 3, textAlign: "right" }}>
              <Typography variant="subtitle2">Have a Question?</Typography>
              <Typography variant="body2">info@oe.com.pk</Typography>
            </Grid>
          </Grid> */}
        </Card>
      </Container>
    </Page>
  );
}

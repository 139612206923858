import { createSlice } from "@reduxjs/toolkit";
import {
  addPaymentVoucherApi ,
  getAllPaymentVoucherApi ,
  getPaymentVoucherByIdApi ,
  deletePaymentVoucherApi ,
  updatePaymentVoucherApi ,
} from "src/api/generalledger/paymentVoucherApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  paymentVouchers: [],
  singlePaymentVoucher: {},
};

const slice = createSlice({
  name: "paymentVoucher",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addPaymentVoucher(state, { payload }) {
      state.paymentVouchers.push(payload);
    },
    _getAllPaymentVoucher(state, { payload }) {
      state.paymentVouchers = payload;
    },
    _getSinglePaymentVoucher(state, { payload }) {
      state.singlePaymentVoucher = payload;
    },
    _deletePaymentVoucher(state, { payload }) {
      const index = state.paymentVouchers.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.paymentVouchers.splice(index, 1);
      }
    },
    _updatePaymentVoucher(state, { payload }) {
      const index = state.paymentVouchers.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.newCustomers[index] = payload;
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addPaymentVoucher,
  _getAllPaymentVoucher,
  _getSinglePaymentVoucher,
  _deletePaymentVoucher,
  _updatePaymentVoucher,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addPaymentVoucher = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addPaymentVoucherApi (data);
    const { status, newpaymentVoucher } = response.data;
    if (status === "success") {
      dispatch(_addPaymentVoucher(newpaymentVoucher));
      return newpaymentVoucher.id; // Return the ID value
    }
  } catch (error) {}
};

export const getAllPaymentVouchers = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getAllPaymentVoucherApi ();

    const { status, newpaymentVouchers } = response.data;
    if (status === "success") {
      dispatch(_getAllPaymentVoucher(newpaymentVouchers));
    }
  } catch (error) {}
};

export const deletePaymentVouchers = (id) => async (dispatch) => {
  try {
    const response = await deletePaymentVoucherApi (id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deletePaymentVoucher(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updatePaymentVouchers = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updatePaymentVoucherApi (data);
    const { status, newpaymentVouchers } = response.data;
    if (status === "success") {
      dispatch(_updatePaymentVoucher(newpaymentVouchers));
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const getPaymentVoucherById = (id) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getPaymentVoucherByIdApi (id);

    const { status, paymentVoucher } = response.data;
    if (status === "success") {
      dispatch(_getSinglePaymentVoucher(paymentVoucher));
    }
  } catch (error) {
    console.log(error);
    // dispatch(hasError(error));
  }
};


// import companyLogo from './logos/comLogo.jpeg'
// import companyLogo from './logos/1.png'
import companyLogo from './images/jaris 2.png'

export { default as DocIllustration } from './illustration_doc';
export { default as SeoIllustration } from './illustration_seo';
export { default as UploadIllustration } from './illustration_upload';
export { default as SeverErrorIllustration } from './illustration_500';
export { default as PageNotFoundIllustration } from './illustration_404';
export { default as MotivationIllustration } from './illustration_motivation';
export { default as ComingSoonIllustration } from './illustration_coming_soon';
export { default as MaintenanceIllustration } from './illustration_maintenance';
export { default as OrderCompleteIllustration } from './illustration_order_complete';
export { default as BookingIllustration } from './illustration_booking';
export { default as CheckInIllustration } from './illustration_checkin';
export { default as CheckOutIllustration } from './illustration_checkout';
//
export { default as SentIcon } from './icon_sent';
export { default as PlanFreeIcon } from './icon_plan_free';
export { default as PlanStarterIcon } from './icon_plan_starter';
export { default as PlanPremiumIcon } from './icon_plan_premium';

// ------------ logos -----------


const LOGOS = {
    companyLogo
}
export default LOGOS
import { get, post, deleteReq, put } from "../../../src/services/restService";

export const addJournalEntryApi = (data) => {
  return post("journal_entry/add", data);
};
export const getAllJournalEntryApi = () => {
  return get("journal_entry/getAll");
};
export const getJournalEntryByIdApi = (id) => {
  return get(`journal_entry/getById/${id}`);
};

export const deleteJournalEntryApi = (id) => {
  return deleteReq(`journal_entry/delete/${id}`);
};

export const updateJournalEntryApi = (data) => {
  return put(`journal_entry/update/${data._id}`, data);
};

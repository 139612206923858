import { createSlice } from "@reduxjs/toolkit";
import {
  addReceiptVoucherApi,
  getAllReceiptVoucherApi,
  getReceiptVoucherByIdApi,
  deleteReceiptVoucherApi,
  updateReceiptVoucherApi,
} from "src/api/generalledger/receiptVoucherApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  receiptVouchers: [],
  singleReceiptVoucher: {},
};

const slice = createSlice({
  name: "receiptVoucher",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addReceiptVoucher(state, { payload }) {
      state.receiptVouchers.push(payload);
    },
    _getAllReceiptVoucher(state, { payload }) {
      state.receiptVouchers = payload;
    },
    _getSingleReceiptVoucher(state, { payload }) {
      state.singleReceiptVoucher = payload;
    },
    _deleteReceiptVoucher(state, { payload }) {
      const index = state.receiptVouchers.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.receiptVouchers.splice(index, 1);
      }
    },
    _updateReceiptVoucher(state, { payload }) {
      const index = state.receiptVouchers.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.newCustomers[index] = payload;
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addReceiptVoucher,
  _getAllReceiptVoucher,
  _getSingleReceiptVoucher,
  _deleteReceiptVoucher,
  _updateReceiptVoucher,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addReceiptVoucher = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addReceiptVoucherApi(data);
    const { status, newreceiptVoucher } = response.data;
    if (status === "success") {
      dispatch(_addReceiptVoucher(newreceiptVoucher));
      return newreceiptVoucher.id; // Return the ID value
    }
  } catch (error) {}
};

export const getAllReceiptVouchers = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getAllReceiptVoucherApi();

    const { status, newreceiptVouchers } = response.data;
    if (status === "success") {
      dispatch(_getAllReceiptVoucher(newreceiptVouchers));
    }
  } catch (error) {}
};

export const deleteReceiptVouchers = (id) => async (dispatch) => {
  try {
    const response = await deleteReceiptVoucherApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteReceiptVoucher(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateReceiptVouchers = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateReceiptVoucherApi(data);
    const { status, newreceiptVouchers } = response.data;
    if (status === "success") {
      dispatch(_updateReceiptVoucher(newreceiptVouchers));
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const getReceiptVoucherById = (id) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getReceiptVoucherByIdApi(id);

    const { status, ReceiptVoucher } = response.data;
    if (status === "success") {
      dispatch(_getSingleReceiptVoucher(ReceiptVoucher));
    }
  } catch (error) {
    console.log(error);
    // dispatch(hasError(error));
  }
};

import { get, post, deleteReq, put } from "src/services/restService";

export const addSalesPersonApi = (data) => {
  return post("sales-person/add", data);
};
export const getAllSalesPersonsApi = () => {
  return get("sales-person/getAll");
};
export const deleteSalesPersonApi = (id) => {
  return deleteReq(`sales-person/delete/${id}`);
};

export const updateSalesPersonApi = (data) => {
  return put(`sales-person/update/${data._id}`, data);
};

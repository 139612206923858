import { get, post, deleteReq, put } from "../../../src/services/restService";

export const addLocationTransferApi = (data) => {
  return post("location_Transfer/add", data);
};
export const getAllLocationTransferApi = () => {
  return get("location_Transfer/getAll");
};
export const getLocationTransferByIdApi = (id) => {
  return get(`location_Transfer/getById/${id}`);
};

export const deleteLocationTransferApi = (id) => {
  return deleteReq(`location_Transfer/delete/${id}`);
};

export const updateLocationTransferApi = (data) => {
  return put(`location_Transfer/update/${data._id}`, data);
};

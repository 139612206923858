import { get, post, deleteReq, put } from "src/services/restService";

export const addPurchasingPriceApi = (data) => {
  return post("purchasing_price/add", data);
};
export const getAllPurchasingPriceApi = () => {
  return get("purchasing_price/getAll");
};
export const deletePurchasingPriceApi = (id) => {
  return deleteReq(`purchasing_price/delete/${id}`);
};

export const updatePurchasingPriceApi = ( data) => {
  return put(`purchasing_price/update/${data._id}`, data);
};

import PropTypes from "prop-types";
import sum from "lodash/sum";
import { Page, View, Text, Image, Document } from "@react-pdf/renderer";
import Iconify from "src/components/Iconify";
// utils
// import Overseas from "../Overseas.jpeg";

import { fCurrency } from "src/utils/formatNumber";
//
import styles from "./GstStyle";
import { Divider } from "@mui/material";
import romanize from "romanize";
// ----------------------------------------------------------------------

UnderTakingPDF.propTypes = {
  creditInvoicePdf: PropTypes.object.isRequired,
};

export default function UnderTakingPDF({ creditInvoicePdf, deliveryNoteIPdf }) {
  const { invoice = {} } = creditInvoicePdf;
  console.log("deliveryNotePdf///<<<<", creditInvoicePdf?.id);
  console.log("deliveryNotePdf///<<<<", deliveryNoteIPdf);
  const { id } = invoice;
  console.log("creditInvoicePdf <<<<", creditInvoicePdf);

  const romanNumeral = romanize(3);
  const romanNumeral11 = romanize(11);
  const romanNumeral1 = romanize(1);
  function splitItemsIntoPages(items) {
    const rowsPerPage = 10;
    const pages = [];

    // add first page with first two items
    const firstPageItems = items.slice(0, 2);
    pages.push({
      title: "Credit Invoice Details (Page 1)",
      items: firstPageItems,
      startIndex: 1,
    });

    // add remaining pages
    const remainingItems = items.slice(2);
    const totalPages = Math.ceil(remainingItems.length / rowsPerPage);

    let currentIndex = 3;
    for (let i = 0; i < totalPages; i++) {
      const start = i * rowsPerPage;
      const end = start + rowsPerPage;
      const pageItems = remainingItems.slice(start, end);
      const pageNumber = i + 2; // start page numbering from 2
      const startIndex = pages[i].startIndex + pages[i].items.length;
      const page = {
        title: `Delivery Note (Page ${pageNumber})`,
        startIndex: startIndex,
        items: pageItems,
      };
      pages.push(page);
    }

    return pages;
  }
  const items = creditInvoicePdf?.items || [];
  const pages = splitItemsIntoPages(items);
  const itemsPerPage = 10;
  const totalPage = Math.ceil(items.length / itemsPerPage);
  let previousDiscount = 0;
  console.log("creditInvoicePdf?.items", creditInvoicePdf?.items);
  return (
    <Document>
      <Page size="A4" style={[styles.page, styles.leftBorder]}>
        <View style={[styles.gridContainer, styles.bottomline]}>
          <View style={[]}>
            <View style={[styles.invoiceborderfront]}>
              <View style={[styles.prt1front]}>
                <Image
                  disabledEffect
                  visibleByDefault
                  alt="logo"
                  src="/favicon/Overseas.jpeg"
                  // style={{ width: 400, height: 100, marginTop: 0 }}
                />
              </View>
              <View style={[styles.prt1front2]}></View>
              <View style={[styles.prt2front]}>
                <Text
                  style={[
                    {
                      color: "#d4d1d2",
                      fontWeight: "bold",
                      fontSize: 20,
                      marginLeft: 80,
                    },
                  ]}
                >
                  G.S.T INVOICE
                </Text>
                <Text style={[{ flexDirection: "row", fontSize: 11 }]}>
                  <Image
                    disabledEffect
                    visibleByDefault
                    alt="logo"
                    src="/favicon/email.png"
                    style={{ width: 15, height: 10 }}
                  />
                  inquiry@oe.com.pk{" "}
                  <Text style={[{ flexDirection: "row", fontSize: 11 }]}>
                    <Image
                      disabledEffect
                      visibleByDefault
                      alt="logo"
                      src="/favicon/website.png"
                      style={{ width: 15, height: 10 }}
                    />{" "}
                    www.oe.com.pk
                  </Text>
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={[]}>
          <Text
            style={[
              {
                fontSize: 20,
                padding: 20,
                textAlign: "center",
                fontWeight: "bold",
              },
            ]}
          >
            UNDER TAKING
          </Text>
        </View>
        <View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              fontSize: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 10,
            }}
          >
            <Text style={{ fontWeight: "bold" }}>1. </Text>
            <Text
              style={[
                {
                  flex: 1,
                },
              ]}
            >
              {" "}
              Our Company Overseas Enterprises holder of NTN : 2731310-7 and
              STRN 12-02-9999-456-28 , registered under Sales Tax ACT ,1990, as
              registered Commercial Importer.
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              fontSize: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 10,
            }}
          >
            <Text style={{ fontWeight: "bold" }}>2. </Text>
            <Text
              style={[
                {
                  flex: 1,
                },
              ]}
            >
              That As Per Sub-Clause C ({romanNumeral}) ({romanNumeral11}) of
              SRO 897({romanNumeral1}) /2013 dated :- 04-10-2013 we are excluded{" "}
              {"\n"}from deduction of 1/5 or 1/10 of sales tax.{"\n"} (Copy of
              said SRO is enclosed herewith for your ready reference).
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              fontSize: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 10,
            }}
          >
            <Text style={{ fontWeight: "bold" }}>3. </Text>
            <Text
              style={[
                {
                  flex: 1,
                },
              ]}
            >
              That as per Clause 47A of Part IV of Second Schedule to The Income
              Tax Ordinance, 2001 and {"\n"}Clause 5(a) of Section 153 of The
              Income Tax Ordinance, ( 2001 , we are exempted from deduction{" "}
              {"\n"}
              of Income Tax on Supplies made by ). as we are registered as
              commercial importers.
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              fontSize: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 10,
            }}
          >
            <Text style={{ fontWeight: "bold" }}>4. </Text>
            <Text
              style={[
                {
                  flex: 1,
                },
              ]}
            >
              If any sales Tax liability , default surcharge and penalties are
              levied on your Company (being the purchaser of the goods and not
              withheld sales tax on the basis of this Undertaking). Our Company
              undertakes ,agree to indemnify Company by depositing sales tax,
              default surcharges and penalties amount on your behalf or
              authorize your company to deduct the same from subsequent
              payment/billing.
            </Text>
          </View>

          <View
            style={[
              {
                fontSize: 15,
                paddingTop: 10,
                paddingLeft: 10,
                fontWeight: "bold",
                borderBottom: 1,
                borderColor: "#000000",
                width: 182,
              },
            ]}
          >
            <Text style={[{}]}>Details of Suppliers are :</Text>
          </View>

          <View
            style={[
              {
                fontSize: 10,
                paddingTop: 10,
                paddingLeft: 300,
              },
            ]}
          >
            <Text style={[{}]}>
              Invoice Number : {"        "}
              IN-{creditInvoicePdf?.id}
            </Text>
            <Text
              style={[
                {
                  border: 1,
                  borderColor: "#000000",
                  width: 100,
                  marginLeft: 100,
                },
              ]}
            ></Text>
          </View>
          <View
            style={[
              {
                fontSize: 10,
                paddingTop: 10,
                paddingLeft: 300,
              },
            ]}
          >
            <Text style={[{}]}>
              Invoice Date : {"              "}
              {creditInvoicePdf?.date}
            </Text>
            <Text
              style={[
                {
                  border: 1,
                  borderColor: "#000000",
                  width: 100,
                  marginLeft: 100,
                },
              ]}
            ></Text>
          </View>
          <View
            style={[
              {
                fontSize: 10,
                paddingTop: 10,
                paddingLeft: 300,
              },
            ]}
          >
            <Text style={[{}]}>
              Amount : {"                      "}
              {creditInvoicePdf?.afterTax}
            </Text>
            <Text
              style={[
                {
                  border: 1,
                  borderColor: "#000000",
                  width: 100,
                  marginLeft: 100,
                },
              ]}
            ></Text>
          </View>
          <View
            style={[
              {
                fontSize: 10,
                paddingTop: 10,
                paddingLeft: 300,
              },
            ]}
          >
            <Text style={[{}]}>
              Purchase Order No :{"  "}{" "}
              <Text style={[]}>{creditInvoicePdf?.purchaseOrder}</Text>
            </Text>
            <Text
              style={[
                {
                  border: 1,
                  borderColor: "#000000",
                  width: 100,
                  marginLeft: 100,
                },
              ]}
            ></Text>
          </View>

          <View style={[{ paddingTop: 20, paddingLeft: 15, fontSize: 12 }]}>
            <Text>FOR OVERSEAS ENTERPRISES</Text>
            <Text
              style={[
                {
                  border: 1,
                  borderColor: "#000000",
                  width: 200,
                  marginTop: 50,
                },
              ]}
            ></Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

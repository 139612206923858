import { get, post, deleteReq, put } from "src/services/restService";

export const addNewCreditInvoiceApi = (data) => {
  return post("credit_invoice/add", data);
};
export const getAllNewCreditInvoiceApi = () => {
  return get("credit_invoice/getAll");
};

export const getNewCreditInvoiceByIdApi = (id) => {
  return get(`credit_invoice/getById/${id}`);
};

export const deleteNewCreditInvoiceApi = (id) => {
  return deleteReq(`credit_invoice/delete/${id}`);
};

export const updateNewCreditInvoiceApi = (data) => {
  return put(`credit_invoice/update/${data._id}`, data);
};

import { useState, useEffect } from "react";
// @mui
import { v4 as uuidv4 } from "uuid";
import { useTheme } from "@mui/material/styles";
import {
  Card,
  Grid,
  Box,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  DialogTitle,
  TablePagination,
} from "@mui/material";
// hooks
import useSettings from "src/hooks/useSettings";
// components
import Page from "src/components/Page";
import Scrollbar from "src/components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";
import Iconify from "src/components/Iconify";
import TextField from "@mui/material/TextField";
import { pink } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import "dayjs/locale/en"; // import any locale that you need
// sections
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "src/sections/@dashboard/user/list";
import { DialogAnimate } from "src/components/animate";
import { CalendarForm } from "src/sections/@dashboard/calendar";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getAllDeliveryNotes,
  deleteDeliveryNotes,
} from "src/redux/slices/customer/deliveryNote";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";

// ----------------------------------------------------------------------
const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function DeliveryNoteDashboard() {
  const location = useLocation();

  const navigate = useNavigate();
  const deliveryNote = useSelector((state) => state.deliveryNote);
  const { deliveryNotes } = deliveryNote;

  const theme = useTheme();
  const { themeStretch } = useSettings();
  const [deliveryItems, setdeliveryItems] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("cus");
  const [selected, setSelected] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedQuotation, setSelectedQuotation] = useState(null);

  useEffect(() => {
    setdeliveryItems(deliveryNotes);
  }, [deliveryNotes]);

  useEffect(() => {
    getDeliveryNotes();
  }, []);

  const getDeliveryNotes = async () => {
    await dispatch(getAllDeliveryNotes());
  };
  const Batch = () => {
    return (
      <Button
        variant="contained"
        startIcon={<Iconify icon={"eva:plus-fill"} />}
        size="medium"
      >
        Batch
      </Button>
    );
  };

  const handleCheckboxClick = (salesOrderID, id) => {
    // Check if the item is already selected
    const isSelected = selectedItems.some(
      (item) => item.salesOrderID === salesOrderID && item.id === id
    );

    if (isSelected) {
      setSelectedItems(
        selectedItems.filter(
          (item) => !(item.salesOrderID === salesOrderID && item.id === id)
        )
      );
    } else {
      setSelectedItems([...selectedItems, { salesOrderID, id }]);
    }
  };
  const TABLE_HEAD = [
    { id: "quote", label: "Sale Order", alignRight: false },
    { id: "Reference", label: "DN Reference", alignRight: false },
    { id: "Refence", label: "Sales Order Reference", alignRight: false },
    { id: "purchaseorder", label: "Puchase Order", alignRight: false },
    { id: "customer", label: "Customer", alignRight: false },
    { id: "branch", label: "Branch", alignRight: false },
    { id: "quotedate", label: "Delivery Date", alignRight: false },
    { id: "deliveryto", label: "Delivery To", alignRight: false },
    { id: "deliverytaz", label: "Tax", alignRight: false },
    { id: "quotetotal", label: "Total", alignRight: false },
    { id: "", label: <Batch />, alignRight: false },
    { id: "quotetotl", label: "", alignRight: false },
  ];

  const dispatch = useDispatch();

  const { isOpenModal, selectedRange } = useSelector((state) => state.calendar);

  const handleEditEvent = (obj) => {
    const newCurrency = {
      data: obj.currency.data,
      value: obj.currency.value,
    };
    // obj.currency = currency
    const newData = {
      ...obj,
      currency: newCurrency,
      isEdit: true,
    };
    navigate(`/dashboard/customers/edit-deliveryNote/${obj.id}`, {
      state: newData,
    });
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = deliveryItems.map((n) => n.cusDiscount);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteUser = (rateId) => {
    const deleteUser = deliveryItems.filter((rate) => rate._id !== rateId);
    setSelected([]);
    setdeliveryItems(deleteUser);
    dispatch(deleteDeliveryNotes(rateId));
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = deliveryItems.filter(
      (rate) => !selected.includes(rate.cusName)
    );
    setSelected([]);
    setdeliveryItems(deleteUsers);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - deliveryItems.length) : 0;

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const { id } = useParams();
  const handleLinkClick = () => {
    navigate(`/dashboard/customers/transactions/${id}/deliveryNote/${id}`);
  };

  const handleButtonClick = (salesOrderID, deliveryNoteId) => {
    if (selectedItems.length > 0) {
      const salesOrderID = selectedItems[0].salesOrderID;

      // Gather selected deliveryNoteIds
      const selectedDeliveryNoteIds = selectedItems.map((item) => item.id);

      // Create a batch by concatenating selected deliveryNoteIds
      const batchID = selectedDeliveryNoteIds.join("_");
      navigate(
        `/dashboard/customers/transactions/${salesOrderID}/deliveryNote/${deliveryNoteId}/creditInvoice`,
        { replace: true }
      );
    }
  };

  const keysToSearch = [
    "cusDiscount",
    "id",
    "purchaseOrder",
    "customer",
    "branch",
    "salesOrderID",
  ];

  const filteredUsers = applySortFilter(
    deliveryItems,
    getComparator(order, orderBy),
    filterName,
    keysToSearch
  ).filter((row) => {
    if (fromDate && toDate) {
      return row.date >= fromDate && row.date <= toDate;
    } else if (fromDate) {
      return row.date >= fromDate;
    } else if (toDate) {
      return row.date <= toDate;
    }
    if (filterName) {
      const normalizedFilterName = filterName.toUpperCase(); // Convert to uppercase for case-insensitive comparison

      // Check if salesOrderID or modified salesOrderID contains the filter
      return (
        row.salesOrderID.includes(normalizedFilterName) ||
        `SO3022${row.salesOrderID}`.includes(normalizedFilterName)
      );
    }
    return true;
  });
  const isNotFound = !filteredUsers.length && Boolean(filterName);

  return (
    <Page title="User: List" padding="1.5rem">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <Card>
          <Grid px={1} py={1} container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 1,

                  borderRadius: 1,
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    rowGap: 2,
                    columnGap: 1,
                    gridTemplateColumns: {
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(1, 1fr)",
                    },
                  }}
                >
                  <Card sx={{ py: 1 }}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filterName={filterName}
                      onFilterName={handleFilterByName}
                      onDeleteUsers={() => handleDeleteMultiUser(selected)}
                    />
                  </Card>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 1,

                  borderRadius: 1,
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    rowGap: 2,
                    columnGap: 1,

                    gridTemplateColumns: {
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(1, 1fr)",
                    },
                  }}
                >
                  <Card sx={{ p: 3 }}>
                    <TextField
                      value={fromDate}
                      onChange={handleFromDateChange}
                      type="date"
                      variant="outlined"
                      label="From Date"
                      size="small"
                      sx={{ m: 1, width: "25ch" }}
                    />
                  </Card>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 1,

                  borderRadius: 1,
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    rowGap: 2,
                    columnGap: 1,
                    gridTemplateColumns: {
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(1, 1fr)",
                    },
                  }}
                >
                  <Card sx={{ p: 3 }}>
                    <TextField
                      value={toDate}
                      onChange={handleToDateChange}
                      type="date"
                      label="To Date"
                      variant="outlined"
                      size="small"
                      sx={{ m: 1, width: "25ch" }}
                    />
                  </Card>
                </Box>
              </Card>
            </Grid>
          </Grid>
          <TableCell align="left">
            <LoadingButton onClick={handleButtonClick}>
              Make Credit Invoice
            </LoadingButton>
          </TableCell>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <h4
                style={{
                  marginBottom: 15,
                  marginTop: 10,
                  textAlign: "center",
                  color: "#ff6347",
                  fontSize: 25,
                }}
              >
                DELIVERY NOTE DASHBOARD
              </h4>

              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={deliveryItems.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        _id,
                        id,
                        bold,
                        customer,
                        branch,
                        address,
                        salesOrderReferenceId,
                        currentCredit,
                        date,
                        invoiceDate,
                        deliverLocation,
                        deliveryDate,
                        currency,
                        shippingCost,
                        salesOrder,
                        salesOrderID,
                        items,
                        shippingCom,
                        delivery,
                        purchaseOrder,
                        memo,
                        actionBalance,
                        subtotal,
                        taxGroup,
                        taxCost,
                        afterTax,
                      } = row;

                      const isItemSelected = selectedItems.some(
                        (item) =>
                          item.salesOrderID === salesOrderID && item.id === id
                      );

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography variant="subtitle2" noWrap>
                              {salesOrderID}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Link
                              to={{
                                pathname: `/dashboard/customers/transactions/${salesOrderID}/deliveryNote/${id}`,
                                state: {
                                  salesOrderReferenceId: salesOrderReferenceId,
                                },
                              }}
                              onClick={handleLinkClick}
                            >
                              DN500{id}
                            </Link>
                          </TableCell>
                          <TableCell align="left">
                            <Link
                              to={`/dashboard/customers/transactions/${salesOrderID}`}
                            >
                              SO3022{salesOrderID}
                            </Link>
                          </TableCell>
                          <TableCell align="left">{purchaseOrder}</TableCell>

                          <TableCell align="left">{customer}</TableCell>
                          <TableCell align="left">{branch}</TableCell>
                          <TableCell align="left">{date}</TableCell>
                          <TableCell align="left">{deliverLocation}</TableCell>
                          <TableCell align="left">{taxGroup}</TableCell>
                          <TableCell align="left">{afterTax}</TableCell>
                          <TableCell align="left">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={() =>
                                handleCheckboxClick(salesOrderID, id)
                              }
                              /* ... other checkbox props */
                            />
                          </TableCell>
                          <TableCell align="left">
                            <LoadingButton
                              onClick={() =>
                                handleButtonClick(salesOrderID, id)
                              }
                            >
                              Make Credit Invoice
                            </LoadingButton>
                          </TableCell>

                          <TableCell align="right">
                            {bold ? null : (
                              <UserMoreMenu
                                onDelete={() => handleDeleteUser(_id)}
                                handleEditEvent={() => handleEditEvent(row)}
                                userName={salesOrderID}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={deliveryItems.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <DialogAnimate modalWidth="sm" open={isOpenModal}>
          <DialogTitle>
            {selectedQuotation ? "Edit Delivery Notes " : "Add Delivery Note "}
          </DialogTitle>

          <CalendarForm
            event={selectedQuotation || {}}
            range={selectedRange}
            // onCancel={handleCloseModal}
          />
        </DialogAnimate>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, keysToSearch) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((item) =>
      keysToSearch.some((key) =>
        String(item[key]).toLowerCase().includes(query.toLowerCase())
      )
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

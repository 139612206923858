import { useState, useEffect } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Card,
  Table,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TextField,
  Typography,
  TableContainer,
  DialogTitle,
  TablePagination,
} from "@mui/material";

import useSettings from "src/hooks/useSettings";
// _mock_

// components
import Page from "src/components/Page";
import Iconify from "src/components/Iconify";
import Scrollbar from "src/components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";
// sections
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "src/sections/@dashboard/user/list";
import { useForm } from "react-hook-form";
import { RHFTextField } from "src/components/hook-form";
import { DialogAnimate } from "src/components/animate";
import { pink } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import useResponsive from "src/hooks/useResponsive";
import {
  getEvents,
  openModal,
  closeModal,
  updateEvent,
  selectEvent,
  selectRange,
} from "src/redux/slices/calendar";
import QuotationItemsForm from "src/sections/@dashboard/calendar/customer/QuotationItemsForm";
import { deleteSalesQuotation } from "src/redux/slices/customer/salesquotation/salesQuotation";
// ----------------------------------------------------------------------

export default function SalesQuotaionsItems({
  onSubmit,
  quotationItems,
  setQuotationItems,
  setshippingCost,
  settaxCost,
  addQuotationItem,
  setafterTax,
  editTaxCost,
  seteditTaxCost,
  editShippingCost,
  seteditShippingCost,
  editAfterTax,
  seteditAfterTax,
  editTaxGroup,
  seteditTaxGroup,
  settaxGroup,
  taxGroup,
  quoatationDetail,
  setquoatationDetail,
  handleUpdateQuoatationDetail,
  isEdit,
  localIsEdit,
}) {
  const { themeStretch } = useSettings();

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [shippingCost, setShippingCostLocal] = useState(0);
  const [taxCost, setTaxCostLocal] = useState(0);
  const [afterTax, setAfterTaxLocal] = useState(0);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedQDetails, setselectedQDetails] = useState([]);
  const [IsEditMode, setIsEditMode] = useState(isEdit);
  const [isAddMode, setIsAddMode] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [displayedTaxGroup, setDisplayedTaxGroup] = useState(0);
  const [displayedTaxCost, setDisplayedTaxCost] = useState(0);

  useEffect(() => {
    setDisplayedTaxGroup(editTaxGroup);
  }, [editTaxGroup]);

  useEffect(() => {
    setDisplayedTaxCost(editTaxCost);
  }, [editTaxCost]);

  useEffect(() => {
    const subtotal = IsEditMode
      ? quoatationDetail.total || 0
      : quotationItems.total || 0;

    if (isCheckboxChecked) {
      if (isNaN(parseFloat(taxGroup))) {
        console.error("Invalid taxGroup value");
        return; // Exit the useEffect if taxGroup is not a valid number
      }

      const taxGroupValue = parseFloat(taxGroup);
      const taxAmount = (subtotal * taxGroupValue) / 100;
      const shippingAmount = shippingCost || 0;
      const totalAmount = (subtotal + taxAmount + shippingAmount).toFixed(2);

      // Update local state
      setShippingCostLocal(shippingAmount);
      setTaxCostLocal(taxAmount);
      setAfterTaxLocal(totalAmount);

      if (IsEditMode) {
        // Update edit state
        seteditShippingCost(shippingAmount);
        seteditTaxCost(taxAmount);
        seteditAfterTax(totalAmount);
      } else {
        // Call the parent setters
        setshippingCost(shippingAmount);
        settaxCost(taxAmount);
        setafterTax(totalAmount);
      }
    } else {
      // Reset the values when the checkbox is unchecked
      setShippingCostLocal(shippingCost || 0);
      setTaxCostLocal(0);
      setAfterTaxLocal(subtotal.toFixed(2));

      if (IsEditMode) {
        // Reset edit state
        seteditShippingCost(shippingCost || 0);
        seteditTaxCost(0);
        seteditAfterTax(subtotal.toFixed(2));
      } else {
        // Call the parent setters
        setshippingCost(shippingCost || 0);
        settaxCost(0);
        setafterTax(subtotal.toFixed(2));
      }
    }

    // Recalculate the total for quoatationDetail.items and update the parent state
    const updatedTotal = quoatationDetail.items.reduce(
      (acc, item) => acc + item.total,
      0
    );
    quoatationDetail.total = updatedTotal;
    handleUpdateQuoatationDetail(quoatationDetail);

    // Update taxGroup, taxCost, shippingCost, and afterTax when they change
    seteditTaxGroup(taxGroup);
    seteditTaxCost(taxCost);
    seteditShippingCost(shippingCost);
    seteditAfterTax(afterTax);
  }, [
    IsEditMode,
    isCheckboxChecked,
    taxGroup,
    setshippingCost,
    settaxCost,
    setafterTax,
    seteditTaxGroup,
    seteditShippingCost,
    seteditTaxCost,
    handleUpdateQuoatationDetail,
    quoatationDetail,
    seteditAfterTax,
    quoatationDetail.total,
    quoatationDetail.items,
    quotationItems.total,
    taxCost,
    shippingCost,
    afterTax,
  ]);

  const AddButton = () => {
    return (
      <Button
        variant="contained"
        startIcon={<Iconify icon={"eva:plus-fill"} />}
        size="medium"
        onClick={handleAddEvent}
      >
        {isAddMode ? "Add" : "New"}
      </Button>
    );
  };

  const handleDeleteItem = (index) => {
    if (IsEditMode) {
      // Clone the items array to avoid modifying the original state
      const updatedItems = [...quoatationDetail.items];

      // Remove the item at the specified index
      updatedItems.splice(index, 1);

      // Calculate the new total based on the updated items array
      const updatedTotal = updatedItems.reduce(
        (acc, item) => acc + item.total,
        0
      );

      // Update the state with the new items array and total
      const updatedQuoatationDetail = {
        ...quoatationDetail,
        items: updatedItems,
        total: updatedTotal,
      };

      setquoatationDetail(updatedQuoatationDetail);

      // Optionally, you can also update the total in your parent component, if needed
      handleUpdateQuoatationDetail(updatedQuoatationDetail);
    }
  };

  const handleDeleteUser = (index) => {
    // Create a copy of the items array

    console.log("Deleting item at index:", index);
    console.log("Current items array length:", quotationItems.items.length);
    const updatedItems = [...quotationItems.items]; // Create a copy of the items array
    updatedItems.splice(index, 1); // Remove the item at the specified index

    // Calculate the new total by summing the totals of the remaining items
    const newTotal = updatedItems.reduce((acc, item) => acc + item.total, 0);

    // Update the state with the modified quotationItems
    setQuotationItems({
      ...quotationItems,
      items: updatedItems,
      total: newTotal, // Update the total in quotationItems
    });
  };

  const handleEditEvent = (obj, index) => {
    const updatedQDetails = { ...obj, index };
    setselectedQDetails(updatedQDetails);
    setIsEditMode(true);
    dispatch(openModal(obj));
  };

  const handleAddEvent = () => {
    setIsEditMode(false);
    dispatch(openModal());
  };

  const TABLE_HEAD = [
    { id: "1", label: "Item Code", alignRight: false },
    { id: "2", label: "Item Description", alignRight: false },
    { id: "3", label: "Long Description", alignRight: false },
    { id: "4", label: "Quantity", alignRight: false },
    { id: "5", label: "Unit", alignRight: false },
    { id: "6", label: "Price Before Tax", alignRight: false },
    { id: "7", label: "Discount %", alignRight: false },
    { id: "8", label: "Total", alignRight: false },

    { id: "" },
    { id: "", label: <AddButton />, alignRight: false },
  ];
  const selectedEventSelector = (state) => {
    const { events, selectedEventId } = state.calendar;
    if (selectedEventId) {
      return events.find((_event) => _event.id === selectedEventId);
    }
    return null;
  };
  const dispatch = useDispatch();
  const isDesktop = useResponsive("up", "sm");

  const selectedEvent = useSelector(selectedEventSelector);
  const { events, isOpenModal, selectedRange } = useSelector(
    (state) => state.calendar
  );



  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Page title="User: List" mt={10}>
      <Container maxWidth={themeStretch ? false : "lg"}>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <h4
                style={{
                  marginBottom: 15,
                  marginTop: 10,
                  textAlign: "center",
                  color: "#ff6347",
                  fontSize: 25,
                }}
              >
                {" "}
                Quotation Item Details
              </h4>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={quotationItems.items.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {IsEditMode && quoatationDetail ? (
                    <>
                      {quoatationDetail?.items?.map((row, index) => {
                        if (row) {
                          const {
                            id,
                            bold,
                            itemCode,
                            itemDescription,
                            longDescription,
                            quantity,
                            unit,
                            priceBeforeTax,
                            hsCode,
                            discount,
                            total,
                          } = row;
                          const isItemSelected =
                            selected.indexOf(itemCode) !== -1;

                          return (
                            <TableRow
                              hover
                              key={index}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                {/* <Avatar alt={name} src={avatarUrl} sx={{ mr: 2 }} /> */}
                                <Typography variant="subtitle2" noWrap>
                                  {itemCode}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                {itemDescription}
                              </TableCell>
                              <TableCell align="left">
                                {longDescription}
                              </TableCell>
                              <TableCell align="left">{quantity}</TableCell>
                              <TableCell align="left">{unit}</TableCell>
                              <TableCell
                                align="left"
                                sx={{ fontWeight: bold ? "bold" : "" }}
                              >
                                {priceBeforeTax}
                              </TableCell>
                              <TableCell align="left">{hsCode}</TableCell>
                              <TableCell
                                align="left"
                                sx={{ fontWeight: bold ? "bold" : "" }}
                              >
                                {discount}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ fontWeight: bold ? "bold" : "" }}
                              >
                                {total}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ fontWeight: bold ? "bold" : "" }}
                              ></TableCell>
                              <TableCell align="right">
                                {isEdit && (
                                  <UserMoreMenu
                                    onDelete={() => handleDeleteItem(index)}
                                    handleEditEvent={() =>
                                      handleEditEvent(row, index)
                                    }
                                    userName={itemCode}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        } else {
                          return null;
                        }
                      })}

                      <TableRow
                        sx={{
                          borderBottom: 1,
                          borderColor: "#C3C3C3 ",
                          borderRadius: 1,
                          width: 50,
                          py: 50,
                        }}
                      >
                        <TableCell rowSpan={1} colSpan={6} />
                        <TableCell colSpan={1}>
                          <h4 px={10}> Sub Total</h4>
                        </TableCell>
                        <TableCell colSpan={10}>
                          <TableCell colSpan={3}>
                            <h1>{quoatationDetail.total}</h1>
                          </TableCell>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          borderBottom: 1,
                          borderColor: "#C3C3C3",
                          borderRadius: 1,
                          width: 50,
                          py: 50,
                        }}
                      >
                        <TableCell rowSpan={1} colSpan={6} />
                        <TableCell colSpan={1}>
                          <h4 px={10}>Tax</h4>
                          <Checkbox
                            checked={isCheckboxChecked}
                            onChange={(e) => {
                              setIsCheckboxChecked(e.target.checked);
                              // When the checkbox is checked, update the displayed values
                              if (e.target.checked) {
                                setDisplayedTaxGroup(editTaxGroup);
                                setDisplayedTaxCost(editTaxCost);
                              } else {
                                // Reset the displayed values when the checkbox is unchecked
                                setDisplayedTaxGroup(0);
                                setDisplayedTaxCost(0);
                              }
                            }}
                            sx={{
                              color: pink[800],
                              "&.Mui-checked": {
                                color: pink[600],
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell colSpan={10}>
                          <h4>{isCheckboxChecked ? displayedTaxGroup : "0"}</h4>
                          <TableCell colSpan={3}>
                            <h1>
                              {isCheckboxChecked ? displayedTaxCost : "0"}
                            </h1>
                          </TableCell>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          borderBottom: 1,
                          borderColor: "#C3C3C3 ",
                          borderRadius: 1,
                        }}
                      >
                        <TableCell rowSpan={1} colSpan={6} />
                        <TableCell colSpan={1} sx={{}}>
                          <h4> Shipping Charges</h4>
                        </TableCell>
                        <TableCell colSpan={3} align="right">
                          <TextField
                            name="shipping"
                            type="text"
                            onChange={(event) => {
                              setShippingCostLocal(Number(event.target.value));
                            }}
                            label="Enter Shipping"
                            size="small"
                            sx={{ mt: 1 }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          borderBottom: 1,
                          borderColor: "#C3C3C3 ",
                          borderRadius: 1,
                        }}
                      >
                        <TableCell rowSpan={1} colSpan={6} />
                        <TableCell colSpan={1} sx={{}}>
                          <h4> Total</h4>
                        </TableCell>
                        <TableCell colSpan={1} align="right">
                          <h1>{editAfterTax}</h1>
                          {console.log("kdlajdls////", afterTax)}
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <>
                      {quotationItems.items.map((row, index) => {
                        const {
                          id,
                          bold,
                          itemCode,
                          itemDescription,
                          longDescription,
                          quantity,
                          unit,
                          priceBeforeTax,
                          hsCode,
                          discount,
                          total,
                        } = row;
                        const isItemSelected =
                          selected.indexOf(itemCode) !== -1;

                        return (
                          <TableRow
                            hover
                            key={index}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              {/* <Avatar alt={name} src={avatarUrl} sx={{ mr: 2 }} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {itemCode}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              {itemDescription}
                            </TableCell>
                            <TableCell align="left">
                              {longDescription}
                            </TableCell>
                            <TableCell align="left">{quantity}</TableCell>
                            <TableCell align="left">{unit}</TableCell>
                            <TableCell
                              align="left"
                              sx={{ fontWeight: bold ? "bold" : "" }}
                            >
                              {priceBeforeTax}
                            </TableCell>
                            <TableCell align="left">{hsCode}</TableCell>
                            <TableCell
                              align="left"
                              sx={{ fontWeight: bold ? "bold" : "" }}
                            >
                              {discount}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ fontWeight: bold ? "bold" : "" }}
                            >
                              {total}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ fontWeight: bold ? "bold" : "" }}
                            ></TableCell>
                            <TableCell align="right">
                              {bold ? null : (
                                <UserMoreMenu
                                  onDelete={() => handleDeleteUser(index)}
                                  handleEditEvent={() =>
                                    handleEditEvent(row, index)
                                  }
                                  userName={itemCode}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow
                        sx={{
                          borderBottom: 1,
                          borderColor: "#C3C3C3 ",
                          borderRadius: 1,
                          width: 50,
                          py: 50,
                        }}
                      >
                        <TableCell rowSpan={1} colSpan={6} />
                        <TableCell colSpan={1}>
                          <h4 px={10}> Sub Total</h4>
                        </TableCell>
                        <TableCell colSpan={10}>
                          <TableCell colSpan={3}>
                            <h1>{quotationItems.total}</h1>
                          </TableCell>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          borderBottom: 1,
                          borderColor: "#C3C3C3",
                          borderRadius: 1,
                          width: 50,
                          py: 50,
                        }}
                      >
                        <TableCell rowSpan={1} colSpan={6} />
                        <TableCell colSpan={1}>
                          <h4 px={10}>Tax</h4>
                          <Checkbox
                            {...label}
                            checked={isCheckboxChecked}
                            onChange={(e) =>
                              setIsCheckboxChecked(e.target.checked)
                            }
                            sx={{
                              color: pink[800],
                              "&.Mui-checked": {
                                color: pink[600],
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell colSpan={10}>
                          <h4>{isCheckboxChecked ? taxGroup : "0"}</h4>
                          <TableCell colSpan={3}>
                            <h1>{isCheckboxChecked ? taxCost : "0"}</h1>
                          </TableCell>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          borderBottom: 1,
                          borderColor: "#C3C3C3 ",
                          borderRadius: 1,
                        }}
                      >
                        <TableCell rowSpan={1} colSpan={6} />
                        <TableCell colSpan={1} sx={{}}>
                          <h4> Shipping Charges</h4>
                        </TableCell>
                        <TableCell colSpan={3} align="right">
                          <TextField
                            name="shipping"
                            type="text"
                            onChange={(event) => {
                              setShippingCostLocal(Number(event.target.value));
                            }}
                            label="Enter Shipping"
                            size="small"
                            sx={{ mt: 1 }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          borderBottom: 1,
                          borderColor: "#C3C3C3 ",
                          borderRadius: 1,
                        }}
                      >
                        <TableCell rowSpan={1} colSpan={6} />
                        <TableCell colSpan={1} sx={{}}>
                          <h4> Total</h4>
                        </TableCell>
                        <TableCell colSpan={1} align="right">
                          <h1>{afterTax}</h1>
                          {console.log("kdlajdls////", afterTax)}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={quotationItems.items.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, value) => setPage(value)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <DialogAnimate
          modalWidth="sm"
          open={isOpenModal}
          onClose={handleCloseModal}
        >
          <DialogTitle>
            {selectedQuotation
              ? "Edit Sales Quotation Items"
              : "Enter Sales Quotation Items"}
          </DialogTitle>
          {console.log("selectedQDetails:<<<<<", selectedQDetails)}
          {console.log("setselectedQDetails:<<<<<", setselectedQDetails)}
          <QuotationItemsForm
            onSubmit={onSubmit}
            quotationItems={quotationItems}
            setQuotationItems={setQuotationItems}
            event={selectedQuotation || {}}
            range={selectedRange}
            onCancel={handleCloseModal}
            selectedQDetails={selectedQDetails}
            quoatationDetail={quoatationDetail}
            handleUpdateQuoatationDetail={handleUpdateQuoatationDetail}
            setselectedQDetails={setselectedQDetails}
            setIsAddMode={setIsAddMode}
            IsEditMode={IsEditMode}
            isAddMode={isAddMode}
          />
          {console.log("selectedQDetails:<<<<<", selectedQDetails)}
          {console.log("setselectedQDetails:<<<<<", setselectedQDetails)}
        </DialogAnimate>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter(
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

import { get, post, deleteReq, put } from "src/services/restService";

export const addgeneralSettingApi = (data) => {
  return post("General_setting/add", data);
};
export const getAllgeneralSettingApi = () => {
  return get("General_setting/getAll");
};
export const deletegeneralSettingApi = (id) => {
  return deleteReq(`General_setting/delete/${id}`);
};

export const updategeneralSettingApi = (data) => {
  return put(`General_setting/update/${data._id}`, data);
};

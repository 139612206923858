import { createSlice } from "@reduxjs/toolkit";
import {
  addFasicalYearApi,
  getAllFasicalYearApi,
  getFasicalYearByIdApi,
  deleteFasicalYearApi,
  updateFasicalYearApi,
} from "src/api/setting/fasicalYearApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  fasicalYears: [],
  singleFesicalYear: {},
};

const slice = createSlice({
  name: "fasicalYear",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addFasicalYear(state, { payload }) {
      state.fasicalYears.push(payload);
    },
    _getAllFasicalYear(state, { payload }) {
      state.fasicalYears = payload;
    },
    _getSingleFasicalYear(state, { payload }) {
      state.singleFesicalYear = payload;
    },
    _deleteFasicalYear(state, { payload }) {
      const index = state.fasicalYears.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.fasicalYears.splice(index, 1);
      }
    },
    _updateFasicalYear(state, { payload }) {
      const index = state.fasicalYears.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.newCustomers[index] = payload;
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addFasicalYear,
  _getAllFasicalYear,
  _getSingleFasicalYear,
  _deleteFasicalYear,
  _updateFasicalYear,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addFasicalYear = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addFasicalYearApi(data);
    const { status, newfasicalYear } = response.data;
    if (status === "success") {
      dispatch(_addFasicalYear(newfasicalYear));
      return newfasicalYear.id; // Return the ID value
    }
  } catch (error) {}
};

export const getAllFasicalYears = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getAllFasicalYearApi();

    const { status, newfasicalYears } = response.data;
    if (status === "success") {
      dispatch(_getAllFasicalYear(newfasicalYears));
    }
  } catch (error) {}
};

export const deleteFasicalYears = (id) => async (dispatch) => {
  try {
    const response = await deleteFasicalYearApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteFasicalYear(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateFasicalYears = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateFasicalYearApi(data);
    const { status, newfasicalYears } = response.data;
    if (status === "success") {
      dispatch(_updateFasicalYear(newfasicalYears));
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const getFasicalYearById = (id) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getFasicalYearByIdApi(id);

    const { status, newfasicalYears } = response.data;
    if (status === "success") {
      dispatch(_getSingleFasicalYear(newfasicalYears));
    }
  } catch (error) {
    console.log(error);
    // dispatch(hasError(error));
  }
};

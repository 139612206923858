import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
// form

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Box, Grid, Card, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// Table
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// hooks
import { useDispatch, useSelector } from "react-redux";
import useAuth from "src/hooks/useAuth";
// utils
// _mock
import dayjs from "dayjs";

// components
import { Controller } from "react-hook-form";
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import { getAllCreditInvoices } from "src/redux/slices/customer/creditInvoice";
import { getAllCashInvoices } from "src/redux/slices/customer/cashInvoice";
import { getAllNewCustomers } from "src/redux/slices/customer/newCustomer";
import { getAllLocalInvoices } from "src/redux/slices/customer/localInvoice";
import { addreceivePayment } from "src/redux/slices/customer/receivePayment";
import { getAllNewBranchs } from "src/redux/slices/customer/newBranch";
import { getAllBankAccounts } from "src/redux/slices/generalledger/bankAccount";
// ----------------------------------------------------------------------

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F04F0E",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ReceivePayment() {
  const dispatch = useDispatch();

  const creditInvoice = useSelector((state) => state.creditInvoice);
  const { creditInvoices } = creditInvoice;

  const cashInvoice = useSelector((state) => state.cashInvoice);
  const { cashInvoices } = cashInvoice;

  const { newBranchs } = useSelector((state) => state.newBranch);
  const { newCustomers } = useSelector((state) => state.newCustomer);

  const bankAccount = useSelector((state) => state.bankAccount);
  const { bankAccounts } = bankAccount;

  const localInvoice = useSelector((state) => state.localInvoice);
  const { localInvoices } = localInvoice;

  const { enqueueSnackbar } = useSnackbar();
  // const [depositdate, setdepositDate] = useState(new Date());
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [addbankaccountitems, setaddbankaccountitems] = useState([]);
  const [customerList, setcustomerList] = useState([]);
  const [branchList, setbranchList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCustomerData, setSelectedCustomerData] = useState(null);
  const [filteredBranchList, setFilteredBranchList] = useState([]);
  const [filteredInvoiceItems, setFilteredInvoiceItems] = useState([]);
  // const [comingPayments, setComingPayments] = useState([]);
  // const [updatedItems, setUpdatedItems] = useState([]);
  // const [receivePaymentItems, setreceivePaymentItems] = useState({
  //   items: [],
  //   total: 0,
  // });
  const [inputAmount, setInputAmount] = useState(0);
  // const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedBranchData, setSelectedBranchData] = useState({});
  const [taxGroup, setTaxGroup] = useState("");
  const [cusName, setCusName] = useState("");
  const [address, setAddress] = useState("");
  // const [remaingAmount, setRemaingAmount] = useState("");
  const [date, setdate] = useState(dayjs().format("YYYY-MM-DD"));
  // const [amount, setAmount] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);

  const calculatePayments = (item, amount) => {
    const afterTax = item.afterTax;
    const comingPayment = parseFloat(amount) || 0;
    const remainingPayment = afterTax - comingPayment;
    return { comingPayment, remainingPayment };
  };

  const handleAmountChange = (event) => {
    const newAmount = parseFloat(event.target.value) || 0;

    // Update the comingPayment value in the default values
    const updatedDefaultValues = {
      ...defaultValues,

      items: defaultValues.items.map((item) => ({
        ...item,
        comingPayment: newAmount,
        remainingPayment: item.afterTax - newAmount,
      })),
      amount: newAmount, // Update the amount field as well
    };

    // Update state with the new default values
    setDefaultValues(updatedDefaultValues);

    // Update the watched value
    setValue("amount", newAmount); // Assuming you've used setValue from react-hook-form
  };

  const handleDateChangeSalesOrders = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setdate(formattedDate);
  };

  const UpdateUserSchema = Yup.object().shape({
    memo: Yup.string().required("Messege is required"),
  });

  const [defaultValues, setDefaultValues] = useState({
    customer: "",
    branch: "",
    bank: "",
    exchangerate: "",
    bankcharges: "",
    date: "",
    cppd: "",
    wht: "",
    gst: "",
    amount: 0,
    memo: "",
    items: [
      {
        invoiceType: "",
        id: "",
        date: "",
        invoiceDate: "",
        afterTax: 0,
        comingPayment: 0,
        remainingPayment: 0,
      },
    ],
  });
  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  /////////////////////// Customer &  Branch //////////////////////////////

  ///////////////////// Cash & Credit  Invoice///////////////////////////////////////
  useEffect(() => {
    setInvoiceItems([...localInvoices, ...creditInvoices, ...cashInvoices]);
  }, [localInvoices, creditInvoices, cashInvoices]);

  useEffect(() => {
    getLocalInvoices();
    getCreditInvoices();
    getCashInvoices();
  }, []);

  const getLocalInvoices = async () => {
    await dispatch(getAllLocalInvoices());
  };

  const getCashInvoices = async () => {
    await dispatch(getAllCashInvoices());
  };

  const getCreditInvoices = async () => {
    await dispatch(getAllCreditInvoices());
  };
  const handleCustomerChange = (event) => {
    const selectedCusName = event.target.value;
    setSelectedCustomer(selectedCusName);

    // Find the selected customer data from newCustomers state
    const selectedCusData = newCustomers.find(
      (cus) => cus.cusName === selectedCusName
    );
    setSelectedCustomerData(selectedCusData);

    // Get the taxGroup for the selected customer
    if (selectedCusData) {
      const selectedTaxGroup = selectedCusData.taxGroup;

      setTaxGroup(selectedTaxGroup);
    }
    // Filter the branch list based on the selected customer
    if (selectedCusData) {
      const filteredBranchs = newBranchs.filter(
        (branch) => branch.customerId === selectedCusData._id
      );
      const temp = filteredBranchs.map((item) => item.name);
      setFilteredBranchList(temp);
    } else {
      // Reset the selected branch when customer changes
      setFilteredBranchList([]);
      setSelectedBranch("");
      setSelectedBranchData({});
    }
  };
  // Update the filteredInvoiceItems whenever the selectedCustomer or selectedBranch changes
  useEffect(() => {
    const filteredItems = invoiceItems.filter((item) => {
      const customerMatch = selectedCustomer
        ? item.customer === selectedCustomer
        : true;
      const branchMatch = selectedBranch
        ? item.branch === selectedBranch
        : true;
      return customerMatch && branchMatch;
    });

    setFilteredInvoiceItems(filteredItems);
  }, [invoiceItems, selectedCustomer, selectedBranch]);

  const handleBranchChange = (event) => {
    setSelectedBranch(event.target.value);
    const selectedBranchData = newBranchs.find(
      (branch) => branch.name === event.target.value
    );
    setSelectedBranchData(selectedBranchData || {});
  };

  useEffect(() => {
    if (newCustomers.length > 0) {
      const { cusName, address, ntnNum, gstNum, phone, taxGroup } =
        newCustomers[0];
      setcustomerList(newCustomers.map((item) => item.cusName));
      setCusName(cusName);
      setAddress(address);
      setTaxGroup(taxGroup);

      // Use setValue to set the form values
      setValue("gstNum", gstNum);
      setValue("ntnNum", ntnNum);
      setValue("PhoneNumber", phone);
    }
  }, [newCustomers, setValue]);

  useEffect(() => {
    getNewCustomers();
  }, []);

  const getNewCustomers = async () => {
    await dispatch(getAllNewCustomers());
  };

  useEffect(() => {
    const temp = newBranchs.map((item) => item.name);
    setbranchList(temp);
  }, [newBranchs]);

  useEffect(() => {
    getNewBranchs();
  }, []);

  const getNewBranchs = async () => {
    await dispatch(getAllNewBranchs());
  };
  /////////////////////////// Bank Account //////////////////////////#  useEffect(() => {
  useEffect(() => {
    setaddbankaccountitems(bankAccounts);
  }, [bankAccounts]);

  useEffect(() => {
    getBankAccounts();
  }, []);

  const getBankAccounts = async () => {
    await dispatch(getAllBankAccounts());
  };

  /////////////////////////////////////////////
  console.log("invoiceItems<<<<", invoiceItems);
  console.log("filteredInvoiceItems<<<<", filteredInvoiceItems);

  const exchangerateValue = watch("exchangerate");
  const bankValue = watch("bank");
  const bankchargesValue = watch("bankcharges");
  const cppdValue = watch("cppd");
  const whtValue = watch("wht");
  const gstValue = watch("gst");
  const amountValue = watch("amount");
  const memoValue = watch("memo");
  console.log("amountValue>>>>>>>", amountValue);

  const onSubmit = async (data) => {
    console.log("=======:::", data);
    try {
      // Reset the form

      reset();
      // Extract the item values from receivePaymentItems
      const itemValues = data.filteredInvoiceItems.items.map((item, index) => {
        const comingPayment = parseFloat(item.comingPayment) || 0;
        const remainingPayment = (
          parseFloat(item.afterTax) - comingPayment
        ).toFixed(2);
        return {
          invoiceType: item.invoiceType,
          id: item.id,
          date: item.date,
          invoiceDate: item.invoiceDate,
          afterTax: parseFloat(item.afterTax),
          comingPayment: comingPayment,
          remainingPayment: parseFloat(remainingPayment),
          index: index, // Include the index as a property in each item
        };
      });
      const itemsToSave = itemValues.filter((item) => item.comingPayment !== 0);
      // const amountValue = watch("amount");
      const formData = {
        customer: selectedCustomer,
        branch: selectedBranch,
        bank: bankValue,
        exchangerate: exchangerateValue,
        bankcharges: bankchargesValue,
        date,
        filteredInvoiceItems: {
          items: itemsToSave,
        },
        cppd: cppdValue,
        wht: whtValue,
        gst: gstValue,
        amount: amountValue,
        memo: memoValue,
      };
      // Clear any previous payment items
      console.log("formData:", formData);
      // Dispatch an action to add the receivePayment
      await dispatch(addreceivePayment(formData));
      // Perform any additional logic or navigation after successful submission

      console.log("Form submitted successfully");
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle the error or display an error message to the user
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid
        px={1}
        py={1}
        container
        spacing={1}
        sx={{ border: 1, borderColor: "#FB7600", borderRadius: 1 }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Card
            height={3}
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 1,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 3 }}>
                <RHFSelect
                  name="customer"
                  label="Customers"
                  size="small"
                  sx={{ mt: 1 }}
                  value={selectedCustomer}
                  onChange={handleCustomerChange}
                >
                  <option value="" />
                  {customerList.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </RHFSelect>

                <RHFSelect
                  name="branch"
                  label="Branch"
                  size="small"
                  sx={{ mt: 1 }}
                  disabled={!selectedCustomerData}
                  value={selectedBranch}
                  onChange={handleBranchChange}
                >
                  <option value="" />
                  {filteredBranchList.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </RHFSelect>

                <RHFSelect
                  name="bank"
                  label="into Bank Account"
                  size="small"
                  sx={{ mt: 1 }}
                >
                  <option value="" />
                  {addbankaccountitems.map((option, index) => (
                    <option key={index} value={option.bankaccountname}>
                      {option.bankaccountname}
                    </option>
                  ))}
                </RHFSelect>
              </Card>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Card sx={{ p: 1, background: "rgba(145, 158, 171, 0.12)" }}>
            <Box
              sx={{
                display: "grid",
                rowGap: 1,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 3 }}>
                <RHFTextField
                  name="exchangerate"
                  label="Exchange Rate (PKR = 1)"
                  size="small"
                  sx={{ mt: 1 }}
                />
                <RHFTextField
                  name="bankcharges"
                  label="Bank Charges"
                  size="small"
                  sx={{ mt: 1 }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <DesktopDatePicker
                      container
                      name="date"
                      label=" Date of Deposit"
                      value={date}
                      minDate={dayjs("2017-01-01")}
                      onChange={handleDateChangeSalesOrders}
                      renderInput={(params) => (
                        <TextField {...params} size="small" sx={{ mt: 1 }} />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Card>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 800, marginTop: 10 }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="right">Transaction Type</StyledTableCell>
              <StyledTableCell align="right">Ref</StyledTableCell>
              <StyledTableCell align="right">Date</StyledTableCell>
              <StyledTableCell align="right">Due Date</StyledTableCell>
              <StyledTableCell align="right">Amount</StyledTableCell>
              <StyledTableCell align="right">Other Allocations</StyledTableCell>
              <StyledTableCell align="right">This Allocation</StyledTableCell>
              <StyledTableCell align="right">
                Left to Allocation
              </StyledTableCell>
              <StyledTableCell align="right"> </StyledTableCell>
              <StyledTableCell align="right"> </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ marginTop: 5 }}>
            {filteredInvoiceItems.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  <Controller
                    control={control}
                    name={`filteredInvoiceItems.items[${index}].invoiceType`}
                    defaultValue={item.invoiceType}
                    render={({ field }) => (
                      <>
                        <input
                          type="hidden"
                          {...field}
                          value={item.invoiceType}
                        />
                        <span>{item.invoiceType}</span>
                      </>
                    )}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  {" "}
                  <Controller
                    control={control}
                    name={`filteredInvoiceItems.items[${index}].id`}
                    defaultValue={item.id}
                    render={({ field }) => (
                      <>
                        <input type="hidden" {...field} value={item.id} />
                        <span>{item.id}</span>
                      </>
                    )}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  {" "}
                  <Controller
                    control={control}
                    name={`filteredInvoiceItems.items[${index}].date`}
                    defaultValue={item.date}
                    render={({ field }) => (
                      <>
                        <input type="hidden" {...field} value={item.date} />
                        <span>{item.date}</span>
                      </>
                    )}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Controller
                    control={control}
                    name={`filteredInvoiceItems.items[${index}].invoiceDate`}
                    defaultValue={item.invoiceDate}
                    render={({ field }) => (
                      <>
                        <input
                          type="hidden"
                          {...field}
                          value={item.invoiceDate}
                        />
                        <span>{item.invoiceDate}</span>
                      </>
                    )}
                  />
                </StyledTableCell>

                <StyledTableCell align="right">
                  {" "}
                  <Controller
                    control={control}
                    name={`filteredInvoiceItems.items[${index}].afterTax`}
                    defaultValue={item.afterTax}
                    render={({ field }) => (
                      <>
                        <input type="hidden" {...field} value={item.afterTax} />
                        <span>{item.afterTax}</span>
                      </>
                    )}
                  />
                </StyledTableCell>

                <StyledTableCell align="right">
                  <Controller
                    control={control}
                    name={`filteredInvoiceItems.items[${index}].comingPayment`}
                    defaultValue={item.comingPayment}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label=""
                        size="small"
                        sx={{ mt: 1 }}
                        type="number"
                      />
                    )}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Controller
                    control={control}
                    name={`filteredInvoiceItems.items[${index}].comingPayment`}
                    defaultValue={item.comingPayment}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label=""
                        size="small"
                        sx={{ mt: 1 }}
                        type="number"
                      />
                    )}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Controller
                    control={control}
                    name={`filteredInvoiceItems.items[${index}].remainingPayment`}
                    defaultValue={item.remainingPayment}
                    render={({ field }) => (
                      <span>
                        {field.value !== undefined
                          ? field.value.toFixed(2)
                          : ""}
                      </span>
                    )}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <LoadingButton>All</LoadingButton>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <LoadingButton>None</LoadingButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/*----------------2nd Portion Detailing Code-------------------------------------------*/}
      <Grid mt={3} container spacing={1}>
        <Grid item xs={12} md={12}>
          <Card
            sx={{
              p: 3,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <h4
              style={{
                marginBottom: 15,
                marginTop: 10,
                textAlign: "center",
                color: "#ff6347",
                fontSize: 25,
              }}
            >
              Amount and WHT Are In Customer's Currency{" "}
            </h4>
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 2,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(3, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 3 }}>
                <RHFTextField
                  name="cppd"
                  label="Customer Promte Payment Discount"
                  size="small"
                  sx={{ mt: 1, borderRadius: 1 }}
                />
              </Card>
              <Card sx={{ p: 3 }}>
                <RHFTextField
                  name="wht"
                  label="With Holding Tax"
                  size="small"
                  sx={{ mt: 1, borderRadius: 1 }}
                />
              </Card>
              <Card sx={{ p: 3 }}>
                <RHFTextField
                  name="gst"
                  label="GST WithHolding Amount"
                  size="small"
                  sx={{ mt: 1, borderRadius: 1 }}
                />
              </Card>
              <Card sx={{ p: 3 }}>
                <RHFTextField
                  name="amount"
                  label="Amount"
                  size="small"
                  sx={{ mt: 1, borderRadius: 1 }}
                  value={amountValue} // Bind the value
                  onChange={handleAmountChange}
                />
              </Card>
              <Card sx={{ p: 3 }}>
                <RHFTextField
                  name="memo"
                  label="MEMO"
                  multiline
                  rows={4}
                  sx={{ mt: 1, borderRadius: 1 }}
                />
              </Card>
            </Box>
            <Stack
              spacing={1}
              alignItems="flex-end"
              sx={{ mt: 1, borderRadius: 1 }}
            >
              <Box display={"flex"} sx={{ justifyContent: "center", m: 5 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Update Payment
                </LoadingButton>
              </Box>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

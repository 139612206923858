import { createSlice } from "@reduxjs/toolkit";
import {
  addJournalEntryApi,
  getAllJournalEntryApi,
  getJournalEntryByIdApi,
  deleteJournalEntryApi,
  updateJournalEntryApi,
} from "src/api/generalledger/journalEntryApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  journalEntrys: [],
  singleJournalEntry: {},
};

const slice = createSlice({
  name: "journalEntry",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addJournalEnrty(state, { payload }) {
      state.journalEntrys.push(payload);
    },
    _getAllJournalEnrty(state, { payload }) {
      state.journalEntrys = payload;
    },
    _getSingleJournalEnrty(state, { payload }) {
      state.singleJournalEntry = payload;
    },
    _deleteJournalEnrty(state, { payload }) {
      const index = state.journalEntrys.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.journalEntrys.splice(index, 1);
      }
    },
    _updateJournalEnrty(state, { payload }) {
      const index = state.journalEntrys.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.newCustomers[index] = payload;
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addJournalEnrty,
  _getAllJournalEnrty,
  _getSingleJournalEnrty,
  _deleteJournalEnrty,
  _updateJournalEnrty,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addJournalEnrty = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addJournalEntryApi(data);
    const { status, newjournalEntry } = response.data;
    if (status === "success") {
      dispatch(_addJournalEnrty(newjournalEntry));
      return newjournalEntry.id; // Return the ID value
    }
  } catch (error) {}
};

export const getAllJournalEnrtys = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getAllJournalEntryApi();

    const { status, newjournalEntrys } = response.data;
    if (status === "success") {
      dispatch(_getAllJournalEnrty(newjournalEntrys));
    }
  } catch (error) {}
};

export const deleteJournalEnrtys = (id) => async (dispatch) => {
  try {
    const response = await deleteJournalEntryApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteJournalEnrty(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateJournalEnrtys = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateJournalEntryApi(data);
    const { status, newjournalEntrys } = response.data;
    if (status === "success") {
      dispatch(_updateJournalEnrty(newjournalEntrys));
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const getJournalEnrtyById = (id) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getJournalEntryByIdApi(id);

    const { status, journalEntry } = response.data;
    if (status === "success") {
      dispatch(_getSingleJournalEnrty(journalEntry));
    }
  } catch (error) {
    console.log(error);
    // dispatch(hasError(error));
  }
};

import { createSlice } from "@reduxjs/toolkit";
import {
  addCoaGroupApi,
  getAllCoaGroupApi,
  deleteCoaGroupApi,
  updateCoaGroupApi,
} from "src/api/generalledger/coaGroupApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  coaGroups: [],
};

const slice = createSlice({
  name: "coaGroup",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addCoaGroup(state, { payload }) {
      state.coaGroups.push(payload);
    },
    _getAllCoaGroup(state, { payload }) {
      state.coaGroups = payload;
    },
    _deleteCoaGroup(state, { payload }) {
      const index = state.coaGroups.findIndex((item) => item._id === payload);
      if (index !== -1) {
        state.coaGroups.splice(index, 1);
      }
    },
    _updateCoaGroup(state, { payload }) {
      const index = state.coaGroups.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.coaGroups[index] = payload;
      }
    },

    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addCoaGroup,
  _getAllCoaGroup,
  _deleteCoaGroup,
  _updateCoaGroup,
  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addCoaGroup = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addCoaGroupApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, coagroup } = response.data;
    if (status === "success") {
      dispatch(_addCoaGroup(coagroup));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllCoaGroups = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllCoaGroupApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, coagroups } = response.data;
    if (status === "success") {
      dispatch(_getAllCoaGroup(coagroups));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};
export const deleteCoaGroups = (id) => async (dispatch) => {
  try {
    const response = await deleteCoaGroupApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteCoaGroup(id));
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateCoaGroups = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateCoaGroupApi(data);
    const { status, coagroups } = response.data;
    if (status === "success") {
      dispatch(_updateCoaGroup(coagroups));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};

import { createSlice } from "@reduxjs/toolkit";
import {
  addUnitsofMeasureApi,
  getAllUnitsofMeasureApi,
  deleteUnitsofMeasureApi,
  updateUnitsofMeasureApi,
} from "src/api/inventory/unitMeasureApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  unitsMeasures: [],
};

const slice = createSlice({
  name: "unitsMeasure",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addUnitofMeasure(state, { payload }) {
      state.unitsMeasures.push(payload);
    },
    _getAllUnitofMeasure(state, { payload }) {
      state.unitsMeasures = payload;
    },
    _deleteUnitofMeasure(state, { payload }) {
      const index = state.unitsMeasures.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.unitsMeasures.splice(index, 1);
      }
    },

    _updateUnitofMeasure(state, { payload }) {
      const index = state.unitsMeasures.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.unitsMeasures[index] = payload;
      }
    },

    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addUnitofMeasure,
  _getAllUnitofMeasure,
  _deleteUnitofMeasure,
  _updateUnitofMeasure,
  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addUnitsMeasure = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addUnitsofMeasureApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, unitmeasure } = response.data;
    if (status === "success") {
      dispatch(_addUnitofMeasure(unitmeasure));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllUnitsMeasures = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllUnitsofMeasureApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, unitmeasures } = response.data;
    if (status === "success") {
      dispatch(_getAllUnitofMeasure(unitmeasures));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};
export const deleteUnitsMeasures = (id) => async (dispatch) => {
  try {
    const response = await deleteUnitsofMeasureApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteUnitofMeasure(id));
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateUnitsMeasures = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateUnitsofMeasureApi(data);
    const { status, unitmeasures } = response.data;
    if (status === "success") {
      dispatch(_updateUnitofMeasure(unitmeasures));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};

import PropTypes from "prop-types";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import merge from "lodash/merge";
import { isBefore } from "date-fns";
import { useSnackbar } from "notistack";
// form
import { Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Card,
  Grid,
  Box,
  Stack,
  Button,
  Label,
  Tooltip,
  TextField,
  IconButton,
  DialogActions,
} from "@mui/material";
import { LoadingButton, MobileDateTimePicker } from "@mui/lab";
// redux
import { useDispatch, useSelector } from "src/redux/store";
import {
  createEvent,
  updateEvent,
  deleteEvent,
  closeModal,
} from "src/redux/slices/calendar";
// components
import Iconify from "src/components/Iconify";
import { ColorSinglePicker } from "src/components/color-utils";
import {
  FormProvider,
  RHFTextField,
  RHFSelect,
  RHFSwitch,
} from "src/components/hook-form";
import { getAllNewItems } from "src/redux/slices/inventory/newItem";
import { getAllUnitsMeasures } from "src/redux/slices/inventory/unitsMeasure";
// ----------------------------------------------------------------------

const COLOR_OPTIONS = [
  "#00AB55", // theme.palette.primary.main,
  "#1890FF", // theme.palette.info.main,
  "#54D62C", // theme.palette.success.main,
  "#FFC107", // theme.palette.warning.main,
  "#FF4842", // theme.palette.error.main
  "#04297A", // theme.palette.info.darker
  "#7A0C2E", // theme.palette.error.darker
];

const getInitialValues = (event) => {
  const _event = {
    itemCode: "",
    itemDescription: "",
    longDescription: "",
    quantity: 0,
    unit: "",
    priceBeforeTax: 0,
    discount: 0,
    total: 0,
    textColor: "#1890FF",
  };

  if (event) {
    return merge({}, _event, event);
  }

  return _event;
};

// ----------------------------------------------------------------------

QuotationItemsForm.propTypes = {
  event: PropTypes.object,
  // range: PropTypes.object,
  onCancel: PropTypes.func,
};

export default function QuotationItemsForm({
  onSubmit,
  event,
  onCancel,
  quotationItems,
  setQuotationItems,
  quoatationDetail,
  setquoatationDetail,
  selectedQDetails,
  setselectedQDetails,
  handleUpdateQuoatationDetail,
  isEdit,
  IsEditMode,
  setIsAddMode,
  isAddMode,
  
}) {
  console.log("quotationItems>>>>>1", quotationItems);
  console.log("quoatationDetail>>>>>1", quoatationDetail);
  console.log("setQuotationDetails>>>>>1", setquoatationDetail);
  const unitsMeasure = useSelector((state) => state.unitsMeasure);
  const { unitsMeasures } = unitsMeasure;
  console.log("selectedQDetails>>>", selectedQDetails);
  const { enqueueSnackbar } = useSnackbar();
  const newItem = useSelector((state) => state.newItem);
  const { newItems } = newItem;
  const [data, setData] = useState(() => {
    if (IsEditMode && selectedQDetails) {
      return {
        itemCode: selectedQDetails.itemCode || "",
        itemDescription: selectedQDetails.itemDescription || "",
        longDescription: selectedQDetails.longDescription || "",
        unit: selectedQDetails.unit || "",
        quantity: selectedQDetails.quantity || 0,
        priceBeforeTax: selectedQDetails.priceBeforeTax || 0,
        discount: selectedQDetails.discount || 0,
        // ... other fields
      };
    } else {
      return {
        itemCode: "",
        itemDescription: "",
        longDescription: "",
        unit: "",
        quantity: 0,
        priceBeforeTax: 0,
        discount: 0,
        // ... other fields
      };
    }
  });

  const [productName, setproductName] = useState("");
  const [itemCode, setitemCode] = useState("");
  const [Category, setCategory] = useState("");
  const [filtereditem, setFiltereditem] = useState([]);
  const [categoryList, setcategoryList] = useState([]);

  const [selectedItemCode, setSelectedItemCode] = useState("");
  const [unitofMeasureitems, setunitofMeasureitems] = useState([]);
  const dispatch = useDispatch();
  ///////////////////////unit of Measure Details //////////////////////
  useEffect(() => {
    const temp = unitsMeasures.map((item) => item.descriptiveName);
    setunitofMeasureitems(temp);
    console.log("temp  >>>>", unitsMeasures);
  }, [unitsMeasures]);

  useEffect(() => {
    getUnitOfMeasures();
  }, []);

  const getUnitOfMeasures = async () => {
    await dispatch(getAllUnitsMeasures());
  };
  //////////////////item Details ///////////////////////////
  useEffect(() => {
    if (newItems.length > 0) {
      setproductName(newItems[0].productName);
      setitemCode(newItems[0].itemCode);
      setCategory(newItems[0].Category);
      setFiltereditem(newItems);
      // initialize filteredAccounts with all accounts
      console.log("filterAccount >>>>>>", newItems);

      // initialize categoryList with all categories
      const allCategories = Array.from(
        new Set(newItems.map((item) => item.Category))
      );
      setcategoryList(allCategories);
    }
  }, [newItems]);

  useEffect(() => {
    getInventorys();
  }, []);

  const getInventorys = async () => {
    await dispatch(getAllNewItems());
  };
  useEffect(() => {
    if (newItems.length > 0) {
      setFiltereditem(newItems);
      const allCategories = Array.from(
        new Set(newItems.map((item) => item.Category))
      );
      setcategoryList(allCategories);
    }
  }, [newItems]);

  const handleItemCodeChange = (e) => {
    setSelectedItemCode(e.target.value);
    const filtered = newItems.filter(
      (item) =>
        item.Category === Category &&
        item.itemCode.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFiltereditem(filtered);
  };

  useEffect(() => {
    const uniqueCategories = Array.from(
      new Set(newItems.map((item) => item.Category))
    );
    setcategoryList(uniqueCategories);
    setCategory(uniqueCategories[0]); // Set the default category to the first one in the list
  }, [newItems]);

  // filter items based on selected category and entered item code
  const filteredItems = filtereditem.filter(
    (item) =>
      item.Category === Category &&
      item.itemCode.toLowerCase().includes(selectedItemCode.toLowerCase())
  );

  const handleChange = (e) => {
    let { value, name } = e.target;
    setData((items) => ({
      ...items,
      [name]: value,
    }));
  };

  const methods = useForm({
    // resolver: yupResolver(EventSchema),
    defaultValues: getInitialValues(event),
  });

  const {
    reset,

    formState: { isSubmitting },
  } = methods;

  const handleSubmitForm = () => {
    try {
      const newItem = { ...data };
      newItem.total = newItem.quantity * newItem.priceBeforeTax;

      if (newItem.discount !== "" && Number(newItem.discount)) {
        newItem.total *= (100 - Number(newItem.discount)) / 100;
      }

      if (isNaN(newItem.total)) {
        newItem.total = newItem.quantity * newItem.priceBeforeTax;
      }

      const updatedQuoatationDetail = { ...quoatationDetail };

      if (
        IsEditMode &&
        selectedQDetails &&
        selectedQDetails.index !== undefined
      ) {
        // Edit existing item
        const indexToUpdate = selectedQDetails.index;
        const updatedItem = { ...data };
        updatedItem.total = updatedItem.quantity * updatedItem.priceBeforeTax;

        updatedQuoatationDetail.items[indexToUpdate] = updatedItem;
      } else {
        // Add new item
        updatedQuoatationDetail.items.push(newItem);
      }

      // Recalculate the total for all items
      const updatedTotal = updatedQuoatationDetail.items.reduce(
        (acc, item) => acc + item.total,
        0
      );

      updatedQuoatationDetail.total = updatedTotal;

      handleUpdateQuoatationDetail(updatedQuoatationDetail);

      // Recalculate the total for quotationItems.items
      const newTotal = updatedQuoatationDetail.items.reduce(
        (acc, val) => acc + val.total,
        0
      );

      setQuotationItems({
        ...quotationItems,
        items: [...updatedQuoatationDetail.items],
        total: newTotal,
      });

      // Reset the form data
      setData(getInitialValues());
    } catch (error) {
      console.error("Error in handleSubmitForm:", error);
    }
    onCancel();
  };

  // const handleSubmitForm = async () => {
  //   console.log("data===", data);
  //   let total = data.quantity * data.priceBeforeTax;

  //   if (data.discount !== "" && Number(data.discount)) {
  //     total *= (100 - Number(data.discount)) / 100;
  //   }

  //   if (isNaN(total)) {
  //     total = data.quantity * data.priceBeforeTax;
  //   }

  //   const objTotal = quotationItems.items.reduce(
  //     (acc, val) => acc + val.total,
  //     0
  //   );

  //   const obj = {
  //     items: [...quotationItems.items, { ...data, total }],
  //     total: objTotal + total,
  //   };

  //   setQuotationItems(obj);
  // };

  return (
    <>
      <Grid item xs={12} md={12}>
        <Card
          sx={{
            p: 5,
            background: "rgba(145, 158, 171, 0.12)",
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              display: "grid",
              columnGap: 2,
              rowGap: 3,
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              },
            }}
          >
            <Card sx={{ p: 3 }}>
              <RHFTextField
                onChange={(e) => {
                  handleChange(e);
                  handleItemCodeChange(e); // call handleItemCodeChange on change of RHFTextField
                }}
                value={data.itemCode}
                name="itemCode"
                label="Item Code"
                sx={{ mt: 2 }}
              />

              <RHFSelect
                name="itemDescription"
                label="Item Description"
                value={data.itemDescription}
                size="small"
                onChange={(e) => {
                  handleChange(e);
                  // call handleItemCodeChange on change of RHFTextField
                }}
                sx={{ mt: 2, borderColor: "#FF0000", borderRadius: 1 }}
              >
                <option value=""></option>
                {categoryList.map((category, index) => {
                  const filteredItems = newItems.filter(
                    (item) =>
                      item.Category === category &&
                      item.itemCode
                        .toLowerCase()
                        .includes(selectedItemCode.toLowerCase())
                  );
                  return (
                    <optgroup key={index} label={category}>
                      {filteredItems.map((item, idx) => (
                        <option
                          key={idx}
                          value={`${item.itemCode} - ${item.productName}`}
                          className="product"
                        >
                          {item.productName} - {item.itemCode}
                        </option>
                      ))}
                    </optgroup>
                  );
                })}
              </RHFSelect>
            </Card>
            <Card sx={{ p: 3 }}>
              <RHFTextField
                onChange={(e) => handleChange(e)}
                value={data.longDescription}
                name="longDescription"
                label="Long Description"
                multiline
                rows={4}
                sx={{ mt: 1 }}
              />
            </Card>
            <Card sx={{ p: 3 }}>
              <RHFSelect
                name="unit"
                label="Unit Of Measure"
                onChange={(e) => handleChange(e)}
                value={data.unit}
                sx={{ mt: 2, borderColor: "#FF0000", borderRadius: 1 }}
              >
                <option value="" />
                {unitofMeasureitems.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </RHFSelect>
              <RHFTextField
                onChange={(e) => handleChange(e)}
                value={data.quantity}
                name="quantity"
                label="Quantity"
                sx={{ mt: 2 }}
              />
            </Card>
            <Card sx={{ p: 3 }}>
              <RHFTextField
                onChange={(e) => handleChange(e)}
                value={data.priceBeforeTax}
                name="priceBeforeTax"
                label="Price Before Tax"
                sx={{ mt: 2 }}
              />
              <RHFTextField
                onChange={(e) => handleChange(e)}
                value={data.discount}
                name="discount"
                label="Discount %"
                sx={{ mt: 2 }}
              />
            </Card>
          </Box>
        </Card>
      </Grid>

      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="outlined" color="inherit" onClick={handleSubmitForm}>
          {isEdit ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </>
  );
}

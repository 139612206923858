import { createSlice } from "@reduxjs/toolkit";
import {
    addSalesQuotationApi,
    getAllSalesQuotationApi,
    updateSalesQuotationApi,
    deleteSalesQuotationApi
} from "src/api/customer/saleQuotation/salesQuotationApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  salesQuotations: [],
};

const slice = createSlice({
  name: "salesQuotation",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addSalesQuotation(state, { payload }) {
      state.salesQuotations.push(payload);
    },
    _getAllSalesQuotation(state, { payload }) {
      state.salesQuotations = payload;
    },
    _updateSalesQuotation(state, { payload }) {
      const index = state.salesQuotations.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.salesQuotations[index] = payload;
      }
    },

    _deleteSalesQuotation(state, { payload }) {
      const index = state.salesQuotations.findIndex((item) => item._id === payload);
      if (index !== -1) {
        state.salesQuotations.splice(index, 1);
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addSalesQuotation,
  _getAllSalesQuotation,
  _updateSalesQuotation,
  _deleteSalesQuotation,
  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addSalesQuotation = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addSalesQuotationApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, salesQuotation } = response.data;
    if (status === "success") {
      dispatch( _addSalesQuotation(salesQuotation));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllSalesQuotation = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllSalesQuotationApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, salesQuotations } = response.data;
    if (status === "success") {
      dispatch( _getAllSalesQuotation(salesQuotations));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};
export const deleteSalesQuotation = (id) => async (dispatch) => {
  try {
    const response = await deleteSalesQuotationApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteSalesQuotation(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateSalesQuotation = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateSalesQuotationApi(data);
    const { status, glaccountclasss } = response.data;
    if (status === "success") {
      dispatch(_updateSalesQuotation(glaccountclasss));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};

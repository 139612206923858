import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import { useTheme } from "@mui/material/styles";
import {
  Card,
  Box,
  Table,
  Grid,
  Avatar,
  Button,
  Checkbox,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  DialogTitle,
  TablePagination,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "src/routes/paths";
// hooks
import useSettings from "src/hooks/useSettings";
// _mock_
import { _userList, _quoteItems } from "src/_mock";
import dayjs from "dayjs";
// components
import Page from "src/components/Page";
import Iconify from "src/components/Iconify";
import Scrollbar from "src/components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en"; // import any locale that you need
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// sections
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "src/sections/@dashboard/user/list";
import { DialogAnimate } from "src/components/animate";
import {
  CalendarForm,
  CalendarStyle,
  CalendarToolbar,
} from "src/sections/@dashboard/calendar";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useResponsive from "src/hooks/useResponsive";
import InputAdornment from "@mui/material/InputAdornment";
import {
  getEvents,
  openModal,
  closeModal,
  updateEvent,
  selectEvent,
  selectRange,
} from "src/redux/slices/calendar";
import {
  getAllSalesOrders,
  deleteSalesOrders,
} from "src/redux/slices/customer/salesOrder";
import PrintReport from "../PrintReport";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
// ----------------------------------------------------------------------

export default function SalesDashboardItems() {
  const navigate = useNavigate();
  const salesOrder = useSelector((state) => state.salesOrder);
  const { salesOrders } = salesOrder;
  console.log("quotations=======", salesOrder);
  // const salesQuotation = useSelector((state) => state.salesQuotation);
  // const { salesQuotations } = salesQuotation;

  const theme = useTheme();
  const { themeStretch } = useSettings();
  const [userList, setUserList] = useState(_userList);
  const [salesOrderItems, setsalesOrderItems] = useState([]);
  const [salequoteItems, setsalequoteItems] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("cus");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedQuotation, setSelectedQuotation] = useState(null);

  const [isEdit, setIsEdit] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  useEffect(() => {
    setsalesOrderItems(salesOrders);
  }, [salesOrders]);

  useEffect(() => {
    getSalesOrders();
  }, []);

  const getSalesOrders = async () => {
    await dispatch(getAllSalesOrders());
  };

  const TABLE_HEAD = [
    { id: "quote", label: "RFQ ID", alignRight: false },
    { id: "Reference", label: "Sales Order Reference", alignRight: false },
    { id: "purchaseorder", label: "Puchase Order", alignRight: false },
    { id: "customer", label: "Customer", alignRight: false },
    { id: "branch", label: "Branch", alignRight: false },

    { id: "quotedate", label: "Quote Date", alignRight: false },
    { id: "validuntil", label: "Valid Until", alignRight: false },
    { id: "deliveryto", label: "Delivery To", alignRight: false },
    { id: "quotetotal", label: " Total", alignRight: false },
  ];
  const selectedEventSelector = (state) => {
    const { events, selectedEventId } = state.calendar;
    if (selectedEventId) {
      return events.find((_event) => _event.id === selectedEventId);
    }
    return null;
  };
  const dispatch = useDispatch();

  const { events, isOpenModal, selectedRange } = useSelector(
    (state) => state.calendar
  );

  const handleEditEvent = (obj) => {
    const newData = {
      ...obj,
      isEdit: true,
    };

    console.log("Navigating with newData:", newData);

    navigate(`/dashboard/customers/edit-salesOrder/${obj.id}`, {
      state: { newData, isEdit: true },
    });
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = salequoteItems.map((n) => n.cusDiscount);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteUser = (rateId) => {
    const deleteUser = salesOrderItems.filter((rate) => rate._id !== rateId);
    setSelected([]);
    setsalesOrderItems(deleteUser);
    dispatch(deleteSalesOrders(rateId));
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = salequoteItems.filter(
      (rate) => !selected.includes(rate.cusName)
    );
    setSelected([]);
    setsalequoteItems(deleteUsers);
  };
  const handleButtonClick = (id) => {
    navigate(`/dashboard/customers/transactions/${id}/deliveryNote`);
  };
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - salequoteItems.length)
      : 0;

  // const filteredData = quoteItems.filter((quote) => {
  //   const quotationDate = dayjs(quote.quotationDate);
  //   return isWithinDateRange(quotationDate, fromDate, toDate);
  // });

  // function isWithinDateRange(date, fromDate, toDate) {
  //   if (fromDate && toDate) {
  //     return (
  //       date.isSameOrAfter(fromDate, "day") &&
  //       date.isSameOrBefore(toDate, "day")
  //     );
  //   } else if (fromDate) {
  //     return date.isSameOrAfter(fromDate, "day");
  //   } else if (toDate) {
  //     return date.isSameOrBefore(toDate, "day");
  //   } else {
  //     return true;
  //   }
  // }

  const keysToSearch = [
    "cusDiscount",
    "id",
    "purchaseOrder",
    "customer",
    "branch",
    "purchaseOrder",
  ];

  const filteredUsers = applySortFilter(
    salesOrderItems,
    getComparator(order, orderBy),
    filterName,
    keysToSearch
  ).filter((row) => {
    if (fromDate && toDate) {
      return row.orderDate >= fromDate && row.orderDate <= toDate;
    } else if (fromDate) {
      return row.orderDate >= fromDate;
    } else if (toDate) {
      return row.orderDate <= toDate;
    }
    return true;
  });
  const isNotFound = !filteredUsers.length && Boolean(filterName);

  return (
    <Page title="User: List" padding="1.5rem">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <Card>
          <Grid px={1} py={1} container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 1,

                  borderRadius: 1,
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    rowGap: 2,
                    columnGap: 1,
                    gridTemplateColumns: {
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(1, 1fr)",
                    },
                  }}
                >
                  <Card sx={{ py: 1 }}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filterName={filterName}
                      onFilterName={handleFilterByName}
                      onDeleteUsers={() => handleDeleteMultiUser(selected)}
                    />
                  </Card>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 1,

                  borderRadius: 1,
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    rowGap: 2,
                    columnGap: 1,

                    gridTemplateColumns: {
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(1, 1fr)",
                    },
                  }}
                >
                  <Card sx={{ p: 3 }}>
                    <TextField
                      value={fromDate}
                      onChange={handleFromDateChange}
                      type="date"
                      variant="outlined"
                      label="From Date"
                      size="small"
                      sx={{ m: 1, width: "25ch" }}
                    />
                  </Card>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 1,

                  borderRadius: 1,
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    rowGap: 2,
                    columnGap: 1,
                    gridTemplateColumns: {
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(1, 1fr)",
                    },
                  }}
                >
                  <Card sx={{ p: 3 }}>
                    <TextField
                      value={toDate}
                      onChange={handleToDateChange}
                      type="date"
                      label="To Date"
                      variant="outlined"
                      size="small"
                      sx={{ m: 1, width: "25ch" }}
                    />
                  </Card>
                </Box>
              </Card>
            </Grid>
          </Grid>
          <Scrollbar>
            {" "}
            <TableContainer sx={{ minWidth: 800 }}>
              <h4
                style={{
                  marginBottom: 15,
                  marginTop: 10,
                  textAlign: "center",
                  color: "#ff6347",
                  fontSize: 25,
                }}
              >
                SALES ORDER DASHBOARD
              </h4>

              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={salesOrderItems.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        _id,
                        id,
                        bold,
                        salesOrderReferenceId,
                        customer,
                        branch,
                        PhoneNumber,
                        reference,
                        cusDiscount,
                        exchangeRate,
                        currentCredit,
                        payment,
                        salesPerson,
                        orderDate,
                        salesOrderDetails,
                        shippingCost,
                        taxCost,
                        afterTax,
                        customerNtn,
                        customerGst,
                        deliverLocation,
                        deliveryDate,
                        deliveryTo,
                        phone,
                        comments,
                        atn,
                        shippingTerms,
                        currency,
                        puOrderDate,
                        deliveryTerms,
                        shippingCom,
                        purchaseOrder,
                        address,
                        purchaseOrderDate,
                        customerReference,
                        carrierReceipt,
                        typeofPacking,
                        gridNo,
                        trData,
                        grn,
                      } = row;
                      const isItemSelected =
                        selected.indexOf(cusDiscount) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography variant="subtitle2" noWrap>
                              {cusDiscount}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Link
                              to={{
                                pathname: `/dashboard/customers/transactions/${id}`,
                                state: { customerReference: customerReference },
                              }}
                            >
                              SO3022{id}
                            </Link>
                          </TableCell>
                          <TableCell align="left">{purchaseOrder}</TableCell>
                          <TableCell align="left">{customer}</TableCell>
                          <TableCell align="left">{branch}</TableCell>
                          <TableCell align="left">{orderDate}</TableCell>
                          <TableCell align="left">{deliveryDate}</TableCell>
                          <TableCell align="left">{deliveryTo}</TableCell>
                          <TableCell align="left">{afterTax}</TableCell>
                          <TableCell align="left">
                            <LoadingButton
                              onClick={() => handleButtonClick(id)}
                            >
                              Make Delivery Note
                            </LoadingButton>
                          </TableCell>

                          <TableCell align="right">
                            {bold ? null : (
                              <UserMoreMenu
                                onDelete={() => handleDeleteUser(_id)}
                                handleEditEvent={() => handleEditEvent(row)}
                                userName={cusDiscount}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={salesOrderItems.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <DialogAnimate
          modalWidth="sm"
          open={isOpenModal}
          onClose={handleCloseModal}
        >
          <DialogTitle>
            {selectedQuotation
              ? "Edit Sales Quotation Items"
              : "Add Sales Quotation Items"}
          </DialogTitle>

          <CalendarForm
            event={selectedQuotation || {}}
            range={selectedRange}
            onCancel={handleCloseModal}
          />
        </DialogAnimate>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, keysToSearch) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((item) =>
      keysToSearch.some((key) =>
        String(item[key]).toLowerCase().includes(query.toLowerCase())
      )
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

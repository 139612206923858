import { get, post, deleteReq, put } from "src/services/restService";

export const addgstDataApi = (data) => {
  return post("gst_data/add", data);
};
export const getAllgstDataApi = () => {
  return get("gst_data/getAll");
};
export const deletegstDataApi = (id) => {
  return deleteReq(`gst_data/delete/${id}`);
};

export const updategstDataApi = ( data) => {
  return put(`gst_data/update/${data._id}`, data);
};

import { createSlice } from "@reduxjs/toolkit";
import {
  addDeliveryNoteApi,
  getAllDeliveryNoteApi,
  getDeliveryNoteByIdApi,
  deleteDeliveryNoteApi,
  updateDeliveryNoteApi,
} from "src/api/customer/deliveryNoteApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  deliveryNotes: [],
  singleDelivery: {},
};

const slice = createSlice({
  name: "deliveryNote",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addDeliveryNote(state, { payload }) {
      state.deliveryNotes.push(payload);
    },
    _getAllDeliveryNote(state, { payload }) {
      state.deliveryNotes = payload;
    },
    _getSingleDeliveryNote(state, { payload }) {
      state.singleDelivery = payload;
    },
    _deleteDeliveryNote(state, { payload }) {
      const index = state.deliveryNotes.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.deliveryNotes.splice(index, 1);
      }
    },
    _updateDeliveryNote(state, { payload }) {
      const index = state.deliveryNotes.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.newCustomers[index] = payload;
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addDeliveryNote,
  _getAllDeliveryNote,
  _getSingleDeliveryNote,
  _deleteDeliveryNote,
  _updateDeliveryNote,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addDeliveryNote = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addDeliveryNoteApi(data);
    const { status, newdelivery } = response.data;
    if (status === "success") {
      dispatch(_addDeliveryNote(newdelivery));
      return newdelivery.id; // Return the ID value
    }
  } catch (error) {}
};

export const getAllDeliveryNotes = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getAllDeliveryNoteApi();

    const { status, newdeliverys } = response.data;
    if (status === "success") {
      dispatch(_getAllDeliveryNote(newdeliverys));
    }
  } catch (error) {}
};

export const deleteDeliveryNotes = (id) => async (dispatch) => {
  try {
    const response = await deleteDeliveryNoteApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteDeliveryNote(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateDeliveryNotes = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateDeliveryNoteApi(data);
    const { status, newdeliverys } = response.data;
    if (status === "success") {
      dispatch(_updateDeliveryNote(newdeliverys));
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const getDeliveryNoteById = (id) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getDeliveryNoteByIdApi(id);

    const { status, delivery } = response.data;
    if (status === "success") {
      dispatch(_getSingleDeliveryNote(delivery));
    }
  } catch (error) {
    console.log(error);
    // dispatch(hasError(error));
  }
};

import { createSlice } from "@reduxjs/toolkit";
import {
  addNewCreditInvoiceApi,
  getAllNewCreditInvoiceApi,
  deleteNewCreditInvoiceApi,
  updateNewCreditInvoiceApi,
  getNewCreditInvoiceByIdApi,
} from "src/api/customer/creditInvoiceApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  creditInvoices: [],
  singleCreditInvoice: {},
};

const slice = createSlice({
  name: "creditInvoice",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addCreditInvoice(state, { payload }) {
      state.creditInvoices.push(payload);
    },
    _getAllCreditInvoice(state, { payload }) {
      state.creditInvoices = payload;
    },
    _getSingleCreditInvoice(state, { payload }) {
      state.singleCreditInvoice = payload;
    },
    _deleteCreditInvoice(state, { payload }) {
      const index = state.creditInvoices.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.creditInvoices.splice(index, 1);
      }
    },
    _updateCreditInvoice(state, { payload }) {
      const index = state.creditInvoices.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.newCustomers[index] = payload;
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addCreditInvoice,
  _getAllCreditInvoice,
  _getSingleCreditInvoice,
  _deleteCreditInvoice,
  _updateCreditInvoice,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addCreditInvoice = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addNewCreditInvoiceApi(data);
    const { status, newcreditInvoice } = response.data;
    if (status === "success") {
      dispatch(_addCreditInvoice(newcreditInvoice));
      return newcreditInvoice.id; // Return the ID value
    }
  } catch (error) {}
};

export const getAllCreditInvoices = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getAllNewCreditInvoiceApi();

    const { status, newcreditInvoices } = response.data;
    if (status === "success") {
      dispatch(_getAllCreditInvoice(newcreditInvoices));
    }
  } catch (error) {}
};

export const deleteCreditInvoices = (id) => async (dispatch) => {
  try {
    const response = await deleteNewCreditInvoiceApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteCreditInvoice(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateCreditInvoices = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateNewCreditInvoiceApi(data);
    const { status, newcreditInvoices } = response.data;
    if (status === "success") {
      dispatch(_updateCreditInvoice(newcreditInvoices));
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const getCreditInvoiceById = (id) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getNewCreditInvoiceByIdApi(id);

    const { status, quotation } = response.data;
    if (status === "success") {
      dispatch(_getSingleCreditInvoice(quotation));
    }
  } catch (error) {
    console.log(error);
    // dispatch(hasError(error));
  }
};

import { get, post, deleteReq, put } from "src/services/restService";

export const addglAccountClassApi = (data) => {
  return post("glAccount_Class/add", data);
};
export const getAllglAccountClassApi = () => {
  return get("glAccount_Class/getAll");
};
export const deleteglAccountClassApi = (id) => {
  return deleteReq(`glAccount_Class/delete/${id}`);
};

export const updateglAccountClassApi = (data) => {
  return put(`glAccount_Class/update/${data._id}`, data);
};

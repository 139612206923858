import { get, post, deleteReq, put } from "src/services/restService";

export const addCustomerContactApi = (data) => {
  return post("customer_contact/add", data);
};
export const getAllCustomerContactApi = () => {
  return get("customer_contact/getAll");
};
export const deleteCustomerContactApi = (id) => {
  return deleteReq(`customer_contact/delete/${id}`);
};

export const updateCustomerContactApi = (data) => {
  return put(`customer_contact/update/${data._id}`, data);
};

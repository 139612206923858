import { get, post, deleteReq, put } from "../../../src/services/restService";

export const addBankTransferApi = (data) => {
  return post("bank_Transfer/add", data);
};
export const getAllBankTransferApi = () => {
  return get("bank_Transfer/getAll");
};
export const getBankTransferByIdApi = (id) => {
  return get(`bank_Transfer/getById/${id}`);
};

export const deleteBankTransferApi = (id) => {
  return deleteReq(`bank_Transfer/delete/${id}`);
};

export const updateBankTransferApi = (data) => {
  return put(`bank_Transfer/update/${data._id}`, data);
};

import { get, post, deleteReq, put } from "src/services/restService";

export const addCoaGroupApi = (data) => {
  return post("coa_group/add", data);
};
export const getAllCoaGroupApi = () => {
  return get("coa_group/getAll");
};
export const deleteCoaGroupApi = (id) => {
  return deleteReq(`coa_group/delete/${id}`);
};

export const updateCoaGroupApi = ( data) => {
  return put(`coa_group/update/${data._id}`, data);
};

import { createSlice } from "@reduxjs/toolkit";
import {
  addPurchaseOrderApi,
  getAllPurchaseOrderApi,
  getPurchaseOrderByIdApi,
  deletePurchaseOrderApi,
  updatePurchaseOrderApi,
} from "src/api/supplier/purchaseOrderApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  purchaseOrders: [],
  singlePurchaseOrder: {},
};

const slice = createSlice({
  name: "purchaseOrder",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    _addPurchaseOrder(state, { payload }) {
      state.purchaseOrders.push(payload);
    },
    _getAllPurchaseOrder(state, { payload }) {
      state.purchaseOrders = payload;
    },
    _getSinglePurchaseOrder(state, { payload }) {
      state.singlePurchaseOrder = payload;
    },
    _deletePurchaseOrder(state, { payload }) {
      const index = state.purchaseOrders.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.purchaseOrders.splice(index, 1);
      }
    },
    _updatePurchaseOrder(state, { payload }) {
      const index = state.purchaseOrders.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.newCustomers[index] = payload;
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addPurchaseOrder,
  _getAllPurchaseOrder,
  _getSinglePurchaseOrder,
  _updatePurchaseOrder,
  _deletePurchaseOrder,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addPurchaseOrder = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addPurchaseOrderApi(data);
    console.log("get addPurchaseOrderApi====", response);
    const { status, newpurchaseOrder } = response.data;
    if (status === "success") {
      dispatch(_addPurchaseOrder(newpurchaseOrder));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addPurchaseOrderApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};
export const getAllPurchaseOrders = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllPurchaseOrderApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, newpurchaseOrders } = response.data;
    if (status === "success") {
      dispatch(_getAllPurchaseOrder(newpurchaseOrders));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const deletePurchaseOrders = (id) => async (dispatch) => {
  try {
    const response = await deletePurchaseOrderApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deletePurchaseOrder(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updatePurchaseOrders = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updatePurchaseOrderApi(data);
    const { status, newpurchaseOrders } = response.data;
    if (status === "success") {
      dispatch(_updatePurchaseOrder(newpurchaseOrders));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const getPurchaseOrderById = (id) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getPurchaseOrderByIdApi(id);
    console.log("get getQuotationByIdApi====", response);
    const { status, purchaseOrder } = response.data;
    if (status === "success") {
      dispatch(_getSinglePurchaseOrder(purchaseOrder));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    console.log(error);
    // dispatch(hasError(error));
  }
};

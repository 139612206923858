import { get, post, deleteReq, put } from "src/services/restService";

export const addBankAccountApi = (data) => {
  return post("bank_Account/add", data);
};
export const getAllBankAccountApi = () => {
  return get("bank_Account/getAll");
};
export const deleteBankAccountApi = (id) => {
  return deleteReq(`bank_Account/delete/${id}`);
};

export const updateBankAccountApi = ( data) => {
  return put(`bank_Account/update/${data._id}`, data);
};

import { createSlice } from "@reduxjs/toolkit";
import {
    addNewBranchApi,
    getAllNewBranchApi,
    updateNewBranchApi,
    deleteNewBranchApi
} from "src/api/customer/newBranchApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  newBranchs: [],
};

const slice = createSlice({
  name: "newBranch",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addNewBranch(state, { payload }) {
      state.newBranchs.push(payload);
    },
    _getAllNewBranch(state, { payload }) {
      state.newBranchs = payload;
    },
    _updateNewBranch(state, { payload }) {
      const index = state.newBranchs.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.newBranchs[index] = payload;
      }
    },

    _deleteNewBranch(state, { payload }) {
      const index = state.newBranchs.findIndex((item) => item._id === payload);
      if (index !== -1) {
        state.newBranchs.splice(index, 1);
      }
    },
    

    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addNewBranch,
  _getAllNewBranch,
  _updateNewBranch,
  _deleteNewBranch,
  _editNewBranch,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addNewBranch = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addNewBranchApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, newbranch } = response.data;
    if (status === "success") {
      dispatch(_addNewBranch(newbranch));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllNewBranchs = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllNewBranchApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, newbranchs } = response.data;
    if (status === "success") {
      dispatch(_getAllNewBranch(newbranchs));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const updateNewBranchs = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateNewBranchApi(data);
    const { status, newbranchs } = response.data;
    if (status === "success") {
      dispatch(_updateNewBranch(newbranchs));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};



export const deleteNewBranch = (id) => async (dispatch) => {
  try {
    const response = await deleteNewBranchApi(id);
    console.log("deleteNewBranchApi response ====", response);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteNewBranch(id));
    }
  } catch (error) {
    // dispatch(hasError(error));
  }
}
import { createSlice } from "@reduxjs/toolkit";
import {
  addChartofAccountApi,
  getAllChartofAccountApi,
  deleteChartofAccountApi,
  updateChartofAccountApi
} from "src/api/generalledger/chartofAccountApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  chartofAccounts: [],
};

const slice = createSlice({
  name: "chartofAccount",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addChartofAccount(state, { payload }) {
      state.chartofAccounts.push(payload);
    },
    _getAllChartofAccount(state, { payload }) {
      state.chartofAccounts = payload;
    },
    _deleteChartofAccount(state, { payload }) {
      const index = state.chartofAccounts.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.chartofAccounts.splice(index, 1);
      }
    },
    _updateChartofAccount(state, { payload }) {
      const index = state.chartofAccounts.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.chartofAccounts[index] = payload;
      }
    },


    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addChartofAccount,
  _getAllChartofAccount,
  _deleteChartofAccount,
  _updateChartofAccount,
  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addChartofAccount = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addChartofAccountApi(data);
    console.log("get addSalesPersonApi>>>>", response);
    const { status, chartofAccount } = response.data;
    if (status === "success") {
      dispatch(_addChartofAccount(chartofAccount));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllChartofAccounts = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllChartofAccountApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, chartofAccounts } = response.data;
    if (status === "success") {
      dispatch(_getAllChartofAccount(chartofAccounts));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};
export const deleteChartofAccounts  = (id) => async (dispatch) => {
  try {
    const response = await deleteChartofAccountApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteChartofAccount(id));
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateChartofAccounts = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateChartofAccountApi(data);
    const { status, chartofAccounts } = response.data;
    if (status === "success") {
      dispatch(_updateChartofAccount(chartofAccounts));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
import { createSlice } from "@reduxjs/toolkit";
import {
  addBankAccountApi,
  getAllBankAccountApi,
  deleteBankAccountApi,
  updateBankAccountApi,
} from "src/api/generalledger/bankAccountApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  bankAccounts: [],
};

const slice = createSlice({
  name: "bankAccount",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addbankAccount(state, { payload }) {
      state.bankAccounts.push(payload);
    },
    _getAllbankAccount(state, { payload }) {
      state.bankAccounts = payload;
    },
    _deletebankAccount(state, { payload }) {
      const index = state.bankAccounts.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.bankAccounts.splice(index, 1);
      }
    },
    _updatebankAccount(state, { payload }) {
      const index = state.bankAccounts.findIndex(
        (bank) => bank._id === payload._id
      );
      if (index !== -1) {
        state.bankAccounts[index] = payload;
      }
    },

    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addbankAccount,
  _getAllbankAccount,
  _deletebankAccount,
  _updatebankAccount,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addBankAccount = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addBankAccountApi(data);
    console.log("get addSalesPersonApi>>>>", response);
    const { status, bankAccount } = response.data;
    if (status === "success") {
      dispatch(_addbankAccount(bankAccount));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllBankAccounts = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllBankAccountApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, bankAccounts } = response.data;
    if (status === "success") {
      dispatch(_getAllbankAccount(bankAccounts));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};
export const deleteBankAccounts = (id) => async (dispatch) => {
  try {
    const response = await deleteBankAccountApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deletebankAccount(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateBankAccounts = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateBankAccountApi(data);
    const { status, bankAccounts } = response.data;
    if (status === "success") {
      dispatch(_updatebankAccount(bankAccounts));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};

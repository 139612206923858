import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Tooltip, IconButton, DialogActions, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "src/components/Iconify";
import { DialogAnimate } from "src/components/animate";
//
import InvoicePDF from "./InvoicePDF";
import GstInvoicePDF from "./GstInvoicePDF";
import UnderTakingPDF from "./UnderTakingPDF";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginBottom: theme.spacing(5),
}));

// ----------------------------------------------------------------------

InvoiceToolbar.propTypes = {
  singlecreditInvoiceObj: PropTypes.object.isRequired,
};

export default function InvoiceToolbar({
  singlecreditInvoiceObj,
  deliveryNoteID,
}) {
  console.log("props.singlecreditInvoiceObj <<<<", singlecreditInvoiceObj);
  const [openPDF, setOpenPDF] = useState(false);
  const [openGSTPDF, setopenGSTPDF] = useState(false);
  const [creditInvoicePdf, setcreditInvoicePdf] = useState({});
  const [deliveryNoteIPdf, setdeliveryNoteIPdf] = useState();
  console.log("props.quotationPdfData <<<<", singlecreditInvoiceObj);

  useEffect(() => {
    setdeliveryNoteIPdf(deliveryNoteID);
  });
  console.log("deliveryNoteIPdf", deliveryNoteIPdf);
  useEffect(() => {
    setcreditInvoicePdf(singlecreditInvoiceObj);
  }, [singlecreditInvoiceObj]);

  console.log("id check<<<< ", creditInvoicePdf?.id);
  // rest of the component code
  const handleOpenPreview = () => {
    setOpenPDF(true);
  };

  const handleClosePreview = () => {
    setOpenPDF(false);
  };
  const handleGSTOpenPreview = () => {
    setopenGSTPDF(true);
  };

  const handleGSTClosePreview = () => {
    setopenGSTPDF(false);
  };
  return (
    <RootStyle>
      <Button
        color="error"
        size="small"
        variant="contained"
        endIcon={<Iconify icon={"eva:share-fill"} />}
      >
        Share
      </Button>
      <PDFDownloadLink
        document={
          <UnderTakingPDF
            creditInvoicePdf={creditInvoicePdf}
            deliveryNoteIPdf={deliveryNoteIPdf}
          />
        }
        fileName={`UNDERTAKING-${creditInvoicePdf?.id}`}
        style={{ textDecoration: "none" }}
      >
        {({ loading }) => (
          <LoadingButton
            size="small"
            loading={loading}
            variant="contained"
            loadingPosition="end"
            endIcon={<Iconify icon={"eva:download-fill"} />}
          >
            Download UNDERTAKING
          </LoadingButton>
        )}
      </PDFDownloadLink>
      <Button
        color="info"
        size="small"
        variant="contained"
        onClick={handleOpenPreview}
        endIcon={<Iconify icon={"eva:eye-fill"} />}
        sx={{ mx: 1 }}
      >
        Preview Commercail Invoice
      </Button>

      <PDFDownloadLink
        document={
          <InvoicePDF
            creditInvoicePdf={creditInvoicePdf}
            deliveryNoteIPdf={deliveryNoteIPdf}
          />
        }
        fileName={`CreditInvoice-${creditInvoicePdf?.id}`}
        style={{ textDecoration: "none" }}
      >
        {({ loading }) => (
          <LoadingButton
            size="small"
            loading={loading}
            variant="contained"
            loadingPosition="end"
            endIcon={<Iconify icon={"eva:download-fill"} />}
          >
            Download Commercial Invoice
          </LoadingButton>
        )}
      </PDFDownloadLink>
      <Button
        color="info"
        size="small"
        variant="contained"
        onClick={handleGSTOpenPreview}
        endIcon={<Iconify icon={"eva:eye-fill"} />}
        sx={{ mx: 1 }}
      >
        Preview G.S.T Invoice
      </Button>
      <PDFDownloadLink
        document={
          <GstInvoicePDF
            creditInvoicePdf={creditInvoicePdf}
            deliveryNoteIPdf={deliveryNoteIPdf}
          />
        }
        fileName={`CreditInvoice-${creditInvoicePdf?.id}`}
        style={{ textDecoration: "none" }}
      >
        {({ loading }) => (
          <LoadingButton
            size="small"
            loading={loading}
            variant="contained"
            loadingPosition="end"
            endIcon={<Iconify icon={"eva:download-fill"} />}
          >
            Download G.S.T Invoice
          </LoadingButton>
        )}
      </PDFDownloadLink>

      <DialogAnimate fullScreen open={openPDF}>
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <DialogActions
            sx={{
              zIndex: 9,
              padding: "12px !important",
              boxShadow: (theme) => theme.customShadows.z8,
            }}
          >
            <Tooltip title="Close">
              <IconButton color="inherit" onClick={handleClosePreview}>
                <Iconify icon={"eva:close-fill"} />
              </IconButton>
            </Tooltip>
          </DialogActions>
          <Box sx={{ flexGrow: 1, height: "100%", overflow: "hidden" }}>
            {creditInvoicePdf.id ? (
              <PDFViewer width="100%" height="100%" style={{ border: "none" }}>
                <InvoicePDF
                  creditInvoicePdf={creditInvoicePdf}
                  deliveryNoteIPdf={deliveryNoteIPdf}
                />
              </PDFViewer>
            ) : (
              <Box>No PDF to preview</Box>
            )}
          </Box>
        </Box>
      </DialogAnimate>
      <DialogAnimate fullScreen open={openGSTPDF}>
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <DialogActions
            sx={{
              zIndex: 9,
              padding: "12px !important",
              boxShadow: (theme) => theme.customShadows.z8,
            }}
          >
            <Tooltip title="Close">
              <IconButton color="inherit" onClick={handleGSTClosePreview}>
                <Iconify icon={"eva:close-fill"} />
              </IconButton>
            </Tooltip>
          </DialogActions>
          <Box sx={{ flexGrow: 1, height: "100%", overflow: "hidden" }}>
            {creditInvoicePdf.id ? (
              <PDFViewer width="100%" height="100%" style={{ border: "none" }}>
                <GstInvoicePDF
                  creditInvoicePdf={creditInvoicePdf}
                  deliveryNoteIPdf={deliveryNoteIPdf}
                />
              </PDFViewer>
            ) : (
              <Box>No PDF to preview</Box>
            )}
          </Box>
        </Box>
      </DialogAnimate>
    </RootStyle>
  );
}

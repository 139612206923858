import { get, post, deleteReq, put } from "src/services/restService";

export const addBarCodesApi = (data) => {
  return post("bar_code/add", data);
};
export const getAllBarCodesApi = () => {
  return get("bar_code/getAll");
};
export const deleteBarCodesApi = (id) => {
  return deleteReq(`bar_code/delete/${id}`);
};

export const updateBarCodesApi = ( data) => {
  return put(`bar_code/update/${data._id}`, data);
};

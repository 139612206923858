import { createSlice } from "@reduxjs/toolkit";
import {
  addLocationTransferApi,
  getAllLocationTransferApi,
  getLocationTransferByIdApi,
  deleteLocationTransferApi,
  updateLocationTransferApi,
} from "src/api/inventory/locationTransferApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  locationTransfers: [],
  singleLocationTransfer: {},
};

const slice = createSlice({
  name: "locationTransfer",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addLocationTransfer(state, { payload }) {
      state.locationTransfers.push(payload);
    },
    _getAllLocationTransfer(state, { payload }) {
      state.locationTransfers = payload;
    },
    _getSingleLocationTransfer(state, { payload }) {
      state.singleLocationTransfer = payload;
    },
    _deleteLocationTransfer(state, { payload }) {
      const index = state.locationTransfers.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.locationTransfers.splice(index, 1);
      }
    },
    _updateLocationTransfer(state, { payload }) {
      const index = state.locationTransfers.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.newCustomers[index] = payload;
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addLocationTransfer,
  _getAllLocationTransfer,
  _getSingleLocationTransfer,
  _deleteLocationTransfer,
  _updateLocationTransfer,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addLocationTransfer = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addLocationTransferApi(data);
    const { status, newlocationTransfer } = response.data;
    if (status === "success") {
      dispatch(_addLocationTransfer(newlocationTransfer));
      return newlocationTransfer.id; // Return the ID value
    }
  } catch (error) {}
};

export const getAllLocationTransfers = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getAllLocationTransferApi();

    const { status, newlocationTransfers } = response.data;
    if (status === "success") {
      dispatch(_getAllLocationTransfer(newlocationTransfers));
    }
  } catch (error) {}
};

export const deleteLocationTransfers = (id) => async (dispatch) => {
  try {
    const response = await deleteLocationTransferApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteLocationTransfer(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateLocationTransfers = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateLocationTransferApi(data);
    const { status, newlocationTransfers } = response.data;
    if (status === "success") {
      dispatch(_updateLocationTransfer(newlocationTransfers));
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const getLocationTransferById = (id) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getLocationTransferByIdApi(id);

    const { status, locationTransfer } = response.data;
    if (status === "success") {
      dispatch(_getSingleLocationTransfer(locationTransfer));
    }
  } catch (error) {
    console.log(error);
    // dispatch(hasError(error));
  }
};

import { createSlice } from "@reduxjs/toolkit";
import {
  addInventoryMovementApi,
  getAllInventoryMovementApi,
  deleteInventoryMovementApi,
  updateInventoryMovementApi
} from "src/api/inventory/inventoryMovementApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  inventoryMovements: [],
};

const slice = createSlice({
  name: "inventoryMovement",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addInventorymovement(state, { payload }) {
      state.inventoryMovements.push(payload);
    },
    _getAllInventoryMovement(state, { payload }) {
      state.inventoryMovements = payload;
    },
    _deleteInventoryMovement(state, { payload }) {
      const index = state.inventoryMovements.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.inventoryMovements.splice(index, 1);
      }
    },

    _updateInventoryMovement(state, { payload }) {
      const index = state.inventoryMovements.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.inventoryMovements[index] = payload;
      }
    },

    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addInventorymovement,
  _getAllInventoryMovement,
  _deleteInventoryMovement,
  _updateInventoryMovement,
  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addInventoryMovement = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addInventoryMovementApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, inventoryMovement } = response.data;
    if (status === "success") {
      dispatch(_addInventorymovement(inventoryMovement));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllInventoryMovements = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllInventoryMovementApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, inventoryMovements } = response.data;
    if (status === "success") {
      dispatch(_getAllInventoryMovement(inventoryMovements));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const deleteInventoryMovements = (id) => async (dispatch) => {
  try {
    const response = await deleteInventoryMovementApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(  _deleteInventoryMovement(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateInventoryMovements = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateInventoryMovementApi(data);
    const { status, inventoryMovements } = response.data;
    if (status === "success") {
      dispatch(_updateInventoryMovement(inventoryMovements));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
import { get, post, deleteReq, put } from "../../../src/services/restService";

export const addGrnReceivedApi = (data) => {
  return post("Grn_Details/add", data);
};
export const getAllGrnReceivedApi = () => {
  return get("Grn_Details/getAll");
};
export const getGrnReceivedByIdApi = (id) => {
  return get(`Grn_Details/getById/${id}`);
};

export const deleteGrnReceivedApi = (id) => {
  return deleteReq(`Grn_Details/delete/${id}`);
};

export const updateGrnReceivedApi = (data) => {
  return put(`Grn_Details/update/${data._id}`, data);
};

import { get, post, deleteReq, put } from "src/services/restService";

export const addSalesTypeApi = (data) => {
  return post("sales-type/add", data);
};
export const getAllSalesTypesApi = () => {
  return get("sales-type/getAll");
};
export const deleteSalesTypeApi = (id) => {
  return deleteReq(`sales-type/delete/${id}`);
};

export const updateSalesTypeApi = (data) => {
  return put(`sales-type/update/${data._id}`, data);
};

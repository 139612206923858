import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

import dayjs from "dayjs";

// form

import { useDispatch, useSelector } from "src/redux/store";
import jwt from "jsonwebtoken";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Box, Grid, Card, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// hooks
import useAuth from "src/hooks/useAuth";
// components
import {
  FormProvider,
  RHFSwitch,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";

import SalesQuotaionsItems from "./quotationsComponents/SalesQuotaionsItems";
import { getAllNewCustomers } from "src/redux/slices/customer/newCustomer";
import { getAllSalesPersons } from "src/redux/slices/salesPerson";
import { getAllCurrencyItems } from "src/redux/slices/generalledger/currencyItem";
import { getAllSalesTypes } from "src/redux/slices/salesType";
import { getAllInventoryLocations } from "src/redux/slices/inventory/inventoryLocation";
import { getAllShippingCompanys } from "src/redux/slices/setting/shippingCompany";
import { getAllPaymentTerms } from "src/redux/slices/setting/paymentTerm";
import { getAllGstDatas } from "src/redux/slices/setting/gstData";
import { getAllNewBranchs } from "src/redux/slices/customer/newBranch";
import {
  addQuotation,
  updateQuotations,
} from "src/redux/slices/customer/quotation";

import { useLocation } from "react-router-dom";

import { getUsers, signIn } from "src/redux/slices/user";

// ----------------------------------------------------------------------

export default function Quotations() {
  const dispatch = useDispatch();
  const location = useLocation();
  const data = location.state?.newData;
  const isEdit = location.state?.isEdit;
  //////////////// updated quotation work component all is done ////////////////////////
  const [isModalOpen, setModalOpen] = useState(false);
  //////////////////////Selectors /////////////////////////////

  console.log("newData>>>>", location.state?.newData);

  console.log("newData>>>>data", data);

  const { newBranchs } = useSelector((state) => state.newBranch);
  const { newCustomers } = useSelector((state) => state.newCustomer);
  const { salesPersons } = useSelector((state) => state.salesPerson);
  const { salesTypes } = useSelector((state) => state.salesType);
  const currencyItem = useSelector((state) => state.currencyItem);
  const { currencyItems } = currencyItem || {};

  const { inventoryLocations } = useSelector(
    (state) => state.inventoryLocation
  );
  const { shippingCompanys } = useSelector((state) => state.shippingCompany);
  const { paymentTerms } = useSelector((state) => state.paymentTerm);
  const { gstDatas } = useSelector((state) => state.gstData);
  ////////////////////////////user information////////////////////////////
  const { users } = useSelector((state) => state.user);
  const userInfo = useSelector((state) => state.user.userInfo);
  const isUserAuthenticated = !!userInfo && !!userInfo.accessToken;
  const userId = isUserAuthenticated
    ? jwt.decode(userInfo.accessToken)._id
    : null;

  useEffect(() => {
    if (isUserAuthenticated) {
      console.log("Current User ID:>>>>>", userId);
    } else {
      console.log("No Current User");
    }
  }, [isUserAuthenticated, userId]);

  ////////////////Use State ////////////////////////////////////
  const [idValue, setIdValue] = useState("");

  const [branchList, setbranchList] = useState([]);
  const [salesPersonList, setsalesPersonList] = useState([]);
  const [customerList, setcustomerList] = useState([]);
  const [inventoryLocationList, setinventoryLocationList] = useState([]);
  const [shippingList, setshippingList] = useState([]);
  const [paymentTermList, setpaymentTermList] = useState([]);
  const [CurrencyList, setCurrencyList] = useState([]);
  const [salesTypeList, setsalesTypeList] = useState([]);
  const [taxGroupList, settaxGroupList] = useState([]);
  const [quoatationDetail, setquoatationDetail] = useState({
    items: [],
    total: 0,
  });
  console.log("quoatationDetail------", quoatationDetail);
  ///////////...this value use for Edit case ....////////////////////////
  const [selectedCustomerReference, setselectedCustomerReference] =
    useState("");

  const [selectedAddress, setselectedAddress] = useState("");
  const [selectedpurchaseOrder, setpurchaseOrder] = useState("");
  const [selectedshippingCom, setselectedshippingCom] = useState("");
  const [selecteddeliveryTerms, setselecteddeliveryTerms] = useState("");
  const [selectedcurrency, setselectedcurrency] = useState("");
  const [selectedshippingTerms, setselectedshippingTerms] = useState("");
  const [selectedatn, setselectedatn] = useState("");

  const [selectedDeliveryTo, setDeliveryTo] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("");
  const [selectedSalesPerson, setSelectedSalesPerson] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedComments, setselectedComments] = useState("");
  const [selectedDeliverLocation, setDeliverLocation] = useState("");
  const [cusDiscount, setCusDiscount] = useState("");
  const [exchangeRate, setExchangeRate] = useState("");
  const [currentCredit, setCurrentCredit] = useState("");
  const [quotationReferenceId, setQuotationReferenceId] = useState("");
  const [quotationItems, setQuotationItems] = useState({ items: [], total: 0 });

  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCustomerData, setSelectedCustomerData] = useState(null);
  const [filteredBranchList, setFilteredBranchList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedBranchData, setSelectedBranchData] = useState({});
  const [shippingCost, setshippingCost] = useState(0);
  const [taxCost, settaxCost] = useState(0);
  const [afterTax, setafterTax] = useState(0);
  const [taxGroup, settaxGroup] = useState(0);
  const [localIsEdit, setlocalIsEdit] = useState(isEdit);
  const [editTaxCost, seteditTaxCost] = useState(0);
  const [editAfterTax, seteditAfterTax] = useState(0);
  const [editShippingCost, seteditShippingCost] = useState(0);
  const [editTaxGroup, seteditTaxGroup] = useState(0);
  const [cusName, setCusName] = useState("");
  const [address, setAddress] = useState("");
  const [customerCurrency, setCustomerCurrency] = useState("");

  // const data = location?.state;
  const currency = data?.currency;

  const UpdateUserSchema = Yup.object().shape({
    cusDiscount: Yup.string().required("RFQ Id is required"),
  });
  const defaultValues = {
    customer: "",
    branch: "",
    quotationReferenceId: "",
    cusDiscount: "",
    exchangeRate: "",
    currentCredit: "",
    purchaseOrderDate: "",
    PhoneNumber: "",
    payment: "",
    salesPerson: "",
    quotationDate: "",
    deliverLocation: "",
    quotationDeliveryDate: "",
    deliveryTo: "",
    contactPhoneNum: "",
    comments: "",
    atn: "",
    currentUser: "",
    shippingTerms: "",
    currency: data?.currency || "",
    puOrderDate: "",
    deliveryTerms: "",
    shippingCom: "",
    purchaseOrder: "",
    address: "",
    customerReference: data?.customerReference || 0,
    afterTax: 0,
    shippingCost: 0,
    taxCost: 0,
    taxGroup: 0,
  };
  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues: isEdit ? data : defaultValues, // Use 'data' here
  });

  const {
    reset,
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    console.log("Form Values:", methods.getValues());
  }, [methods]);
  // const { currencyItems } = currencyItem || {};

  //////////////////////Use Effect //////////////////////////////////

  const handleCustomerChange = (event) => {
    const selectedCusName = event.target.value;
    setSelectedCustomer(selectedCusName);

    const selectedCusData = newCustomers.find(
      (cus) => cus.cusName === selectedCusName
    );
    setSelectedCustomerData(selectedCusData);

    if (selectedCusData) {
      const selectedTaxGroup = selectedCusData.taxGroup;
      settaxGroup(selectedTaxGroup);
    }

    if (selectedCusData) {
      const filteredBranchs = newBranchs.filter(
        (branch) => branch.customerId === selectedCusData._id
      );
      const temp = filteredBranchs.map((item) => item.name);

      setFilteredBranchList(temp);
      setSelectedBranch("");
      setSelectedBranchData({});
    } else {
      setFilteredBranchList([]);
      setSelectedBranch("");
      setSelectedBranchData({});
    }
  };
  const handleBranchChange = (event) => {
    const selectedBranchValue = event.target.value;
    console.log("Selected Branch Value://///", selectedBranchValue);

    setSelectedBranch(selectedBranchValue);

    const selectedBranchData = newBranchs.find(
      (branch) => branch.name === selectedBranchValue
    );

    setSelectedBranchData(selectedBranchData || {});
  };
  ////////////////////////////////is Edit CASE ////////////////////////////////////
  useEffect(() => {
    if (data && data.quoatationDetails) {
      setquoatationDetail(data.quoatationDetails);
    }
  }, [data]);

  const handleUpdateQuoatationDetail = (updatedQuoatationDetail) => {
    setquoatationDetail(updatedQuoatationDetail);
  };

  console.log("updatedQuoatationDetail OUT<<<<>>>>", quoatationDetail);

  useEffect(() => {
    if (isEdit && location.state.newData) {
      const newData = location.state.newData;
      console.log("location.state.newData", location.state.newData);
      setSelectedCustomer(newData.customer);
      setSelectedBranch(newData.branch);
      console.log("newData.branch", newData.branch);
      setQuotationReferenceId(newData.quotationReferenceId);
      setCusDiscount(newData.cusDiscount);
      setExchangeRate(newData.exchangeRate);
      setCurrentCredit(newData.currentCredit);
      setSelectedPayment(newData.payment);
      setSelectedSalesPerson(newData.salesPerson);
      setDeliveryTo(newData.deliveryTo);
      setDeliverLocation(newData.deliverLocation);
      setselectedComments(newData.comments);
      setselectedAddress(newData.address);
      setselectedCustomerReference(newData.customerReference);
      setpurchaseOrder(newData.purchaseOrder);
      setselectedshippingCom(newData.shippingCom);
      setselecteddeliveryTerms(newData.deliveryTerms);
      setselectedcurrency(newData.currency);
      setselectedshippingTerms(newData.shippingTerms);
      setselectedatn(newData.atn);
      setPhoneNumber(newData.PhoneNumber);

      if (newData.quotationDate) {
        const formattedDate = dayjs(newData.quotationDate).format("YYYY-MM-DD");
        setQuotationDate(formattedDate);
      }

      if (newData.quotationDeliveryDate) {
        const formattedDate = dayjs(newData.quotationDeliveryDate).format(
          "YYYY-MM-DD"
        );
        setQuotationDeliveryDate(formattedDate);
      }

      if (newData.purchaseOrderDate) {
        const formattedDate = dayjs(newData.purchaseOrderDate).format(
          "YYYY-MM-DD"
        );
        setPurchaseOrderDate(formattedDate);
      }
    }
  }, [isEdit, location.state]);

  console.log("check final  value<><><><", editAfterTax);
  useEffect(() => {
    if (data && data.quoatationDetails) {
      setquoatationDetail(data.quoatationDetails);
    }

    if (data && data.taxGroup) {
      seteditTaxGroup(data.taxGroup);
    }
    if (data && data.taxCost) {
      seteditTaxCost(data.taxCost);
    }
    if (data && data.shippingCost) {
      seteditShippingCost(data.shippingCost);
    }
    if (data && data.afterTax) {
      seteditAfterTax(data.afterTax);
    }
  }, [data]);

  ////////////////////////Customer ///////////////////////////////////
  useEffect(() => {}, [taxGroup]);
  useEffect(() => {
    // Code to retrieve customer data based on the selectedCustomer value
  }, [selectedCustomer]);

  useEffect(() => {
    if (newCustomers.length > 0) {
      const {
        cusName,
        address,
        ntnNum,
        gstNum,
        phone,
        taxGroup,
        customerCurrency,
      } = newCustomers[0];
      setcustomerList(newCustomers.map((item) => item.cusName));
      setCusName(cusName);
      setAddress(address);

      settaxGroup(taxGroup);
      setCustomerCurrency(customerCurrency);

      setValue("gstNum", gstNum);
      setValue("ntnNum", ntnNum);
      setValue("PhoneNumber", phone);
    }
  }, [newCustomers, setValue]);

  useEffect(() => {
    getNewCustomers();
  }, []);

  const getNewCustomers = async () => {
    await dispatch(getAllNewCustomers());
  };

  ///////////////////////Branch //////////////////////////////
  useEffect(() => {
    const temp = newBranchs.map((item) => item.name);
    setbranchList(temp);
  }, [newBranchs]);

  useEffect(() => {
    getNewBranchs();
  }, []);

  const getNewBranchs = async () => {
    await dispatch(getAllNewBranchs());
  };
  //////////////////////Sales Person//////////////////////////
  useEffect(() => {
    getSalesPersons();
  }, []);

  const getSalesPersons = async () => {
    await dispatch(getAllSalesPersons());
  };
  useEffect(() => {
    const temp = salesPersons.map((item) => item.name);
    setsalesPersonList(temp);
  }, [salesPersons]);
  //////////////////////Customer Currency/////////////////////////
  useEffect(() => {
    getCurrencys();
  }, []);

  const getCurrencys = async () => {
    await dispatch(getAllCurrencyItems());
  };

  useEffect(() => {
    const temp = currencyItems.map((item) => item.Name);
    setCurrencyList(temp);
  }, [currencyItems]);
  //////////////////////////Sales Type///////////////////////////////
  useEffect(() => {
    getSalesTypes();
  }, []);

  const getSalesTypes = async () => {
    await dispatch(getAllSalesTypes());
  };

  useEffect(() => {
    const temp = salesTypes.map((item) => item.name);
    setsalesTypeList(temp);
  }, [salesTypes]);
  ////////////////////////  Inventory Location /////////////////////

  useEffect(() => {
    getInventoryLocations();
  }, []);

  const getInventoryLocations = async () => {
    await dispatch(getAllInventoryLocations());
  };
  useEffect(() => {
    const temp = inventoryLocations.map((item) => item.locationName);
    setinventoryLocationList(temp);
  }, [inventoryLocations]);

  ////////////////////////Shipping company //////////////////

  useEffect(() => {
    getShippingCompanys();
  }, []);

  const getShippingCompanys = async () => {
    await dispatch(getAllShippingCompanys());
  };
  useEffect(() => {
    const temp = shippingCompanys.map((item) => item.companyName);
    setshippingList(temp);
  }, [shippingCompanys]);

  ////////////////////Payment Terms/////////////////////////////
  useEffect(() => {
    getPaymentTerms();
  }, []);

  const getPaymentTerms = async () => {
    await dispatch(getAllPaymentTerms());
  };
  useEffect(() => {
    const temp = paymentTerms.map((item) => item.termDescription);
    setpaymentTermList(temp);
  }, [paymentTerms]);

  /////////////////////////////Tax Group ///////////////////////////////

  useEffect(() => {
    getGstDatas();
  }, []);

  const getGstDatas = async () => {
    await dispatch(getAllGstDatas());
  };
  useEffect(() => {
    const temp = gstDatas.map((item) => item.descrption);
    settaxGroupList(temp);
  }, [gstDatas]);

  const { enqueueSnackbar } = useSnackbar();
  const [quotationDate, setQuotationDate] = useState(
    dayjs().format("YYYY-MM-DD")
  ); // Initialize with the current date in "YYYY-MM-DD" format
  const [quotationDeliveryDate, setQuotationDeliveryDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [purchaseOrderDate, setPurchaseOrderDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const handleDateChangeQuotations = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setQuotationDate(formattedDate);
  };
  const handleDateChangeQuotation = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setQuotationDeliveryDate(formattedDate);
  };
  const handleDateChangePurchase = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");

    setPurchaseOrderDate(formattedDate);
  };

  ////////////////////////quotation Refernce////////////////////////////////

  const { user } = useAuth();

  const addNewQuotationFun = async (data) => {
    try {
      setQuotationItems({ items: [], total: 0 });

      // Get the selected customer data
      const selectedCusData = newCustomers.find(
        (cus) => cus.cusName === selectedCustomer
      );

      data.customer = selectedCustomer;
      data.branch = selectedBranch;
      data.PhoneNumber = selectedBranchData.PhoneNumber;
      data.address = selectedBranchData.billingAddress;
      data.quotationDate = quotationDate;
      data.quotationDeliveryDate = quotationDeliveryDate;
      data.purchaseOrderDate = purchaseOrderDate;
      data.quoatationDetails = quotationItems;
      data.taxGroup = taxGroup;
      data.afterTax = afterTax;
      data.taxCost = taxCost;
      data.shippingCost = shippingCost;
      data.currentUser = userId;
      if (selectedCusData) {
        data.gstNum = selectedCusData.gstNum;
        data.ntnNum = selectedCusData.ntnNum;
      }

      setSelectedCustomer("");
      setSelectedBranch("");
      setSelectedBranchData({});
      settaxGroup("");

      console.log("dispatch>>>>>>>", userId);

      if (userId !== null) {
        const id = await dispatch(
          addQuotation(
            data,
            shippingCost,
            taxGroup,
            taxCost,
            afterTax,

            String(userId)
          )
        );
        setIdValue(id);
        enqueueSnackbar("Add success!");
        reset();
        openPopupWindow(id);
        return id;
      } else {
        console.log("User not authenticated");
        // Handle the case where userId is not available
      }
      // reset(isEdit ? state.newData : defaultValues);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error updating Successfully", { variant: "error" });
    }
  };

  const onSubmit = async (data) => {
    try {
      if (userId !== null) {
        if (isEdit) {
          data.quoatationDetails = quoatationDetail;
          await updateNewQuotationsFun(data);
        } else {
          console.log("onsubmit>>>>>", userId);
          const id = await addNewQuotationFun(data, userId);
          // setModalOpen(true);
          // openPopupWindow(id);
        }
      } else {
        console.log("User not authenticated");
      }
    } catch (error) {
      console.error(error);
      // Handle the error if needed
    }
  };

  const openPopupWindow = (id) => {
    const newWindow = window.open("", "Popup", "width=800,height=600");
    newWindow.document.title = "Popup Window";

    const link1 = newWindow.document.createElement("a");
    link1.href = `/dashboard/customers/reports/${id}`;
    link1.innerHTML = "QUOTATION PRINT VIEW";
    link1.style.display = "block";
    link1.style.margin = "20px";
    link1.addEventListener("click", (event) => {
      event.preventDefault();
      const newPath = `/dashboard/customers/reports/${id}`;
      newWindow.location.href = newPath;
    });

    newWindow.document.body.appendChild(link1);
    newWindow.onbeforeunload = function () {
      reset();
    };
  };

  const updateNewQuotationsFun = async (data) => {
    try {
      data.customer = selectedCustomer;
      data.branch = selectedBranch;
      data.taxCost = editTaxCost;
      data.taxGroup = editTaxGroup;
      data.shippingCost = editShippingCost;
      data.afterTax = editAfterTax;
      await dispatch(updateQuotations(data));
      enqueueSnackbar("Update success!");

      reset();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error updating payment term.", { variant: "error" });
    }
  };
  console.log("check updated quotation values ", quotationItems);
  const addQuotationItem = (item) => {
    setQuotationItems((prevState) => [
      ...prevState,
      { ...item, quotationReferenceId: Quotations.quotationReferenceId },
    ]);
  };

  return (
    <>
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        sx={{ border: 1, borderColor: "black" }}
      >
        <Grid
          px={1}
          py={1}
          container
          spacing={1}
          sx={{ border: 1, borderColor: "#FB7600", borderRadius: 1 }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Card
              height={3}
              sx={{
                p: 1,
                background: "rgba(145, 158, 171, 0.12)",
                borderRadius: 1,
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  rowGap: 2,
                  columnGap: 1,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(1, 1fr)",
                  },
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                      <RHFSelect
                        name="customer"
                        label="Customers"
                        size="small"
                        sx={{ mt: 1 }}
                        value={selectedCustomer}
                        onChange={handleCustomerChange}
                      >
                        <option value="" />
                        {customerList.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </RHFSelect>
                      {console.log("selectedBranch", selectedBranch)}
                      {console.log(" ", filteredBranchList)}
                      <RHFSelect
                        name="branch"
                        label="Branch"
                        size="small"
                        sx={{ mt: 1 }}
                        disabled={!selectedCustomerData}
                        value={selectedBranch}
                        onChange={handleBranchChange}
                      >
                        <option value="" />
                        {filteredBranchList.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </RHFSelect>
                      <RHFTextField
                        name="quotationReferenceId   "
                        label="Reference"
                        size="small"
                        // value={quotationReferenceId}
                        defaultValue={quotationReferenceId}
                        register={register}
                        sx={{ mt: 1 }}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                p: 1,
                background: "rgba(145, 158, 171, 0.12)",
                borderRadius: 1,
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  rowGap: 2,
                  columnGap: 1,

                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(1, 1fr)",
                  },
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                      <RHFTextField
                        name="cusDiscount"
                        label="RFQ Number"
                        size="small"
                        defaultValue={cusDiscount}
                        register={register}
                        sx={{ mt: 1 }}
                      />
                      <RHFTextField
                        name="exchangeRate"
                        label="Exchange Rate"
                        size="small"
                        // value={exchangeRate}
                        defaultValue={exchangeRate}
                        register={register}
                        sx={{ mt: 1 }}
                      />
                      <RHFTextField
                        name="currentCredit"
                        label="Current Credit"
                        size="small"
                        // value={currentCredit}
                        defaultValue={currentCredit}
                        register={register}
                        sx={{ mt: 1 }}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                p: 1,
                background: "rgba(145, 158, 171, 0.12)   ",
                borderRadius: 1,
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  rowGap: 2,
                  columnGap: 1,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(1, 1fr)",
                  },
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                      <RHFSelect
                        name="payment"
                        label="Payment"
                        placeholder="Payment"
                        size="small"
                        // value={selectedPayment}
                        defaultValue={selectedPayment}
                        register={register}
                        sx={{ mt: 1 }}
                      >
                        <option value="" />
                        {paymentTermList.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </RHFSelect>
                      <RHFSelect
                        name="salesPerson"
                        label="Sales Person"
                        placeholder="Sales Person"
                        size="small"
                        // value={selectedSalesPerson}
                        defaultValue={selectedSalesPerson}
                        register={register}
                        sx={{ mt: 1 }}
                      >
                        <option value="" />
                        {salesPersonList.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </RHFSelect>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            container
                            name="quotationDate"
                            label="Quotation Date"
                            value={quotationDate}
                            minDate={dayjs("2017-01-01")}
                            onChange={(newValue) => {
                              setValue("quotationDate", newValue); // Manually update the form value
                              handleDateChangeQuotations(newValue); // Your custom function
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                sx={{ mt: 1 }}
                              />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Grid>
        {/*----------------SALES TABLE CALLING-------------------------------------------*/}

        <SalesQuotaionsItems
          setshippingCost={setshippingCost}
          settaxCost={settaxCost}
          setafterTax={setafterTax}
          quotationItems={quotationItems}
          setQuotationItems={setQuotationItems}
          addQuotationItem={addQuotationItem}
          settaxGroup={settaxGroup}
          taxGroup={taxGroup}
          editTaxCost={editTaxCost}
          seteditTaxCost={seteditTaxCost}
          editShippingCost={editShippingCost}
          seteditShippingCost={seteditShippingCost}
          editAfterTax={editAfterTax}
          seteditAfterTax={seteditAfterTax}
          editTaxGroup={editTaxGroup}
          seteditTaxGroup={seteditTaxGroup}
          quoatationDetail={quoatationDetail}
          setquoatationDetail={setquoatationDetail}
          handleUpdateQuoatationDetail={handleUpdateQuoatationDetail}
          isEdit={isEdit}
        />
        {/*----------------3rd portion Detailing Code-------------------------------------------*/}
        <Grid mt={3} container spacing={1}>
          <Grid item xs={12} md={12}>
            <Card
              sx={{
                p: 3,
                background: "rgba(145, 158, 171, 0.12)",
                borderRadius: 1,
              }}
            >
              <h4
                style={{
                  marginBottom: 15,
                  marginTop: 10,
                  textAlign: "center",
                  color: "#ff6347",
                  fontSize: 30,
                }}
              >
                {" "}
                Quotation Delivery Details
              </h4>
              <Box
                sx={{
                  display: "grid",
                  rowGap: 2,
                  columnGap: 2,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(3, 1fr)",
                  },
                }}
              >
                <Grid mt={3} container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                      <RHFSelect
                        name="deliverLocation"
                        label="Deliver from Location"
                        placeholder="Deliver from Location"
                        // value={selectedDeliverLocation}
                        defaultValue={selectedDeliverLocation}
                        register={register}
                        size="small"
                        sx={{ mt: 2 }}
                      >
                        <option value="" />
                        {inventoryLocationList.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </RHFSelect>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3} sx={{ mt: 2 }}>
                          <DesktopDatePicker
                            label="Valid Until "
                            name="quotationDeliveryDate"
                            value={quotationDeliveryDate}
                            minDate={dayjs("2017-01-01")}
                            onChange={(newValue) => {
                              setValue("quotationDeliveryDate", newValue); // Manually update the form value
                              handleDateChangeQuotation(newValue); // Your custom function
                            }}
                            renderInput={(params) => (
                              <TextField {...params} size="small" />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Card>
                  </Grid>
                </Grid>
                <Grid mt={3} container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                      <RHFTextField
                        name="deliveryTo"
                        label="Delivery To"
                        size="small"
                        // value={selectedDeliveryTo}
                        defaultValue={selectedDeliveryTo}
                        register={register}
                        sx={{ mt: 2 }}
                      />
                      <RHFTextField
                        name="PhoneNumber "
                        label="Contact Phone Number"
                        size="small"
                        sx={{ mt: 2 }}
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </Card>
                  </Grid>
                </Grid>
                <Grid mt={3} container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                      <RHFTextField
                        name="comments"
                        label="Comments"
                        size="small"
                        // value={selectedComments}
                        defaultValue={selectedComments}
                        register={register}
                        sx={{ mt: 2 }}
                      />
                      <RHFTextField
                        name="atn"
                        label="ATTN"
                        // value={selectedatn}
                        defaultValue={selectedatn}
                        register={register}
                        size="small"
                        sx={{ mt: 2 }}
                      />
                    </Card>
                  </Grid>
                </Grid>
                <Grid mt={1} container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                      <RHFTextField
                        name="shippingTerms"
                        label="Shipping Terms"
                        // value={selectedshippingTerms}
                        defaultValue={selectedshippingTerms}
                        register={register}
                        size="small"
                        sx={{ mt: 2 }}
                      />
                      <RHFSelect
                        name="currency"
                        label="Currency"
                        // value={selectedcurrency}
                        defaultValue={selectedcurrency}
                        register={register}
                        size="small"
                        sx={{ mt: 2 }}
                      >
                        <option value="" />
                        {CurrencyList.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </RHFSelect>
                    </Card>
                  </Grid>
                </Grid>
                <Grid mt={1} container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3} sx={{ mt: 2 }}>
                          <DesktopDatePicker
                            label="Purchase Order Date"
                            value={purchaseOrderDate}
                            minDate={dayjs("2017-01-01")}
                            onChange={(newValue) => {
                              setValue("purchaseOrderDate", newValue); // Manually update the form value
                              handleDateChangePurchase(newValue); // Your custom function
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                sx={{ borderRadius: 1 }}
                              />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                      <RHFTextField
                        name="deliveryTerms"
                        label="Delivery Terms"
                        // value={selecteddeliveryTerms}
                        defaultValue={selecteddeliveryTerms}
                        register={register}
                        size="small"
                        sx={{ mt: 2, borderRadius: 1 }}
                      />
                    </Card>
                  </Grid>
                </Grid>
                <Grid mt={1} container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                      <RHFSelect
                        name="shippingCom"
                        label="Shipping Company"
                        // value={selectedshippingCom}
                        defaultValue={selectedshippingCom}
                        register={register}
                        size="small"
                        sx={{ mt: 2, borderRadius: 1 }}
                      >
                        <option value="" />
                        {shippingList.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </RHFSelect>
                      <RHFTextField
                        name="purchaseOrder"
                        label="Purchase Order"
                        // value={selectedpurchaseOrder}
                        defaultValue={selectedpurchaseOrder}
                        register={register}
                        size="small"
                        sx={{ mt: 2, borderRadius: 2 }}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </Box>

              <Grid mt={5} ml={25} container spacing={1}>
                <Grid item xs={8} sm={4} md={8}>
                  <Card sx={{ p: 3 }}>
                    <RHFTextField
                      name="address"
                      label="Address"
                      size="small"
                      value={
                        selectedBranchData?.billingAddress ||
                        selectedCustomerData?.address ||
                        ""
                      }
                      sx={{ mt: 2 }}
                    />
                    <RHFTextField
                      name="customerReference"
                      label="Customer Reference"
                      // value={selectedCustomerReference}
                      defaultValue={selectedCustomerReference}
                      register={register}
                      size="small"
                      sx={{ mt: 2 }}
                    />
                  </Card>
                </Grid>
              </Grid>
              <Stack
                spacing={1}
                alignItems="flex-bottom"
                sx={{ mt: 1, borderRadius: 1 }}
              >
                <Box display={"flex"} sx={{ justifyContent: "center", m: 5 }}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    loadingIndicator="Loading..."
                  >
                    {isEdit ? "Update" : "Place Quotation"}
                  </LoadingButton>

                  {isModalOpen && idValue && (
                    <LoadingButton
                      href="#"
                      onClick={() => openPopupWindow(idValue)}
                    >
                      Open window for Print (Etc)
                    </LoadingButton>
                  )}
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import {
  addGrnReceivedApi,
  getAllGrnReceivedApi,
  getGrnReceivedByIdApi,
  deleteGrnReceivedApi,
  updateGrnReceivedApi,
} from "src/api/supplier/grnReceived";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  grnReceiveds: [],
  singleGRNReceived: {},
};

const slice = createSlice({
  name: "grnReceived",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addGrnReceived(state, { payload }) {
      state.grnReceiveds.push(payload);
    },
    _getAllGrnReceived(state, { payload }) {
      state.grnReceiveds = payload;
    },

    _getSingleGrnReceived(state, { payload }) {
      state.singleGRNReceived = payload;
    },
    _deleteGrnReceived(state, { payload }) {
      const index = state.grnReceiveds.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.grnReceived.splice(index, 1);
      }
    },
    _updateGrnReceived(state, { payload }) {
      const index = state.grnReceiveds.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.newCustomers[index] = payload;
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addGrnReceived,
  _getAllGrnReceived,
  _getSingleGrnReceived,
  _deleteGrnReceived,
  _updateGrnReceived,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addGRNReceived = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addGrnReceivedApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, newGrn } = response.data;
    if (status === "success") {
      dispatch(_addGrnReceived(newGrn));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllGRNReceiveds = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllGrnReceivedApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, newGrns } = response.data;
    if (status === "success") {
      dispatch(_getAllGrnReceived(newGrns));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const deleteGRNReceiveds = (id) => async (dispatch) => {
  try {
    const response = await deleteGrnReceivedApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteGrnReceived(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateGRNReceiveds = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateGrnReceivedApi(data);
    const { status, newGrns } = response.data;
    if (status === "success") {
      dispatch(_updateGrnReceived(newGrns));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const getGRNReceivedById = (id) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getGrnReceivedByIdApi(id);
    console.log("get getQuotationByIdApi====", response);
    const { status, newGrns } = response.data;
    if (status === "success") {
      dispatch(_getSingleGrnReceived(newGrns));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    console.log(error);
    // dispatch(hasError(error));
  }
};

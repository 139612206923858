import { get, post, deleteReq, put } from "../../../src/services/restService";

export const addPaymentVoucherApi = (data) => {
  return post("payment_voucher/add", data);
};
export const getAllPaymentVoucherApi = () => {
  return get("payment_voucher/getAll");
};
export const getPaymentVoucherByIdApi = (id) => {
  return get(`payment_voucher/getById/${id}`);
};

export const deletePaymentVoucherApi = (id) => {
  return deleteReq(`payment_voucher/delete/${id}`);
};

export const updatePaymentVoucherApi = (data) => {
  return put(`payment_voucher/update/${data._id}`, data);
};

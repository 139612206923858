import PropTypes from "prop-types";
import sum from "lodash/sum";
import { Page, View, Text, Image, Document } from "@react-pdf/renderer";
import Iconify from "src/components/Iconify";
// utils
// import Overseas from "../Overseas.jpeg";

import { fCurrency } from "src/utils/formatNumber";
//
import styles from "./GstStyle";
import { Divider } from "@mui/material";

// ----------------------------------------------------------------------

GstInvoicePDF.propTypes = {
  creditInvoicePdf: PropTypes.object.isRequired,
};

export default function GstInvoicePDF({ creditInvoicePdf, deliveryNoteIPdf }) {
  const { invoice = {} } = creditInvoicePdf;
  console.log("deliveryNotePdf///<<<<", creditInvoicePdf?.id);
  console.log("deliveryNotePdf///<<<<", deliveryNoteIPdf);
  const { id } = invoice;
  console.log("creditInvoicePdf <<<<", creditInvoicePdf);
  function splitItemsIntoPages(items) {
    const rowsPerPage = 10;
    const pages = [];

    // add first page with first two items
    const firstPageItems = items.slice(0, 2);
    pages.push({
      title: "Credit Invoice Details (Page 1)",
      items: firstPageItems,
      startIndex: 1,
    });

    // add remaining pages
    const remainingItems = items.slice(2);
    const totalPages = Math.ceil(remainingItems.length / rowsPerPage);

    let currentIndex = 3;
    for (let i = 0; i < totalPages; i++) {
      const start = i * rowsPerPage;
      const end = start + rowsPerPage;
      const pageItems = remainingItems.slice(start, end);
      const pageNumber = i + 2; // start page numbering from 2
      const startIndex = pages[i].startIndex + pages[i].items.length;
      const page = {
        title: `Delivery Note (Page ${pageNumber})`,
        startIndex: startIndex,
        items: pageItems,
      };
      pages.push(page);
    }

    return pages;
  }
  const items = creditInvoicePdf?.items || [];
  const pages = splitItemsIntoPages(items);
  const itemsPerPage = 10;
  const totalPage = Math.ceil(items.length / itemsPerPage);

  console.log("creditInvoicePdf?.items", creditInvoicePdf?.items);
  return (
    <Document>
      {pages.map(({ title, items, startIndex }, pageIndex) => (
        <Page
          size="A4"
          style={[styles.page, styles.leftBorder]}
          key={pageIndex}
        >
          <View style={[styles.gridContainer, styles.bottomline]}>
            <View style={[]}>
              <View style={[styles.invoiceborderfront]}>
                <View style={[styles.prt1front]}>
                  <Image
                    disabledEffect
                    visibleByDefault
                    alt="logo"
                    src="/favicon/Overseas.jpeg"
                    // style={{ width: 400, height: 100, marginTop: 0 }}
                  />
                </View>
                <View style={[styles.prt1front2]}></View>
                <View style={[styles.prt2front]}>
                  <Text
                    style={[
                      {
                        color: "#D6D6E7",
                        fontWeight: "bold",
                        fontSize: 20,
                        marginLeft: 80,
                      },
                    ]}
                  >
                    G.S.T INVOICE
                  </Text>
                  <Text style={[{ flexDirection: "row", fontSize: 11 }]}>
                    <Image
                      disabledEffect
                      visibleByDefault
                      alt="logo"
                      src="/favicon/email.png"
                      style={{ width: 15, height: 10 }}
                    />
                    inquiry@oe.com.pk{" "}
                    <Text style={[{ flexDirection: "row", fontSize: 11 }]}>
                      <Image
                        disabledEffect
                        visibleByDefault
                        alt="logo"
                        src="/favicon/website.png"
                        style={{ width: 15, height: 10 }}
                      />{" "}
                      www.oe.com.pk
                    </Text>
                  </Text>
                </View>
              </View>

              {/* <View style={styles.col6}>
                <Text style={[styles.overline, styles.mb80]}>Buyer</Text>
                <Text style={[styles.cell01, { fontWeight: "bold" }]}>
                  {creditInvoicePdf.customer}
                </Text>

                <Text style={[styles.cell01]}>
                  {creditInvoicePdf.customerAddress}
                </Text>

                <Text style={[styles.cell01]}>
                  <Text style={[styles.boldText]}>PH :</Text>{" "}
                  {creditInvoicePdf.PhoneNumber}
                </Text>
                <Text style={[styles.mb80]}>
                  <Text style={[styles.boldText]}>STR :</Text>{" "}
                  {creditInvoicePdf.gstNum}
                </Text>
                <Text style={[styles.mb80]}>
                  <Text style={[styles.boldText]}>NTN:</Text>{" "}
                  {creditInvoicePdf.ntnNum}
                </Text>
                <Text style={[styles.cell01, { fontWeight: "bold" }]}>
                  Delivery Address
                </Text>
                <Text
                  style={[styles.body1, styles.cell01, { fontWeight: "bold" }]}
                >
                  {creditInvoicePdf.branch}
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  {creditInvoicePdf.branchAddress}
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  PH: {creditInvoicePdf.PhoneNumber}
                </Text>
              </View>
              <View style={styles.col7}>
                <View style={[styles.col10]}>
                  <Text style={[{ textAlign: "center", fontSize: 10 }]}>
                    Invoice
                  </Text>
                </View>
                <View style={[styles.col101]}>
                  <Text style={[styles.body1, styles.cell01]}>
                    Number / Date
                    <Text style={[styles.body1, { fontWeight: "bold" }]}>
                      {"\n"}
                      CR500{creditInvoicePdf?.id}
                    </Text>{" "}
                    / {creditInvoicePdf.date} {"\n"}
                    (Please state invoice no. with every payment)
                  </Text>
                  <Text style={[styles.body1, styles.cell01]}>
                    {"\n"}
                    Responsible for Order processing: {"\n"}
                    {creditInvoicePdf.salesPerson}
                  </Text>
                  <Text style={[styles.body1, styles.cell01]}>
                    Tel: +92 42 35922681-85 ,{" "}
                    {creditInvoicePdf.salespersonPhone}
                  </Text>
                  <Text style={[styles.body1, styles.cell01]}>
                    <Text
                      style={[styles.subtitle20, styles.cell0, styles.overline]}
                    >
                      STR Number :{"    "}
                    </Text>
                    12-02-9999-456-28
                  </Text>
                  <Text style={[styles.body1, styles.cell01]}>
                    <Text
                      style={[styles.subtitle20, styles.cell0, styles.overline]}
                    >
                      Our NTN NO :{"     "}
                    </Text>
                    27313110-7
                  </Text>
                  <Text style={[styles.body1, styles.cell01]}>
                    <Text
                      style={[styles.subtitle20, styles.cell0, styles.overline]}
                    >
                      CNIC NO. :{"          "}
                    </Text>
                    42301-7700217-7
                  </Text>
                  <Text style={[styles.body1, styles.cell01]}>
                    <Text
                      style={[styles.subtitle20, styles.cell0, styles.overline]}
                    >
                      Commercial invoice! See individual items
                    </Text>
                  </Text>
                </View>
              </View> */}
            </View>
            {/* <Image
              disabledEffect
              visibleByDefault
              alt="logo"
              src="/favicon/Overseas.jpeg"
              style={{ width: 400, height: 100, marginTop: 0 }}
            />
            <Text style={[{ marginTop: 50, marginLeft: 250, color: "gray" }]}>
              Invoice {"\n"}
              <Text></Text>
            </Text>
            <Text style={[{ marginTop: 50 }]}>
              {"\n"} Invoice : INV500-{creditInvoicePdf?.id}
            </Text> */}
            {/* <View
              style={[
                { alignItems: "flex-end", flexDirection: "column" },
                styles.overline,
              ]}
            >
              <Text>
                Page {pageIndex + 1} of {pages.length}
              </Text>
            </View> */}
          </View>

          {pageIndex === 0 && (
            <View style={[]}>
              <View style={[styles.invoiceborder]}>
                <View style={[styles.prt1]}>
                  <Text style={[styles.cell01]}>
                    <Text style={[styles.cell01, { fontWeight: "bold" }]}>
                      Buyer :
                    </Text>
                    {"    "}
                    <Text style={[styles.cell01121]}>
                      <Text style={[styles.cell01121, { fontWeight: "bold" }]}>
                        {creditInvoicePdf?.customer}
                        {"\n"}
                      </Text>
                      <Text style={[styles.cell01121]}>
                        <Text style={[styles.cell01121, { marginLeft: 10 }]}>
                          {creditInvoicePdf?.customerAddress}
                        </Text>
                        {"\n"}
                        <Text style={[styles.cell01121, { marginLeft: 10 }]}>
                          Ph: {creditInvoicePdf?.PhoneNumber}
                        </Text>
                        {"\n"}

                        {"\n"}
                        <Text style={[styles.cell01121, { marginLeft: 10 }]}>
                          S.Tax Reg : {creditInvoicePdf?.gstNum}
                        </Text>
                        {"\n"}
                        <Text style={[styles.cell01121, { marginLeft: 10 }]}>
                          Client Ref : {creditInvoicePdf?.purchaseOrder}
                        </Text>
                        {"\n"}
                        <Text style={[styles.cell01121, { marginLeft: 10 }]}>
                          NTN{"         "} : {creditInvoicePdf?.ntnNum}
                        </Text>
                      </Text>
                    </Text>
                  </Text>
                </View>
                <View style={[styles.prt1]}>
                  <Text style={[styles.cell01]}>
                    <Text style={[styles.cell01, { fontWeight: "bold" }]}>
                      Supplier :
                    </Text>
                    {"    "}
                    <Text style={[styles.cell01121]}>
                      <Text style={[styles.cell01121, { fontWeight: "bold" }]}>
                        OVERSEAS ENTERPRISES
                        {"\n"}
                      </Text>
                      <Text style={[styles.cell01121]}>
                        <Text style={[styles.cell01121]}>
                          Plot No. 28, Street No. 01, 18th KM Ferozepur Road,
                          Usman Colony, Lahore, Punjab 54600, Pakistan
                        </Text>
                        {"\n"}

                        {"\n"}
                        <Text style={[styles.cell01121, { marginLeft: 10 }]}>
                          DATE {"                  "} : {"  "}
                          {creditInvoicePdf?.date}
                        </Text>
                        {"\n"}

                        <Text style={[styles.cell01121, { marginLeft: 10 }]}>
                          DN Number {"       "}:{"  "} DN500
                          {creditInvoicePdf?.deliveryNote}
                        </Text>
                        {"\n"}
                        <Text style={[styles.cell01121, { marginLeft: 10 }]}>
                          Invoice Number:{"  "} INV500-{creditInvoicePdf?.id}
                        </Text>
                      </Text>
                    </Text>
                  </Text>
                </View>
                <View style={[styles.prt2]}>
                  <Text style={[styles.cell01]}>
                    <Text style={[styles.cell01121]}>
                      <Text style={[styles.cell01121]}>
                        {/* <Text style={[styles.cell01121, { marginLeft: 10 }]}>
                          Phone : {"  "}
                          +92 42 3718 6381-85
                          {"\n"}
                          Fax : {"      "} +92 42 3718 6380
                        </Text> */}
                        {/* {"\n"} */}
                        <Text style={[styles.cell01121, { marginLeft: 10 }]}>
                          NTN :{"      "}
                          2731310-7
                        </Text>
                        {"\n"}
                        <Text style={[styles.cell01121, { marginLeft: 10 }]}>
                          STRN : {"   "}
                          12-02-9999-456-28
                        </Text>
                        {/* {"\n"}

                        {"\n"} */}
                        <Text style={[styles.cell01121, { marginLeft: 10 }]}>
                          {"\n"} CNIC : {"    "}
                          42301-7700217-7
                        </Text>
                        {"\n"}
                        {"\n"}
                        {"\n"}
                        <Text style={[styles.cell01121, { marginLeft: 10 }]}>
                          <Text style={[styles.cell01, { fontWeight: "bold" }]}>
                            Sales Person{"\n"}
                          </Text>
                          {creditInvoicePdf?.salesPerson}
                          {"\n"}
                          {creditInvoicePdf?.salespersonPhone}
                        </Text>
                      </Text>
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
          )}
          {/* <View style={[styles.gridContainer_2]}>
            <View>
              <Text
                style={[styles.subtitle20, styles.cel1l01, styles.overline]}
              >
                Client Reference :
              </Text>
            </View>
            <View style={[styles.top]}>
              <Text
                style={[styles.subtitle20, styles.cel1l01, styles.overline]}
              >
                Shipping Details:
              </Text>
            </View>
          </View> */}
          {/* <View style={[styles.gridContainer, styles.mb40]}>
            <View
              style={[styles.table01, { border: 1, borderColor: "#000000" }]}
            >
              <View style={[styles.tableRow01111]}>
                <View style={[styles.tableCell_401]}>
                  <Text> Purchase Order No.</Text>
                </View>
                <View style={[styles.tableCell_4011]}>
                  <Text>{creditInvoicePdf?.purchaseOrder}</Text>
                </View>
              </View>
              <View
                style={[
                  styles.tableRow01111,
                  { borderTop: 1, borderColor: "#000000" },
                ]}
              >
                <View style={[styles.tableCell_401]}>
                  <Text> GRN No.</Text>
                </View>
                <View style={[styles.tableCell_4011]}>
                  <Text>
                    {"   "}
                    {creditInvoicePdf?.grnNo}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.tableRow01111,
                  { borderTop: 1, borderColor: "#000000" },
                ]}
              >
                <View style={[styles.tableCell_401]}>
                  <Text>P.O. Date</Text>
                </View>
                <View style={[styles.tableCell_4011]}>
                  <Text>
                    {"   "}
                    {creditInvoicePdf?.purchaseOrderDate}
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={[styles.table01, { border: 1, borderColor: "#000000" }]}
            >
              <View style={[styles.tableRow01111]}>
                <View style={[styles.tableCell_401]}>
                  <Text> Delivery Note No./Date</Text>
                </View>
                <View style={[styles.tableCell_4011]}>
                  <Text>
                    DN500{creditInvoicePdf?.deliveryNote} {"   "}{" "}
                    {creditInvoicePdf?.date}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.tableRow01111,
                  { borderTop: 1, borderColor: "#000000" },
                ]}
              >
                <View style={[styles.tableCell_401]}>
                  <Text> Carrier</Text>
                </View>
                <View style={[styles.tableCell_4011]}>
                  <Text>
                    {"   "} {creditInvoicePdf?.shippingCom}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.tableRow01111,
                  { borderTop: 1, borderColor: "#000000" },
                ]}
              >
                <View style={[styles.tableCell_401]}>
                  <Text> Carrier Receipt No.</Text>
                </View>
                <View style={[styles.tableCell_4011]}>
                  <Text>
                    {"   "}
                    {creditInvoicePdf?.carrierReceipt}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.tableRow01111,
                  { borderTop: 1, borderColor: "#000000" },
                ]}
              >
                <View style={[styles.tableCell_401]}>
                  <Text>T.R Date</Text>
                </View>
                <View style={[styles.tableCell_4011]}>
                  <Text>
                    {"   "}
                    {creditInvoicePdf?.trData}
                  </Text>
                </View>
              </View>
            </View>
          </View> */}

          <View
            style={[styles.subtotal, styles.tableContainer, { marginTop: 5 }]}
          >
            <View style={[styles.tableLatest]}>
              <View style={styles.table}>
                <View style={[styles.tableHeader0]}>
                  <View style={[styles.tableCell_110, styles.alignCenter]}>
                    <Text style={[styles.cell01, { fontWeight: "bold" }]}>
                      Qty
                    </Text>
                  </View>
                  <View style={[styles.tableCell_30, styles.alignCenter]}>
                    <Text style={[styles.cell01, { fontWeight: "bold" }]}>
                      {" "}
                      Product / Part No. Description
                    </Text>
                  </View>

                  <View style={[styles.tableCell_2210, styles.alignCenter]}>
                    <Text style={[styles.cell01, { fontWeight: "bold" }]}>
                      Unit Price
                    </Text>
                  </View>
                  <View style={[styles.tableCell_2210, styles.alignCenter]}>
                    <Text style={[styles.cell01, { fontWeight: "bold" }]}>
                      Value Excl.S.Tax
                    </Text>
                  </View>
                  <View style={[styles.tableCell_12100, styles.alignCenter]}>
                    <Text style={[styles.cell01, { fontWeight: "bold" }]}>
                      H.S Code
                    </Text>
                  </View>
                  <View style={[styles.tableCell_12100Tax, styles.alignCenter]}>
                    <Text style={[styles.cell01, { fontWeight: "bold" }]}>
                      S.Tax
                    </Text>
                  </View>
                  <View style={[styles.tableCell_12100, styles.alignCenter]}>
                    <Text style={[styles.cell01, { fontWeight: "bold" }]}>
                      S.Tax Amount
                    </Text>
                  </View>
                  <View style={[styles.tableCell_2210Last, styles.alignCenter]}>
                    <Text style={[styles.cell01, { fontWeight: "bold" }]}>
                      Total
                    </Text>
                  </View>
                </View>
              </View>

              <View style={[styles.tableBody]}>
                {items.map((item, index) => {
                  const currentPage = Math.floor(index / itemsPerPage) + 1;
                  const isLastPage = currentPage === totalPage;
                  const tableBodyHeight = isLastPage ? "auto" : 300;

                  // Check if previous item exists and has a 3-line description
                  const previousItem = index > 0 ? items[index - 1] : null;
                  const isPreviousItemThreeLines =
                    previousItem &&
                    previousItem.longDescription &&
                    previousItem.longDescription.split("\n").length === 3;
                  const hasPreviousDiscount = items
                    .slice(0, index)
                    .some((prevItem) => prevItem.discount > 0);
                  const descriptionLines = item.longDescription
                    ? item.longDescription.split("\n").length
                    : 0;
                  const maxDescriptionLines = isPreviousItemThreeLines ? 5 : 3;
                  const maxTotalLines = isPreviousItemThreeLines ? 6 : 4;

                  let mergedDescription = item.longDescription;
                  if (hasPreviousDiscount && descriptionLines > 2) {
                    // Merge description with previous item's description
                    const previousItems = items.slice(0, index);
                    const mergedDescriptions = previousItems.map(
                      (prevItem) => prevItem.longDescription
                    );
                    mergedDescriptions.push(item.longDescription);
                    mergedDescription = mergedDescriptions.join("\n");
                  }

                  return (
                    <View>
                      <View
                        style={[
                          styles.tableRowLatest,
                          {
                            minHeight: maxTotalLines * 20,
                            height: tableBodyHeight,
                          },
                        ]}
                        key={index}
                      >
                        <View style={[styles.tableCell_110, styles.Dlong]}>
                          <Text style={[styles.cell01]}>{item.delivery}</Text>
                        </View>
                        <View style={[styles.tableCell_30]}>
                          <Text
                            style={[styles.cell01, styles.Dlong]}
                            numberOfLines={maxDescriptionLines}
                          >
                            {item.itemDescription}
                            {"\n"} {"\n"}
                            {mergedDescription}
                          </Text>
                        </View>

                        <View style={[styles.tableCell_2210, styles.Dlong]}>
                          <Text style={[styles.cell01]}>
                            {item.priceBeforeTax.toLocaleString("en-IN", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}
                          </Text>
                        </View>
                        <View style={[styles.tableCell_2210, styles.Dlong]}>
                          <Text style={[styles.cell01]}>
                            {item.total.toLocaleString("en-IN", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })}
                          </Text>
                        </View>
                        <View style={[styles.tableCell_12100, styles.Dlong]}>
                          <Text style={[styles.cell01]}>{item.hsCode}</Text>
                        </View>
                        <View style={[styles.tableCell_12100Tax, styles.Dlong]}>
                          <Text style={[styles.cell01]}>
                            {creditInvoicePdf.taxGroup}
                          </Text>
                        </View>
                        <View style={[styles.tableCell_12100, styles.Dlong]}>
                          <Text style={[styles.cell01]}>
                            {" "}
                            {(
                              (item.total *
                                parseFloat(
                                  creditInvoicePdf.taxGroup.replace("%", "")
                                )) /
                              100
                            ).toLocaleString("en-IN")}{" "}
                            {/* Calculate tax amount */}
                          </Text>
                        </View>
                        <View style={[styles.tableCell_2210Last, styles.Dlong]}>
                          <Text style={[styles.cell01]}>
                            {" "}
                            {(
                              item.total +
                              (item.total *
                                parseFloat(
                                  creditInvoicePdf.taxGroup.replace("%", "")
                                )) /
                                100
                            ).toLocaleString("en-IN")}{" "}
                            {/* Calculate total amount after tax */}
                          </Text>
                        </View>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
          {pageIndex === pages.length - 1 && (
            <View>
              <View style={[styles.subtotalend]}>
                <View style={[styles.prt12]}>
                  <Text style={[styles.cell01]}>
                    <Text
                      style={[
                        styles.cell01,
                        {
                          fontWeight: "bold",
                          fontSize: 15,
                          textAlign: "center",
                        },
                      ]}
                    >
                      Terms & Conditions
                    </Text>
                    {"\n"}
                    {"    "}
                    <Text style={[styles.cell01121]}>
                      <Text style={[styles.cell01121, { fontWeight: "bold" }]}>
                        This invoice is issued without any alteration &
                        Correction.
                        {"\n"}
                        <Text
                          style={[
                            styles.cell01121,
                            { fontWeight: "bold", textAlign: "center" },
                          ]}
                        >
                          All Price are in Pakistani Rupees.
                        </Text>
                        {"\n"}
                      </Text>
                      <Text style={[styles.cell01121]}></Text>
                    </Text>
                  </Text>
                </View>

                <View style={[styles.prt212]}>
                  <Text style={[styles.cell01]}>
                    <Text style={[styles.cell01121]}>
                      <Text style={[styles.cell01121]}>
                        <Text>
                          <Text
                            style={[
                              styles.cell01121,
                              { fontWeight: "bold", fontSize: 12 },
                            ]}
                          >
                            Sub Total : {"             "}
                          </Text>
                          <Text style={[styles.cell01121, { fontSize: 12 }]}>
                            {creditInvoicePdf.subtotal
                              ? creditInvoicePdf.subtotal.toLocaleString(
                                  "en-IN",
                                  {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  }
                                )
                              : ""}
                          </Text>
                          {"\n"}
                        </Text>
                        <Text>
                          <Text
                            style={[
                              styles.cell01121,
                              { fontWeight: "bold", fontSize: 12 },
                            ]}
                          >
                            Tax
                            <Text style={{ marginRight: 10 }}>
                              {creditInvoicePdf.taxGroup}:{" "}
                              {"                  "}
                            </Text>
                          </Text>
                          <Text style={[styles.cell01121, { fontSize: 12 }]}>
                            {creditInvoicePdf.taxCost
                              ? creditInvoicePdf.taxCost.toLocaleString(
                                  "en-IN",
                                  {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  }
                                )
                              : ""}
                          </Text>
                          {"\n"}
                        </Text>{" "}
                        <Text style={[styles.grandtotalStyle]}>
                          <Text
                            style={[
                              styles.cell01121,
                              styles.grandtotalStyle,
                              { fontWeight: "bold", fontSize: 12 },
                            ]}
                          >
                            {" "}
                            Grand Total :{"        "}
                          </Text>
                          <Text
                            style={[
                              styles.cell01121,
                              styles.grandtotalStyle,
                              { fontSize: 12 },
                            ]}
                          >
                            {" "}
                            {creditInvoicePdf.afterTax
                              ? creditInvoicePdf.afterTax.toLocaleString(
                                  "en-IN",
                                  {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                  }
                                )
                              : ""}
                            {"  "}
                          </Text>
                          {"\n"}
                        </Text>
                      </Text>
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
          )}
          {pageIndex === pages.length - 1 && (
            <View style={styles.endPage}>
              <View style={[styles.Note4]}>
                <View style={[styles.Note112]}>
                  <Text style={[styles.textStyle2]}></Text>
                  <Text style={[styles.textStyle10, { padding: 5 }]}>
                    Prepared By
                  </Text>
                </View>
                <View style={[styles.Note11]}>
                  <Text style={[styles.textStyle2]}></Text>
                  <Text style={[styles.textStyle10, { padding: 5 }]}>
                    Authorised Signature
                  </Text>
                </View>
                {/* <View style={[styles.Note10]}>
                  <Text>Packaging</Text>
                </View>
                <View style={[styles.Note10, { marginRight: 100 }]}>
                  <Text>Packaging</Text>
                </View> */}
                {/* <View style={[styles.Note102]}>
                  <View style={[styles.tableLatest10]}>
                    <View style={[styles.tableCell_4012]}>
                      <Text style={[styles.tableCell_401222]}>
                        {"      "}Quantity
                      </Text>
                    </View>

                    <View
                      style={[
                        styles.tableRow0111,
                        { borderTop: 1, borderColor: "#000000" },
                      ]}
                    >
                      <View></View>
                    </View>
                  </View>
                </View> */}
                {/* <View style={[styles.Note101]}>
                  <View style={[styles.tableLatest101]}>
                    <View style={[styles.tableCell_40122]}>
                      <Text>{"      "}Type Of Packaging </Text>
                    </View>

                    <View
                      style={[
                        styles.tableRow0111,
                        { borderTop: 1, borderColor: "#000000" },
                      ]}
                    >
                      <View></View>
                    </View>
                  </View>
                </View> */}
              </View>

              <View style={[styles.gridContainer, styles.footer]}>
                <View style={[styles.Note]}>
                  <Text style={[styles.textStyle2, { fontWeight: "bold" }]}>
                    Remit To{" "}
                  </Text>
                  <Text style={[styles.textStyle]}>
                    Plot No. 28, Street No. 01, 18th KM Ferozepur Road, Usman
                    Colony, Lahore, Punjab 54600, Pakistan
                  </Text>
                </View>
                <View style={[styles.Notephone]}>
                  <Text style={[{ alignItems: "flex-start" }]}>
                    <Image
                      disabledEffect
                      visibleByDefault
                      alt="logo"
                      src="/favicon/phone.png"
                      style={{ width: 115, height: 30, paddingTop: 50 }}
                    />
                  </Text>
                  <Text
                    style={[
                      styles.textStyle,
                      {
                        alignItems: "flex-start",
                        paddingTop: 40,
                        marginRight: 120,
                      },
                    ]}
                  >
                    +92 42 3718 6381-85
                  </Text>
                </View>
                <View style={[styles.Note1]}>
                  <Text style={[styles.textStyle2]}></Text>
                  <Text style={[styles.textStyle10]}>Company Stamp</Text>
                </View>
              </View>
            </View>
          )}
        </Page>
      ))}
    </Document>
  );
}

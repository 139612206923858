import { createSlice } from "@reduxjs/toolkit";
import {
  addExchangeRateApi,
  getAllExchangeRateApi,
} from "src/api/generalledger/exchangeRateApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  exchangeRates: [],
};

const slice = createSlice({
  name: "exchangeRate",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addExchangeRate(state, { payload }) {
      state.exchangeRates.push(payload);
    },
    _getAllExchangeRate(state, { payload }) {
      state.exchangeRates = payload;
    },
    // _updateSalesPerson(state, { payload }) {
    //   const index = state.salesPersons.findIndex(
    //     (salesPerson) => salesPerson.id === payload.id
    //   );
    //   if (index !== -1) {
    //     state.salesPersons[index] = payload;
    //   }
    // },
    // _deleteSalesPerson(state, { payload }) {
    //   state.salesPersons = state.salesPersons.filter(
    //     (salesPerson) => salesPerson.id !== payload
    //   );
    // },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addExchangeRate,
  _getAllExchangeRate,
  //   _updateSalesPerson,
  //   _deleteSalesPerson,
  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addExchangeRate = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addExchangeRateApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, exchangeRate } = response.data;
    if (status === "success") {
      dispatch(_addExchangeRate(exchangeRate));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllExchangeRates = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllExchangeRateApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, exchangeRates } = response.data;
    if (status === "success") {
      dispatch(_getAllExchangeRate(exchangeRates));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};
// export const updateSalesPerson = (id, data) => async (dispatch) => {
//   try {
//     const response = await updateSalesPersonApi(id, data);
//     console.log("get updateCustomerCurrencyApi====", response);
//     const { status, exchangeRates } = response.data;
//     if (status === "success") {
//       dispatch(_updateSalesPerson(exchangeRates));
//     }
//     console.log("get updateCustomerCurrencyApi response====", response);
//   } catch (error) {
//     console.log("Error updating customer currency:", error);
//   }
// };

// export const deleteSalesPerson = (id) => async (dispatch) => {
//   try {
//     const response = await deleteSalesPersonApi(id);
//     const { status } = response.data;
//     if (status === "success") {
//       dispatch(_deleteSalesPerson(id));
//     }
//   } catch (error) {
//     console.log("Error deleting sales person:", error);
//   }
// };

import { get, post, deleteReq, put } from "src/services/restService";

export const addCurrencyItemsApi = (data) => {
  return post("currency_item/add", data);
};
export const getAllCurrencyItemsApi = () => {
  return get("currency_item/getAll");
};
export const deleteCurrencyItemsApi = (id) => {
  return deleteReq(`currency_item/delete/${id}`);
};

export const updateCurrencyItemsApi = ( data) => {
  return put(`currency_item/update/${data._id}`, data);
};

import { useState, useEffect, useMemo } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Card,
  Table,
  Grid,
  Box,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  DialogTitle,
  TablePagination,
} from "@mui/material";

// hooks
import useSettings from "src/hooks/useSettings";
// _mock_

// components
import Page from "src/components/Page";

import Scrollbar from "src/components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";

import TextField from "@mui/material/TextField";
// sections
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "src/sections/@dashboard/user/list";
import { DialogAnimate } from "src/components/animate";
import { CalendarForm } from "src/sections/@dashboard/calendar";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useResponsive from "src/hooks/useResponsive";
import { useLocation } from "react-router-dom";
import {
  getAllCreditInvoices,
  deleteCreditInvoices,
} from "src/redux/slices/customer/creditInvoice";
import {
  getAllLocalInvoices,
  deleteLocalInvoices,
} from "src/redux/slices/customer/localInvoice";
import {
  getAllCashInvoices,
  deleteCashInvoices,
} from "src/redux/slices/customer/cashInvoice";

import { useNavigate, useParams } from "react-router-dom";
import CreditInvoiceProcess from "src/pages/dashboard/components/customers/transactions/CreditInvoiceProcess";
import purchaseOrder from "src/redux/slices/supplier/purchaseOrder";
// ----------------------------------------------------------------------

export default function CreditInvoiceDashBoard() {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const creditInvoice = useSelector((state) => state.creditInvoice);
  const { creditInvoices } = creditInvoice;

  const localInvoice = useSelector((state) => state.localInvoice);
  const { localInvoices } = localInvoice;

  const cashInvoice = useSelector((state) => state.cashInvoice);
  const { cashInvoices } = cashInvoice;

  const { themeStretch } = useSettings();
  const [creditInvoiceItems, setcreditInvoiceItems] = useState([]);
  const [LocalInvoiceItems, setLocalInvoiceItems] = useState([]);
  const [cashInvoiceItems, setcashInvoiceItems] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("cus");
  const [selected, setSelected] = useState([]);

  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  /////////////////////////////////////////
  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };
  //////////////////////////////////////////
  useEffect(() => {
    setcashInvoiceItems(cashInvoices);
  }, [cashInvoices]);

  useEffect(() => {
    getCashInvoices();
  }, []);

  const getCashInvoices = async () => {
    await dispatch(getAllCashInvoices());
  };
  ///////////////////////////////////////////
  useEffect(() => {
    setcreditInvoiceItems(creditInvoices);
  }, [creditInvoices]);

  useEffect(() => {
    getCreditInvoices();
  }, []);

  const getCreditInvoices = async () => {
    await dispatch(getAllCreditInvoices());
  };
  ///////////////////////////////////////////
  useEffect(() => {
    setLocalInvoiceItems(localInvoices);
  }, [localInvoices]);

  useEffect(() => {
    getLocalInvoices();
  }, []);

  const getLocalInvoices = async () => {
    await dispatch(getAllLocalInvoices());
  };

  const TABLE_HEAD = [
    { id: "type", label: "Type", alignRight: false },
    { id: "quote", label: "PO. NO", alignRight: false },
    { id: "Reference", label: "Sales Order Reference", alignRight: false },
    { id: "Reference", label: "DN Reference", alignRight: false },
    { id: "Reference", label: "Invoice Reference", alignRight: false },
    { id: "purchaseorder", label: "Date", alignRight: false },
    { id: "customer", label: "Customer", alignRight: false },
    { id: "branch", label: "Branch", alignRight: false },
    { id: "validuntil", label: "Valid Until", alignRight: false },
    { id: "vtax", label: "Tax", alignRight: false },
    { id: "quotetotal", label: "Total", alignRight: false },
  ];
  const selectedEventSelector = (state) => {
    const { events, selectedEventId } = state.calendar;
    if (selectedEventId) {
      return events.find((_event) => _event.id === selectedEventId);
    }
    return null;
  };
  const dispatch = useDispatch();
  const isDesktop = useResponsive("up", "sm");
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState(isDesktop ? "dayGridMonth" : "listWeek");
  const selectedEvent = useSelector(selectedEventSelector);
  const { events, isOpenModal, selectedRange } = useSelector(
    (state) => state.calendar
  );

  const handleEditEvent = (obj) => {
    const newCurrency = {
      data: obj.currency.data,
      value: obj.currency.value,
    };
    // obj.currency = currency
    const newData = {
      ...obj,
      currency: newCurrency,
      isEdit: true,
    };
    navigate(`/dashboard/customers/edit-quotation/${obj.id}`, {
      state: newData,
    });

    // pathname: `/dashboard/customers/reports/${id}`,

    // setSelectedQuotation(obj);
    // dispatch(openModal());
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = creditInvoiceItems.map((n) => n.cusDiscount);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteUser = (rateId) => {
    const deleteUser = creditInvoiceItems.filter((rate) => rate._id !== rateId);
    setSelected([]);
    setcreditInvoiceItems(deleteUser);
    dispatch(deleteCreditInvoices(rateId));
  };
  const handleDeleteUsers = (rateId) => {
    const deleteUser = LocalInvoiceItems.filter((rate) => rate._id !== rateId);
    setSelected([]);
    setLocalInvoiceItems(deleteUser);
    dispatch(deleteLocalInvoices(rateId));
  };
  const handleDeleteUserss = (rateId) => {
    const deleteUser = cashInvoiceItems.filter((rate) => rate._id !== rateId);
    setSelected([]);
    setcashInvoiceItems(deleteUser);
    dispatch(deleteCashInvoices(rateId));
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = creditInvoiceItems.filter(
      (rate) => !selected.includes(rate.cusName)
    );
    setSelected([]);
    setcreditInvoiceItems(deleteUsers);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - creditInvoiceItems.length)
      : 0;

  const { id } = useParams();
  const handleLinkClick = () => {
    navigate(`/dashboard/customers/transactions/${id}/deliveryNote/${id}`);
  };

  const combinedItems = [
    ...LocalInvoiceItems,
    ...creditInvoiceItems,
    ...cashInvoiceItems,
  ];
  const keysToSearch = [
    "cusDiscount",
    "id",
    "salesOrderID",
    "invoiceType",
    "",
    "purchaseOrder",
    "customer",
    "branch",
    "purchaseOrder",
  ];

  const filteredUsers = applySortFilter(
    combinedItems,
    getComparator(order, orderBy),
    filterName,
    keysToSearch
  ).filter((row) => {
    if (fromDate && toDate) {
      return row.invoiceDate >= fromDate && row.invoiceDate <= toDate;
    } else if (fromDate) {
      return row.invoiceDate >= fromDate;
    } else if (toDate) {
      return row.invoiceDate <= toDate;
    }
    return true;
  });

  const isNotFound = !filteredUsers.length && Boolean(filterName);

  return (
    <Page title="User: List" padding="1.5rem">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <Card>
          <Grid px={1} py={1} container spacing={1}>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 1,

                  borderRadius: 1,
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    rowGap: 2,
                    columnGap: 1,
                    gridTemplateColumns: {
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(1, 1fr)",
                    },
                  }}
                >
                  <Card sx={{ py: 1 }}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filterName={filterName}
                      onFilterName={handleFilterByName}
                      onDeleteUsers={() => handleDeleteMultiUser(selected)}
                    />
                  </Card>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 1,

                  borderRadius: 1,
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    rowGap: 2,
                    columnGap: 1,

                    gridTemplateColumns: {
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(1, 1fr)",
                    },
                  }}
                >
                  <Card sx={{ p: 3 }}>
                    <TextField
                      value={fromDate}
                      onChange={handleFromDateChange}
                      type="date"
                      variant="outlined"
                      label="From Date"
                      size="small"
                      sx={{ m: 1, width: "25ch" }}
                    />
                  </Card>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 1,

                  borderRadius: 1,
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    rowGap: 2,
                    columnGap: 1,
                    gridTemplateColumns: {
                      xs: "repeat(1, 1fr)",
                      sm: "repeat(1, 1fr)",
                    },
                  }}
                >
                  <Card sx={{ p: 3 }}>
                    <TextField
                      value={toDate}
                      onChange={handleToDateChange}
                      type="date"
                      label="To Date"
                      variant="outlined"
                      size="small"
                      sx={{ m: 1, width: "25ch" }}
                    />
                  </Card>
                </Box>
              </Card>
            </Grid>
          </Grid>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <h4
                style={{
                  marginBottom: 15,
                  marginTop: 10,
                  textAlign: "center",
                  color: "#ff6347",
                  fontSize: 25,
                }}
              >
                INVOICE DASHBOARD
              </h4>

              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={creditInvoiceItems.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        _id,
                        id,
                        bold,
                        customer,
                        branch,
                        address,
                        salesOrderReferenceId,
                        currentCredit,
                        date,
                        invoiceType,
                        invoiceDate,
                        purchaseOrder,
                        deliverLocation,
                        deliveryDate,
                        currency,
                        deliveryNote,
                        shippingCost,
                        salesOrder,
                        salesOrderID,
                        items,
                        shippingCom,
                        delivery,
                        memo, // Add memo field with an initial empty string
                        actionBalance,
                        subtotal,
                        taxGroup,
                        taxCost,
                        afterTax,
                      } = row;
                      const isItemSelected =
                        selected.indexOf(purchaseOrder) !== -1;
                      const isLocalInvoice = LocalInvoiceItems.some(
                        (item) => item.id === row.id
                      );
                      const isCreditInvoice = creditInvoiceItems.some(
                        (item) => item.id === row.id
                      );
                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography variant="subtitle2" noWrap>
                              {invoiceType}
                            </Typography>
                          </TableCell>
                          <TableCell>{purchaseOrder}</TableCell>
                          <TableCell align="left">
                            <Link
                              to={`/dashboard/customers/transactions/${salesOrderID}`}
                            >
                              SO3022{salesOrderID}
                            </Link>
                          </TableCell>
                          <TableCell align="left">
                            <Link
                              to={{
                                pathname: `/dashboard/customers/transactions/${salesOrderID}/deliveryNote/${deliveryNote}`,
                                state: {
                                  salesOrderReferenceId: salesOrderReferenceId,
                                },
                              }}
                              onClick={handleLinkClick}
                            >
                              DN500{deliveryNote}
                            </Link>
                          </TableCell>
                          <TableCell align="left">
                            <Link
                              to={`/dashboard/customers/transactions/${salesOrderID}/deliveryNote/${id}/creditInvoice/${id}`}
                              onClick={() => handleLinkClick(id)}
                            >
                              IN-{id}
                            </Link>
                          </TableCell>

                          <TableCell align="left">{date}</TableCell>
                          <TableCell align="left">{customer}</TableCell>
                          <TableCell align="left">{branch}</TableCell>
                          <TableCell align="left">{invoiceDate}</TableCell>

                          <TableCell align="left">{taxGroup}</TableCell>
                          <TableCell align="left">{afterTax}</TableCell>
                          {/* <TableCell align="left">
                            <LoadingButton
                              onClick={() =>
                                handleButtonClick(salesOrderID, id)
                              }
                            >
                              Make Credit Invoice
                            </LoadingButton>
                          </TableCell> */}
                          <TableCell align="right">
                            {bold ? null : (
                              <UserMoreMenu
                                onDelete={() => {
                                  if (
                                    LocalInvoiceItems.some(
                                      (item) => item._id === _id
                                    )
                                  ) {
                                    handleDeleteUsers(_id);
                                  } else if (
                                    creditInvoiceItems.some(
                                      (item) => item._id === _id
                                    )
                                  ) {
                                    handleDeleteUser(_id);
                                  } else {
                                    handleDeleteUserss(_id);
                                  }
                                }}
                                handleEditEvent={() => handleEditEvent(row)}
                                userName={purchaseOrder}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <DialogAnimate modalWidth="sm" open={isOpenModal}>
          <DialogTitle>
            {selectedQuotation ? "Edit Delivery Notes " : "Add Delivery Note "}
          </DialogTitle>

          <CalendarForm
            event={selectedQuotation || {}}
            range={selectedRange}
            // onCancel={handleCloseModal}
          />
        </DialogAnimate>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, keysToSearch) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((item) =>
      keysToSearch.some((key) =>
        String(item[key]).toLowerCase().includes(query.toLowerCase())
      )
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

import { get, post, deleteReq, put } from "src/services/restService";

export const addNewItemApi = (data) => {
  return post("new_Item/add", data);
};
export const getAllNewItemApi = () => {
  return get("new_Item/getAll");
};
export const deleteNewItemApi = (id) => {
  return deleteReq(`new_Item/delete/${id}`);
};

export const updateNewItemApi = (data) => {
  return put(`new_Item/update/${data._id}`, data);
};

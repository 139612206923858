import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
// form

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Box, Grid, Card, Stack, Typography } from "@mui/material";
import { LoadingButton, MobileDateTimePicker } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// hooks
import useAuth from "src/hooks/useAuth";

// _mock
import { countries } from "src/_mock";
import { Icon } from "@iconify/react";
// components
import {
  FormProvider,
  RHFSwitch,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from "src/components/hook-form";
import dayjs from "dayjs";
import QuotationDashItems from "./QuotationDashboardComponents/QuotationDashItems";

import { getAllNewCustomers } from "src/redux/slices/customer/newCustomer";
import { getAllSalesPersons } from "src/redux/slices/salesPerson";
import { getAllCustomerCurrencys } from "src/redux/slices/customerCurrency";
import { getAllSalesTypes } from "src/redux/slices/salesType";
import { getAllInventoryLocations } from "src/redux/slices/inventory/inventoryLocation";
import { getAllShippingCompanys } from "src/redux/slices/setting/shippingCompany";
import { getAllPaymentTerms } from "src/redux/slices/setting/paymentTerm";
import { getAllGstDatas } from "src/redux/slices/setting/gstData";
import { getAllNewItems } from "src/redux/slices/inventory/newItem";

// ----------------------------------------------------------------------

export default function QuotationDashboard() {
  const dispatch = useDispatch();
  const { newItems } = useSelector((state) => state.newItem);
  const { newBranchs } = useSelector((state) => state.newBranch);
  const { newCustomers } = useSelector((state) => state.newCustomer);
  const { salesPersons } = useSelector((state) => state.salesPerson);
  const { salesTypes } = useSelector((state) => state.salesType);
  const { customerCurrencys } = useSelector((state) => state.customerCurrency);
  const { inventoryLocations } = useSelector(
    (state) => state.inventoryLocation
  );
  const { shippingCompanys } = useSelector((state) => state.shippingCompany);
  const { paymentTerms } = useSelector((state) => state.paymentTerm);
  const { gstDatas } = useSelector((state) => state.gstData);

  ////////////////Use State ////////////////////////////////////
  const [itemList, setitemList] = useState([]);
  const [salesPersonList, setsalesPersonList] = useState([]);
  const [customerList, setcustomerList] = useState([]);
  const [inventoryLocationList, setinventoryLocationList] = useState([]);
  const [shippingList, setshippingList] = useState([]);
  const [paymentTermList, setpaymentTermList] = useState([]);
  const [CurrencyList, setCurrencyList] = useState([]);
  const [salesTypeList, setsalesTypeList] = useState([]);
  const [taxGroupList, settaxGroupList] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  //////////////////////Use Effect //////////////////////////////////

  useEffect(() => {
    const temp = newItems.map((item) => item.productName);
    setitemList(temp);
  }, [newItems]);

  useEffect(() => {
    getInventorys();
  }, []);

  const getInventorys = async () => {
    await dispatch(getAllNewItems());
  };
  ////////////////////////Customer ///////////////////////////////////

  useEffect(() => {
    const temp = newCustomers.map((item) => item.cusName);
    setcustomerList(temp);
  }, [newCustomers]);
  useEffect(() => {
    getNewCustomers();
  }, []);

  const getNewCustomers = async () => {
    await dispatch(getAllNewCustomers());
  };

  //////////////////////Sales Person//////////////////////////
  useEffect(() => {
    getSalesPersons();
  }, []);

  const getSalesPersons = async () => {
    await dispatch(getAllSalesPersons());
    console.log("salesperson >>>", getAllSalesPersons);
  };
  useEffect(() => {
    const temp = salesPersons.map((item) => item.name);
    setsalesPersonList(temp);
    console.log("temp  >>>>", salesPersons);
  }, [salesPersons]);
  //////////////////////Customer Currency/////////////////////////
  useEffect(() => {
    getCurrencys();
  }, []);
  const getCurrencys = async () => {
    await dispatch(getAllCustomerCurrencys());
  };
  useEffect(() => {
    const temp = customerCurrencys.map((item) => item.Name);
    setCurrencyList(temp);
    console.log("temp  >>>>", customerCurrencys);
  }, [customerCurrencys]);
  //////////////////////////Sales Type///////////////////////////////
  useEffect(() => {
    getSalesTypes();
  }, []);

  const getSalesTypes = async () => {
    await dispatch(getAllSalesTypes());
  };

  useEffect(() => {
    const temp = salesTypes.map((item) => item.name);
    setsalesTypeList(temp);
    console.log("temp  >>>>", salesTypes);
  }, [salesTypes]);
  ////////////////////////  Inventory Location /////////////////////

  useEffect(() => {
    getInventoryLocations();
  }, []);

  const getInventoryLocations = async () => {
    await dispatch(getAllInventoryLocations());
  };
  useEffect(() => {
    const temp = inventoryLocations.map((item) => item.locationName);
    setinventoryLocationList(temp);
    console.log("temp  >>>>", inventoryLocations);
  }, [inventoryLocations]);
  ////////////////////////Shipping company //////////////////

  useEffect(() => {
    getShippingCompanys();
  }, []);

  const getShippingCompanys = async () => {
    await dispatch(getAllShippingCompanys());
  };
  useEffect(() => {
    const temp = shippingCompanys.map((item) => item.companyName);
    setshippingList(temp);
    console.log("temp  >>>>", shippingCompanys);
  }, [shippingCompanys]);

  ////////////////////Payment Terms/////////////////////////////
  useEffect(() => {
    getPaymentTerms();
  }, []);

  const getPaymentTerms = async () => {
    await dispatch(getAllPaymentTerms());
  };
  useEffect(() => {
    const temp = paymentTerms.map((item) => item.termDescription);
    setpaymentTermList(temp);
  }, [paymentTerms]);

  /////////////////////////////Tax Group ///////////////////////////////

  useEffect(() => {
    getGstDatas();
  }, []);

  const getGstDatas = async () => {
    await dispatch(getAllGstDatas());
  };
  useEffect(() => {
    const temp = gstDatas.map((item) => item.descrption);
    settaxGroupList(temp);
  }, [gstDatas]);

  const { enqueueSnackbar } = useSnackbar();
  const [date, setDate] = useState(new Date());
  const { user } = useAuth();

  const UpdateUserSchema = Yup.object().shape({});

  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      enqueueSnackbar("Update success!");
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          "photoURL",
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const handleSearch = () => {
    // Perform any necessary actions when the search button is clicked
    // You can also update the fromDate and toDate states here if needed
    const selectedFromDate = fromDate;
    const selectedToDate = toDate;
    setFromDate(selectedFromDate);
    setToDate(selectedToDate);
  };
  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      sx={{ border: 1, borderColor: "black" }}
    >
      {/* <Grid
        px={1}
        py={1}
        container
        spacing={1}
        sx={{ border: 1, borderColor: "#FB7600", borderRadius: 1 }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Card
            height={3}
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 3 }}>
                <RHFTextField
                  name="sno"
                  label="S.NO #"
                  size="small"
                  sx={{ mt: 1 }}
                />
                <RHFTextField
                  name="reference"
                  label="Reference"
                  size="small"
                  sx={{ mt: 1 }}
                />
                <RHFTextField
                  name="PurchaseOrder"
                  label="Purchase Order"
                  size="small"
                  sx={{ mt: 1 }}
                />
              </Card>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,

                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 3 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <DesktopDatePicker
                      container
                      label="From"
                      value={fromDate}
                      onChange={setFromDate}
                      renderInput={(params) => (
                        <TextField {...params} size="small" sx={{ mt: 1 }} />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <DesktopDatePicker
                      container
                      label="To"
                      value={toDate}
                      onChange={setToDate}
                      renderInput={(params) => (
                        <TextField {...params} size="small" sx={{ mt: 1 }} />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
                <RHFSelect
                  name="location"
                  label="Location"
                  size="small"
                  sx={{ mt: 1 }}
                >
                  <option value="" />
                  {inventoryLocationList.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </RHFSelect>
              </Card>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 3 }}>
                <RHFSelect
                  name="allcustomer"
                  label="Select a Customer"
                  size="small"
                  sx={{ mt: 1 }}
                >
                  <option value="" />
                  {customerList.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </RHFSelect>
                <RHFTextField
                  name="items"
                  label="Items"
                  size="small"
                  sx={{ mt: 1 }}
                />
                <RHFSelect
                  name="allitems"
                  label="All Items"
                  size="small"
                  sx={{ mt: 1 }}
                >
                  <option value="" />
                  {itemList.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </RHFSelect>
              </Card>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 3 }}>
                <Box m={3}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    onClick={handleSearch}
                  >
                    <Icon icon="dashicons:search" width={20} />
                    Search
                  </LoadingButton>
                </Box>
              </Card>
            </Box>
          </Card>
        </Grid>
      </Grid> */}
      {/*----------------SALES TABLE CALLING-------------------------------------------*/}
      <QuotationDashItems />
      {/*----------------3rd portion Detailing Code-------------------------------------------*/}
    </FormProvider>
  );
}

import { get, post, deleteReq, put } from "src/services/restService";

export const addUnitsofMeasureApi = (data) => {
  return post("units_measure/add", data);
};
export const getAllUnitsofMeasureApi = () => {
  return get("units_measure/getAll");
};
export const deleteUnitsofMeasureApi = (id) => {
  return deleteReq(`units_measure/delete/${id}`);
};

export const updateUnitsofMeasureApi = (data) => {
  return put(`units_measure/update/${data._id}`, data);
};

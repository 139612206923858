import { get, post, deleteReq, put } from "src/services/restService";

export const additemTaxApi = (data) => {
  return post("item_tax/add", data);
};
export const getAllitemTaxApi = () => {
  return get("item_tax/getAll");
};
export const deleteitemTaxApi = (id) => {
  return deleteReq(`item_tax/delete/${id}`);
};

export const updateitemTaxApi = ( data) => {
  return put(`item_tax/update/${data._id}`, data);
};

import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Link, Typography } from "@mui/material";
// hooks
import useAuth from "../../../hooks/useAuth";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import MyAvatar from "../../../components/MyAvatar";
import { getUsers } from "src/redux/slices/user";
import { useDispatch, useSelector } from "src/redux/store";
import jwt from "jsonwebtoken";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [userList, setuserList] = useState([]);
  const { users } = useSelector((state) => state.user);
  console.log("userList >>>>>>", userList);
  const userInfo = useSelector((state) => state.user.userInfo);
  const isUserAuthenticated = !!userInfo && !!userInfo.accessToken;
  const userId = isUserAuthenticated
    ? jwt.decode(userInfo.accessToken)._id
    : null;

  useEffect(() => {
    if (isUserAuthenticated) {
      console.log("Current User ID:1>>>>>", userId);
      console.log("Current User Name:2>>>>>", userInfo.userName);
      console.log("Current User Email:3>>>>>", userInfo.email);
      console.log("Current User Roles:4>>>>>", userInfo.roles);
    } else {
      console.log("No Current User");
    }
  }, [isUserAuthenticated, userId, userInfo]);


  return (
    <Link
      underline="none"
      color="inherit"
      component={RouterLink}
      to={PATH_DASHBOARD.user.account}
    >
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: "transparent",
          }),
        }}
      >
        <MyAvatar />

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create("width", {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {isUserAuthenticated ? userInfo?.userName : "Not logged in"}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>
            {user?.role}
          </Typography>
        </Box>
      </RootStyle>
    </Link>
  );
}

import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
// form
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Box, Grid, Card, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// hooks
import { Controller } from "react-hook-form";
import useAuth from "src/hooks/useAuth";
import { useParams } from "react-router-dom";
// components
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import { getAllInventoryLocations } from "src/redux/slices/inventory/inventoryLocation";
import { useNavigate } from "react-router-dom";
import { getSalesOrdernById } from "src/redux/slices/customer/salesOrder";
import { getAllShippingCompanys } from "src/redux/slices/setting/shippingCompany";
import { getAllNewCustomers } from "src/redux/slices/customer/newCustomer";
import { addDeliveryNote } from "src/redux/slices/customer/deliveryNote";
import { useLocation } from "react-router-dom";

// ----------------------------------------------------------------------

export default function DeliveryNote() {
  const navigate = useNavigate();
  const location = useLocation();
  const newData = location.state;
  const dispatch = useDispatch();
  const { id } = useParams();
  const salesOrder = useSelector((state) => state.salesOrder);
  const { salesOrders, singleSalesOrder } = salesOrder;
  const newCustomer = useSelector((state) => state.newCustomer);
  const { newCustomers } = newCustomer;

  const { inventoryLocations } = useSelector(
    (state) => state.inventoryLocation
  );
  const [SalesOrderObj, setSalesOrderObj] = useState([]);
  const [inventoryLocationList, setinventoryLocationList] = useState([]);
  const [shippingList, setshippingList] = useState([]);
  const [viewCustomerItems, setviewCustomerItems] = useState([]);
  const shippingCompany = useSelector((state) => state.shippingCompany);
  const { shippingCompanys } = shippingCompany;
  const [showPopup, setShowPopup] = useState(false);
  const [deliveryNoteId, setDeliveryNoteId] = useState("");
  const [idValue, setIdValue] = useState("");

  console.log(SalesOrderObj);
  useEffect(() => {
    setviewCustomerItems(newCustomers);
  }, [newCustomers]);

  useEffect(() => {
    getNewCustomers();
  }, []);

  const getNewCustomers = async () => {
    await dispatch(getAllNewCustomers());
  };

  const openPopupWindow = (id, navigateToDeliveryNote) => {
    const newWindow = window.open("", "Popup", "width=800,height=600");
    newWindow.document.title = "Popup Window";
    const idValue = id;
    const createButton = (text, clickHandler) => {
      const button = newWindow.document.createElement("button");
      button.innerHTML = text;
      button.style.display = "block";
      button.style.margin = "20px";
      button.style.alignItems = "center";
      button.style.backgroundColor = "#FC5B05"; // Set the button color
      button.style.borderColor = "#FC5B05";
      button.addEventListener("click", clickHandler);
      return button;
    };
    const link1 = createButton("DELIVERY NOTE PRINT VIEW", (event) => {
      event.preventDefault();
      const newPath = `/dashboard/customers/transactions/${SalesOrderObj.id}/deliveryNote/${idValue}`;
      newWindow.location.href = newPath;
    });
    newWindow.document.body.appendChild(link1);

    const link2 = createButton("MAKE Credit Invoice", (event) => {
      event.preventDefault();
      navigateToDeliveryNote();
    });
    newWindow.document.body.appendChild(link2);

    return link2;
  };

  const handleButtonClick = () => {
    setShowPopup(true);
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  useEffect(() => {
    getSalesOrders();
  }, []);

  const getSalesOrders = async () => {
    await dispatch(getSalesOrdernById(id));
  };
  useEffect(() => {
    if (singleSalesOrder) {
      setSalesOrderObj(singleSalesOrder);
    }
  }, [singleSalesOrder]);

  ////////////////Use State ////////////////////////////////////

  console.log("singleSalesOrder >>>>", SalesOrderObj);
  ///////////////////////// id save Value details//////////////////////////
  const [receivedValues, setReceivedValues] = useState([]);

  const salesOrderIDDefaultValue = setSalesOrderObj.id
    ? `PO550${setSalesOrderObj.id}`
    : "";

  useEffect(() => {
    return () => {
      // Clean up any pending state updates or tasks
      setReceivedValues({});
    };
  }, []);
  ///////////////////////  Shipping Company////////////////////////////

  useEffect(() => {
    const temp = shippingCompanys.map((item) => item.companyName);
    setshippingList(temp);
    console.log("temp  >>>>", shippingCompanys);
  }, [shippingCompanys]);

  useEffect(() => {
    getShippingCompanys();
  }, []);

  const getShippingCompanys = async () => {
    await dispatch(getAllShippingCompanys());
  };

  // Access the current value of the shippingCom field

  //////////////////////Use Effect //////////////////////////////////

  const { enqueueSnackbar } = useSnackbar();
  // const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
  const { user } = useAuth();

  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [invoiceDate, setinvoiceDate] = useState(dayjs().format("YYYY-MM-DD"));

  const handleDateChangeReceived = (newValue) => {
    const formattedDate = newValue.toDate();
    setDate(formattedDate);
  };

  const handleDateChangeInvoiceDate = (newValue) => {
    const formattedDate = newValue.toDate();
    setinvoiceDate(formattedDate);
  };
  const {
    handleSubmit,
    control,
    setValue,
    register,
    watch,
    getValues,
    reset, // Use the reset function instead of setValue
    formState: { errors },
  } = useForm();
  const totalValue = SalesOrderObj.salesOrderDetails?.items?.[0]?.total;
  console.log("totalValue>>>", totalValue);
  const actionBalanceValue = watch("actionBalance");
  const UpdateUserSchema = Yup.object().shape({});

  const defaultValues = {
    customer: "",
    branch: "",
    address: "",
    salesOrderReferenceId: "",
    currentCredit: "",
    date: "",
    invoiceDate: "",
    deliverLocation: "",
    deliveryDate: "",
    currency: "",
    shippingCost: 0,
    salesOrder: "",
    salesOrderID: "",
    items: [],
    shippingCom: "",
    memo: "",
    payment: "",
    actionBalance: "",
    subtotal: SalesOrderObj.salesOrderDetails?.items?.[0]?.total || 0,
    taxGroup: SalesOrderObj.taxGroup || "",
    taxCost: SalesOrderObj.taxCost || 0,
    afterTax: SalesOrderObj.afterTax || 0,
    PhoneNumber: "",
    salesPerson: "",
    gstNum: "",
    ntnNum: "",
    atn: "",
    gridNo: "",
    grn: "",
    purchaseOrderDate: "",
    carrierReceipt: "",
    purchaseOrder: "",
    puOrderDate: "",
    salespersonPhone: "",
    branchAddress: "",
    customerAddress: "",
  };
  console.log("<<<<<:", SalesOrderObj.salesOrderDetails?.items?.[0]?.total);
  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  ////////////////////////////////////Value ///////////////////////////////////
  const {
    currency,
    branch,
    customer,
    grn,
    gstNum,
    atn,
    gridNo,
    address,
    ntnNum,
    currentCredit,
    PhoneNumber,
    salesPerson,
    purchaseOrderDate,
    carrierReceipt,
    purchaseOrder,
    puOrderDate,
    salespersonPhone,
    branchAddress,
    payment,
    customerAddress,
  } = SalesOrderObj;
  useEffect(() => {
    setValue("id", id);
    setValue("currency", currency);
    setValue("customer", customer);
    setValue("branch", branch);
    setValue("address", address);
    setValue("PhoneNumber", PhoneNumber);
    setValue("salesPerson", salesPerson);
    setValue("gstNum", gstNum);
    setValue("ntnNum", ntnNum);
    setValue("atn", atn);
    setValue("gridNo", gridNo);
    setValue("purchaseOrderDate", purchaseOrderDate);
    setValue("purchaseOrder", purchaseOrder);
    setValue("carrierReceipt", carrierReceipt);
    setValue("puOrderDate", puOrderDate);
    setValue("currentCredit", currentCredit); // Save supplierCurrency value
    setValue("salespersonPhone", salespersonPhone);
    setValue("branchAddress", branchAddress);
    setValue("customerAddress", customerAddress);
    setValue("payment", payment);
  }, [
    id,
    currency,
    branch,
    customer,
    grn,
    gstNum,
    atn,
    gridNo,
    address,
    ntnNum,
    currentCredit,
    PhoneNumber,
    salesPerson,
    purchaseOrderDate,
    purchaseOrder,
    carrierReceipt,
    puOrderDate,
    branchAddress,
    customerAddress,
    salespersonPhone,
    payment,
    setValue,
  ]);

  useEffect(() => {
    setValue("salesOrderID", SalesOrderObj.id || "");
  }, [SalesOrderObj.id, setValue]);
  useEffect(() => {
    const initialItems = SalesOrderObj.salesOrderDetails?.items || [];
    reset({ items: initialItems });
  }, [SalesOrderObj.salesOrderDetails?.items, reset]);
  useEffect(() => {
    setValue("totalValue", totalValue);
  }, [totalValue, setValue]);
  useEffect(() => {
    register("memo");
    register("actionBalance");
  }, [register]);
  //////////////////////////  inventory Location /////////////////////////////////////
  useEffect(() => {
    getInventoryLocations();
  }, []);

  const getInventoryLocations = async () => {
    await dispatch(getAllInventoryLocations());
  };
  useEffect(() => {
    const temp = inventoryLocations.map((item) => item.locationName);
    setinventoryLocationList(temp);
    console.log("temp  >>>>", inventoryLocations);
  }, [inventoryLocations]);
  ////////////////////////////////////////////////////////////////////////////
  const handleLinkClick = (event, id) => {
    event.preventDefault();
    const url = `/dashboard/customers/transactions/${id}`;
    window.open(
      url,
      "popup",
      "width=600,height=600,scrollbars=yes,resizable=yes"
    );
  };

  ///////////////////////////////////// Onsubmit ////////////////////////////
  const onSubmit = async (data) => {
    console.log("data<<<", data);

    // Extract the items array from the data object
    const { memo, actionBalance, items, ...formData } = data;

    // Add additional properties from SalesOrderObj if needed
    const updatedData = {
      ...formData,
      PhoneNumber: SalesOrderObj.PhoneNumber,
      salesPerson: SalesOrderObj.salesPerson,
      gstNum: SalesOrderObj.gstNum,
      purchaseOrder: SalesOrderObj.purchaseOrder,
      puOrderDate: SalesOrderObj.puOrderDate,
      carrierReceipt: SalesOrderObj.carrierReceipt,
      ntnNum: SalesOrderObj.ntnNum,
      atn: SalesOrderObj.atn,
      gridNo: SalesOrderObj.gridNo,
      grn: SalesOrderObj.grn,
      purchaseOrderDate: SalesOrderObj.purchaseOrderDate,
      branchAddress: SalesOrderObj.branchAddress,
      customerAddress: SalesOrderObj.customerAddress,
      salespersonPhone: SalesOrderObj.salespersonPhone,
      customer: SalesOrderObj.customer,
      branch: SalesOrderObj.branch,
      currency: SalesOrderObj.currency,
      currentCredit: SalesOrderObj.currentCredit,
      deliverLocation: SalesOrderObj.deliverLocation,
      address: SalesOrderObj.address,
      invoiceDate: invoiceDate,
      shippingCom: SalesOrderObj.shippingCom,
      payment: SalesOrderObj.payment,
      actionBalance: actionBalance,
      date: date,
      memo: memo,

      items: items.map((item, index) => ({
        ...item,
        delivery: item.delivery, // Ensure the delivery value is converted to a string
        // Add any additional properties specific to each item
      })),
      // Add any additional properties from SalesOrderObj
      subtotal: SalesOrderObj.salesOrderDetails?.items?.[0]?.total || 0,
      taxGroup: SalesOrderObj?.taxGroup || "",
      taxCost: SalesOrderObj?.taxCost || 0,
      afterTax: SalesOrderObj?.afterTax || 0,
    };

    const id = await dispatch(addDeliveryNote(updatedData));

    setIdValue(id);
    setShowPopup(true);
    const navigateToDeliveryNote = () => {
      const creditInvoicePath = `${location.pathname}/${id}/creditInvoice`;
      window.location.href = creditInvoicePath;
    };

    openPopupWindow(id, navigateToDeliveryNote);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid
        px={1}
        py={1}
        container
        spacing={1}
        sx={{ border: 1, borderColor: "#FB7600", borderRadius: 1 }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Card
            height={3}
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 3 }}>
                <RHFTextField
                  name="customer"
                  label="Customer "
                  control={methods.control}
                  value={SalesOrderObj.customer || ""}
                  onChange={(e) =>
                    setSalesOrderObj((prevState) => ({
                      ...prevState,
                      customer: e.target.value,
                    }))
                  }
                  size="small"
                  sx={{ mt: 1 }}
                />

                <RHFTextField
                  name="branch"
                  label="Customer Branch"
                  control={methods.control}
                  value={SalesOrderObj.branch || ""}
                  onChange={(e) =>
                    setSalesOrderObj((prevState) => ({
                      ...prevState,
                      branch: e.target.value,
                    }))
                  }
                  size="small"
                  sx={{ mt: 1 }}
                />
                <RHFTextField
                  name="deliverLocation"
                  control={methods.control}
                  label="Delivery Location "
                  value={SalesOrderObj.deliverLocation || ""}
                  onChange={(e) =>
                    setSalesOrderObj((prevState) => ({
                      ...prevState,
                      deliverLocation: e.target.value,
                    }))
                  }
                  size="small"
                  sx={{ mt: 1 }}
                />
              </Card>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,

                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 3 }}>
                <RHFTextField
                  name="salesOrderReferenceId"
                  control={methods.control}
                  label="Reference "
                  defaultValue=""
                  size="small"
                  sx={{ mt: 1 }}
                />
                Sales Order :
                <Controller
                  name="salesOrderID"
                  control={methods.control}
                  defaultValue={salesOrderIDDefaultValue}
                  render={({ field }) => (
                    <>
                      {SalesOrderObj.id && (
                        <a
                          href={`/dashboard/customers/transactions/${SalesOrderObj.id}`}
                          onClick={(e) => {
                            handleLinkClick(e, SalesOrderObj.id);
                          }}
                        >
                          SO550{SalesOrderObj.id}
                        </a>
                      )}
                      <input
                        type="hidden"
                        {...register("salesOrderID", {
                          value: SalesOrderObj.id || "",
                        })}
                      />
                    </>
                  )}
                />
                <RHFTextField
                  name="shippingCom"
                  control={methods.control}
                  label="Shipping Company "
                  value={SalesOrderObj.shippingCom || ""}
                  onChange={(e) =>
                    setSalesOrderObj((prevState) => ({
                      ...prevState,
                      shippingCom: e.target.value,
                    }))
                  }
                  size="small"
                  sx={{ mt: 1 }}
                />
              </Card>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 3 }}>
                <RHFTextField
                  name="currency"
                  control={methods.control}
                  label="Customer Currency "
                  value={SalesOrderObj.currency || ""}
                  onChange={(e) =>
                    setSalesOrderObj((prevState) => ({
                      ...prevState,
                      currency: e.target.value,
                    }))
                  }
                  size="small"
                  sx={{ mt: 1 }}
                />
                <RHFTextField
                  name="salesType"
                  control={methods.control}
                  label="Sales Type "
                  value={SalesOrderObj.salesType || ""}
                  onChange={(e) =>
                    setSalesOrderObj((prevState) => ({
                      ...prevState,
                      salesType: e.target.value,
                    }))
                  }
                  size="small"
                  sx={{ mt: 1 }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} sx={{ mt: 2 }}>
                    <DesktopDatePicker
                      label="Date"
                      name="date"
                      value={date}
                      minDate={dayjs("2017-01-01")}
                      onChange={handleDateChangeReceived}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Card>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 3 }}>
                <RHFTextField
                  name="currentCredit"
                  control={methods.control}
                  label="Current Credit"
                  value={SalesOrderObj.currentCredit || ""}
                  onChange={(e) =>
                    setSalesOrderObj((prevState) => ({
                      ...prevState,
                      currentCredit: e.target.value,
                    }))
                  }
                  size="small"
                  sx={{ mt: 1 }}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} sx={{ mt: 2 }}>
                    <DesktopDatePicker
                      label="Invoice Dead-Line"
                      name="invoiceDate"
                      value={invoiceDate}
                      minDate={dayjs("2017-01-01")}
                      onChange={handleDateChangeInvoiceDate}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Card>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <h4
        style={{
          marginTop: 10,
          textAlign: "center",
          color: "#ff6347",
          fontSize: 30,
        }}
      >
        {" "}
        DELIVERY NOTES ITEMS
      </h4>
      <TableContainer>
        <Table sx={{ minWidth: 700, mt: 10 }} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell>Item Code</TableCell>
              <TableCell align="right">Item Description</TableCell>
              <TableCell align="center">Long Description</TableCell>
              <TableCell align="right">Order </TableCell>
              <TableCell align="right">Unit</TableCell>
              <TableCell align="right">Delivered</TableCell>
              <TableCell align="center">This Delivery </TableCell>
              <TableCell align="center">Price</TableCell>
              <TableCell align="center">H.S. Code</TableCell>
              <TableCell align="left">Tax Type </TableCell>
              <TableCell align="right">Discount </TableCell>
              <TableCell align="right">Total </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {SalesOrderObj.salesOrderDetails?.items.map((item, index) => {
              return (
                <TableRow sx={{ P: 2, borderBottom: 1 }} key={index}>
                  <TableCell>{item.itemCode}</TableCell>
                  <TableCell align="center">{item.itemDescription}</TableCell>
                  <TableCell align="center">{item.longDescription}</TableCell>
                  <TableCell align="center">{item.quantity}</TableCell>
                  <TableCell align="center">{item.unit}</TableCell>
                  <TableCell align="center">0</TableCell>
                  <TableCell>
                    <Controller
                      control={control}
                      name={`items[${index}].delivery`}
                      defaultValue={item.quantity}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label=""
                          size="small"
                          sx={{ mt: 1 }}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell align="center">{item.priceBeforeTax}</TableCell>
                  <TableCell align="center">{item.hsCode}</TableCell>
                  <TableCell align="center">{item.taxGroup}</TableCell>
                  <TableCell align="center">{item.discount}</TableCell>
                  <TableCell align="center">{item.total}</TableCell>
                </TableRow>
              );
            })}

            <TableRow>
              <TableCell colSpan={7}></TableCell>
              <TableCell align="center" sx={{ P: 2, borderBottom: 1 }}>
                Subtotal
              </TableCell>
              <TableCell colSpan={2} sx={{ P: 2, borderBottom: 1 }}></TableCell>
              <TableCell align="center" sx={{ P: 2, borderBottom: 1 }}>
                {SalesOrderObj.subtotal}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={7}></TableCell>
              <TableCell align="center" sx={{ P: 2, borderBottom: 1 }}>
                Tax {SalesOrderObj.taxGroup}
              </TableCell>
              <TableCell colSpan={2} sx={{ P: 2, borderBottom: 1 }}></TableCell>
              <TableCell align="center" sx={{ P: 2, borderBottom: 1 }}>
                {SalesOrderObj.taxCost}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={7}></TableCell>
              <TableCell align="center" sx={{ P: 2, borderBottom: 1 }}>
                Total
              </TableCell>
              <TableCell colSpan={2} sx={{ P: 2, borderBottom: 1 }}></TableCell>
              <TableCell align="center" sx={{ P: 2, borderBottom: 1 }}>
                {SalesOrderObj.afterTax}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12} sm={6} md={12}>
        <Card
          height={3}
          sx={{
            p: 1,
            background: "rgba(145, 158, 171, 0.12)",
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              display: "grid",
              rowGap: 2,
              columnGap: 1,
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(1, 1fr)",
              },
            }}
          >
            <Card sx={{ p: 3 }}>
              <Controller
                name="actionBalance"
                control={control}
                defaultValue={defaultValues.actionBalance || ""}
                render={({ field }) => (
                  <RHFSelect
                    {...field}
                    label="Action For Balance"
                    size="small"
                    sx={{ mt: 1 }}
                    onChange={(e) => {
                      field.onChange(e);
                      setValue("actionBalance", e.target.value);
                    }}
                    value={actionBalanceValue || ""}
                  >
                    <option value="" />
                    {shippingList.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </RHFSelect>
                )}
              />

              <Controller
                name="memo"
                control={control}
                defaultValue={defaultValues.memo || ""}
                render={({ field }) => (
                  <RHFTextField
                    {...field}
                    name="memo"
                    label="MEMO"
                    size="small"
                    multiline
                    rows={4}
                    sx={{ mt: 1 }}
                    onChange={(e) => {
                      field.onChange(e);
                      setValue("memo", e.target.value);
                    }}
                  />
                )}
              />
            </Card>
          </Box>
        </Card>
      </Grid>
      <Stack
        spacing={1}
        alignItems="flex-bottom"
        sx={{ mt: 1, borderRadius: 1 }}
      >
        <Box display={"flex"} sx={{ justifyContent: "center", m: 5 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            onClick={handleButtonClick}
          >
            Process Dispatch
          </LoadingButton>
        </Box>
      </Stack>
    </FormProvider>
  );
}

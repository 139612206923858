import { createSlice } from "@reduxjs/toolkit";
import {
  addPaymentTermsApi,
  getAllPaymentTermsApi,
  updatePaymentTermsApi,
  deletePaymentTermsApi,
} from "src/api/setting/paymentTermApi";

const initialState = {
  isLoading: false,
  error: null,
  paymentTerms: [],
};

const slice = createSlice({
  name: "paymentTerm",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    _addPaymentTerm(state, { payload }) {
      state.paymentTerms.push(payload);
    },
    _getAllPaymentTerm(state, { payload }) {
      state.paymentTerms = payload;
    },
    _updatePaymentTerm(state, { payload }) {
      const index = state.paymentTerms.findIndex(
        (term) => term._id === payload._id
      );
      if (index !== -1) {
        state.paymentTerms[index] = payload;
      }
    },
    _deletePaymentTerms(state, { payload }) {
      const index = state.paymentTerms.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.paymentTerms.splice(index, 1);
      }
    },
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const {
  _addPaymentTerm,
  _getAllPaymentTerm,
  _updatePaymentTerm,
  _deletePaymentTerms,
  startLoading,
  hasError,
} = slice.actions;

export const addPaymentTerm = (data) => async (dispatch) => {
  try {
    const response = await addPaymentTermsApi(data);
    const { status, paymentterm } = response.data;
    if (status === "success") {
      dispatch(_addPaymentTerm(paymentterm));
    }
  } catch (error) {
    dispatch(hasError(error));
  }
};

export const getAllPaymentTerms = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await getAllPaymentTermsApi();
    const { status, paymentterms } = response.data;
    if (status === "success") {
      dispatch(_getAllPaymentTerm(paymentterms));
    }
  } catch (error) {
    dispatch(hasError(error));
  }
};

export const updatePaymentTerm = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updatePaymentTermsApi(data);
    const { status, paymentterm } = response.data;
    if (status === "success") {
      dispatch(_updatePaymentTerm(paymentterm));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const deletePaymentTerms = (id) => async (dispatch) => {
  try {
    const response = await deletePaymentTermsApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deletePaymentTerms(id));
    }
  } catch (error) {
    console.log(error);
  }
};

export default slice.reducer;

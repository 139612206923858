import { createSlice } from "@reduxjs/toolkit";
import {
  addInventoryLocationApi,
  getAllInventoryLocationApi,
  deleteInventoryLocationApi,
  updateInventoryLocationApi,
} from "src/api/inventory/inventoryLocationApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  inventoryLocations: [],
};

const slice = createSlice({
  name: "inventoryLocation",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addInventoryLocation(state, { payload }) {
      state.inventoryLocations.push(payload);
    },
    _getAllInventoryLocation(state, { payload }) {
      state.inventoryLocations = payload;
    },

    _deleteInventoryLocation(state, { payload }) {
      const index = state.inventoryLocations.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.inventoryLocations.splice(index, 1);
      }
    },
    _updateInventoryLocation(state, { payload }) {
      const index = state.inventoryLocations.findIndex(
        (term) => term._id === payload._id
      );
      if (index !== -1) {
        state.inventoryLocations[index] = payload;
      }
    },

    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addInventoryLocation,
  _getAllInventoryLocation,
  _deleteInventoryLocation,
  _updateInventoryLocation,
  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addInventoryLocation = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addInventoryLocationApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, inventoryLocation } = response.data;
    if (status === "success") {
      dispatch(_addInventoryLocation(inventoryLocation));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllInventoryLocations = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllInventoryLocationApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, inventoryLocations } = response.data;
    if (status === "success") {
      dispatch(_getAllInventoryLocation(inventoryLocations));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};
export const deleteInventoryLocations = (id) => async (dispatch) => {
  try {
    const response = await deleteInventoryLocationApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteInventoryLocation(id));
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateInventoryLocations = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateInventoryLocationApi(data);
    const { status, inventoryLocation } = response.data;
    if (status === "success") {
      dispatch(_updateInventoryLocation(inventoryLocation));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};

import { get, post, deleteReq, put } from "../../../src/services/restService";

export const addPurchaseOrderApi = (data) => {
  return post("Purchase_order/add", data);
};
export const getAllPurchaseOrderApi = () => {
  return get("Purchase_order/getAll");
};
export const getPurchaseOrderByIdApi = (id) => {
  return get(`Purchase_order/getById/${id}`);
};

export const deletePurchaseOrderApi = (id) => {
  return deleteReq(`Purchase_order/delete/${id}`);
};

export const updatePurchaseOrderApi = (data) => {
  return put(`Purchase_order/update/${data._id}`, data);
};

import { get, post, deleteReq, put } from "../../../src/services/restService";

export const addFasicalYearApi = (data) => {
  return post("fasical_year/add", data);
};
export const getAllFasicalYearApi = () => {
  return get("fasical_year/getAll");
};
export const getFasicalYearByIdApi = (id) => {
  return get(`fasical_year/getById/${id}`);
};

export const deleteFasicalYearApi = (id) => {
  return deleteReq(`fasical_year/delete/${id}`);
};

export const updateFasicalYearApi = (data) => {
  return put(`fasical_year/update/${data._id}`, data);
};

import { createSlice } from "@reduxjs/toolkit";
import {
  addSalesOrderApi,
  getAllSalesOrderApi,
  getSalesOrderByIdApi,
  deleteSalesOrderApi,
  updateSalesOrderApi,
} from "src/api/customer/salesOrderApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  salesOrders: [],
  singleSalesOrder: {},
};

const slice = createSlice({
  name: "salesOrder",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addSalesOrder(state, { payload }) {
      state.salesOrders.push(payload);
    },
    _getAllSalesOrder(state, { payload }) {
      state.salesOrders = payload;
    },
    _getSingleSalesOrder(state, { payload }) {
      state.singleSalesOrder = payload;
    },
    _deleteSalesOrder(state, { payload }) {
      const index = state.salesOrders.findIndex((item) => item._id === payload);
      if (index !== -1) {
        state.salesOrders.splice(index, 1);
      }
    },
    _updateSalesOrder(state, { payload }) {
      const index = state.salesOrders.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.newCustomers[index] = payload;
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addSalesOrder,
  _getAllSalesOrder,
  _getSingleSalesOrder,
  _deleteSalesOrder,
  _updateSalesOrder,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addSalesOrder = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addSalesOrderApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, newsalesOrder } = response.data;
    if (status === "success") {
      dispatch(_addSalesOrder(newsalesOrder));
      return newsalesOrder.id;
    }
    // dispatch(_addSalesPerso(response))
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllSalesOrders = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllSalesOrderApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, newsalesOrders } = response.data;
    if (status === "success") {
      dispatch(_getAllSalesOrder(newsalesOrders));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const deleteSalesOrders = (id) => async (dispatch) => {
  try {
    const response = await deleteSalesOrderApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteSalesOrder(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateSalesOrders = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateSalesOrderApi(data);
    const { status, newsalesOrders } = response.data;
    if (status === "success") {
      dispatch(_updateSalesOrder(newsalesOrders));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const getSalesOrdernById = (id) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getSalesOrderByIdApi(id);
    console.log("get getQuotationByIdApi====", response);
    const { status, newsalesOrders } = response.data;
    if (status === "success") {
      dispatch(_getSingleSalesOrder(newsalesOrders));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    console.log(error);
    // dispatch(hasError(error));
  }
};

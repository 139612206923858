import React, { useState, useEffect } from "react";
import * as Yup from "yup";

import { useSnackbar } from "notistack";

import dayjs from "dayjs";
// form

import { useDispatch, useSelector } from "src/redux/store";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Box, Grid, Card, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// hooks
import useAuth from "src/hooks/useAuth";
// components
import {
  FormProvider,
  RHFSwitch,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import SalesReturnItems from "./salesReturnComponents/SalesReturnItems";
import { getAllNewCustomers } from "src/redux/slices/customer/newCustomer";
import { getAllSalesPersons } from "src/redux/slices/salesPerson";
import { getAllCurrencyItems } from "src/redux/slices/generalledger/currencyItem";

import { getAllSalesTypes } from "src/redux/slices/salesType";
import { getAllInventoryLocations } from "src/redux/slices/inventory/inventoryLocation";
import { getAllShippingCompanys } from "src/redux/slices/setting/shippingCompany";
import { getAllPaymentTerms } from "src/redux/slices/setting/paymentTerm";
import { getAllNewBranchs } from "src/redux/slices/customer/newBranch";
import {
  addSalesReturn,
  deleteSalesReturns,
} from "src/redux/slices/customer/salesReturn";
import { useLocation } from "react-router-dom";
import { getAllChartofAccounts } from "src/redux/slices/generalledger/chartofAccount";

// ----------------------------------------------------------------------

export default function SalesReturn({ isEdit }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [idValue, setIdValue] = useState("");
  const [popupWindow, setPopupWindow] = useState(null);

  const [isModalOpen, setModalOpen] = useState(false);
  //////////////////////Selectors /////////////////////////////

  const { newBranchs } = useSelector((state) => state.newBranch);
  const { newCustomers } = useSelector((state) => state.newCustomer);
  const { salesPersons } = useSelector((state) => state.salesPerson);
  const { salesTypes } = useSelector((state) => state.salesType);
  const currencyItem = useSelector((state) => state.currencyItem);
  const { currencyItems } = currencyItem || {};

  const { inventoryLocations } = useSelector(
    (state) => state.inventoryLocation
  );
  const { shippingCompanys } = useSelector((state) => state.shippingCompany);
  const { paymentTerms } = useSelector((state) => state.paymentTerm);
  const chartofAccount = useSelector((state) => state.chartofAccount);
  const { chartofAccounts } = chartofAccount;
  console.log("chartofAccounts", chartofAccounts);
  ////////////////Use State ////////////////////////////////////
  const [branchList, setbranchList] = useState([]);
  const [salesPersonList, setsalesPersonList] = useState([]);
  const [customerList, setcustomerList] = useState([]);
  const [inventoryLocationList, setinventoryLocationList] = useState([]);
  const [shippingList, setshippingList] = useState([]);
  const [paymentTermList, setpaymentTermList] = useState([]);
  const [CurrencyList, setCurrencyList] = useState([]);
  const [salesTypeList, setsalesTypeList] = useState([]);
  console.log("salesTypeList", salesTypeList);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState(null);
  const [salesReturnItems, setsalesReturnItems] = useState({
    items: [],
    total: 0,
  });
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCustomerData, setSelectedCustomerData] = useState(null);
  const [filteredBranchList, setFilteredBranchList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedBranchData, setSelectedBranchData] = useState({});
  const [shippingCost, setshippingCost] = useState(0);
  const [bankAccountName, setBankAccountName] = useState("");
  const [accountGroup, setAccountGroup] = useState("");
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [taxCost, settaxCost] = useState(0);
  const [afterTax, setafterTax] = useState(0);
  const [taxGroup, setTaxGroup] = useState("");
  const [cusName, setCusName] = useState("");
  const [address, setAddress] = useState("");
  const [gstNum, setGstNum] = useState("");
  const [ntnNum, setNtnNum] = useState("");
  const [phone, setPhone] = useState("");
  const [subtotal, setsubtotal] = useState("");
  const [customerCurrency, setCustomerCurrency] = useState("");
  const [selectedItemStatus, setSelectedItemStatus] = useState("");
  const data = location?.state;
  const currency = data?.currency;
  console.log("selectedSalesPerson ", selectedSalesPerson);
  console.log("salesOrderItems.total", salesReturnItems.total);

  useEffect(() => {
    if (salesReturnItems.total) {
      setsubtotal(salesReturnItems.total);
    }
  }, [salesReturnItems.total]);
  const UpdateUserSchema = Yup.object().shape({
    // customer: Yup.string().required("Select the Customer "),
    // branch: Yup.string().required("Select the Customer"),
    cusDiscount: Yup.string().required("RFq Id is required"),
    // deliveryTo: Yup.string().required("Delivery to is required"),
    // shippingTerms: Yup.string().required("Shipping Terms is required"),
    // deliverFromLocation: Yup.string().required(
    //   "Deliver From Location is required"
    // ),
    // shippingCom: Yup.string().required("Shipping Company  is required"),
  });
  const defaultValues = {
    customer: "",
    branch: "",
    salesOrderReferenceId: "",
    cusDiscount: "",
    exchangeRate: "",
    currentCredit: "",
    purchaseOrderDate: "",
    salespersonPhone: "",
    salesPerson: "",
    PhoneNumber: "",
    shipping: "",
    orderDate: "",
    gstNum: "",
    ntnNum: "",
    deliverLocation: "",
    deliveryDate: "",
    trData: "",
    deliveryTo: "",
    comments: "",
    atn: "",
    customerAddress: "",
    branchAddress: "",
    shippingTerms: "",
    currency: data?.currency || "",
    puOrderDate: "",
    itemStatus: "",
    shippingCom: "",
    location: "",
    bankaccountGLcode: "",
    customerReference: data?.customerReference || 0,
    afterTax: 0,
    shippingCost: 0,
    taxCost: 0,
    taxGroup: "",
    carrierReceipt: "",
    typeofPacking: "",
    gridNo: "",
    grn: "",
    subtotal: data?.subtotal || 0,
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  // const { currencyItems } = currencyItem || {};
  const specificOptions = [
    {
      label: "Items Returned to Inventory Location",
      value: "Items Returned to Inventory Location",
    },
    { label: "Items Written Off", value: "Items Written Off" },
  ];
  const handleItemStatusChange = (event) => {
    setSelectedItemStatus(event.target.value);
  };
  /////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getChartofAccounts();
  }, []);

  const getChartofAccounts = async () => {
    await dispatch(getAllChartofAccounts());
  };
  useEffect(() => {
    if (chartofAccounts.length > 0) {
      setBankAccountName(chartofAccounts[0].accountName);
      setAccountGroup(chartofAccounts[0].accountGroup);
      setFilteredAccounts(chartofAccounts);
      // initialize filteredAccounts with all accounts
      console.log("filterAccount >>>>>>", chartofAccounts);
    }
  }, [chartofAccounts]);
  //////////////////////Use Effect //////////////////////////////////
  const handleCustomerChange = (event) => {
    const selectedCusName = event.target.value;
    setSelectedCustomer(selectedCusName);

    // Find the selected customer data from newCustomers state
    const selectedCusData = newCustomers.find(
      (cus) => cus.cusName === selectedCusName
    );
    setSelectedCustomerData(selectedCusData);

    // Get the taxGroup for the selected customer
    if (selectedCusData) {
      const selectedTaxGroup = selectedCusData.taxGroup;

      setTaxGroup(selectedTaxGroup);
    }
    // Filter the branch list based on the selected customer
    if (selectedCusData) {
      const filteredBranchs = newBranchs.filter(
        (branch) => branch.customerId === selectedCusData._id
      );
      const temp = filteredBranchs.map((item) => item.name);
      setFilteredBranchList(temp);
    } else {
      // Reset the selected branch when customer changes
      setFilteredBranchList([]);
      setSelectedBranch("");
      setSelectedBranchData({});
    }
  };

  const handleBranchChange = (event) => {
    setSelectedBranch(event.target.value);
    const selectedBranchData = newBranchs.find(
      (branch) => branch.name === event.target.value
    );
    setSelectedBranchData(selectedBranchData || {});
  };
  ////////////////////////Customer ///////////////////////////////////
  // console.log("taxGroup", taxGroup);
  useEffect(() => {}, [taxGroup]);
  useEffect(() => {
    // Code to retrieve customer data based on the selectedCustomer value
  }, [selectedCustomer]);

  useEffect(() => {
    if (newCustomers.length > 0) {
      const {
        cusName,
        address,
        ntnNum,
        gstNum,
        phone,
        taxGroup,
        customerCurrency,
      } = newCustomers[0];
      setcustomerList(newCustomers.map((item) => item.cusName));
      setCusName(cusName);
      setAddress(address);

      console.log("taxGroup:", taxGroup); // Check if the taxGroup value is present

      setTaxGroup(taxGroup);
      setCustomerCurrency(customerCurrency);

      // Use setValue to set the form values
      setValue("gstNum", gstNum);
      setValue("ntnNum", ntnNum);
      setValue("PhoneNumber", phone);
    }
  }, [newCustomers, setValue]);

  useEffect(() => {
    getNewCustomers();
  }, []);

  const getNewCustomers = async () => {
    await dispatch(getAllNewCustomers());
  };

  ///////////////////////Branch //////////////////////////////
  useEffect(() => {
    const temp = newBranchs.map((item) => item.name);
    setbranchList(temp);
  }, [newBranchs]);

  useEffect(() => {
    getNewBranchs();
  }, []);

  const getNewBranchs = async () => {
    await dispatch(getAllNewBranchs());
  };
  //////////////////////Sales Person//////////////////////////
  useEffect(() => {
    getSalesPersons();
  }, []);

  const getSalesPersons = async () => {
    await dispatch(getAllSalesPersons());
    console.log("salesperson >>>", getAllSalesPersons);
  };
  useEffect(() => {
    const temp = salesPersons.map((item) => ({
      name: item.name,
      phoneNo: item.phoneNo,
    }));

    setsalesPersonList(temp);
  }, [salesPersons]);

  const handleSalesPersonSelection = (selectedIndex) => {
    const selectedPerson = salesPersonList[selectedIndex];
    setSelectedSalesPerson(selectedPerson);
  };
  //////////////////////Customer Currency/////////////////////////
  useEffect(() => {
    getCurrencys();
  }, []);

  const getCurrencys = async () => {
    await dispatch(getAllCurrencyItems());
  };

  useEffect(() => {
    const temp = currencyItems.map((item) => item.Name);
    setCurrencyList(temp);
    console.log("temp  >>>>", currencyItems);
  }, [currencyItems]);
  //////////////////////////Sales Type///////////////////////////////
  useEffect(() => {
    getSalesTypes();
  }, []);

  const getSalesTypes = async () => {
    await dispatch(getAllSalesTypes());
    console.log("getAllSalesTypes", getAllSalesTypes);
  };

  useEffect(() => {
    const temp = salesTypes.map((item) => item.name);
    setsalesTypeList(temp);
    console.log("temp  >>>>", salesTypes);
  }, [salesTypes]);
  ////////////////////////  Inventory Location /////////////////////

  useEffect(() => {
    getInventoryLocations();
  }, []);

  const getInventoryLocations = async () => {
    await dispatch(getAllInventoryLocations());
  };
  useEffect(() => {
    const temp = inventoryLocations.map((item) => item.locationName);
    setinventoryLocationList(temp);
    console.log("temp  >>>>", inventoryLocations);
  }, [inventoryLocations]);
  ////////////////////////Shipping company //////////////////

  useEffect(() => {
    getShippingCompanys();
  }, []);

  const getShippingCompanys = async () => {
    await dispatch(getAllShippingCompanys());
  };
  useEffect(() => {
    const temp = shippingCompanys.map((item) => item.companyName);
    setshippingList(temp);
    console.log("temp  >>>>", shippingCompanys);
  }, [shippingCompanys]);

  ////////////////////Payment Terms/////////////////////////////
  useEffect(() => {
    getPaymentTerms();
  }, []);

  const getPaymentTerms = async () => {
    await dispatch(getAllPaymentTerms());
  };
  useEffect(() => {
    const temp = paymentTerms.map((item) => item.termDescription);
    setpaymentTermList(temp);
  }, [paymentTerms]);

  const { enqueueSnackbar } = useSnackbar();
  const [orderDate, setorderDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [trData, settrData] = useState(dayjs().format("YYYY-MM-DD"));
  const [deliveryDate, setdeliveryDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [purchaseOrderDate, setPurchaseOrderDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const handleDateChangeSalesOrders = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setorderDate(formattedDate);
  };

  ////////////////////////SalesOrder Refernce////////////////////////////////

  const { user } = useAuth();

  const openPopupWindow = (id, navigateToDeliveryNote) => {
    const newWindow = window.open("", "Popup", "width=800,height=600");
    newWindow.document.title = "Popup Window";

    const link1 = newWindow.document.createElement("a");
    link1.href = `/dashboard/customers/transactions/${id}`;
    link1.innerHTML = "SALES ORDER PRINT VIEW";
    link1.style.display = "block";
    link1.style.margin = "20px";
    link1.addEventListener("click", (event) => {
      event.preventDefault();
      const newPath = `/dashboard/customers/transactions/${id}`;
      newWindow.location.href = newPath;
    });

    // return link2; // Return the link2 element
    const link2 = newWindow.document.createElement("a");
    link2.href = "#";
    link2.innerHTML = "Click On Link For Dispatch Delivery";
    link2.style.display = "block";
    link2.style.margin = "20px";
    link2.addEventListener("click", (event) => {
      event.preventDefault();
      navigateToDeliveryNote(); // Navigate to DeliveryNote page
    });
    newWindow.document.body.appendChild(link1);
    newWindow.document.body.appendChild(link2);

    return link2; // Return the link2 element
  };

  const onSubmit = async (data) => {
    const salesOrderId = await addNewSalesOrderFun(data);
    console.log("addNewSalesOrderFun555555555--", data);
    setModalOpen(true);
  };

  const addNewSalesOrderFun = async (data) => {
    console.log("555555555--", data);
    try {
      reset();
      setsalesReturnItems({ items: [], total: 0 });

      const orderData = {
        ...data,
        subtotal: subtotal,
        customer: selectedCustomer,
        branch: selectedBranch,

        customerAddress: selectedCustomerData?.address || "",
        branchAddress: selectedBranchData?.billingAddress || "",
        orderDate,

        itemStatus: data.itemStatus,
        deliveryDate,
        purchaseOrderDate,
        salesOrderDetails: salesReturnItems,
        afterTax,
        taxCost,
        shippingCost,
        gstNum: data.gstNum, // Save the gstNum from the form data
        ntnNum: data.ntnNum,
        taxGroup: taxGroup, // Save the ntnNum from the form data
        salesPerson: selectedSalesPerson?.name || "", // Store the selected salesperson
        salespersonPhone: selectedSalesPerson?.phoneNo || "", // Store the selected salesperson's phone number
      };

      console.log("taxGroup data:", data.taxGroup);
      Object.assign(data, orderData);
      setSelectedCustomer("");
      setSelectedBranch("");
      setSelectedBranchData({});

      const id = await dispatch(
        addSalesReturn(data, taxGroup, shippingCost, taxCost, afterTax)
      );
      console.log("orderData555555555--", orderData);
      setIdValue(id);
      enqueueSnackbar("Add success!");

      // Create a function to navigate to the DeliveryNote page
      const navigateToDeliveryNote = () => {
        const deliveryNotePath = `${location.pathname}/${id}/deliveryNote`;
        window.location.href = deliveryNotePath;
      };

      openPopupWindow(id, navigateToDeliveryNote);

      return id;
    } catch (error) {
      console.error(error);
      throw new Error("Error adding sales order");
    }
  };

  const addSalesReturnItem = (item) => {
    setsalesReturnItems((prevState) => [
      ...prevState,
      { ...item, salesOrderReferenceId: SalesReturn.salesOrderReferenceId },
    ]);
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      sx={{ border: 1, borderColor: "black" }}
    >
      <Grid
        px={1}
        py={1}
        container
        spacing={1}
        sx={{ border: 1, borderColor: "#FB7600", borderRadius: 1 }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Card
            height={3}
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}>
                    <RHFSelect
                      name="customer"
                      label="Customers"
                      size="small"
                      sx={{ mt: 1 }}
                      value={selectedCustomer}
                      onChange={handleCustomerChange}
                    >
                      <option value="" />
                      {customerList.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </RHFSelect>

                    <RHFSelect
                      name="branch"
                      label="Branch"
                      size="small"
                      sx={{ mt: 1 }}
                      disabled={!selectedCustomerData}
                      value={selectedBranch}
                      onChange={handleBranchChange}
                    >
                      <option value="" />
                      {filteredBranchList.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </RHFSelect>
                    <RHFTextField
                      name="salesOrderReferenceId"
                      label="Reference"
                      size="small"
                      sx={{ mt: 1 }}
                    />
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,

                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}>
                    <RHFTextField
                      name="cusDiscount"
                      label="RFQ Number"
                      size="small"
                      sx={{ mt: 1 }}
                    />
                    <RHFTextField
                      name="exchangeRate"
                      label="Exchange Rate"
                      size="small"
                      sx={{ mt: 1 }}
                    />
                    <RHFTextField
                      name="currentCredit"
                      label="Current Credit"
                      size="small"
                      sx={{ mt: 1 }}
                    />
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)   ",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}>
                    <RHFSelect
                      name="shipping"
                      label="Shipping "
                      placeholder="Shipping"
                      size="small"
                      sx={{ mt: 1 }}
                    >
                      <option value="" />
                      {shippingList.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </RHFSelect>
                    <RHFSelect
                      name="salesType"
                      label="Sales Type"
                      size="small"
                      sx={{ mt: 1 }}
                    >
                      <option value="" />
                      {salesTypeList.map((option, index) => (
                        <option key={index} value={index}>
                          {option.name}
                        </option>
                      ))}
                    </RHFSelect>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          container
                          name="orderDate"
                          label="Order Date"
                          value={orderDate}
                          minDate={dayjs("2017-01-01")}
                          onChange={handleDateChangeSalesOrders}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              sx={{ mt: 1 }}
                            />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
      </Grid>
      {/*----------------SALES TABLE CALLING-------------------------------------------*/}

      <SalesReturnItems
        setshippingCost={setshippingCost}
        settaxCost={settaxCost}
        setafterTax={setafterTax}
        salesReturnItems={salesReturnItems}
        setsalesReturnItems={setsalesReturnItems}
        addSalesReturnItem={addSalesReturnItem}
        taxGroup={taxGroup}
        setTaxGroup={setTaxGroup}
      />

      {/*----------------3rd portion Detailing Code-------------------------------------------*/}
      <Grid mt={3} container spacing={1}>
        <Grid item xs={12} md={12}>
          <Card
            sx={{
              p: 3,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <h4
              style={{
                marginBottom: 15,
                marginTop: 10,
                textAlign: "center",
                color: "#ff6347",
                fontSize: 30,
              }}
            >
              {" "}
              Order Delivery Details
            </h4>

            <Grid mt={5} ml={25} container spacing={1}>
              <Grid item xs={8} sm={4} md={8}>
                <Card sx={{ p: 3 }}>
                  <RHFSelect
                    name="itemStatus"
                    label="Item Status "
                    size="small"
                    sx={{ mt: 2, borderRadius: 1 }}
                    onChange={handleItemStatusChange}
                    value={selectedItemStatus}
                  >
                    <option value="" />
                    {specificOptions.map((option) => (
                      <option key={option.code} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </RHFSelect>
                  {selectedItemStatus ===
                    "Items Returned to Inventory Location" && (
                    <RHFSelect
                      name="location"
                      label="Office Location"
                      placeholder="Shipping Company"
                      size="small"
                      sx={{ mt: 2, borderRadius: 1 }}
                    >
                      <option value="" />
                      {inventoryLocationList.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </RHFSelect>
                  )}
                  {selectedItemStatus === "Items Written Off" && (
                    <RHFSelect
                      name="bankaccountGLcode"
                      label="Bank Account Gl Code"
                      placeholder="Bank Account Gl Code"
                      sx={{ mt: 2 }}
                    >
                      <option value="" />
                      {chartofAccounts.map((option, index) => (
                        <React.Fragment key={option._id}>
                          {index === 0 ||
                          option.accountGroup !==
                            chartofAccounts[index - 1].accountGroup ? (
                            <optgroup label={option.accountGroup}>
                              <option
                                value={`${option.accountName} - ${option.accountGroup}`}
                              >
                                {`${option.accountCode} - ${option.accountName}`}
                              </option>
                            </optgroup>
                          ) : (
                            <option
                              value={`${option.accountName} - ${option.accountGroup}`}
                            >
                              {`${option.accountCode} - ${option.accountName}`}
                            </option>
                          )}
                        </React.Fragment>
                      ))}
                    </RHFSelect>
                  )}
                  <RHFTextField
                    name="memo"
                    label="MEMO"
                    size="small"
                    multiline
                    rows={4}
                    sx={{ mt: 2 }}
                  />
                </Card>
              </Grid>
            </Grid>
            <Stack
              spacing={1}
              alignItems="flex-bottom"
              sx={{ mt: 1, borderRadius: 1 }}
            >
              <Box display={"flex"} sx={{ justifyContent: "center", m: 5 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  loadingIndicator="Loading..."
                  // onClick={handleButtonClick}
                >
                  {isEdit ? "Update" : "Process Credit Note"}
                </LoadingButton>
              </Box>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

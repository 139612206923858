import { get, post, deleteReq, put } from "src/services/restService";

export const addChartofAccountApi = (data) => {
  return post("chartof_account/add", data);
};
export const getAllChartofAccountApi = () => {
  return get("chartof_account/getAll");
};
export const deleteChartofAccountApi = (id) => {
  return deleteReq(`chartof_account/delete/${id}`);
};

export const updateChartofAccountApi = (data) => {
  return put(`chartof_account/update/${data._id}`, data);
};

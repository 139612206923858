import sum from "lodash/sum";
// @mui
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Card,
  Table,
  Divider,
  TableRow,
  Container,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "src/routes/paths";
// utils
import { fCurrency } from "src/utils/formatNumber";
// _mock_
import { _invoice } from "src/_mock";
// hooks
import useSettings from "src/hooks/useSettings";
// components
import Page from "src/components/Page";
import Label from "src/components/Label";
import Image from "src/components/Image";
import Scrollbar from "src/components/Scrollbar";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import comLogo from "src/assets/logos/comLogo.jpeg";
import { useParams } from "react-router-dom";
// sections
import {
  getAllGRNReceiveds,
  getGRNReceivedById,
} from "src/redux/slices/supplier/grnReceived";
import InvoiceToolbar from "src/pages/dashboard/components/customers/Reports/invoice/InvoiceToolbar";

// ----------------------------------------------------------------------

const RowResultStyle = styled(TableRow)(({ theme }) => ({
  "& td": {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

export default function GrnItemsDetailsView() {
  const { id } = useParams();
  const grnReceived = useSelector((state) => state.grnReceived);
  const { grnReceiveds, singleGRNReceived } = grnReceived;
  console.log("single>>>> ", singleGRNReceived);
  const { themeStretch } = useSettings();
  const [singleGRNObj, setsingleGRNObj] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    getQuotations();
  }, []);
  useEffect(() => {
    setsingleGRNObj(singleGRNReceived);
  }, [singleGRNReceived]);

  const getQuotations = async () => {
    console.log("single singleGRNObj >>>>>", singleGRNObj);

    await dispatch(getGRNReceivedById(id));
    // await dispatch(getAllQuotations());
    console.log("getAllGRNReceiveds >>>>>", getAllGRNReceiveds);
  };
  return (
    <Page title="suppliers: GRN Received  ">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="GRN Received Detials"
          links={[
            { href: PATH_DASHBOARD.root },
            {
              href: PATH_DASHBOARD.suppliers.root,
            },
          ]}
        />

        {/* <InvoiceToolbar singlePurchaseOrderObj={singlePurchaseOrderObj} /> */}

        <Card sx={{ pt: 5, px: 5 }}>
          <Grid container>
            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Image
                disabledEffect
                visibleByDefault
                alt="logo"
                src="/favicon/Overseas.jpeg"
                sx={{ maxWidth: 200 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Box sx={{ textAlign: { sm: "right" } }}>
                <Label
                  color="success"
                  sx={{ textTransform: "uppercase", mb: 1 }}
                >
                  {singleGRNObj.supplierName}
                  {console.log("single che>>>>", singleGRNObj.supplierCurrency)}
                  {/* {_invoice.status} */}
                </Label>
                <Typography variant="h6">GNR550{id}</Typography>
              </Box>
            </Grid>
          </Grid>

          <TableContainer sx={{ minWidth: 960 }}>
            <Table
            // sx={{
            //   border: (theme) => `solid 2px ${theme.palette.divider}`,
            //   "& th": { backgroundColor: "transparent" },
            // }}
            >
              <TableHead
                sx={{
                  border: 2,
                  borderColor: "grey.500",
                  backgroundColor: "orange",
                }}
              >
                <TableRow sx={{ fontWeight: "Bold" }}>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    width={40}
                  >
                    Suppliers
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    Delivery Into Location
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    Received Date
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    Delivery Address
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    Order Currency
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    {singleGRNObj.supplierName}
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    {singleGRNObj.locationList}
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    {singleGRNObj.orderDate}
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    {singleGRNObj.locationList}
                  </TableCell>
                  <TableCell
                    sx={{ border: 2, borderColor: "grey.500" }}
                    align="center"
                  >
                    {singleGRNObj.supplierCurrency}
                  </TableCell>
                </TableRow>
                <TableRow sx={{ border: 1, borderColor: "gray" }}>
                  <b> DELIVERY ADDRESS -- </b>
                  {singleGRNObj.deliverTo}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 960 }}>
              <Table>
                <TableHead
                  sx={{
                    borderBottom: (theme) =>
                      `solid 1px ${theme.palette.divider}`,
                    "& th": { backgroundColor: "transparent" },
                  }}
                >
                  <TableRow>
                    <TableCell align="left">ITEM</TableCell>
                    <TableCell align="left">ITEM CODE</TableCell>
                    <TableCell align="left">DESCRIPTION </TableCell>
                    <TableCell align="left">QTY</TableCell>
                    <TableCell align="left">Received</TableCell>
                    <TableCell align="left">UNIT PRICE</TableCell>
                    <TableCell align="right">TOTAL</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {singleGRNObj.items?.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        borderBottom: (theme) =>
                          `solid 1px ${theme.palette.divider}`,
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell align="left">
                        <Box>
                          <Typography variant="subtitle2">
                            {item.itemCode}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="left">{item.itemDescription}</TableCell>
                      <TableCell align="left">{item.quantity}</TableCell>
                      <TableCell align="left">{item.received}</TableCell>

                      <TableCell align="left">{item.priceBeforeTax}</TableCell>
                      <TableCell align="right">{item.total}</TableCell>
                    </TableRow>
                  ))}

                  <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Box sx={{ mt: 2 }} />
                      <Typography>Subtotal</Typography>
                    </TableCell>
                    <TableCell align="right" width={120}>
                      <Box sx={{ mt: 2 }} />
                      <Typography>
                        {" "}
                        {console.log("single PO", singleGRNObj)}
                        {singleGRNObj.subtotal}
                      </Typography>
                    </TableCell>
                  </RowResultStyle>

                  <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Typography>Taxes</Typography>
                      <Typography>{singleGRNObj.taxGroup}</Typography>
                      {console.log(
                        "singleGRNObj.taxGroup ''''''' >>>>>>",
                        singleGRNObj.taxGroup
                      )}
                    </TableCell>
                    <TableCell align="right" width={120}>
                      <Typography>{singleGRNObj.taxCost}</Typography>
                      {console.log(
                        "singleGRNObj.taxCost ////>>> ",
                        singleGRNObj.taxCost
                      )}
                    </TableCell>
                  </RowResultStyle>
                  <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Typography variant="h6">Total</Typography>
                    </TableCell>
                    <TableCell align="right" width={140}>
                      <Typography variant="h6">
                        {singleGRNObj.afterTax}
                      </Typography>
                    </TableCell>
                  </RowResultStyle>
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Divider sx={{ mt: 5 }} />

          {/* <Grid container>
            <Grid item xs={12} md={9} sx={{ py: 3 }}>
              <Typography variant="subtitle2">NOTES</Typography>
              <Typography variant="body2">
                We appreciate your business. Should you need us to add VAT or
                extra notes let us know!
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} sx={{ py: 3, textAlign: "right" }}>
              <Typography variant="subtitle2">Have a Question?</Typography>
              <Typography variant="body2">info@oe.com.pk</Typography>
            </Grid>
          </Grid> */}
        </Card>
      </Container>
    </Page>
  );
}

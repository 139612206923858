import { get, post, deleteReq, put } from "src/services/restService";

export const addInventoryLocationApi = (data) => {
  return post("inventory_location/add", data);
};
export const getAllInventoryLocationApi = () => {
  return get("inventory_location/getAll");
};
export const deleteInventoryLocationApi = (id) => {
  return deleteReq(`inventory_location/delete/${id}`);
};

export const updateInventoryLocationApi = (data) => {
  return put(`inventory_location/update/${data._id}`, data);
};

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import mailReducer from "./slices/mail";
import chatReducer from "./slices/chat";
import productReducer from "./slices/product";
import calendarReducer from "./slices/calendar";
import kanbanReducer from "./slices/kanban";
import userReducer from "./slices/user";
import salesPerson from "./slices/salesPerson";
import salesType from "./slices/salesType";
import customerCurrency from "./slices/customerCurrency";
import exchangeRate from "./slices/exchangeRate";
import customerContact from "./slices/customer/customerContact";
import chartofAccount from "./slices/generalledger/chartofAccount";
import coaGroup from "./slices/generalledger/coaGroup";
import glAccountClass from "./slices/generalledger/glAccountClass";
import salesPricing from "./slices/inventory/salesPricing";
import purchasePrice from "./slices/inventory/purchasePrice";
import barCode from "./slices/inventory/barCode";
import inventoryCategory from "./slices/inventory/inventoryCategory";
import inventoryLocation from "./slices/inventory/inventoryLocation";
import inventoryMovement from "./slices/inventory/inventoryMovement";
import unitsMeasure from "./slices/inventory/unitsMeasure";
import newCustomer from "./slices/customer/newCustomer";
import newBranch from "./slices/customer/newBranch";
import paymentTerm from "./slices/setting/paymentTerm";
import shippingCompany from "./slices/setting/shippingCompany";
import newItem from "./slices/inventory/newItem";
import bankAccount from "./slices/generalledger/bankAccount";
import gstData from "./slices/setting/gstData";
import generalSetting from "./slices/supplier/generalSetting";
import quotation from "./slices/customer/quotation";
import salesQuotation from "./slices/customer/salesquotation/salesQuotation";
import currencyItem from "./slices/generalledger/currencyItem";
import itemTax from "./slices/setting/itemTax";
import cashInvoice from "./slices/customer/cashInvoice";
import salesOrder from "./slices/customer/salesOrder";
import purchaseOrder from "./slices/supplier/purchaseOrder";
import grnReceived from "./slices/supplier/grnReceived";
import deliveryNote from "./slices/customer/deliveryNote";
import locationTransfer from "./slices/inventory/locationTransfer";
import creditInvoice from "./slices/customer/creditInvoice";
import localInvoice from "./slices/customer/localInvoice";
import receivePayment from "./slices/customer/receivePayment";
import budgetEntry from "./slices/generalledger/budgetEntry";
import bankTransfer from "./slices/generalledger/bankTransfer";
import paymentVoucher from "./slices/generalledger/paymentVoucher";
import receiptVoucher from "./slices/generalledger/receiptVoucher";
import journalEntry from "./slices/generalledger/journalEntry";
import fasicalYear from "./slices/setting/fasicalYear";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const userPersistConfig = {
  key: "user",
  storage,
  keyPrefix: "redux-",
  whitelist: ["accessToken", "userInfo"],
};

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  salesPerson: salesPerson,
  salesType: salesType,
  customerCurrency: customerCurrency,
  exchangeRate: exchangeRate,
  customerContact: customerContact,
  chartofAccount: chartofAccount,
  coaGroup: coaGroup,
  glAccountClass: glAccountClass,
  salesPricing: salesPricing,
  purchasePrice: purchasePrice,
  barCode: barCode,
  inventoryCategory: inventoryCategory,
  inventoryLocation: inventoryLocation,
  inventoryMovement: inventoryMovement,
  unitsMeasure: unitsMeasure,
  newCustomer: newCustomer,
  newBranch: newBranch,
  paymentTerm: paymentTerm,
  shippingCompany: shippingCompany,
  newItem: newItem,
  bankAccount: bankAccount,
  gstData: gstData,
  generalSetting: generalSetting,
  quotation: quotation,
  salesQuotation: salesQuotation,
  currencyItem: currencyItem,
  itemTax: itemTax,
  cashInvoice: cashInvoice,
  salesOrder: salesOrder,
  purchaseOrder: purchaseOrder,
  grnReceived: grnReceived,
  deliveryNote: deliveryNote,
  locationTransfer: locationTransfer,
  creditInvoice: creditInvoice,
  localInvoice: localInvoice,
  receivePayment: receivePayment,
  bankTransfer: bankTransfer,
  budgetEntry: budgetEntry,
  paymentVoucher: paymentVoucher,
  receiptVoucher: receiptVoucher,
  journalEntry: journalEntry,
  fasicalYear: fasicalYear,
});

export { rootPersistConfig, rootReducer };

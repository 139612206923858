import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
// form
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Divider from "@mui/material/Divider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Box, Grid, Card, Label, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// hooks
import { Controller } from "react-hook-form";
import useAuth from "src/hooks/useAuth";
import { useParams } from "react-router-dom";
// _mock
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
// components
import {
  FormProvider,
  RHFSwitch,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from "src/components/hook-form";
import { getAllInventoryLocations } from "src/redux/slices/inventory/inventoryLocation";

import {
  getAllPurchaseOrders,
  getPurchaseOrderById,
} from "src/redux/slices/supplier/purchaseOrder";
import {
  addGRNReceived,
  getAllGRNReceiveds,
} from "src/redux/slices/supplier/grnReceived";

// ----------------------------------------------------------------------

export default function GrnReceived() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const purchaseOrder = useSelector((state) => state.purchaseOrder);
  const { purchaseOrders, singlePurchaseOrder } = purchaseOrder;
  console.log(
    "singlePurchaseOrdersinglePurchaseOrder >>>",
    singlePurchaseOrder
  );
  const { inventoryLocations } = useSelector(
    (state) => state.inventoryLocation
  );

  useEffect(() => {
    getPurchaseOrders();
  }, []);

  const getPurchaseOrders = async () => {
    await dispatch(getPurchaseOrderById(id));
  };
  useEffect(() => {
    if (singlePurchaseOrder) {
      setPurchaseOrderObj(singlePurchaseOrder);
    }
  }, [singlePurchaseOrder]);

  ////////////////Use State ////////////////////////////////////
  const [PurchaseOrderObj, setPurchaseOrderObj] = useState([]);
  const [inventoryLocationList, setinventoryLocationList] = useState([]);

  ///////////////////////// id save Value details//////////////////////////
  const [receivedValues, setReceivedValues] = useState([]);
  console.log("receivedValues >>>", receivedValues);
  console.log("setReceivedValues >>>", setReceivedValues);
  const purchaseOrderIDDefaultValue = PurchaseOrderObj.id
    ? `PO550${PurchaseOrderObj.id}`
    : "";

  useEffect(() => {
    return () => {
      // Clean up any pending state updates or tasks
      setReceivedValues({});
    };
  }, []);

  //////////////////////Use Effect //////////////////////////////////

  const { enqueueSnackbar } = useSnackbar();
  // const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
  const { user } = useAuth();

  const [date, setDate] = useState(dayjs().toISOString());

  const handleDateChangeReceived = (newValue) => {
    const formattedDate = newValue.toDate();
    setDate(formattedDate);
  };
  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { errors },
  } = useForm();

  const UpdateUserSchema = Yup.object().shape({});

  const defaultValues = {
    supplierName: "",
    reference: "",
    exchangeRate: "",
    currentCredit: "",
    orderDate: "",
    receivedDate: "",
    suppliersReference: "",
    comment: "",
    deliverLocation: "",
    purchaseOrderID: "",
    purchaseId: "",
    items: [],
    subtotal: PurchaseOrderObj.orderItemDetails?.total || 0,
    taxGroup: PurchaseOrderObj.taxGroup || "",
    taxCost: PurchaseOrderObj.taxCost || 0,
    afterTax: PurchaseOrderObj.afterTax || 0,
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  ////////////////////////////////////Value ///////////////////////////////////
  const { orderDate, supplierName, supplierCurrency } = PurchaseOrderObj;

  useEffect(() => {
    setValue("id", id);
    setValue("orderDate", orderDate);
    setValue("supplierName", supplierName);
    setValue("supplierCurrency", supplierCurrency); // Save supplierCurrency value
  }, [id, orderDate, supplierName, supplierCurrency, setValue]);

  useEffect(() => {
    setValue("purchaseOrderID", PurchaseOrderObj.id || "");
  }, [PurchaseOrderObj.id, setValue]);
  //////////////////////////  inventory Location /////////////////////////////////////
  useEffect(() => {
    getInventoryLocations();
  }, []);

  const getInventoryLocations = async () => {
    await dispatch(getAllInventoryLocations());
  };
  useEffect(() => {
    const temp = inventoryLocations.map((item) => item.locationName);
    setinventoryLocationList(temp);
    console.log("temp  >>>>", inventoryLocations);
  }, [inventoryLocations]);
  ////////////////////////////////////////////////////////////////////////////
  const handleLinkClick = (event, id, suppliersReference) => {
    event.preventDefault();
    window.open(
      `/dashboard/suppliers/transaction/${id}`,
      "popup",
      "width=600,height=600,scrollbars=yes,resizable=yes"
    );
  };
  ///////////////////////////////////// Onsubmit ////////////////////////////
  const onSubmit = async (data) => {
    console.log("data ====>>>", data);

    const items = PurchaseOrderObj.orderItemDetails?.items.map(
      (item, index) => {
        const receivedValue = receivedValues[index] || "";
        return {
          ...item,
          received: receivedValue,
        };
      }
    );

    const {
      supplierName,
      reference,
      receivedDate,
      orderDate,
      deliverLocation,
      comment,
      purchaseOrderID,
      suppliersReference,
      ...otherData
    } = data;

    const formattedDate = dayjs(receivedDate).format("YYYY-MM-DD");
    const grnData = {
      supplierName: supplierName || "",
      reference: reference || "",
      receivedDate: formattedDate || "",
      orderDate: orderDate || "",
      deliverLocation: deliverLocation || "",
      comment: comment || "",
      purchaseOrderID: purchaseOrderID || "",
      suppliersReference: suppliersReference || "",
      items,
      taxGroup: PurchaseOrderObj.taxGroup || "",
      taxCost: PurchaseOrderObj.taxCost || 0,
      afterTax: PurchaseOrderObj.afterTax || 0,
      subtotal: PurchaseOrderObj.orderItemDetails?.total || 0,
      tax: PurchaseOrderObj.taxCost || 0,
      total: PurchaseOrderObj.afterTax || 0,
      supplierCurrency: PurchaseOrderObj.supplierCurrency || "",
      ...otherData,
    };

    await dispatch(addGRNReceived(grnData));
    console.log("grndata >>>>", grnData);

    // Retrieve all GRN related to the purchaseOrderID
  };

  const ControllerWithRef = React.forwardRef(
    ({ control, name, render, defaultValue, ...rest }, ref) => {
      return (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={(props) => render({ ...props, ref: ref })}
          {...rest}
        />
      );
    }
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid
        px={1}
        py={1}
        container
        spacing={1}
        sx={{ border: 1, borderColor: "#FB7600", borderRadius: 1 }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Card
            height={3}
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 3 }}>
                Supplier Name
                <ControllerWithRef
                  name="supplierName"
                  control={control}
                  render={({ field }) => (
                    <RHFTextField
                      {...field}
                      defaultValue="" // Provide an initial value
                      size="small"
                      sx={{ mt: 1 }}
                      ref={field.ref}
                    />
                  )}
                />
                <Controller
                  name="reference"
                  control={control}
                  render={({ field }) => (
                    <RHFTextField
                      {...field}
                      label="Reference"
                      defaultValue=""
                      size="small"
                      sx={{ mt: 1 }}
                    />
                  )}
                />
              </Card>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,

                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 3 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <Controller
                      name="receivedDate"
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <DesktopDatePicker
                          {...field}
                          container
                          label="Received Date"
                          value={date}
                          minDate={dayjs("2017-01-01")}
                          onChange={(date) => {
                            field.onChange(date);
                            handleDateChangeReceived(date);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              sx={{ mt: 1 }}
                            />
                          )}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
                Ordered On
                <ControllerWithRef
                  name="orderDate"
                  control={control}
                  defaultValue="" // Provide an initial value
                  render={({ field }) => (
                    <RHFTextField
                      {...field}
                      value={field.value || ""} // Ensure controlled input
                      size="small"
                      sx={{ mt: 1 }}
                      ref={field.ref}
                    />
                  )}
                />
              </Card>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 3 }}>
                <Controller
                  name="deliverLocation"
                  control={control}
                  render={({ field }) => (
                    <RHFSelect
                      {...field}
                      label="Delivery into Location"
                      defaultValue=""
                      size="small"
                      sx={{ mt: 1 }}
                    >
                      <option value="" />
                      {inventoryLocationList.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </RHFSelect>
                  )}
                />
                <Controller
                  name="comment"
                  control={control}
                  render={({ field }) => (
                    <RHFTextField
                      {...field}
                      label="Comment"
                      defaultValue=""
                      size="small"
                      sx={{ mt: 4 }}
                    />
                  )}
                />
              </Card>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 4 }}>
                <Box m={1}>
                  <Controller
                    name="suppliersReference"
                    control={control}
                    render={({ field }) => (
                      <RHFTextField
                        {...field}
                        label="Supplier Reference"
                        defaultValue=""
                        size="small"
                      />
                    )}
                  />
                  Purchase Order
                  <Controller
                    name="purchaseOrderID"
                    control={control}
                    defaultValue={purchaseOrderIDDefaultValue}
                    render={({ field }) => (
                      <>
                        {PurchaseOrderObj.id && (
                          <a
                            href={`/suppliers/transaction/${PurchaseOrderObj.id}`}
                            onClick={(e) => {
                              handleLinkClick(e, PurchaseOrderObj.id);
                            }}
                          >
                            PO550{PurchaseOrderObj.id}
                          </a>
                        )}
                        <input
                          type="hidden"
                          {...register("purchaseOrderID", {
                            value: PurchaseOrderObj.id || "",
                          })}
                        />
                      </>
                    )}
                  />
                </Box>
              </Card>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <TableContainer>
        <Table sx={{ minWidth: 700, mt: 10 }} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell>Item Code</TableCell>
              <TableCell align="right">Item Description</TableCell>
              <TableCell align="right">Order Quantity</TableCell>
              <TableCell align="right">Unit</TableCell>
              <TableCell align="right">Received</TableCell>
              <TableCell align="right">Outstanding </TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="left">Price </TableCell>
              <TableCell align="right">Total </TableCell>
              <TableCell align="right">Remaining Items </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {PurchaseOrderObj.orderItemDetails?.items.map((item, index) => {
              const receivedValue = receivedValues[index] || "";
              const quantityValue = item.quantity;
              const remainingQuantity = quantityValue - receivedValue;

              const handleReceivedChange = (event, index) => {
                const { value } = event.target;
                const receivedQuantity = parseInt(value, 10);

                setReceivedValues((prevReceivedValues) => {
                  const updatedReceivedValues = [...prevReceivedValues];
                  updatedReceivedValues[index] = receivedQuantity;
                  return updatedReceivedValues;
                });
              };
              return (
                <TableRow sx={{ P: 2, borderBottom: 1 }} key={index}>
                  <TableCell>{item.itemCode}</TableCell>
                  <TableCell align="center">{item.itemDescription}</TableCell>
                  <TableCell align="center">{item.quantity}</TableCell>
                  <TableCell align="center">{item.unit}</TableCell>
                  <TableCell align="center">0</TableCell>
                  <TableCell align="center">0</TableCell>
                  <TableCell>
                    <TextField
                      name={`items[${index}].received`}
                      label="This Delivery"
                      onChange={(event) => handleReceivedChange(event, index)}
                      value={receivedValue}
                    />
                  </TableCell>
                  <TableCell align="center">{item.priceBeforeTax}</TableCell>
                  <TableCell align="center">{item.total}</TableCell>
                  <TableCell align="center">{remainingQuantity}</TableCell>
                </TableRow>
              );
            })}

            <TableRow>
              <TableCell colSpan={5}></TableCell>
              <TableCell align="center" sx={{ P: 2, borderBottom: 1 }}>
                Subtotal
              </TableCell>
              <TableCell colSpan={2} sx={{ P: 2, borderBottom: 1 }}></TableCell>
              <TableCell align="center" sx={{ P: 2, borderBottom: 1 }}>
                {PurchaseOrderObj.orderItemDetails?.total}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={5}></TableCell>
              <TableCell align="center" sx={{ P: 2, borderBottom: 1 }}>
                Tax {PurchaseOrderObj.taxGroup}
              </TableCell>
              <TableCell colSpan={2} sx={{ P: 2, borderBottom: 1 }}></TableCell>
              <TableCell align="center" sx={{ P: 2, borderBottom: 1 }}>
                {PurchaseOrderObj.taxCost}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={5}></TableCell>
              <TableCell align="center" sx={{ P: 2, borderBottom: 1 }}>
                Total
              </TableCell>
              <TableCell colSpan={2} sx={{ P: 2, borderBottom: 1 }}></TableCell>
              <TableCell align="center" sx={{ P: 2, borderBottom: 1 }}>
                {PurchaseOrderObj.afterTax}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        spacing={1}
        alignItems="flex-bottom"
        sx={{ mt: 1, borderRadius: 1 }}
      >
        <Box display={"flex"} sx={{ justifyContent: "center", m: 5 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            // loading={isSubmitting}
          >
            Process Receive Items
          </LoadingButton>
        </Box>
      </Stack>
    </FormProvider>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import {
  addCustomerCurrencyApi,
  getAllCustomerCurrencyApi,
} from "src/api/customerCurrencyApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  customerCurrencys: [],
};

const slice = createSlice({
  name: "customerCurrency",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addCustomerCurrency(state, { payload }) {
      state.customerCurrencys.push(payload);
    },
    _getAllCustomerCurrency(state, { payload }) {
      state.customerCurrencys = payload;
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addCustomerCurrency,
  _getAllCustomerCurrency,
  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addCustomerCurrency = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addCustomerCurrencyApi(data);
    console.log("get addSalesTypeApi====", response);
    const { status, customercurrency } = response.data;
    if (status === "success") {
      dispatch(_addCustomerCurrency(customercurrency));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get customercurrencyApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllCustomerCurrencys = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllCustomerCurrencyApi();
    console.log("get getAllSalesTypeApi====", response);
    const { status, customerCurrencys } = response.data;
    if (status === "success") {
      dispatch(_getAllCustomerCurrency(customerCurrencys));
    }
    // dispatch(_addSalesPerso(response))
    console.log("getAllCustomerCurrency response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

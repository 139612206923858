import { get, post, deleteReq, put } from "../../../src/services/restService";

export const addDeliveryNoteApi = (data) => {
  return post("delivery_note/add", data);
};
export const getAllDeliveryNoteApi = () => {
  return get("delivery_note/getAll");
};
export const getDeliveryNoteByIdApi = (id) => {
  return get(`delivery_note/getById/${id}`);
};

export const deleteDeliveryNoteApi = (id) => {
  return deleteReq(`delivery_note/delete/${id}`);
};

export const updateDeliveryNoteApi = (data) => {
  return put(`delivery_note/update/${data._id}`, data);
};

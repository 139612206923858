import { get, post, deleteReq, put } from "src/services/restService";

export const addShippingcompanyApi = (data) => {
  return post("shipping_company/add", data);
};
export const getAllShippingcompanyApi = () => {
  return get("shipping_company/getAll");
};
export const deleteShippingcompanyApi = (id) => {
  return deleteReq(`shipping_company/delete/${id}`);
};

export const updateShippingcompanyApi = (data) => {
  return put(`shipping_company/update/${data._id}`, data);
};  

import { createSlice } from "@reduxjs/toolkit";
import {
  addNewLocalInvoiceApi,
  getAllNewLocalInvoiceApi,
  deleteNewLocalInvoiceApi,
  updateNewLocalInvoiceApi,
  getNewLocalInvoiceByIdApi,
} from "src/api/customer/localInvocieApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  localInvoices: [],
  singleLocalInvoice: {},
};

const slice = createSlice({
  name: "localInvoice",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addlocalInvoice(state, { payload }) {
      state.localInvoices.push(payload);
    },
    _getAllLocalInvoice(state, { payload }) {
      state.localInvoices = payload;
    },
    _getSingleLocalInvoice(state, { payload }) {
      state.singleLocalInvoice = payload;
    },
    _deleteLocalInvoice(state, { payload }) {
      const index = state.localInvoices.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.localInvoices.splice(index, 1);
      }
    },
    _updateLocalInvoice(state, { payload }) {
      const index = state.localInvoices.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.newCustomers[index] = payload;
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addlocalInvoice,
  _getAllLocalInvoice,
  _getSingleLocalInvoice,
  _deleteLocalInvoice,
  _updateLocalInvoice,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addLocalInvoice = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addNewLocalInvoiceApi(data);
    const { status, newlocalInvoice } = response.data;
    if (status === "success") {
      dispatch(_addlocalInvoice(newlocalInvoice));
      return newlocalInvoice.id; // Return the ID value
    }
  } catch (error) {}
};

export const getAllLocalInvoices = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getAllNewLocalInvoiceApi();

    const { status, newlocalInvoices } = response.data;
    if (status === "success") {
      dispatch(_getAllLocalInvoice(newlocalInvoices));
    }
  } catch (error) {}
};

export const deleteLocalInvoices = (id) => async (dispatch) => {
  try {
    const response = await deleteNewLocalInvoiceApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteLocalInvoice(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateLocalInvoices = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateNewLocalInvoiceApi(data);
    const { status, newlocalInvoices } = response.data;
    if (status === "success") {
      dispatch(_updateLocalInvoice(newlocalInvoices));
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const getLocalInvoiceById = (id) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getNewLocalInvoiceByIdApi(id);

    const { status, quotation } = response.data;
    if (status === "success") {
      dispatch(_getSingleLocalInvoice(quotation));
    }
  } catch (error) {
    console.log(error);
    // dispatch(hasError(error));
  }
};

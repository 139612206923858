import { useState, useEffect } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Card,
  Table,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TextField,
  Typography,
  TableContainer,
  DialogTitle,
  TablePagination,
} from "@mui/material";

import useSettings from "src/hooks/useSettings";
// _mock_

// components
import Page from "src/components/Page";
import Iconify from "src/components/Iconify";
import Scrollbar from "src/components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";
// sections
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "src/sections/@dashboard/user/list";
import { useForm } from "react-hook-form";
import { RHFTextField } from "src/components/hook-form";
import { DialogAnimate } from "src/components/animate";
import { pink } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import useResponsive from "src/hooks/useResponsive";
import {
  getEvents,
  openModal,
  closeModal,
  updateEvent,
  selectEvent,
  selectRange,
} from "src/redux/slices/calendar";
import SalesReturnForm from "src/sections/@dashboard/calendar/customer/SalesReturnForm";
import { deleteSalesQuotation } from "src/redux/slices/customer/salesquotation/salesQuotation";
// ----------------------------------------------------------------------

export default function SalesReturnItems({
  onSubmit,
  salesReturnItems,
  setsalesReturnItems,
  setshippingCost,
  settaxCost,
  setafterTax,
  setTaxGroup,
  taxGroup,
  props,
}) {
  const theme = useTheme();
  console.log("setTaxGroup======", taxGroup);
  const { themeStretch } = useSettings();

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [shippingCost, setShippingCostLocal] = useState(0);
  const [taxCost, setTaxCostLocal] = useState(0);
  const [afterTax, setAfterTaxLocal] = useState(0);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);

  useEffect(() => {
    const subtotal = salesReturnItems.total || 0;

    if (isCheckboxChecked) {
      if (!taxGroup) {
        console.log("Invalid taxGroup value");
        return; // Exit the useEffect if taxGroup is not a valid number
      }

      const taxGroupValue = parseFloat(taxGroup);

      const taxAmount = (subtotal * taxGroupValue) / 100;
      const shippingAmount = shippingCost || 0;
      const totalAmount = (subtotal + taxAmount + shippingAmount).toFixed(2);

      setShippingCostLocal(shippingAmount);
      setTaxCostLocal(taxAmount);
      setAfterTaxLocal(totalAmount);

      // Call the parent setters
      setshippingCost(shippingAmount);
      settaxCost(taxAmount);
      setafterTax(totalAmount);
    } else {
      // Reset the values when the checkbox is unchecked
      setShippingCostLocal(shippingCost || 0);
      setTaxCostLocal(0);
      setAfterTaxLocal(subtotal.toFixed(2));

      // Call the parent setters
      setshippingCost(shippingCost || 0);
      settaxCost(0);
      setafterTax(subtotal.toFixed(2));
    }
  }, [
    isCheckboxChecked,
    taxGroup,
    salesReturnItems.total,
    shippingCost,
    setshippingCost,
    settaxCost,
    setafterTax,
  ]);

  const AddButton = () => {
    return (
      <Button
        variant="contained"
        startIcon={<Iconify icon={"eva:plus-fill"} />}
        size="medium"
        onClick={handleAddEvent}
      >
        Add
      </Button>
    );
  };
  const TABLE_HEAD = [
    { id: "1", label: "Item Code", alignRight: false },
    { id: "2", label: "Item Description", alignRight: false },
    { id: "3", label: "Long Description", alignRight: false },
    { id: "4", label: "Quantity", alignRight: false },
    { id: "5", label: "Unit", alignRight: false },
    { id: "6", label: "Price Before Tax", alignRight: false },
    { id: "6", label: "H.S.Code", alignRight: false },
    { id: "7", label: "Discount %", alignRight: false },
    { id: "8", label: "Total", alignRight: false },

    { id: "" },
    { id: "", label: <AddButton />, alignRight: false },
  ];
  const selectedEventSelector = (state) => {
    const { events, selectedEventId } = state.calendar;
    if (selectedEventId) {
      return events.find((_event) => _event.id === selectedEventId);
    }
    return null;
  };
  const dispatch = useDispatch();
  const isDesktop = useResponsive("up", "sm");
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState(isDesktop ? "dayGridMonth" : "listWeek");
  const selectedEvent = useSelector(selectedEventSelector);
  const { events, isOpenModal, selectedRange } = useSelector(
    (state) => state.calendar
  );

  function calculateTotal(row) {
    const { quantity, priceBeforeTax, discount } = row;
    return quantity * priceBeforeTax * (1 - discount / 100);
  }

  function Subtotal({ rows }) {
    const total = rows.reduce((acc, row) => acc + calculateTotal(row), 0);
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleAddEvent = () => {
    dispatch(openModal());
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteUser = (rateId) => {
    const deleteUser = salesReturnItems.items.filter(
      (rate) => rate.id !== rateId
    );
    setSelected([]);
    setsalesReturnItems({ ...salesReturnItems, items: deleteUser });
  };

  return (
    <Page title="User: List" mt={10}>
      <Container maxWidth={themeStretch ? false : "lg"}>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <h4
                style={{
                  marginBottom: 15,
                  marginTop: 10,
                  textAlign: "center",
                  color: "#ff6347",
                  fontSize: 25,
                }}
              >
                {" "}
                Sales Return item Details
              </h4>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={salesReturnItems.items.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {salesReturnItems.items.map((row, index) => {
                    const {
                      id,
                      bold,
                      itemCode,
                      itemDescription,
                      longDescription,
                      quantity,
                      unit,
                      priceBeforeTax,
                      discount,
                      total,
                    } = row;
                    const isItemSelected = selected.indexOf(itemCode) !== -1;
                    const Total = calculateTotal(row);

                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          {/* <Avatar alt={name} src={avatarUrl} sx={{ mr: 2 }} /> */}
                          <Typography variant="subtitle2" noWrap>
                            {itemCode}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{itemDescription}</TableCell>
                        <TableCell align="left">{longDescription}</TableCell>
                        <TableCell align="left">{quantity}</TableCell>
                        <TableCell align="left">{unit}</TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontWeight: bold ? "bold" : "" }}
                        >
                          {priceBeforeTax}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontWeight: bold ? "bold" : "" }}
                        >
                          {discount}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontWeight: bold ? "bold" : "" }}
                        >
                          {total}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontWeight: bold ? "bold" : "" }}
                        ></TableCell>

                        <TableCell align="right">
                          {bold ? null : (
                            <UserMoreMenu
                              onDelete={() => handleDeleteUser(id)}
                              userName={itemCode}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  <TableRow
                    sx={{
                      borderBottom: 1,
                      borderColor: "#C3C3C3 ",
                      borderRadius: 1,
                      width: 50,
                      py: 50,
                    }}
                  >
                    <TableCell rowSpan={1} colSpan={6} />
                    <TableCell colSpan={1}>
                      <h4 px={10}> Sub Total</h4>
                    </TableCell>
                    <TableCell colSpan={10}>
                      <TableCell colSpan={3}>
                        <h1>{salesReturnItems.total}</h1>
                      </TableCell>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      borderBottom: 1,
                      borderColor: "#C3C3C3",
                      borderRadius: 1,
                      width: 50,
                      py: 50,
                    }}
                  >
                    <TableCell rowSpan={1} colSpan={6} />
                    <TableCell colSpan={1}>
                      <h4 px={10}>Tax</h4>
                      <Checkbox
                        {...label}
                        checked={isCheckboxChecked}
                        onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                        sx={{
                          color: pink[800],
                          "&.Mui-checked": {
                            color: pink[600],
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell colSpan={10}>
                      <h4>{isCheckboxChecked ? taxGroup : "0"}</h4>
                      <TableCell colSpan={3}>
                        <h1>{isCheckboxChecked ? taxCost : "0"}</h1>
                      </TableCell>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      borderBottom: 1,
                      borderColor: "#C3C3C3 ",
                      borderRadius: 1,
                    }}
                  >
                    <TableCell rowSpan={1} colSpan={6} />
                    <TableCell colSpan={1} sx={{}}>
                      <h4> Shipping Charges</h4>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        name="shipping"
                        type="text"
                        onChange={(event) => {
                          setShippingCostLocal(Number(event.target.value));
                        }}
                        label="Enter Shipping"
                        size="small"
                        sx={{ mt: 1 }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      borderBottom: 1,
                      borderColor: "#C3C3C3 ",
                      borderRadius: 1,
                    }}
                  >
                    <TableCell rowSpan={1} colSpan={6} />
                    <TableCell colSpan={1} sx={{}}>
                      <h4> Total</h4>
                    </TableCell>
                    <TableCell colSpan={1} align="right">
                      <h1>{afterTax}</h1>
                      {console.log("kdlajdls////", afterTax)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={salesReturnItems.items.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, value) => setPage(value)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <DialogAnimate
          modalWidth="sm"
          open={isOpenModal}
          onClose={handleCloseModal}
        >
          <DialogTitle>
            {selectedQuotation
              ? "Edit Sales Quotation Items"
              : "Enter Sales Quotation Items"}
          </DialogTitle>
          <SalesReturnForm
            onSubmit={onSubmit}
            salesReturnItems={salesReturnItems}
            setsalesReturnItems={setsalesReturnItems}
            event={selectedQuotation || {}}
            range={selectedRange}
            onCancel={handleCloseModal}
          />
        </DialogAnimate>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter(
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

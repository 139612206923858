import { createSlice } from "@reduxjs/toolkit";
import {
  addBudgetEntryApi,
  getAllBudgetEntryApi,
  getBudgetEntryByIdApi,
  deleteBudgetEntryApi,
  updateBudgetEntryApi,
} from "src/api/generalledger/budgetEntryApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  budgetEntrys: [],
  singleBudgtEntry: {},
};

const slice = createSlice({
  name: "budgetEntry",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addBudgtEntry(state, { payload }) {
      state.budgetEntrys.push(payload);
    },
    _getAllBudgtEntry(state, { payload }) {
      state.budgetEntrys = payload;
    },
    _getSingleBudgtEntry(state, { payload }) {
      state.singleBudgtEntry = payload;
    },
    _deleteBudgtEntry(state, { payload }) {
      const index = state.budgetEntrys.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.budgetEntrys.splice(index, 1);
      }
    },
    _updateBudgtEntry(state, { payload }) {
      const index = state.budgetEntrys.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.newCustomers[index] = payload;
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addBudgtEntry,
  _getAllBudgtEntry,
  _getSingleBudgtEntry,
  _deleteBudgtEntry,
  _updateBudgtEntry,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addBudgtEntry = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addBudgetEntryApi(data);
    const { status, newbudgtEntry } = response.data;
    if (status === "success") {
      dispatch(_addBudgtEntry(newbudgtEntry));
      return newbudgtEntry.id; // Return the ID value
    }
  } catch (error) {}
};

export const getAllBudgtEntrys = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getAllBudgetEntryApi();

    const { status, newbudgtEntrys } = response.data;
    if (status === "success") {
      dispatch(_getAllBudgtEntry(newbudgtEntrys));
    }
  } catch (error) {}
};

export const deleteBudgtEntrys = (id) => async (dispatch) => {
  try {
    const response = await deleteBudgetEntryApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteBudgtEntry(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateBudgtEntrys = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateBudgetEntryApi(data);
    const { status, newbudgtEntrys } = response.data;
    if (status === "success") {
      dispatch(_updateBudgtEntry(newbudgtEntrys));
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const getBudgtEntryById = (id) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getBudgetEntryByIdApi(id);

    const { status, budgetEntry } = response.data;
    if (status === "success") {
      dispatch(_getSingleBudgtEntry(budgetEntry));
    }
  } catch (error) {
    console.log(error);
    // dispatch(hasError(error));
  }
};

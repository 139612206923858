import { get, post, deleteReq, put } from "src/services/restService";

export const addSalesPricingApi = (data) => {
  return post("sales_pricing/add", data);
};
export const getAllSalesPricingApi = () => {
  return get("sales_pricing/getAll");
};
export const deleteSalesPricingApi = (id) => {
  return deleteReq(`sales_pricing/delete/${id}`);
};

export const updateSalesPricingApi = ( data) => {
  return put(`sales_pricing/update/${data._id}`, data);
};

import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import dayjs from "dayjs";
// form
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "src/redux/store";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Box, Grid, Card, Stack, Typography } from "@mui/material";
import { LoadingButton, MobileDateTimePicker } from "@mui/lab";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// hooks
import useAuth from "src/hooks/useAuth";
// components
import {
  FormProvider,
  RHFSwitch,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import SalesOrderItems from "./salesOrderComponents/SalesOrderItems";
import { getAllNewCustomers } from "src/redux/slices/customer/newCustomer";
import { getAllSalesPersons } from "src/redux/slices/salesPerson";
import { getAllCurrencyItems } from "src/redux/slices/generalledger/currencyItem";

import { getAllSalesTypes } from "src/redux/slices/salesType";
import { getAllInventoryLocations } from "src/redux/slices/inventory/inventoryLocation";
import { getAllShippingCompanys } from "src/redux/slices/setting/shippingCompany";
import { getAllPaymentTerms } from "src/redux/slices/setting/paymentTerm";
import { getAllNewBranchs } from "src/redux/slices/customer/newBranch";
import {
  addSalesOrder,
  deleteSalesOrders,
  updateSalesOrders,
} from "src/redux/slices/customer/salesOrder";
import SalesOrderPrint from "src/pages/dashboard/components/customers/transactions/SalesOrderPrint";
import { useLocation } from "react-router-dom";
import ReactDOM from "react-dom";
import { addDeliveryNote } from "src/redux/slices/customer/deliveryNote";
import { pink } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
// ----------------------------------------------------------------------

export default function SalesOrder() {
  const dispatch = useDispatch();
  const location = useLocation();
  const data = location.state?.newData;
  const isEdit = location.state?.isEdit;
  console.log("data:", data);
  console.log("isEdit:", isEdit);
  const [idValue, setIdValue] = useState("");
  const [popupWindow, setPopupWindow] = useState(null);

  const [isModalOpen, setModalOpen] = useState(false);
  //////////////////////Selectors /////////////////////////////

  const { newBranchs } = useSelector((state) => state.newBranch);
  const { newCustomers } = useSelector((state) => state.newCustomer);
  const { salesPersons } = useSelector((state) => state.salesPerson);
  const { salesTypes } = useSelector((state) => state.salesType);
  const currencyItem = useSelector((state) => state.currencyItem);
  const { currencyItems } = currencyItem || {};

  const { inventoryLocations } = useSelector(
    (state) => state.inventoryLocation
  );
  const { shippingCompanys } = useSelector((state) => state.shippingCompany);
  const { paymentTerms } = useSelector((state) => state.paymentTerm);

  ////////////////Use State ////////////////////////////////////
  const [branchList, setbranchList] = useState([]);
  const [salesPersonList, setsalesPersonList] = useState([]);
  const [customerList, setcustomerList] = useState([]);
  const [inventoryLocationList, setinventoryLocationList] = useState([]);
  const [shippingList, setshippingList] = useState([]);
  const [paymentTermList, setpaymentTermList] = useState([]);
  const [CurrencyList, setCurrencyList] = useState([]);
  const [salesTypeList, setsalesTypeList] = useState([]);

  const [selectedCustomerReference, setselectedCustomerReference] =
    useState("");

  const [selectedAddress, setselectedAddress] = useState("");
  const [selectedpurchaseOrder, setpurchaseOrder] = useState("");
  const [selectedshippingCom, setselectedshippingCom] = useState("");
  const [selecteddeliveryTerms, setselecteddeliveryTerms] = useState("");
  const [selectedcurrency, setselectedcurrency] = useState("");
  const [selectedshippingTerms, setselectedshippingTerms] = useState("");
  const [selectedatn, setselectedatn] = useState("");

  const [selectedDeliveryTo, setDeliveryTo] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("");
  const [selectedSalesPerson, setSelectedSalesPerson] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedComments, setselectedComments] = useState("");
  const [selectedDeliverLocation, setDeliverLocation] = useState("");
  const [cusDiscount, setCusDiscount] = useState("");
  const [exchangeRate, setExchangeRate] = useState("");
  const [currentCredit, setCurrentCredit] = useState("");
  const [quotationReferenceId, setQuotationReferenceId] = useState("");

  // const [selectedSalesPerson, setSelectedSalesPerson] = useState(null);
  const [salesOrderItems, setsalesOrderItems] = useState({
    items: [],
    total: 0,
  });
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCustomerData, setSelectedCustomerData] = useState(null);
  const [filteredBranchList, setFilteredBranchList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedBranchData, setSelectedBranchData] = useState({});
  const [shippingCost, setshippingCost] = useState(0);
  const [taxCost, settaxCost] = useState(0);
  const [afterTax, setafterTax] = useState(0);
  const [taxGroup, setTaxGroup] = useState("");
  const [cusName, setCusName] = useState("");
  const [address, setAddress] = useState("");
  const [gstNum, setGstNum] = useState("");
  const [ntnNum, setNtnNum] = useState("");
  const [phone, setPhone] = useState("");
  const [subtotal, setsubtotal] = useState("");
  const [customerCurrency, setCustomerCurrency] = useState("");

  const currency = data?.currency;
  console.log("selectedSalesPerson ", selectedSalesPerson);
  console.log("salesOrderItems.total", salesOrderItems.total);

  useEffect(() => {
    if (salesOrderItems.total) {
      setsubtotal(salesOrderItems.total);
    }
  }, [salesOrderItems.total]);
  const UpdateUserSchema = Yup.object().shape({
    // customer: Yup.string().required("Select the Customer "),
    // branch: Yup.string().required("Select the Customer"),
    cusDiscount: Yup.string().required("RFq Id is required"),
    // deliveryTo: Yup.string().required("Delivery to is required"),
    // shippingTerms: Yup.string().required("Shipping Terms is required"),
    // deliverFromLocation: Yup.string().required(
    //   "Deliver From Location is required"
    // ),
    // shippingCom: Yup.string().required("Shipping Company  is required"),
  });
  const defaultValues = {
    customer: "",
    branch: "",
    salesOrderReferenceId: "",
    cusDiscount: "",
    exchangeRate: "",
    currentCredit: "",
    purchaseOrderDate: "",
    salespersonPhone: "",
    salesPerson: "",
    PhoneNumber: "",
    payment: "",
    orderDate: "",
    gstNum: "",
    ntnNum: "",
    deliverLocation: "",
    deliveryDate: "",
    trData: "",
    deliveryTo: "",
    comments: "",
    atn: "",
    customerAddress: "",
    branchAddress: "",
    shippingTerms: "",
    currency: data?.currency || "",
    puOrderDate: "",
    deliveryTerms: "",
    shippingCom: "",
    purchaseOrder: "",
    address: "",
    customerReference: data?.customerReference || 0,
    afterTax: 0,
    shippingCost: 0,
    taxCost: 0,
    taxGroup: "",
    carrierReceipt: "",
    typeofPacking: "",
    gridNo: "",
    grn: "",
    subtotal: data?.subtotal || 0,
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues: isEdit ? data : defaultValues, // Use 'data' here
  });
  const {
    reset,
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  // const { currencyItems } = currencyItem || {};

  //////////////////////Use Effect //////////////////////////////////
  const handleCustomerChange = (event) => {
    const selectedCusName = event.target.value;
    setSelectedCustomer(selectedCusName);

    // Find the selected customer data from newCustomers state
    const selectedCusData = newCustomers.find(
      (cus) => cus.cusName === selectedCusName
    );
    setSelectedCustomerData(selectedCusData);

    // Get the taxGroup for the selected customer
    if (selectedCusData) {
      const selectedTaxGroup = selectedCusData.taxGroup;

      setTaxGroup(selectedTaxGroup);
    }
    // Filter the branch list based on the selected customer
    if (selectedCusData) {
      const filteredBranchs = newBranchs.filter(
        (branch) => branch.customerId === selectedCusData._id
      );
      const temp = filteredBranchs.map((item) => item.name);
      setFilteredBranchList(temp);
    } else {
      // Reset the selected branch when customer changes
      setFilteredBranchList([]);
      setSelectedBranch("");
      setSelectedBranchData({});
    }
  };

  const handleBranchChange = (event) => {
    setSelectedBranch(event.target.value);
    const selectedBranchData = newBranchs.find(
      (branch) => branch.name === event.target.value
    );
    setSelectedBranchData(selectedBranchData || {});
  };

  useEffect(() => {
    if (isEdit && location.state.newData) {
      const newData = location.state.newData; // Access newData from location.state

      setSelectedCustomer(newData.customer);
      setSelectedBranch(newData.branch);
      console.log("newData.branch", newData.branch);
      setQuotationReferenceId(newData.quotationReferenceId);
      setCusDiscount(newData.cusDiscount);
      setExchangeRate(newData.exchangeRate);
      setCurrentCredit(newData.currentCredit);
      setSelectedPayment(newData.payment);
      setSelectedSalesPerson(newData.salesPerson);
      setDeliveryTo(newData.deliveryTo);
      setDeliverLocation(newData.deliverLocation);
      setselectedComments(newData.comments);
      setselectedAddress(newData.address);
      setselectedCustomerReference(newData.customerReference);
      setpurchaseOrder(newData.purchaseOrder);
      setselectedshippingCom(newData.shippingCom);
      setselecteddeliveryTerms(newData.deliveryTerms);
      setselectedcurrency(newData.currency);
      setselectedshippingTerms(newData.shippingTerms);
      setselectedatn(newData.atn);
      setPhoneNumber(newData.PhoneNumber);

      if (newData.quotationDate) {
        const formattedDate = dayjs(newData.quotationDate).format("YYYY-MM-DD");
        setorderDate(formattedDate);
      }

      if (newData.quotationDeliveryDate) {
        const formattedDate = dayjs(newData.quotationDeliveryDate).format(
          "YYYY-MM-DD"
        );
        settrData(formattedDate);
      }
      if (newData.quotationDeliveryDate) {
        const formattedDate = dayjs(newData.quotationDeliveryDate).format(
          "YYYY-MM-DD"
        );
        setdeliveryDate(formattedDate);
      }
      if (newData.purchaseOrderDate) {
        const formattedDate = dayjs(newData.purchaseOrderDate).format(
          "YYYY-MM-DD"
        );
        setPurchaseOrderDate(formattedDate);
      }
    }
  }, [isEdit, location.state]);

  ////////////////////////Customer ///////////////////////////////////
  // console.log("taxGroup", taxGroup);
  useEffect(() => {}, [taxGroup]);
  useEffect(() => {
    // Code to retrieve customer data based on the selectedCustomer value
  }, [selectedCustomer]);

  useEffect(() => {
    if (newCustomers.length > 0) {
      const {
        cusName,
        address,
        ntnNum,
        gstNum,
        phone,
        taxGroup,
        customerCurrency,
      } = newCustomers[0];
      setcustomerList(newCustomers.map((item) => item.cusName));
      setCusName(cusName);
      setAddress(address);

      console.log("taxGroup:", taxGroup); // Check if the taxGroup value is present

      setTaxGroup(taxGroup);
      setCustomerCurrency(customerCurrency);

      // Use setValue to set the form values
      setValue("gstNum", gstNum);
      setValue("ntnNum", ntnNum);
      setValue("PhoneNumber", phone);
    }
  }, [newCustomers, setValue]);

  useEffect(() => {
    getNewCustomers();
  }, []);

  const getNewCustomers = async () => {
    await dispatch(getAllNewCustomers());
  };

  ///////////////////////Branch //////////////////////////////
  useEffect(() => {
    const temp = newBranchs.map((item) => item.name);
    setbranchList(temp);
  }, [newBranchs]);

  useEffect(() => {
    getNewBranchs();
  }, []);

  const getNewBranchs = async () => {
    await dispatch(getAllNewBranchs());
  };
  //////////////////////Sales Person//////////////////////////
  useEffect(() => {
    getSalesPersons();
  }, []);

  const getSalesPersons = async () => {
    await dispatch(getAllSalesPersons());
    console.log("salesperson >>>", getAllSalesPersons);
  };
  useEffect(() => {
    const temp = salesPersons.map((item) => ({
      name: item.name,
      phoneNo: item.phoneNo,
    }));

    setsalesPersonList(temp);
  }, [salesPersons]);

  const handleSalesPersonSelection = (selectedIndex) => {
    const selectedPerson = salesPersonList[selectedIndex];
    setSelectedSalesPerson(selectedPerson);
  };
  //////////////////////Customer Currency/////////////////////////
  useEffect(() => {
    getCurrencys();
  }, []);

  const getCurrencys = async () => {
    await dispatch(getAllCurrencyItems());
  };

  useEffect(() => {
    const temp = currencyItems.map((item) => item.Name);
    setCurrencyList(temp);
    console.log("temp  >>>>", currencyItems);
  }, [currencyItems]);
  //////////////////////////Sales Type///////////////////////////////
  useEffect(() => {
    getSalesTypes();
  }, []);

  const getSalesTypes = async () => {
    await dispatch(getAllSalesTypes());
  };

  useEffect(() => {
    const temp = salesTypes.map((item) => item.name);
    setsalesTypeList(temp);
    console.log("temp  >>>>", salesTypes);
  }, [salesTypes]);
  ////////////////////////  Inventory Location /////////////////////

  useEffect(() => {
    getInventoryLocations();
  }, []);

  const getInventoryLocations = async () => {
    await dispatch(getAllInventoryLocations());
  };
  useEffect(() => {
    const temp = inventoryLocations.map((item) => item.locationName);
    setinventoryLocationList(temp);
    console.log("temp  >>>>", inventoryLocations);
  }, [inventoryLocations]);
  ////////////////////////Shipping company //////////////////

  useEffect(() => {
    getShippingCompanys();
  }, []);

  const getShippingCompanys = async () => {
    await dispatch(getAllShippingCompanys());
  };
  useEffect(() => {
    const temp = shippingCompanys.map((item) => item.companyName);
    setshippingList(temp);
    console.log("temp  >>>>", shippingCompanys);
  }, [shippingCompanys]);

  ////////////////////Payment Terms/////////////////////////////
  useEffect(() => {
    getPaymentTerms();
  }, []);

  const getPaymentTerms = async () => {
    await dispatch(getAllPaymentTerms());
  };
  useEffect(() => {
    const temp = paymentTerms.map((item) => item.termDescription);
    setpaymentTermList(temp);
  }, [paymentTerms]);

  const { enqueueSnackbar } = useSnackbar();
  const [orderDate, setorderDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [trData, settrData] = useState(dayjs().format("YYYY-MM-DD"));
  const [deliveryDate, setdeliveryDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [purchaseOrderDate, setPurchaseOrderDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const handleDateChangeSalesOrders = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setorderDate(formattedDate);
  };
  const handleDateChangeTRDate = (newValue) => {
    const formattedDate = dayjs(newValue).format("DD-MM-YYYY");
    settrData(formattedDate);
  };
  const handleDateChangeSalesOrder = (newValue) => {
    const formattedDate = dayjs(newValue).format("DD-MM-YYYY");
    setdeliveryDate(formattedDate);
  };
  const handleDateChangePurchase = (newValue) => {
    const formattedDate = dayjs(newValue).format("DD-MM-YYYY");
    setPurchaseOrderDate(formattedDate);
  };

  ////////////////////////SalesOrder Refernce////////////////////////////////

  const { user } = useAuth();

  const openPopupWindow = (id, navigateToDeliveryNote) => {
    const newWindow = window.open("", "Popup", "width=800,height=600");
    newWindow.document.title = "Popup Window";

    const link1 = newWindow.document.createElement("a");
    link1.href = `/dashboard/customers/transactions/${id}`;
    link1.innerHTML = "SALES ORDER PRINT VIEW";
    link1.style.display = "block";
    link1.style.margin = "20px";
    link1.addEventListener("click", (event) => {
      event.preventDefault();
      const newPath = `/dashboard/customers/transactions/${id}`;
      newWindow.location.href = newPath;
    });

    // return link2; // Return the link2 element
    const link2 = newWindow.document.createElement("a");
    link2.href = "#";
    link2.innerHTML = "Click On Link For Dispatch Delivery";
    link2.style.display = "block";
    link2.style.margin = "20px";
    link2.addEventListener("click", (event) => {
      event.preventDefault();
      navigateToDeliveryNote(); // Navigate to DeliveryNote page
    });
    newWindow.document.body.appendChild(link1);
    newWindow.document.body.appendChild(link2);

    return link2; // Return the link2 element
  };

  const onSubmit = async (data) => {
    if (isEdit) {
      await updateNewSalesOrdersFun(data);
    } else {
      const salesOrderId = await addNewSalesOrderFun(data);
      console.log("addNewSalesOrderFun555555555--", data);
      setModalOpen(true);
    }
  };

  const addNewSalesOrderFun = async (data) => {
    console.log("555555555--", data);
    try {
      reset();
      setsalesOrderItems({ items: [], total: 0 });

      const orderData = {
        ...data,
        subtotal: subtotal,
        customer: selectedCustomer,
        branch: selectedBranch,
        PhoneNumber:
          selectedBranchData?.PhoneNumber || selectedCustomerData?.phone || "",
        address:
          selectedBranchData?.billingAddress ||
          selectedCustomerData?.address ||
          "",
        customerAddress: selectedCustomerData?.address || "",
        branchAddress: selectedBranchData?.billingAddress || "",
        orderDate,
        trData,
        deliveryDate,
        purchaseOrderDate,
        salesOrderDetails: salesOrderItems,
        afterTax,
        taxCost,
        shippingCost,
        gstNum: data.gstNum, // Save the gstNum from the form data
        ntnNum: data.ntnNum,
        taxGroup: taxGroup, // Save the ntnNum from the form data
        salesPerson: selectedSalesPerson?.name || "", // Store the selected salesperson
        salespersonPhone: selectedSalesPerson?.phoneNo || "", // Store the selected salesperson's phone number
      };

      console.log("taxGroup data:", data.taxGroup);
      Object.assign(data, orderData);
      setSelectedCustomer("");
      setSelectedBranch("");
      setSelectedBranchData({});

      const id = await dispatch(
        addSalesOrder(data, taxGroup, shippingCost, taxCost, afterTax)
      );
      console.log("orderData555555555--", orderData);
      setIdValue(id);
      enqueueSnackbar("Add success!");

      // Create a function to navigate to the DeliveryNote page
      const navigateToDeliveryNote = () => {
        const deliveryNotePath = `${location.pathname}/${id}/deliveryNote`;
        window.location.href = deliveryNotePath;
      };

      openPopupWindow(id, navigateToDeliveryNote);

      return id;
    } catch (error) {
      console.error(error);
      throw new Error("Error adding sales order");
    }
  };

  const updateNewSalesOrdersFun = async (data) => {
    console.log("555555555--update", data);
    try {
      await dispatch(updateSalesOrders(data));
      enqueueSnackbar("Update success!");

      reset();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error updating payment term.", { variant: "error" });
    }
  };

  const addSalesOrderItem = (item) => {
    setsalesOrderItems((prevState) => [
      ...prevState,
      { ...item, salesOrderReferenceId: SalesOrder.salesOrderReferenceId },
    ]);
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      sx={{ border: 1, borderColor: "black" }}
    >
      <Grid
        px={1}
        py={1}
        container
        spacing={1}
        sx={{ border: 1, borderColor: "#FB7600", borderRadius: 1 }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Card
            height={3}
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}>
                    <RHFSelect
                      name="customer"
                      label="Customers"
                      size="small"
                      sx={{ mt: 1 }}
                      value={selectedCustomer}
                      onChange={handleCustomerChange}
                    >
                      <option value="" />
                      {customerList.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </RHFSelect>

                    <RHFSelect
                      name="branch"
                      label="Branch"
                      size="small"
                      sx={{ mt: 1 }}
                      disabled={!selectedCustomerData}
                      value={selectedBranch}
                      onChange={handleBranchChange}
                    >
                      <option value="" />
                      {filteredBranchList.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </RHFSelect>
                    <RHFTextField
                      name="salesOrderReferenceId"
                      label="Reference"
                      size="small"
                      sx={{ mt: 1 }}
                    />
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,

                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}>
                    <RHFTextField
                      name="cusDiscount"
                      label="RFQ Number"
                      size="small"
                      sx={{ mt: 1 }}
                    />
                    <RHFTextField
                      name="exchangeRate"
                      label="Exchange Rate"
                      size="small"
                      sx={{ mt: 1 }}
                    />
                    <RHFTextField
                      name="currentCredit"
                      label="Current Credit"
                      size="small"
                      sx={{ mt: 1 }}
                    />
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)   ",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}>
                    <RHFSelect
                      name="payment"
                      label="Payment"
                      placeholder="Payment"
                      size="small"
                      sx={{ mt: 1 }}
                    >
                      <option value="" />
                      {paymentTermList.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </RHFSelect>
                    <RHFSelect
                      name="salesPerson"
                      label="Sales Person"
                      placeholder="Sales Person"
                      size="small"
                      sx={{ mt: 1 }}
                      value={salesPersonList.indexOf(selectedSalesPerson)} // Set the selected value index
                      onChange={(e) =>
                        handleSalesPersonSelection(e.target.value)
                      } // Update the selected salesperson index
                    >
                      <option value="" />
                      {salesPersonList.map((option, index) => (
                        <option key={index} value={index}>
                          {option.name}
                        </option>
                      ))}
                    </RHFSelect>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          container
                          name="orderDate"
                          label="Order Date"
                          value={orderDate}
                          minDate={dayjs("2017-01-01")}
                          onChange={handleDateChangeSalesOrders}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              sx={{ mt: 1 }}
                            />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
      </Grid>
      {/*----------------SALES TABLE CALLING-------------------------------------------*/}

      <SalesOrderItems
        setshippingCost={setshippingCost}
        settaxCost={settaxCost}
        setafterTax={setafterTax}
        salesOrderItems={salesOrderItems}
        setsalesOrderItems={setsalesOrderItems}
        addSalesOrderItem={addSalesOrderItem}
        taxGroup={taxGroup}
        setTaxGroup={setTaxGroup}
      />

      {/*----------------3rd portion Detailing Code-------------------------------------------*/}
      <Grid mt={3} container spacing={1}>
        <Grid item xs={12} md={12}>
          <Card
            sx={{
              p: 3,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <h4
              style={{
                marginBottom: 15,
                marginTop: 10,
                textAlign: "center",
                color: "#ff6347",
                fontSize: 30,
              }}
            >
              {" "}
              Order Delivery Details
            </h4>
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 2,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(3, 1fr)",
                },
              }}
            >
              <Grid mt={3} container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}>
                    <RHFSelect
                      name="deliverLocation"
                      label="Deliver from Location"
                      placeholder="Deliver from Location"
                      size="small"
                      sx={{ mt: 2 }}
                    >
                      <option value="" />
                      {inventoryLocationList.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </RHFSelect>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3} sx={{ mt: 2 }}>
                        <DesktopDatePicker
                          label="Required Delivery Date"
                          name="deliveryDate"
                          value={deliveryDate}
                          minDate={dayjs("2017-01-01")}
                          onChange={handleDateChangeSalesOrder}
                          renderInput={(params) => (
                            <TextField {...params} size="small" />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3} sx={{ mt: 2 }}>
                        <DesktopDatePicker
                          label="T.R. Date"
                          name="trData"
                          value={trData}
                          minDate={dayjs("2017-01-01")}
                          onChange={handleDateChangeTRDate}
                          renderInput={(params) => (
                            <TextField {...params} size="small" />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Card>
                </Grid>
              </Grid>
              <Grid mt={3} container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}>
                    <RHFTextField
                      name="PhoneNumber "
                      label="Contact Phone Number"
                      size="small"
                      sx={{ mt: 2 }}
                      value={
                        selectedBranchData?.PhoneNumber ||
                        selectedCustomerData?.phone ||
                        ""
                      }
                    />{" "}
                    <RHFTextField
                      name="gridNo "
                      label="Grid Number"
                      size="small"
                      sx={{ mt: 2 }}
                    />
                  </Card>
                </Grid>
              </Grid>
              <Grid mt={3} container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}>
                    <RHFTextField
                      name="comments"
                      label="Comments"
                      size="small"
                      sx={{ mt: 2 }}
                    />
                    <RHFTextField
                      name="atn"
                      label="ATTN"
                      size="small"
                      sx={{ mt: 2 }}
                    />
                  </Card>
                </Grid>
              </Grid>
              <Grid mt={1} container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}>
                    <RHFTextField
                      name="shippingTerms"
                      label="Shipping Terms"
                      size="small"
                      sx={{ mt: 2 }}
                    />
                    <RHFSelect
                      name="currency"
                      label="Currency"
                      size="small"
                      sx={{ mt: 2 }}
                      value={defaultValues.currency}
                    >
                      <option value="" />
                      {CurrencyList.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </RHFSelect>
                    <RHFTextField
                      name="carrierReceipt"
                      label="Carrier Receipt No:"
                      size="small"
                      sx={{ mt: 2, borderRadius: 1 }}
                    />
                  </Card>
                </Grid>
              </Grid>
              <Grid mt={1} container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3} sx={{ mt: 2 }}>
                        <DesktopDatePicker
                          label="Purchase Order Date"
                          value={purchaseOrderDate}
                          minDate={dayjs("2017-01-01")}
                          onChange={handleDateChangePurchase}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              sx={{ borderRadius: 1 }}
                            />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                    <RHFTextField
                      name="deliveryTerms"
                      label="Delivery Terms"
                      size="small"
                      sx={{ mt: 2, borderRadius: 1 }}
                    />
                    <RHFTextField
                      name="typeofPacking"
                      label="Type Of Packing    "
                      size="small"
                      sx={{ mt: 2, borderRadius: 1 }}
                    />
                  </Card>
                </Grid>
              </Grid>
              <Grid mt={1} container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Card sx={{ p: 3 }}>
                    <RHFSelect
                      name="shippingCom"
                      label="Shipping Company"
                      placeholder="Shipping Company"
                      size="small"
                      sx={{ mt: 2, borderRadius: 1 }}
                    >
                      <option value="" />
                      {shippingList.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </RHFSelect>
                    <RHFTextField
                      name="purchaseOrder"
                      label="Purchase Order"
                      size="small"
                      sx={{ mt: 2, borderRadius: 2 }}
                    />
                    <RHFTextField
                      name="grn"
                      label="GRN"
                      size="small"
                      sx={{ mt: 2, borderRadius: 1 }}
                    />
                  </Card>
                </Grid>
              </Grid>
            </Box>

            <Grid mt={5} ml={25} container spacing={1}>
              <Grid item xs={8} sm={4} md={8}>
                <Card sx={{ p: 3 }}>
                  <RHFTextField
                    name="address"
                    label="Address"
                    size="small"
                    multiline
                    rows={4}
                    value={
                      selectedBranchData?.billingAddress ||
                      selectedCustomerData?.address ||
                      ""
                    }
                    sx={{ mt: 2 }}
                  />
                  <RHFTextField
                    name="customerReference"
                    placeholderTextColor={"green"}
                    label="Customer Reference"
                    size="small"
                    defaultValue={defaultValues.customerReference}
                    sx={{ mt: 2 }}
                  />
                </Card>
              </Grid>
            </Grid>
            <Stack
              spacing={1}
              alignItems="flex-bottom"
              sx={{ mt: 1, borderRadius: 1 }}
            >
              <Box display={"flex"} sx={{ justifyContent: "center", m: 5 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  loadingIndicator="Loading..."
                  // onClick={handleButtonClick}
                >
                  {isEdit ? "Update" : "Place Sales Order"}
                </LoadingButton>
                {/* {isModalOpen && idValue && (
                  <LoadingButton
                    href="#"
                    onClick={() => openPopupWindow(idValue)}
                  >
                    Open window for Print (Etc)
                  </LoadingButton>
                )} */}
              </Box>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

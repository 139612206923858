import { createSlice } from "@reduxjs/toolkit";
import {
  addSalesPricingApi,
  getAllSalesPricingApi,
  deleteSalesPricingApi,
  updateSalesPricingApi
} from "src/api/inventory/salesPricingApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  salesPricings: [],
};

const slice = createSlice({
  name: "salesPricing",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addsalesPricing(state, { payload }) {
      state.salesPricings.push(payload);
    },
    _getAllsalesPricings(state, { payload }) {
      state.salesPricings = payload;
    },
    _deletesalesPricings(state, { payload }) {
      const index = state.salesPricings.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.salesPricings.splice(index, 1);
      }
    },
    

    _updatesalesPricings(state, { payload }) {
      const index = state.salesPricings.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.salesPricings[index] = payload;
      }
    },


    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addsalesPricing,
  _getAllsalesPricings,
  _deletesalesPricings,
  _updatesalesPricings,
  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addSalesPricing = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addSalesPricingApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, salespricing } = response.data;
    if (status === "success") {
      dispatch(_addsalesPricing(salespricing));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllSalesPricings = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllSalesPricingApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, salespricings } = response.data;
    if (status === "success") {
      dispatch(_getAllsalesPricings(salespricings));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};
export const deleteSalesPricing = (barcodeId) => async (dispatch) => {
  try {
    const response = await deleteSalesPricingApi(barcodeId);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deletesalesPricings(barcodeId));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateSalesPricings = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateSalesPricingApi(data);
    const { status, salespricings } = response.data;
    if (status === "success") {
      dispatch(_updatesalesPricings(salespricings));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};

import { createSlice } from "@reduxjs/toolkit";
import {
  addCustomerContactApi,
  getAllCustomerContactApi,
  deleteCustomerContactApi,
  updateCustomerContactApi,
} from "src/api/customer/customerContactApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  customerContacts: [],
};

const slice = createSlice({
  name: "customerContact",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addCustomerContact(state, { payload }) {
      state.customerContacts.push(payload);
    },
    _getAllCustomerContact(state, { payload }) {
      state.customerContacts = payload;
    },
    _updateCustomerContact(state, { payload }) {
      const index = state.customerContacts.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.customerContacts[index] = payload;
      }
    },

    _deleteCustomerContact(state, { payload }) {
      state.customerContacts = state.customerContacts.filter(
        (customerContact) => customerContact.id !== payload
      );
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addCustomerContact,
  _getAllCustomerContact,
  _updateCustomerContact,
  _deleteCustomerContact,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addCustomerContact = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addCustomerContactApi(data);
    const { status, customerContact } = response.data;
    if (status === "success") {
      dispatch(_addCustomerContact, customerContact);
    }
  } catch (error) {}
};

export const getAllCustomerContacts = (data) => async (dispatch) => {
  try {
    const response = await getAllCustomerContactApi();
    const { status, customerContacts } = response.data;
    if (status === "success") {
      dispatch(_getAllCustomerContact(customerContacts));
    }
  } catch (error) {}
};

export const updateCustomerContacts = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateCustomerContactApi(data);
    const { status, customerContacts } = response.data;
    if (status === "success") {
      dispatch(_updateCustomerContact(customerContacts));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};


export const deleteCustomerContact = (id) => async (dispatch) => {
  try {
    const response = await deleteCustomerContactApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteCustomerContact(id));
    }
  } catch (error) {}
};

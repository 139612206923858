import { createSlice } from "@reduxjs/toolkit";
import {
  addSalesPersonApi,
  getAllSalesPersonsApi,
  deleteSalesPersonApi,
  updateSalesPersonApi,
} from "src/api/salesPersonApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  salesPersons: [],
};

const slice = createSlice({
  name: "salesPerson",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addSalesPerson(state, { payload }) {
      state.salesPersons.push(payload);
    },
    _getAllSalesPerson(state, { payload }) {
      state.salesPersons = payload;
    },
    _updateSalesPerson(state, { payload }) {
      const index = state.salesPersons.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.salesPersons[index] = payload;
      }
    },
    _deleteSalesPerson(state, { payload }) {
      state.salesPersons = state.salesPersons.filter(
        (salesPerson) => salesPerson.id !== payload
      );
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addSalesPerson,
  _getAllSalesPerson,
  _updateSalesPerson,
  _deleteSalesPerson,
  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addSalesPerson = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addSalesPersonApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, salesPerson } = response.data;
    if (status === "success") {
      dispatch(_addSalesPerson(salesPerson));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllSalesPersons = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllSalesPersonsApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, salesPersons } = response.data;
    if (status === "success") {
      dispatch(_getAllSalesPerson(salesPersons));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};
export const updateSalesPersons = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateSalesPersonApi(data);
    const { status, salesPersons } = response.data;
    if (status === "success") {
      dispatch(_updateSalesPerson(salesPersons));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};

export const deleteSalesPerson = (id) => async (dispatch) => {
  try {
    const response = await deleteSalesPersonApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteSalesPerson(id));
    }
  } catch (error) {
    console.log("Error deleting sales person:", error);
  }
};

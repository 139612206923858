import { get, post, deleteReq, put } from "../../../src/services/restService";

export const addSalesReturnsApi = (data) => {
  return post("sales_return/add", data);
};
export const getAllSalesReturnsApi = () => {
  return get("sales_return/getAll");
};
export const getSalesReturnsByIdApi = (id) => {
  return get(`sales_return/getById/${id}`);
};

export const deleteSalesReturnsApi = (id) => {
  return deleteReq(`sales_return/delete/${id}`);
};

export const updateSalesReturnsApi = (data) => {
  return put(`sales_return/update/${data._id}`, data);
};

import { createSlice } from "@reduxjs/toolkit";
import {
  addBankTransferApi,
  getAllBankTransferApi,
  getBankTransferByIdApi,
  deleteBankTransferApi,
  updateBankTransferApi,
} from "src/api/generalledger/bankTransferApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  bankTransfers: [],
  singleBankTransfer: {},
};

const slice = createSlice({
  name: "bankTransfer",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addBankTransfer(state, { payload }) {
      state.bankTransfers.push(payload);
    },
    _getAllBankTransfer(state, { payload }) {
      state.bankTransfers = payload;
    },
    _getSingleBankTransfer(state, { payload }) {
      state.singleBankTransfer = payload;
    },
    _deleteBankTransfer(state, { payload }) {
      const index = state.bankTransfers.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.bankTransfers.splice(index, 1);
      }
    },
    _updateBankTransfer(state, { payload }) {
      const index = state.bankTransfers.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.newCustomers[index] = payload;
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addBankTransfer,
  _getAllBankTransfer,
  _getSingleBankTransfer,
  _deleteBankTransfer,
  _updateBankTransfer,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addBankTransfer = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addBankTransferApi(data);
    const { status, newbankTransfer } = response.data;
    if (status === "success") {
      dispatch(_addBankTransfer(newbankTransfer));
      return newbankTransfer.id; // Return the ID value
    }
  } catch (error) {}
};

export const getAllBankTransfers = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getAllBankTransferApi();

    const { status, newbankTransfers } = response.data;
    if (status === "success") {
      dispatch(_getAllBankTransfer(newbankTransfers));
    }
  } catch (error) {}
};

export const deleteBankTransfers = (id) => async (dispatch) => {
  try {
    const response = await deleteBankTransferApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteBankTransfer(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateBankTransfers = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateBankTransferApi(data);
    const { status, newbankTransfers } = response.data;
    if (status === "success") {
      dispatch(_updateBankTransfer(newbankTransfers));
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const getBankTransferById = (id) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await getBankTransferByIdApi(id);

    const { status, bankTransfer } = response.data;
    if (status === "success") {
      dispatch(_getSingleBankTransfer(bankTransfer));
    }
  } catch (error) {
    console.log(error);
    // dispatch(hasError(error));
  }
};

import { get, post, deleteReq, put } from "src/services/restService";

export const addNewBranchApi = (data) => {
  return post("new_branch/add", data);
};
export const getAllNewBranchApi = () => {
  return get("new_branch/getAll");
};
export const deleteNewBranchApi = (id) => {
  return deleteReq(`new_branch/delete/${id}`);
};

export const updateNewBranchApi = (data) => {
  return put(`new_branch/update/${data._id}`, data);
};

import { createSlice } from "@reduxjs/toolkit";
import {
  addglAccountClassApi,
  getAllglAccountClassApi,
  deleteglAccountClassApi,
  updateglAccountClassApi,
} from "src/api/generalledger/glAccountClassApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  glAccountClasss: [],
};

const slice = createSlice({
  name: "glAccountClass",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addGLAccountClass(state, { payload }) {
      state.glAccountClasss.push(payload);
    },
    _getAllGLAccountClass(state, { payload }) {
      state.glAccountClasss = payload;
    },
    _deleteGLAccountClass(state, { payload: id }) {
      state.glAccountClasss = state.glAccountClasss.filter(
        (glAccountClass) => glAccountClass._id !== id
      );
    },

    _updateGLAccountClass(state, { payload }) {
      const index = state.glAccountClasss.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.glAccountClasss[index] = payload;
      }
    },

    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addGLAccountClass,
  _getAllGLAccountClass,
  _deleteGLAccountClass,
  _updateGLAccountClass,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addGLAccountClass = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addglAccountClassApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, glaccountclass } = response.data;
    if (status === "success") {
      dispatch(_addGLAccountClass(glaccountclass));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllGLAccountClasss = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllglAccountClassApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, glaccountclasss } = response.data;
    if (status === "success") {
      dispatch(_getAllGLAccountClass(glaccountclasss));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const deleteGLAccountClass = (id) => async (dispatch) => {
  try {
    const response = await deleteglAccountClassApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteGLAccountClass(id));
    }
    return response.data;
  } catch (error) {
    dispatch(hasError(error));
    throw error;
  }
};
export const updateGLAccountClass = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateglAccountClassApi(data);
    const { status, glaccountclasss } = response.data;
    if (status === "success") {
      dispatch(_updateGLAccountClass(glaccountclasss));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};

import { get, post, deleteReq, put } from "src/services/restService";

export const addNewLocalInvoiceApi = (data) => {
  return post("local_invoice/add", data);
};
export const getAllNewLocalInvoiceApi = () => {
  return get("local_invoice/getAll");
};

export const getNewLocalInvoiceByIdApi = (id) => {
  return get(`local_invoice/getById/${id}`);
};

export const deleteNewLocalInvoiceApi = (id) => {
  return deleteReq(`local_invoice/delete/${id}`);
};

export const updateNewLocalInvoiceApi = (data) => {
  return put(`local_invoice/update/${data._id}`, data);
};

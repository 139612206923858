import { get, post,deleteReq,put } from "src/services/restService"

export const addNewCustomerApi = (data) => {
    return post('new_customer/add', data)
}
export const getAllNewCustomerApi = () => {
    return get('new_customer/getAll')
}
export const deleteNewCustomerApi = (id) => {
    return deleteReq(`new_customer/delete/${id}`);
  }
  export const updateNewCustomerApi = ( data) => {
    return put(`new_customer/update/${data._id}`, data);
  }
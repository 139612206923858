import { createSlice } from "@reduxjs/toolkit";
import {
  refreshTokenApi,
  signInApi,
  signOutApi,
  getAllUsersApi,
  signUpApi,
} from "src/api/authApi";

import tokenService from "src/services/tokenService";
// utils

//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  userInfo: {},
  accessToken: "",
  users: [],
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    _addUser(state, { payload }) {
      state.isLoading = false;
      state.error = null;
      state.users = [...state.users, payload.user]; // Add the new user to the state
    },
    _signIn(state, { payload }) {
      state.accessToken = payload;
      state.isLoading = false;
      state.error = null;
      state.userInfo = payload.user; // Ensure this line is present
    },
    _signOut(state) {
      state.accessToken = "";
    },
    _logout(state) {
      state.accessToken = "";
    },
    _refreshToken(state, { payload }) {
      state.userInfo = payload;
    },

    _getUsers(state, { payload }) {
      state.isLoading = false;
      state.users = payload; // Assuming newUsers is the array of users
      state.error = null;
    },

    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { _signIn, _getUsers, _addUser } = slice.actions;
const {
  _signOut,
  _logout,
  _refreshToken,
  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------

export const addUser = (userData) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await signUpApi(userData);
    console.log("Add User API Response:", response);

    const { status, message, user } = response.data;
    if (status === "success") {
      dispatch(_addUser({ user })); // Assuming you have a getUsers action to refresh the user list
    } else {
      dispatch(hasError(message));
    }
  } catch (error) {
    console.error("Error adding user:", error);
    dispatch(hasError(error));
  }
};

export const signIn = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await signInApi(data);
    console.log("SignIn API Response:-=======", response);
    const { status, message, error, user } = response.data;
    if (status === "success") {
      dispatch(_signIn({ accessToken: user.accessToken, user: user }));
      tokenService.setUser(user);
    } else {
      return error;
    }
  } catch (error) {
    console.log("88*********error", error);
    dispatch(hasError(error));
  }
};

export const signOut = (data) => async (dispatch) => {
  // dispatch(startLoading());
  const refreshToken = tokenService.getLocalRefreshToken();
  try {
    const response = await signOutApi(refreshToken);
    dispatch(_signOut());
    tokenService.removeUser();
    sessionStorage.clear();
  } catch (error) {
    dispatch(hasError(error));
  }
};

export const logout = () => (dispatch) => {
  dispatch(_logout());
  tokenService.removeUser();
};
export const refreshToken = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await refreshTokenApi(data);
    console.log("refreshToken response====", response);
    dispatch(_refreshToken(response));
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getUsers = (data) => async (dispatch) => {
  dispatch(startLoading()); // Assuming you have a startLoading action

  try {
    const response = await getAllUsersApi();
    console.log("get allusers response====", response);

    const { status, newUsers } = response.data;

    if (status === "success") {
      dispatch(_getUsers(newUsers)); // Corrected action name
    } else {
      // Handle the error condition if needed
      dispatch(hasError(response.message)); // Assuming you want to dispatch an error message
    }
  } catch (error) {
    // Handle errors if needed
    dispatch(hasError(error));
  }
};

import { createSlice } from "@reduxjs/toolkit";
import {
  addNewCustomerApi,
  getAllNewCustomerApi,
  deleteNewCustomerApi,
  updateNewCustomerApi,
} from "src/api/customer/newCustomerApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  newCustomers: [],
};

const slice = createSlice({
  name: "newCustomer",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addNewCustomer(state, { payload }) {
      state.newCustomers.push(payload);
    },
    _getAllNewCustomer(state, { payload }) {
      state.newCustomers = payload;
    },
    _updateNewCustomer(state, { payload }) {
      const index = state.newCustomers.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.newCustomers[index] = payload;
      }
    },

    _deleteNewCustomer(state, { payload }) {
      const index = state.newBranchs.findIndex((item) => item._id === payload);
      if (index !== -1) {
        state.newBranchs.splice(index, 1);
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addNewCustomer,
  _getAllNewCustomer,
  _updateNewCustomer,
  _deleteNewCustomer,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addNewCustomer = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addNewCustomerApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, newCustomer } = response.data;
    if (status === "success") {
      dispatch(_addNewCustomer(newCustomer));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllNewCustomers = (data) => async (dispatch) => {
  try {
    const response = await getAllNewCustomerApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, newCustomers } = response.data;
    if (status === "success") {
      dispatch(_getAllNewCustomer(newCustomers));
    }

    console.log("get addSalesPersonApi response====", response);
  } catch (error) {}
};

export const updateNewCustomers = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateNewCustomerApi(data);
    const { status, newCustomers } = response.data;
    if (status === "success") {
      dispatch(_updateNewCustomer(newCustomers));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};

export const deleteNewCustomer = (id) => async (dispatch) => {
  try {
    const response = await deleteNewCustomerApi(id);
    console.log("deleteNewBranchApi response ====", response);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteNewCustomer(id));
    }
  } catch (error) {
    // dispatch(hasError(error));
  }
};

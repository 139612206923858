import { Font, StyleSheet } from "@react-pdf/renderer";

// ----------------------------------------------------------------------

Font.register({
  family: "Roboto",
  fonts: [
    { src: "/fonts/Roboto-Regular.ttf" },
    { src: "/fonts/Roboto-Bold.ttf" },
  ],
});

const styles = StyleSheet.create({
  tableContainer: {
    borderWidth: 1,
    borderColor: "#000000",
    marginBottom: 10,
  },
  tableLatest: {},
  table0: {
    marginTop: 0,
    width: "auto",
    marginBottom: 1,
    border: 1,
    borderColor: "#000000",
  },
  tableHeader0: {
    backgroundColor: "#F2F2F2",
    height: 40, // change the value as per your requirement
    flexDirection: "row",
    alignItems: "center",
    // paddingHorizontal: 10,
  },
  tableRow0: {
    flexDirection: "row",
    border: 0.5,
    // borderBottom: 1,

    borderColor: "#000000",
  },
  tableRow011: {
    flexDirection: "row",

    borderColor: "#000000",
  },
  tableRowLatest: {
    flexDirection: "row",
    border: 1,

    borderColor: "#000000",
  },
  tableRow02: {
    padding: "8px 0",
    flexDirection: "row",
    // borderBottomWidth: 1,
    // borderStyle: "solid",
    // borderColor: "#DFE3E8",
    marginTop: "20px",
  },
  tableRow1: {
    width: "30%",
    flexDirection: "row",
    border: 1,
    borderColor: "#000000",
    height: 40,
  },
  tableCell_10: {
    width: "10%",
    paddingLeft: 5,
    borderRightWidth: 1,
    // borderBottom: 0.5,
    borderColor: "#000000",
    justifyContent: "center",
    // alignItems: "flex-start",
  },
  tableCell_10s: {
    width: "10%",
    height: 10,

    justifyContent: "center",
    // alignItems: "flex-start",
  },
  tableCell_101: {
    width: "10%",
    height: 15,
    justifyContent: "center",
    // alignItems: "flex-start",
  },
  tableCell_110: {
    width: "15%",
    paddingLeft: 5,
    borderRightWidth: 1,

    borderColor: "#000000",
    justifyContent: "center",
    // alignItems: "flex-start",
  },
  tableCell_40: {
    width: "50%",
    paddingLeft: 5,
    borderRightWidth: 1,
    borderColor: "#000000",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  tableCell_100: {
    width: "30%",
    paddingLeft: 5,
    borderRightWidth: 1,
    borderColor: "#000000",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  tableCell_1100: {
    width: "30%",
    paddingLeft: 5,
    // borderRightWidth: 1,
    borderColor: "#000000",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  tableCell_30: {
    width: "70%",
    height: "auto",
    paddingLeft: 5,
    borderRightWidth: 1,
    borderColor: "#000000",
    // justifyContent: "center",
  },
  tableCell1_30: {
    width: "50%",
    height: "auto",
    borderRightWidth: 1,

    borderColor: "#000000",
    justifyContent: "center",
    alignItems: "center",
  },
  tableCellh_30: {
    width: "70%",
    height: 50,
    borderRightWidth: 1,
    borderColor: "#000000",
    justifyContent: "center",
    alignItems: "center",
  },
  tableCell_70: {
    width: "40%",
    borderRightWidth: 1,
    borderColor: "#000000",
    justifyContent: "center",
    alignItems: "center",
  },
  tableCell_50: {
    width: "15%",

    // borderRightWidth: 1,
    borderColor: "#000000",
    justifyContent: "center",
    alignItems: "center",
  },
  tableCell_50H: {
    width: "15%",
    height: 30,
    // borderRightWidth: 1,
    borderColor: "#000000",
    justifyContent: "center",
    alignItems: "center",
  },
  tableCell_50s: {
    width: "15%",
    // borderRightWidth: 1,
    borderColor: "#000000",
    justifyContent: "center",
    alignItems: "center",
  },
  tableCellC_50: {
    width: "15%",
    // borderRightWidth: 1,
    borderColor: "#000000",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "#F8C9BE",
  },
  alignCenter0: {
    alignItems: "center",
    textAlign: "center",
  },
  alignRight0: {
    alignItems: "flex-end",
    paddingRight: 5,
  },
  subtitle20: {
    fontSize: 10,
  },
  cell0: {
    padding: 5,
    fontSize: 8,
    color: "#333333",
  },
  cell01: {
    color: "#333333",
  },
  cell1: {
    width: "30%",
    padding: 5,
    fontSize: 8,
    color: "#333333",
  },
  bold: {
    fontWeight: "bold",
  },

  endPage: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
  // gridContainer: {
  //   display: "flex",
  //   flexWrap: "wrap",
  //   // alignItems: "center",
  //   padding: 10,
  // },
  col8: {
    flexBasis: "60%",
    flexGrow: 0,
    maxWidth: "60%",
    padding: 10,
  },
  col6: {
    flexBasis: "30%",
    flexGrow: 0,
    maxWidth: "30%",
    padding: 10,
  },
  subtitle2: {
    fontSize: 16,
    fontWeight: "bold",
  },
  bor: {
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
  },
  subtotal: {
    border: 3,
    borderColor: "#000000",
    // height: 400,
  },
  alignCenter: {
    textAlign: "center",
  },
  bolds: {
    fontWeight: "bold",
  },
  space3: {
    marginTop: 30,
  },

  ////////////////////////////////////////////////////
  col4: { width: "25%" },
  col8: { width: "75%" },
  col6: { width: "50%" },
  mb8: { marginBottom: 8 },
  mb80: { marginRight: 10, fontSize: 10 },
  mb40: { marginBottom: 2 },
  overline: {
    fontSize: 8,
    marginBottom: 5,
    fontWeight: 700,
    letterSpacing: 1.2,
    textTransform: "uppercase",
  },
  h3: { fontSize: 16, fontWeight: 700 },
  h4: { fontSize: 10, fontWeight: 700 },
  h24: { fontSize: 10, fontWeight: 700, color: "red" },
  h2: { fontSize: 18, fontWeight: 700 },
  body1: { fontSize: 10 },

  // endPage2: {
  //   marginBottom: 0,
  // },
  subtitle2: { fontSize: 9, fontWeight: 700 },
  alignRight: { textAlign: "right" },
  alignCenter: { textAlign: "center" },
  page: {
    padding: "10px 24px 0 24px",
    fontSize: 9,
    lineHeight: 1.6,
    fontFamily: "Roboto",
    backgroundColor: "#fff",
    textTransform: "capitalize",
  },
  footer: {
    left: 0,
    right: 0,
    bottom: 0,
    padding: 8,

    margin: "auto",
    // minWidth: 300,
    // borderTopWidth: 1,
    borderStyle: "solid",
    position: "absolute",
    borderColor: "#DFE3E8",
  },

  row: { border: 1, borderColor: "#DFE3E8", borderStyle: "solid" },
  row5: {
    borderBottom: 1,
    marginTop: 5,
    borderColor: "#DFE3E8",
    borderStyle: "solid",
  },

  gridContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 0,
    padding: 0,
  },
  table: { display: "flex", width: "auto" },
  tableHeader: {},
  tableBody: {},
  tableRow: {
    padding: "8px 0",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#DFE3E8",
    height: "auto",
  },
  space: { marginTop: "10px" },
  space2: { marginTop: "20px" },
  space3: { marginTop: "10px" },
  bor: {
    borderBottom: 2,
    borderColor: "#DFE3E8",
    borderStyle: "solid",
    flexDirection: "row",
  },
  bor2: {
    border: 2,
    borderColor: "#DFE3E8",
    borderStyle: "solid",
    width: 500,
    flexDirection: "row",
    alignContent: "center",
  },
  noBorder: { paddingTop: 8, paddingBottom: 0, borderBottomWidth: 0 },
  tableCell_1: {
    width: "10%",
  },
  tableCell_2: { width: "50%", paddingRight: 16, alignContent: "center" },
  tableCell_3: { width: "15%" },
  tableCell_4: { width: "40%" },
});

export default styles;

import { get, post, deleteReq, put } from "../../../src/services/restService";

export const addCashInvoiceApi = (data) => {
  return post("cash_invoice/add", data);
};
export const getAllCashInvoiceApi = () => {
  return get("cash_invoice/getAll");
};
export const getCashInvoiceByIdApi = (id) => {
  return get(`cash_invoice/getById/${id}`);
};

export const deleteCashInvoiceApi = (id) => {
  return deleteReq(`cash_invoice/delete/${id}`);
};

export const updateCashInvoiceApi = (data) => {
  return put(`cash_invoice/update/${data._id}`, data);
};

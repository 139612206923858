import { get, post, deleteReq, put } from "src/services/restService";

export const addInventoryCategoryApi = (data) => {
  return post("item_Category/add", data);
};
export const getAllInventoryCategoryApi = () => {
  return get("item_Category/getAll");
};
export const deleteInventoryCategoryApi = (id) => {
  return deleteReq(`item_Category/delete/${id}`);
};

export const updateInventoryCategoryApi = (data) => {
  return put(`item_Category/update/${data._id}`, data);
};

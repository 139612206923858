import { createSlice } from "@reduxjs/toolkit";
import {
  addSalesTypeApi,
  getAllSalesTypesApi,
  deleteSalesTypeApi,
  updateSalesTypeApi,
} from "src/api/salesTypeApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  salesTypes: [],
};

const slice = createSlice({
  name: "salesType",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addSalesType(state, { payload }) {
      state.salesTypes.push(payload);
    },
    _getAllSalesType(state, { payload }) {
      state.salesTypes = payload;
    },
    _deleteSalesType(state, { payload }) {
      state.salesTypes = state.salesTypes.filter(
        (type) => type._id !== payload
      );
    },
    _updateSalesType(state, { payload }) {
      const index = state.salesTypes.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.salesTypes[index] = payload;
      }
    },

    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addSalesType,
  _getAllSalesType,
  _deleteSalesType,
  _updateSalesType,
  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addSalesType = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addSalesTypeApi(data);
    console.log("get addSalesTypeApi====", response);
    const { status, salesType } = response.data;
    if (status === "success") {
      dispatch(_addSalesType(salesType));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesTypeApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllSalesTypes = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllSalesTypesApi();
    console.log("get getAllSalesTypeApi====", response);
    const { status, salesTypes } = response.data;
    if (status === "success") {
      dispatch(_getAllSalesType(salesTypes));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesTypeApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const deleteSalesType = (id) => async (dispatch) => {
  try {
    const response = await deleteSalesTypeApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteSalesType(id));
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateSalesType = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateSalesTypeApi(data);
    const { status, salesTypes } = response.data;
    if (status === "success") {
      dispatch(_updateSalesType(salesTypes));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
import { createSlice } from "@reduxjs/toolkit";
import {
  addShippingcompanyApi,
  getAllShippingcompanyApi,
  deleteShippingcompanyApi,
  updateShippingcompanyApi
} from "src/api/setting/shippingCompanyApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  shippingCompanys: [],
};

const slice = createSlice({
  name: "shippingCompany",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addShippingCompany(state, { payload }) {
      state.shippingCompanys.push(payload);
    },
    _getAllShippingCompany(state, { payload }) {
      state.shippingCompanys = payload;
    },
    _deleteShippingCompany(state, { payload }) {
      const index = state.shippingCompanys.findIndex(
        (item) => item._id === payload
      );
      if (index !== -1) {
        state.shippingCompanys.splice(index, 1);
      }
    },
    _updateShippingCompany(state, { payload }) {
      const index = state.shippingCompanys.findIndex(
        (shipping) => shipping._id === payload._id
      );
      if (index !== -1) {
        state.shippingCompanys[index] = payload;
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addShippingCompany,
  _getAllShippingCompany,
  _deleteShippingCompany,
  _updateShippingCompany,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addShippingCompany = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addShippingcompanyApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, shippingcompany } = response.data;
    if (status === "success") {
      dispatch(_addShippingCompany(shippingcompany));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllShippingCompanys = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllShippingcompanyApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, shippingcompanys } = response.data;
    if (status === "success") {
      dispatch(_getAllShippingCompany(shippingcompanys));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};
export const updateshippingCompany= (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateShippingcompanyApi(data);
    const { status, shippingcompany } = response.data;
    if (status === "success") {
      dispatch(_updateShippingCompany(shippingcompany));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};
export const deleteshippingCompany = (id) => async (dispatch) => {
  try {
    const response = await deleteShippingcompanyApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteShippingCompany(id));
    }
  } catch (error) {
    console.log(error);
  }
};

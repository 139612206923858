import PropTypes from "prop-types";
import { useState, useEffect, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";

// pages
import Login from "../pages/auth/Login";
import { logout } from "../redux/slices/user";
// components
import LoadingScreen from "../components/LoadingScreen";
import { useSelector, useDispatch } from "react-redux";
import tokenService from "src/services/tokenService";
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isInitialized } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const isAuthenticated = useSelector((state) => state.user.accessToken);
  const dispatch = useDispatch();
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = setTimeout(() => {
        console.log("Timeout triggered!");
        if (isAuthenticated) {
          console.log("Logging out due to inactivity...");
          dispatch(logout());
        }
      }, 240000); // 4 minutes in milliseconds
    };

    const handleUserActivity = () => {
      console.log("User activity detected!");
      resetTimeout();
    };

    const handleUnload = () => {
      // Perform cleanup (e.g., logout) before the page is unloaded
      if (isAuthenticated && document.visibilityState === "hidden") {
        sessionStorage.setItem("tabClosed", "true");
      }
    };

    const handleBeforeUnload = (event) => {
      // Set a session variable to indicate that the tab is being closed
      sessionStorage.setItem("tabClosed", "true");

      // Check if the tab is being closed (not just reloaded)
      if (event.clientY < 0) {
        dispatch(logout());
        sessionStorage.removeItem("tabClosed"); // Clear the session variable
      }
    };

    if (isInitialized) {
      window.addEventListener("mousemove", handleUserActivity);
      window.addEventListener("keydown", handleUserActivity);
      window.addEventListener("visibilitychange", handleUnload); // Listen for the visibilitychange event
      window.addEventListener("beforeunload", handleBeforeUnload); // Listen for the beforeunload event

      resetTimeout();

      // Check authentication status on app load
      const user = tokenService.getUser();
      if (!user || !user.accessToken) {
        dispatch(logout());
        setRequestedLocation(pathname); // Save the requested location for redirection after login
      }
    }

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("visibilitychange", handleUnload); // Remove the visibilitychange event listener
      window.removeEventListener("beforeunload", handleBeforeUnload); // Remove the beforeunload event listener
      clearTimeout(timeoutIdRef.current);
    };
  }, [isInitialized, isAuthenticated, dispatch, pathname]);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  // Check if isAuthenticated is undefined (not yet initialized)
  if (isAuthenticated === undefined) {
    return null; // or loading indicator
  }

  if (!isAuthenticated) {
    // if (pathname !== requestedLocation) {
    //   setRequestedLocation(pathname);
    // }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  return <>{children}</>;
}

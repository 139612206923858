import { createSlice } from "@reduxjs/toolkit";
import {
  addNewItemApi,
  getAllNewItemApi,
  deleteNewItemApi,
  updateNewItemApi
} from "src/api/inventory/newItemApi";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  newItems: [],
};

const slice = createSlice({
  name: "newItem",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    _addNewItem(state, { payload }) {
      state.newItems.push(payload);
    },
    _getAllNewItem(state, { payload }) {
      state.newItems = payload;
    },
    _deleteNewItem(state, { payload }) {
      const index = state.newItems.findIndex((item) => item._id === payload);
      if (index !== -1) {
        state.newItems.splice(index, 1);
      }
    },
    _updateNewItem(state, { payload }) {
      const index = state.newItems.findIndex(
        (chart) => chart._id === payload._id
      );
      if (index !== -1) {
        state.newItems[index] = payload;
      }
    },
    // HAS ERROR
    hasError(state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

// Reducer
export default slice.reducer;
const {
  _addNewItem,
  _getAllNewItem,
  _deleteNewItem,
  _updateNewItem,

  startLoading,
  getLabelsSuccess,
  hasError,
} = slice.actions;
// ----------------------------------------------------------------------

export const addNewItem = (data) => async (dispatch) => {
  // dispatch(startLoading());
  try {
    const response = await addNewItemApi(data);
    console.log("get addSalesPersonApi====", response);
    const { status, newItem } = response.data;
    if (status === "success") {
      dispatch(_addNewItem(newItem));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};

export const getAllNewItems = (data) => async (dispatch) => {
  console.log("get datakkk====");
  // dispatch(startLoading());
  try {
    const response = await getAllNewItemApi();
    console.log("get getAllSalesPersonApi====", response);
    const { status, newItems } = response.data;
    if (status === "success") {
      dispatch(_getAllNewItem(newItems));
    }
    // dispatch(_addSalesPerso(response))
    console.log("get addSalesPersonApi response====", response);
    // dispatch(_getUsers(response))
    // dispatch(getLabelsSuccess(response.data.labels));
  } catch (error) {
    // dispatch(hasError(error));
  }
};
export const deleteNewItem = (id) => async (dispatch) => {
  try {
    const response = await deleteNewItemApi(id);
    const { status } = response.data;
    if (status === "success") {
      dispatch(_deleteNewItem(id));
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateNewItem = (data) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await updateNewItemApi(data);
    const { status, newItems } = response.data;
    if (status === "success") {
      dispatch(_updateNewItem(newItems));
      // enqueueSnackbar("Update success!");
    }
  } catch (error) {
    dispatch(hasError(error));
    // enqueueSnackbar("Error updating payment term.", { variant: "error" });
  }
};


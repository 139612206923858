import { get, post, deleteReq, put } from "../../../src/services/restService";

export const addReceivePaymentApi = (data) => {
  return post("receive_payment/add", data);
};
export const getAllReceivePaymentApi = () => {
  return get("receive_payment/getAll");
};
export const getReceivePaymentByIdApi = (id) => {
  return get(`receive_payment/getById/${id}`);
};

export const deleteReceivePaymentApi = (id) => {
  return deleteReq(`receive_payment/delete/${id}`);
};

export const updateReceivePaymentApi = (data) => {
  return put(`receive_payment/update/${data._id}`, data);
};

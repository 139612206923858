import PropTypes from "prop-types";
import sum from "lodash/sum";
import { Page, View, Text, Image, Document } from "@react-pdf/renderer";
// utils
// import Overseas from "../Overseas.jpeg";

import { fCurrency } from "src/utils/formatNumber";
//
import styles from "./InvoiceStyle";

// ----------------------------------------------------------------------

InvoicePDF.propTypes = {
  quotationPdf: PropTypes.object.isRequired,
};

export default function InvoicePDF({ quotationPdf }) {
  const { invoice = {} } = quotationPdf;
  const { id } = invoice;

  function splitItemsIntoPages(items) {
    const rowsPerPage = 10;
    const pages = [];

    // add first page with first two items
    const firstPageItems = items.slice(0, 2);
    pages.push({
      title: "Quotation Details (Page 1)",
      items: firstPageItems,
      startIndex: 1,
    });

    // add remaining pages
    const remainingItems = items.slice(2);
    const totalPages = Math.ceil(remainingItems.length / rowsPerPage);

    let currentIndex = 3;
    for (let i = 0; i < totalPages; i++) {
      const start = i * rowsPerPage;
      const end = start + rowsPerPage;
      const pageItems = remainingItems.slice(start, end);
      const pageNumber = i + 2; // start page numbering from 2
      const startIndex = pages[i].startIndex + pages[i].items.length;
      const page = {
        title: `Quotation Details (Page ${pageNumber})`,
        startIndex: startIndex,
        items: pageItems,
      };
      pages.push(page);
    }

    return pages;
  }
  const items = quotationPdf.quoatationDetails?.items || [];
  const pages = splitItemsIntoPages(items);
  const itemsPerPage = 10;
  const totalPage = Math.ceil(items.length / itemsPerPage);
  let previousDiscount = 0;

  return (
    <Document>
      {pages.map(({ title, items, startIndex }, pageIndex) => (
        <Page size="A4" style={styles.page} key={pageIndex}>
          <View style={[styles.gridContainer]}>
            <Image
              disabledEffect
              visibleByDefault
              alt="logo"
              src="/favicon/Overseas.jpeg"
              style={{ width: 150, height: 100, marginTop: 5 }}
            />

            <View
              style={[
                { alignItems: "flex-end", flexDirection: "column" },
                styles.overline,
              ]}
            >
              <Text>
                Page {pageIndex + 1} of {pages.length}
              </Text>
              <Text style={styles.h1}>
                Qoutation : SQ100-{quotationPdf?.id}
                {console.log("quotationPdf?.id", quotationPdf?.id)}
              </Text>
            </View>
          </View>

          {pageIndex === 0 && (
            <View style={[styles.gridContainer, styles.mb40]}>
              <View style={styles.col6}>
                <Text style={[styles.overline, styles.mb80]}>
                  Quotation From
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  Overseas Enterprises (Automation & Control Division)
                </Text>

                <Text style={[styles.body1, styles.cell01]}>
                  Plot No. 28, Street No. 01, 18th KM Ferozepur Road,{" "}
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  Usman Colony, Lahore, Punjab 54600, Pakistan
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  Ph: +92 42 3718 6381-85,0300-8245084-94
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  Fax : +92 42 3718 6380
                </Text>
                <Text style={[styles.body1, styles.mb80]}>
                  Email : inquiry@oe.com.pk, Web : www.oe.com.pk
                </Text>
              </View>
              <View style={styles.col6}>
                <Text style={[styles.overline, styles.mb80]}>Quotation To</Text>
                <Text style={[styles.body1, styles.cell01]}>
                  {" "}
                  {quotationPdf.customer}
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  {" "}
                  {quotationPdf.address}
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  {quotationPdf.PhoneNumber}
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  <Text
                    style={[styles.subtitle20, styles.cell0, styles.overline]}
                  >
                    ATTN:
                  </Text>
                  {quotationPdf.atn}
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  {quotationPdf?.taxCost > 0 && (
                    <>
                      <Text
                        style={[
                          styles.subtitle20,
                          styles.cell0,
                          styles.overline,
                        ]}
                      >
                        NTN:
                      </Text>
                      {quotationPdf?.ntnNum}
                    </>
                  )}
                </Text>
                <Text style={[styles.body1, styles.cell01]}>
                  {quotationPdf?.taxCost > 0 && (
                    <>
                      <Text
                        style={[
                          styles.subtitle20,
                          styles.cell0,
                          styles.overline,
                        ]}
                      >
                        GST:
                      </Text>
                      {quotationPdf?.gstNum}
                    </>
                  )}
                </Text>
              </View>
            </View>
          )}
          <View style={styles.table0}>
            <View style={[styles.tableHeader0, styles.tableRow0]}>
              <View style={[styles.tableCell_100, styles.border0]}>
                <Text style={[styles.subtitle20, styles.cell0]}>YOUR REF.</Text>
              </View>
              <View
                style={[
                  styles.tableCell_70,
                  styles.alignCenter0,
                  styles.border0,
                ]}
              >
                <Text style={[styles.subtitle20, styles.cell0]}>OUR REF.</Text>
              </View>
              <View style={[styles.tableCell_100, styles.border0]}>
                <Text style={[styles.subtitle20, styles.cell0]}>VALIDITY</Text>
              </View>
              <View style={[styles.tableCell_100, styles.border0]}>
                <Text style={[styles.subtitle20, styles.cell0]}>
                  PAYMENT TERMS
                </Text>
              </View>
              <View style={[styles.tableCell_1100, styles.alignCenter0]}>
                <Text style={[styles.subtitle20, styles.cell0]}>DATE</Text>
              </View>
            </View>
            <View style={[styles.tableRow0]}>
              <View
                style={[
                  styles.tableCell_100,
                  styles.border0,
                  styles.alignCenter,
                ]}
              >
                <Text style={[styles.subtitle20, styles.cell0]}>
                  {quotationPdf?.cusDiscount}
                </Text>
              </View>
              <View
                style={[
                  styles.tableCell_70,
                  styles.alignCenter0,
                  styles.border0,
                ]}
              >
                <Text style={[styles.subtitle20, styles.cell0]}>
                  SQ100-{quotationPdf?.id}
                </Text>
              </View>
              <View style={[styles.tableCell_100, styles.border0]}>
                <Text style={[styles.subtitle20, styles.cell0]}>
                  {" "}
                  {quotationPdf?.quotationDeliveryDate}
                </Text>
              </View>
              <View style={[styles.tableCell_100, styles.border0]}>
                <Text style={[styles.subtitle20, styles.cell0]}>
                  {quotationPdf?.payment}
                </Text>
              </View>
              <View style={[styles.tableCell_100, styles.alignRight0]}>
                <Text style={[styles.subtitle20, styles.cell0]}>
                  {" "}
                  {quotationPdf?.quotationDate}
                </Text>
              </View>
            </View>

            <View style={styles.tableRow0}>
              <View style={[styles.tableCell_30]}>
                <Text style={[styles.subtitle20, styles.cell0]}>
                  <Text style={{ fontWeight: "bold" }}> DELIVERY :</Text>
                  {quotationPdf?.deliveryTerms}
                </Text>
              </View>
              <View style={[styles.tableCellh_30]}>
                <Text style={[styles.subtitle20, styles.cell0]}>
                  <Text style={{ fontWeight: "bold" }}> G.S.T. {"     "}</Text>
                  12-02-9999-456-28 {"\n"}
                  <Text>
                    {" "}
                    <Text style={{ fontWeight: "bold" }}>
                      {" "}
                      NTN. {"     "}{" "}
                    </Text>{" "}
                    2731310-7
                  </Text>
                  {"\n"}{" "}
                  <Text style={{ fontWeight: "bold" }}> CNIC. {"     "}</Text>
                  42301-7700217-7
                </Text>
              </View>
              <View style={[styles.tableCell_30]}>
                <Text style={[styles.subtitle20, styles.cell0]}>
                  {" "}
                  <Text style={{ fontWeight: "bold" }}> CURRENCY:</Text>{" "}
                  {quotationPdf?.currency}
                </Text>
              </View>
            </View>
          </View>
          {pageIndex === 0 && (
            <View>
              <Text>Dear Sir</Text>
              <Text>
                As per your request, we are pleased to submit our quotation as
                follows.
              </Text>
              <Text style={[styles.overline, styles.mb8]}>
                Quotation Details
              </Text>
            </View>
          )}
          {/* <View style={[styles.table0, styles.row]}> */}
          <View style={[styles.subtotal, styles.tableContainer]}>
            <View style={[styles.tableLatest]}>
              <View style={styles.table}>
                <View style={[styles.tableHeader0]}>
                  <View style={[styles.tableCell_10]}>
                    <Text style={[styles.cell01]}>ITEM</Text>
                  </View>
                  <View style={[styles.tableCell_30, styles.alignCenter]}>
                    <Text style={[styles.cell01]}>Description</Text>
                  </View>
                  <View style={[styles.tableCell_10, styles.alignCenter]}>
                    <Text style={[styles.cell01]}>Qty</Text>
                  </View>
                  <View style={[styles.tableCell_110, styles.alignCenter]}>
                    <Text style={[styles.cell01]}>Unit price</Text>
                  </View>
                  {/* {items.some((item) => item.discount > 0) && ( */}
                  <View style={[styles.tableCell_10, styles.alignCenter]}>
                    <Text style={[styles.cell01]}>Disc</Text>
                  </View>
                  {/* )} */}
                  <View style={[styles.tableCell_50, styles.alignRight]}>
                    <Text style={[styles.cell01]}>Total</Text>
                  </View>
                </View>
              </View>

              <View style={[styles.tableBody]}>
                {items.map((item, index) => {
                  const currentPage = Math.floor(index / itemsPerPage) + 1;
                  const isLastPage = currentPage === totalPage;
                  const tableBodyHeight = isLastPage ? "auto" : 300;

                  // Check if previous item exists and has a 3-line description
                  const previousItem = index > 0 ? items[index - 1] : null;
                  const isPreviousItemThreeLines =
                    previousItem &&
                    previousItem.longDescription &&
                    previousItem.longDescription.split("\n").length === 3;
                  const hasPreviousDiscount = items
                    .slice(0, index)
                    .some((prevItem) => prevItem.discount > 0);
                  const descriptionLines = item.longDescription
                    ? item.longDescription.split("\n").length
                    : 0;
                  const maxDescriptionLines = isPreviousItemThreeLines ? 5 : 3;
                  const maxTotalLines = isPreviousItemThreeLines ? 6 : 4;

                  let mergedDescription = item.longDescription;
                  if (hasPreviousDiscount && descriptionLines > 2) {
                    // Merge description with previous item's description
                    const previousItems = items.slice(0, index);
                    const mergedDescriptions = previousItems.map(
                      (prevItem) => prevItem.longDescription
                    );
                    mergedDescriptions.push(item.longDescription);
                    mergedDescription = mergedDescriptions.join("\n");
                  }

                  return (
                    <View
                      style={[
                        styles.tableRowLatest,
                        {
                          minHeight: maxTotalLines * 20,
                          height: tableBodyHeight,
                        },
                      ]}
                      key={index}
                    >
                      <View style={[styles.tableCell_10]}>
                        <Text style={[styles.cell01]}>
                          {startIndex + index}
                        </Text>
                      </View>
                      <View style={[styles.tableCell_30]}>
                        <Text
                          style={[styles.cell01]}
                          numberOfLines={maxDescriptionLines}
                        >
                          {mergedDescription}
                        </Text>
                      </View>
                      <View style={[styles.tableCell_10, styles.alignCenter]}>
                        <Text style={[styles.cell01]}>{item.quantity}</Text>
                      </View>
                      <View style={[styles.tableCell_110, styles.alignCenter]}>
                        <Text style={[styles.cell01]}>
                          {item.priceBeforeTax}
                        </Text>
                      </View>
                      {/* {hasPreviousDiscount  && ( */}
                      <View style={[styles.tableCell_10, styles.alignCenter]}>
                        <Text style={[styles.cell01]}>{item.discount}%</Text>
                      </View>
                      {/* )} */}
                      <View style={[styles.tableCell_50, styles.alignRight]}>
                        <Text style={[styles.cell01]}>{item.total}</Text>
                      </View>
                    </View>
                  );
                })}

                {pageIndex === pages.length - 1 && (
                  <View>
                    <View style={styles.tableRow011}>
                      <View style={[styles.tableCell_10s]}>
                        <Text style={[styles.cell01]}></Text>
                      </View>
                      <View
                        style={[styles.tableCell_30, styles.alignLeft]}
                      ></View>
                      <View style={[styles.tableCell_10s, styles.alignLeft]}>
                        <Text style={[styles.cell01]}></Text>
                      </View>
                      <View style={[styles.tableCell_110, styles.alignLeft]}>
                        <Text style={[styles.cell01]}> SUB TOTAL</Text>
                      </View>
                      <View
                        style={[styles.tableCell_101, styles.alignLeft]}
                      ></View>
                      <View style={[styles.tableCell_50, styles.alignRight]}>
                        <Text style={[styles.cell01]}>
                          {quotationPdf.quoatationDetails?.total !== undefined
                            ? quotationPdf.quoatationDetails.total.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : "N/A"}
                        </Text>
                      </View>
                    </View>

                    <View style={styles.tableRow011}>
                      <View style={[styles.tableCell_10s, styles.alignLeft]}>
                        <Text style={[styles.cell01]}></Text>
                      </View>
                      <View
                        style={[styles.tableCell_30, styles.alignLeft]}
                      ></View>
                      <View style={[styles.tableCell_10s]}>
                        <Text style={[styles.cell01]}></Text>
                      </View>
                      <View style={[styles.tableCell_110, styles.alignLeft]}>
                        <Text style={[styles.cell01]}> SHIPPING </Text>
                      </View>
                      <View style={[styles.tableCell_101]}></View>
                      <View style={[styles.tableCell_50, styles.alignRight]}>
                        <Text style={[styles.cell01]}>
                          {quotationPdf?.shippingCost !== undefined
                            ? quotationPdf?.shippingCost.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : // Handle the case when quotationPdf.taxCost is undefined
                              "N/A"}
                        </Text>
                      </View>
                    </View>

                    <View style={styles.tableRow011}>
                      <View style={[styles.tableCell_10s]}>
                        <Text style={[styles.cell01]}></Text>
                      </View>
                      <View
                        style={[styles.tableCell_30, styles.alignLeft]}
                      ></View>
                      <View style={[styles.tableCell_10s, styles.alignLeft]}>
                        <Text style={[styles.cell01]}></Text>
                      </View>
                      <View style={[styles.tableCell_110, styles.alignLeft]}>
                        <Text style={[styles.cell01]}>TAX</Text>
                        {quotationPdf?.taxCost !== undefined &&
                        quotationPdf?.taxCost > 0 ? (
                          <>
                            <Text>{quotationPdf?.taxGroup}</Text>
                          </>
                        ) : (
                          <Text></Text>
                        )}
                      </View>
                      {/* <View style={[styles.tableCell_110, styles.alignLeft]}>
                        {quotationPdf?.taxCost !== undefined &&
                        quotationPdf?.taxCost > 0 &&
                        quotationPdf?.taxGroup !== undefined &&
                        quotationPdf?.taxGroup > 0 ? (
                          <>
                            <Text style={[styles.cell01]}>TAX</Text>
                            <Text>{quotationPdf?.taxGroup}%</Text>
                          </>
                        ) : (
                          <>
                            <Text style={[styles.cell01]}>TAX</Text>

                            <Text>{quotationPdf?.taxGroup}</Text>
                          </>
                        )}
                      </View> */}
                      <View
                        style={[styles.tableCell_101, styles.alignLeft]}
                      ></View>
                      <View style={[styles.tableCell_50, styles.alignRight]}>
                        <Text style={[styles.cell01]}>
                          {typeof quotationPdf?.taxCost === "number" &&
                          !isNaN(quotationPdf?.taxCost)
                            ? quotationPdf?.taxCost.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : "N/A"}
                        </Text>
                      </View>
                      {/* <View style={[styles.tableCell_50, styles.alignRight]}>
                        <Text style={[styles.cell01]}>
                          {typeof quotationPdf?.taxCost === "number" &&
                          !isNaN(quotationPdf?.taxCost)
                            ? quotationPdf?.taxCost.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : "N/A"}
                        </Text>
                      </View> */}
                    </View>

                    <View style={styles.tableRow011}>
                      <View style={[styles.tableCell_10s]}>
                        <Text style={[styles.cell01]}></Text>
                      </View>
                      <View
                        style={[styles.tableCell_30, styles.alignLeft]}
                      ></View>
                      <View style={[styles.tableCell_10s, styles.alignLeft]}>
                        <Text style={[styles.cell01]}></Text>
                      </View>
                      <View style={[styles.tableCell_110, styles.alignLeft]}>
                        <Text style={[styles.cell01]}>
                          <b> TOTAL</b>
                        </Text>
                      </View>
                      <View
                        style={[styles.tableCell_101, styles.alignLeft]}
                      ></View>
                      <View style={[styles.tableCell_50, styles.alignRight]}>
                        <Text style={[styles.cell01]}>
                          {quotationPdf.afterTax
                            ? quotationPdf.afterTax.toLocaleString("en-IN", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })
                            : ""}
                        </Text>
                      </View>
                    </View>
                  </View>
                )}
              </View>
            </View>
          </View>
          {pageIndex === pages.length - 1 && (
            <View style={styles.endPage}>
              <View style={[styles.gridContainer, styles.footer]}>
                <View style={styles.col8}>
                  <Text style={[styles.subtitle2, styles.bor]}>Principal</Text>
                  <Text> </Text>
                  <Text style={[styles.bor, styles.space3, styles.cell01]}>
                    {" "}
                    WE HOPE YOU WILL FIND OUR QUOTATION SATISFACTORY IN ALL
                    RESPECT, AND LOOK TO RECEIVING YOUR VALUABLE ORDER.
                  </Text>
                </View>
                <View style={styles.col6}>
                  <Text
                    style={[styles.subtitle2, styles.bor, styles.alignCenter]}
                  >
                    Have a Question?
                  </Text>
                  <Text style={[styles.alignCenter]}> inquiry@oe.com.pk</Text>
                  <Text></Text>
                </View>
                <View style={[styles.col8, styles.alignCenter]}>
                  <Text style={[styles.subtitle2, styles.bor]}>
                    Yours sincerely
                  </Text>
                  <Text>
                    For OVERSEAS ENTERPRISES{" "}
                    <Text style={[styles.bolds]}>
                      :{quotationPdf.salesPerson}
                    </Text>
                  </Text>
                  <Text style={[styles.bor, styles.space3, styles.cell01]}>
                    THIS QUOTATION IS VALID FOR THE PERIOD INDICATED AND SUBJECT
                    TO THE TERMS AND CONDITIONS ON REVERSE.
                  </Text>
                </View>
              </View>
            </View>
          )}
        </Page>
      ))}
    </Document>
  );
}

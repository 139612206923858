import React, { useState, useEffect } from "react";
import { pink } from "@mui/material/colors";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

// form
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Box, Grid, Card, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// hooks
import { Controller } from "react-hook-form";
import useAuth from "src/hooks/useAuth";
import { useParams } from "react-router-dom";
// _mock
import { Link } from "react-router-dom";

// components
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import { getAllInventoryLocations } from "src/redux/slices/inventory/inventoryLocation";
import { useNavigate } from "react-router-dom";
import { getSalesOrdernById } from "src/redux/slices/customer/salesOrder";
import { getAllShippingCompanys } from "src/redux/slices/setting/shippingCompany";
import { getAllNewCustomers } from "src/redux/slices/customer/newCustomer";
import { addCreditInvoice } from "src/redux/slices/customer/creditInvoice";
import { useLocation } from "react-router-dom";
import { getAllPaymentTerms } from "src/redux/slices/setting/paymentTerm";
import { getDeliveryNoteById } from "src/redux/slices/customer/deliveryNote";
import { addLocalInvoice } from "src/redux/slices/customer/localInvoice";
// ----------------------------------------------------------------------

const label = { inputProps: { "aria-label": "Checkbox demo" } };
export default function CreditInvoiceProcess() {
  const { id, salesOrderID, deliveryNoteId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  ///////////////////// Single Sales Order Details ////////////////////////
  // const salesOrder = useSelector((state) => state.salesOrder);
  // const { salesOrders, singleSalesOrder } = salesOrder;

  /////////////// Single Delivery Note Detials /////////////////////////////
  const deliveryNote = useSelector((state) => state.deliveryNote);
  const { singleDelivery } = deliveryNote;

  const [singledeliveryNoteObj, setsingledeliveryNoteObj] = useState({});
  const [SalesOrderObj, setSalesOrderObj] = useState([]);
  const [inventoryLocationList, setinventoryLocationList] = useState([]);
  const [shippingList, setshippingList] = useState([]);
  const [paymentterms, setpaymentterms] = useState([]);
  const [viewCustomerItems, setviewCustomerItems] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isNewSeries, setIsNewSeries] = useState(false);
  const [idValue, setIdValue] = useState("");

  useEffect(() => {
    getDeliveryNotes();
  }, []);

  useEffect(() => {
    setsingledeliveryNoteObj(singleDelivery);
  }, [singleDelivery]);

  const getDeliveryNotes = async () => {
    await dispatch(getDeliveryNoteById(deliveryNoteId));
  };

  const newCustomer = useSelector((state) => state.newCustomer);
  const { newCustomers } = newCustomer;

  const { inventoryLocations } = useSelector(
    (state) => state.inventoryLocation
  );
  const paymentTerm = useSelector((state) => state.paymentTerm);
  const { paymentTerms } = paymentTerm;

  const shippingCompany = useSelector((state) => state.shippingCompany);
  const { shippingCompanys } = shippingCompany;

  useEffect(() => {
    const temp = paymentTerms.map((item) => item.termDescription);
    setpaymentterms(temp);
  }, [paymentTerms]);

  useEffect(() => {
    getPaymentTerms();
  }, []);

  const getPaymentTerms = async () => {
    await dispatch(getAllPaymentTerms());
  };

  useEffect(() => {
    setviewCustomerItems(newCustomers);
  }, [newCustomers]);

  useEffect(() => {
    getNewCustomers();
  }, []);

  const getNewCustomers = async () => {
    await dispatch(getAllNewCustomers());
  };

  // Render the calculated value

  const openPopupWindow = (id, navigateToCreditInvoice) => {
    const newWindow = window.open("", "Popup", "width=800,height=600");
    newWindow.document.title = "Popup Window";

    const idValue = id;

    const link1 = newWindow.document.createElement("a");
    const currentPath = window.location.pathname;
    const creditInvoicePath = `${currentPath}/${idValue}`;
    link1.href = creditInvoicePath;
    link1.innerHTML = "Invoice's  Print ";
    link1.style.display = "block";
    link1.style.margin = "20px";
    link1.addEventListener("click", (event) => {
      event.preventDefault();
      navigateToCreditInvoice(id);
    });
    newWindow.document.body.appendChild(link1);
  };

  const handleButtonClick = () => {
    setShowPopup(true);
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  ////////////////Use State ////////////////////////////////////

  ///////////////////////// id save Value details//////////////////////////
  const [receivedValues, setReceivedValues] = useState([]);

  const salesOrderIDDefaultValue = salesOrderID ? `PO550${salesOrderID}` : "";

  useEffect(() => {
    return () => {
      // Clean up any pending state updates or tasks
      setReceivedValues({});
    };
  }, []);
  ///////////////////////  Shipping Company////////////////////////////

  useEffect(() => {
    const temp = shippingCompanys.map((item) => item.companyName);
    setshippingList(temp);
  }, [shippingCompanys]);

  useEffect(() => {
    getShippingCompanys();
  }, []);

  const getShippingCompanys = async () => {
    await dispatch(getAllShippingCompanys());
  };
  const location = useLocation();
  //////////////////////Use Effect //////////////////////////////////

  const { enqueueSnackbar } = useSnackbar();
  // const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
  const { user } = useAuth();

  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [invoiceDate, setinvoiceDate] = useState(dayjs().format("YYYY-MM-DD"));
  const handleDateChangeReceived = (newValue) => {
    const formattedDate = newValue.toDate();
    setDate(formattedDate);
  };

  const handleDateChangeInvoiceDate = (newValue) => {
    const formattedDate = newValue.toDate();
    setinvoiceDate(formattedDate);
  };
  const {
    handleSubmit,
    control,
    setValue,
    register,
    getValues,
    reset, // Use the reset function instead of setValue
    formState: { errors },
  } = useForm();

  const UpdateUserSchema = Yup.object().shape({});

  const defaultValues = {
    customer: "",
    branch: "",
    address: "",
    salesOrderReferenceId: "",
    currentCredit: "",
    date: "",
    invoiceDate: "",
    payment: "",
    deliveryNoteId: 0,
    deliveryDate: "",
    currency: "",
    shippingCost: 0,
    salesOrder: "",
    salesOrderID: "",
    invoiceType: "",
    grnNumber: "",
    items: [],
    shippingCom: "",
    memo: "", // Add memo field with an initial empty string
    actionBalance: "",
    subtotal: 0,
    taxGroup: "",
    taxCost: 0,
    afterTax: 0,
    PhoneNumber: "",
    salesPerson: "",
    gstNum: "",
    ntnNum: "",
    atn: "",
    gridNo: "",
    grn: "",
    trData: "",
    deliveryNote: deliveryNoteId,
    purchaseOrderDate: "",
    carrierReceipt: "",
    purchaseOrder: "",
    puOrderDate: "",
    salespersonPhone: "",
    branchAddress: "",
    customerAddress: "",
    shippingTerms: "",
    deliveryTerms: "",
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  ////////////////////////////////////Value ///////////////////////////////////
  const {
    currency,
    branch,
    customer,
    grn,
    gstNum,
    atn,
    gridNo,
    address,
    ntnNum,
    trData,
    currentCredit,
    PhoneNumber,
    salesPerson,
    purchaseOrderDate,
    carrierReceipt,
    taxGroup,
    purchaseOrder,
    puOrderDate,
    salespersonPhone,
    branchAddress,
    customerAddress,
    deliveryTerms,
    shippingTerms,
  } = singledeliveryNoteObj;
  useEffect(() => {
    setValue("deliveryNote", id || "");
    setValue("currency", currency || "");
    setValue("customer", customer || "");
    setValue("branch", branch || "");
    setValue("address", address || "");
    setValue("taxGroup", taxGroup || "");
    setValue("PhoneNumber", PhoneNumber || "");
    setValue("salesPerson", salesPerson || "");
    setValue("gstNum", gstNum || "");
    setValue("ntnNum", ntnNum || "");
    setValue("atn", atn || "");
    setValue("trData", trData || "");
    setValue("gridNo", gridNo || "");
    setValue("purchaseOrderDate", purchaseOrderDate || "");
    setValue("purchaseOrder", purchaseOrder || "");
    setValue("carrierReceipt", carrierReceipt || "");
    setValue("puOrderDate", puOrderDate || "");
    setValue("currentCredit", currentCredit || ""); // Save supplierCurrency value
    setValue("salespersonPhone", salespersonPhone || "");
    setValue("branchAddress", branchAddress || "");
    setValue("customerAddress", customerAddress || "");
    setValue("deliveryTerms", deliveryTerms || "");
    setValue("shippingTerms", shippingTerms || "");
  }, [
    id,
    currency,
    branch,
    customer,
    grn,
    trData,
    gstNum,
    atn,
    gridNo,
    address,
    taxGroup,
    ntnNum,
    currentCredit,
    PhoneNumber,
    salesPerson,
    purchaseOrderDate,
    purchaseOrder,
    carrierReceipt,
    puOrderDate,
    branchAddress,
    customerAddress,
    salespersonPhone,
    shippingTerms,
    deliveryTerms,
    setValue,
  ]);

  useEffect(() => {
    setValue("deliveryNoteId", deliveryNoteId || "");
  }, [deliveryNoteId, setValue]);
  useEffect(() => {
    const initialItems = singledeliveryNoteObj?.items || [];
    reset({ items: initialItems });
  }, [singledeliveryNoteObj?.items, reset]);

  useEffect(() => {
    register("memo");
    register("memo");
    register("grnNumber");
    register("actionBalance");
  }, [register]);
  //////////////////////////  inventory Location /////////////////////////////////////
  useEffect(() => {
    getInventoryLocations();
  }, []);

  const getInventoryLocations = async () => {
    await dispatch(getAllInventoryLocations());
  };
  useEffect(() => {
    const temp = inventoryLocations.map((item) => item.locationName);
    setinventoryLocationList(temp);
  }, [inventoryLocations]);
  ////////////////////////////////////////////////////////////////////////////
  const handleLinkClick = (event, id) => {
    event.preventDefault();
    const url = `/dashboard/customers/transactions/${id}`;
    window.open(
      url,
      "popup",
      "width=600,height=600,scrollbars=yes,resizable=yes"
    );
  };
  const specificOptions = [
    { label: "Credit Invoice", value: "Credit Invoice" },
    { label: "Local Invoice", value: "Local Invoice" },
  ];
  ///////////////////////////////////// Onsubmit ////////////////////////////

  const onSubmit = async (data) => {
    if (isNewSeries) {
      await onSubmitLocalInvoice(data);
    } else {
      await onSubmitCreditInvoice(data);
    }
  };
  const onSubmitLocalInvoice = async (data) => {
    // Extract the items array from the data object
    const { items, deliveryNote, ...formData } = data;

    // Add additional properties from SalesOrderObj if needed
    const updatedData = {
      ...formData,
      isNewSeries: false, // Set isNewSeries to false for LocalInvoice

      deliveryNote: singledeliveryNoteObj.id,
      PhoneNumber: singledeliveryNoteObj.PhoneNumber,
      salesPerson: singledeliveryNoteObj.salesPerson,
      gstNum: singledeliveryNoteObj.gstNum,
      purchaseOrder: singledeliveryNoteObj.purchaseOrder,
      puOrderDate: singledeliveryNoteObj.puOrderDate,
      carrierReceipt: singledeliveryNoteObj.carrierReceipt,
      ntnNum: singledeliveryNoteObj.ntnNum,
      atn: singledeliveryNoteObj.atn,
      gridNo: singledeliveryNoteObj.gridNo,
      grn: singledeliveryNoteObj.grn,
      trData: singledeliveryNoteObj.trData,
      purchaseOrderDate: singledeliveryNoteObj.purchaseOrderDate,
      branchAddress: singledeliveryNoteObj.branchAddress,
      customerAddress: singledeliveryNoteObj.customerAddress,
      salespersonPhone: singledeliveryNoteObj.salespersonPhone,
      customer: singledeliveryNoteObj.customer,
      taxGroup: singledeliveryNoteObj.taxGroup,
      branch: singledeliveryNoteObj.branch,
      currency: singledeliveryNoteObj.currency,
      currentCredit: singledeliveryNoteObj.currentCredit,
      payment: singledeliveryNoteObj.payment,
      address: singledeliveryNoteObj.address,
      invoiceDate: invoiceDate,
      shippingCom: singledeliveryNoteObj.shippingCom,
      shippingTerms: singledeliveryNoteObj.shippingTerms,
      deliveryTerms: singledeliveryNoteObj.deliveryTerms,
      actionBalance: data.actionBalance,
      subtotal: data.subtotal,
      taxCost: data.taxCost,
      afterTax: data.totalAmount,
      // deliveryNote: data.deliveryNote,
      date: date,
      memo: data.memo,
      invoiceType: data.invoiceType,
      grnNumber: data.grnNumber,

      items: items.map((item, index) => ({
        ...item,
        total: data.items[index].calculatedValue,
        delivery: item.delivery,
      })),
    };

    const id = await dispatch(addLocalInvoice(updatedData));

    setIdValue(id);
    setShowPopup(true);
    const navigateToCreditInvoice = (id) => {
      const creditInvoicePath = `${location.pathname}/${id}`;
      window.location.href = creditInvoicePath;
    };

    openPopupWindow(id, navigateToCreditInvoice);
  };
  const onSubmitCreditInvoice = async (data) => {
    // Extract the items array from the data object
    const { items, deliveryNote, ...formData } = data;

    // Add additional properties from SalesOrderObj if needed
    const updatedData = {
      ...formData,
      isNewSeries: true,

      deliveryNote: singledeliveryNoteObj.id,
      PhoneNumber: singledeliveryNoteObj.PhoneNumber,
      salesPerson: singledeliveryNoteObj.salesPerson,
      gstNum: singledeliveryNoteObj.gstNum,
      purchaseOrder: singledeliveryNoteObj.purchaseOrder,
      puOrderDate: singledeliveryNoteObj.puOrderDate,
      carrierReceipt: singledeliveryNoteObj.carrierReceipt,
      ntnNum: singledeliveryNoteObj.ntnNum,
      atn: singledeliveryNoteObj.atn,
      gridNo: singledeliveryNoteObj.gridNo,
      grn: singledeliveryNoteObj.grn,
      trData: singledeliveryNoteObj.trData,
      purchaseOrderDate: singledeliveryNoteObj.purchaseOrderDate,
      branchAddress: singledeliveryNoteObj.branchAddress,
      customerAddress: singledeliveryNoteObj.customerAddress,
      salespersonPhone: singledeliveryNoteObj.salespersonPhone,
      customer: singledeliveryNoteObj.customer,
      taxGroup: singledeliveryNoteObj.taxGroup,
      branch: singledeliveryNoteObj.branch,
      currency: singledeliveryNoteObj.currency,
      currentCredit: singledeliveryNoteObj.currentCredit,
      payment: singledeliveryNoteObj.payment,
      address: singledeliveryNoteObj.address,
      invoiceDate: invoiceDate,
      shippingCom: singledeliveryNoteObj.shippingCom,
      shippingTerms: singledeliveryNoteObj.shippingTerms,
      deliveryTerms: singledeliveryNoteObj.deliveryTerms,
      actionBalance: data.actionBalance,
      subtotal: data.subtotal,
      taxCost: data.taxCost,
      afterTax: data.totalAmount,
      // deliveryNote: data.deliveryNote,
      date: date,
      memo: data.memo,
      invoiceType: data.invoiceType,
      grnNumber: data.grnNumber,

      items: items.map((item, index) => ({
        ...item,
        total: data.items[index].calculatedValue,
        delivery: item.delivery, // Ensure the delivery value is converted to a string
        // Add any additional properties specific to each item
      })),
    };

    const id = await dispatch(addCreditInvoice(updatedData));

    setIdValue(id);
    setShowPopup(true);
    const navigateToCreditInvoice = (id) => {
      const creditInvoicePath = `${location.pathname}/${id}`;
      window.location.href = creditInvoicePath;
    };

    openPopupWindow(id, navigateToCreditInvoice);
  };
  useEffect(() => {
    if (
      singledeliveryNoteObj &&
      singledeliveryNoteObj.items &&
      singledeliveryNoteObj.taxGroup
    ) {
      singledeliveryNoteObj.items.forEach((item, index) => {
        const delivery = getValues(`items[${index}].delivery`); // Get the value from the form's controller

        if (delivery) {
          const deliveryValue = parseFloat(delivery);
          const priceBeforeTaxValue = parseFloat(item.priceBeforeTax);
          const calculatedValue = deliveryValue * priceBeforeTaxValue;

          setValue(`items[${index}].calculatedValue`, calculatedValue); // Set the calculated value in the form's controller
        }
      });

      const subtotal = singledeliveryNoteObj.items.reduce(
        (acc, item, index) => {
          const calculatedValue = parseFloat(
            getValues(`items[${index}].calculatedValue`) || 0
          );
          return acc + calculatedValue;
        },
        0
      );

      const taxGroupValue =
        parseFloat(singledeliveryNoteObj.taxGroup.replace("%", "")) / 100;
      const taxCost = subtotal * taxGroupValue;
      const totalAmount = subtotal + taxCost;

      setValue(`subtotal`, subtotal);
      setValue(`taxCost`, taxCost);
      setValue(`totalAmount`, totalAmount);
    }
  }, [singledeliveryNoteObj, getValues, setValue]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid
        px={1}
        py={1}
        container
        spacing={1}
        sx={{ border: 1, borderColor: "#FB7600", borderRadius: 1 }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Card
            height={3}
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 3 }}>
                <RHFTextField
                  name="customer"
                  label="Customer "
                  control={methods.control}
                  value={singledeliveryNoteObj.customer || ""}
                  onChange={(e) =>
                    setsingledeliveryNoteObj((prevState) => ({
                      ...prevState,
                      customer: e.target.value,
                    }))
                  }
                  size="small"
                  sx={{ mt: 1 }}
                />

                <RHFTextField
                  name="branch"
                  label="Customer Branch"
                  control={methods.control}
                  value={singledeliveryNoteObj.branch || ""}
                  onChange={(e) =>
                    setsingledeliveryNoteObj((prevState) => ({
                      ...prevState,
                      branch: e.target.value,
                    }))
                  }
                  size="small"
                  sx={{ mt: 1 }}
                />
                <RHFTextField
                  name="payment"
                  control={methods.control}
                  label="Payment Term "
                  value={singledeliveryNoteObj.payment || ""}
                  onChange={(e) =>
                    setsingledeliveryNoteObj((prevState) => ({
                      ...prevState,
                      payment: e.target.value,
                    }))
                  }
                  size="small"
                  sx={{ mt: 1 }}
                />
              </Card>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 3 }}>
                <RHFTextField
                  name="currency"
                  control={methods.control}
                  label="Customer Currency "
                  value={singledeliveryNoteObj.currency || ""}
                  onChange={(e) =>
                    setsingledeliveryNoteObj((prevState) => ({
                      ...prevState,
                      currency: e.target.value,
                    }))
                  }
                  size="small"
                  sx={{ mt: 1 }}
                />
                <RHFTextField
                  name="salesType"
                  control={methods.control}
                  defaultValue={defaultValues.salesType}
                  label="Sales Types "
                  size="small"
                  sx={{ mt: 1 }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} sx={{ mt: 2 }}>
                    <DesktopDatePicker
                      label="Date"
                      name="date"
                      value={date}
                      minDate={dayjs("2017-01-01")}
                      onChange={handleDateChangeReceived}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Card>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 3 }}>
                <RHFTextField
                  name="salesPerson"
                  control={methods.control}
                  label="Sales Person "
                  value={singledeliveryNoteObj.salesPerson || ""}
                  onChange={(e) =>
                    setsingledeliveryNoteObj((prevState) => ({
                      ...prevState,
                      salesPerson: e.target.value,
                    }))
                  }
                  size="small"
                  sx={{ mt: 1 }}
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} sx={{ mt: 2 }}>
                    <DesktopDatePicker
                      label="Invoice Dead-Line"
                      name="invoiceDate"
                      value={invoiceDate}
                      minDate={dayjs("2017-01-01")}
                      onChange={handleDateChangeInvoiceDate}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>

                <Controller
                  name="grnNumber"
                  control={control}
                  defaultValue={defaultValues.grnNumber || ""}
                  render={({ field }) => (
                    <RHFTextField
                      {...field}
                      name="grnNumber"
                      label="G.R.N Number"
                      size="small"
                      sx={{ mt: 1 }}
                      onChange={(e) => {
                        field.onChange(e);
                        setValue("grnNumber", e.target.value);
                      }}
                    />
                  )}
                />
              </Card>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 2,
                columnGap: 1,

                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 3 }}>
                <input
                  type="hidden"
                  {...register("deliveryNote")}
                  defaultValue={deliveryNoteId}
                />
                <RHFTextField
                  name="salesOrderReferenceId"
                  control={methods.control}
                  label="Reference "
                  defaultValue=""
                  size="small"
                  sx={{ mt: 1 }}
                />
                Sales Order :
                <Controller
                  name="salesOrderID"
                  control={methods.control}
                  defaultValue={salesOrderIDDefaultValue}
                  render={({ field }) => (
                    <>
                      {salesOrderID && (
                        <a
                          href={`/dashboard/customers/transactions/${salesOrderID}`}
                          onClick={(e) => {
                            handleLinkClick(e, salesOrderID);
                          }}
                        >
                          SO550{salesOrderID}
                        </a>
                      )}
                      <input
                        type="hidden"
                        {...register("salesOrderID", {
                          value: salesOrderID || "",
                        })}
                      />
                    </>
                  )}
                />
                <RHFTextField
                  name="shippingCom"
                  control={methods.control}
                  label="Shipping Company "
                  value={singledeliveryNoteObj.shippingCom || ""}
                  onChange={(e) =>
                    setsingledeliveryNoteObj((prevState) => ({
                      ...prevState,
                      shippingCom: e.target.value,
                    }))
                  }
                  size="small"
                />
              </Card>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <h4
        style={{
          marginTop: 10,
          textAlign: "center",
          color: "#ff6347",
          fontSize: 30,
        }}
      >
        {" "}
        INVOICE ITEMS
      </h4>
      <TableContainer>
        <Table sx={{ minWidth: 700, mt: 10 }} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell>Item Code</TableCell>
              <TableCell align="right">Item Description</TableCell>
              <TableCell align="center">Long Description</TableCell>
              <TableCell align="right">Order </TableCell>
              <TableCell align="right">Unit</TableCell>
              <TableCell align="right">Delivered</TableCell>
              <TableCell align="center">This Delivery </TableCell>
              <TableCell align="center">Price</TableCell>
              <TableCell align="center">H.S. Code </TableCell>
              <TableCell align="left">Tax Type </TableCell>
              <TableCell align="right">Discount </TableCell>
              <TableCell align="right">Total </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {singledeliveryNoteObj?.items &&
              singledeliveryNoteObj.items.map((item, index) => {
                return (
                  <TableRow sx={{ P: 2, borderBottom: 1 }} key={index}>
                    <TableCell>{item.itemCode}</TableCell>
                    <TableCell align="center">{item.itemDescription}</TableCell>
                    <TableCell align="center">{item.longDescription}</TableCell>
                    <TableCell align="center">{item.quantity}</TableCell>
                    <TableCell align="center">{item.unit}</TableCell>
                    <TableCell align="center">0</TableCell>
                    <TableCell>
                      <Controller
                        control={control}
                        name={`items[${index}].delivery`}
                        defaultValue={item.delivery} // Set initial value as the quantity value
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label=""
                            size="small"
                            sx={{ mt: 1 }}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell align="center">{item.priceBeforeTax}</TableCell>
                    <TableCell align="center">{item.hsCode}</TableCell>
                    <TableCell align="center">{item.taxGroup}</TableCell>
                    <TableCell align="center">{item.discount}</TableCell>
                    <TableCell align="center">
                      {" "}
                      {getValues(`items[${index}].calculatedValue`)}
                    </TableCell>
                  </TableRow>
                );
              })}

            <TableRow>
              <TableCell colSpan={7}></TableCell>
              <TableCell align="center" sx={{ P: 2, borderBottom: 1 }}>
                Subtotal
              </TableCell>
              <TableCell colSpan={2} sx={{ P: 2, borderBottom: 1 }}></TableCell>
              <TableCell align="center" sx={{ P: 2, borderBottom: 1 }}>
                {getValues(`subtotal`)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={7}></TableCell>
              <TableCell align="center" sx={{ P: 2, borderBottom: 1 }}>
                Tax {singledeliveryNoteObj.taxGroup}
              </TableCell>
              <TableCell colSpan={2} sx={{ P: 2, borderBottom: 1 }}></TableCell>
              <TableCell align="center" sx={{ P: 2, borderBottom: 1 }}>
                {getValues(`taxCost`)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={7}></TableCell>
              <TableCell align="center" sx={{ P: 2, borderBottom: 1 }}>
                Total
              </TableCell>
              <TableCell colSpan={2} sx={{ P: 2, borderBottom: 1 }}></TableCell>
              <TableCell align="center" sx={{ P: 2, borderBottom: 1 }}>
                {getValues(`totalAmount`)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Grid
        px={1}
        py={1}
        container
        spacing={1}
        sx={{ border: 1, borderColor: "#FB7600", borderRadius: 1 }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Card
            height={3}
            sx={{
              p: 1,
              background: "rgba(145, 158, 171, 0.12)",
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                rowGap: 1,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 2 }}>
                <Controller
                  name="invoiceType"
                  control={control}
                  defaultValue={defaultValues.invoiceType || ""}
                  render={({ field }) => (
                    <RHFSelect
                      {...field}
                      name="invoiceType"
                      label="Invoice Type"
                      size="small"
                      sx={{ mt: 2 }}
                    >
                      <option value="" />
                      {specificOptions.map((option) => (
                        <option key={option.code} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </RHFSelect>
                  )}
                />
              </Card>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Card sx={{ p: 1, background: "rgba(145, 158, 171, 0.12)" }}>
            <Box
              sx={{
                display: "grid",
                rowGap: 1,
                columnGap: 1,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(1, 1fr)",
                },
              }}
            >
              <Card sx={{ p: 3 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...label}
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                      }}
                      onChange={(e) => setIsNewSeries(e.target.checked)}
                    />
                  }
                  label="Click For Local Invoice"
                />
              </Card>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={12}>
        <Card
          height={3}
          sx={{
            p: 1,
            background: "rgba(145, 158, 171, 0.12)",
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              display: "grid",
              rowGap: 2,
              columnGap: 1,
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(1, 1fr)",
              },
            }}
          >
            <Card sx={{ p: 3 }}>
              <Controller
                name="memo"
                control={control}
                defaultValue={defaultValues.memo || ""}
                render={({ field }) => (
                  <RHFTextField
                    {...field}
                    name="memo"
                    label="MEMO"
                    size="small"
                    multiline
                    rows={4}
                    sx={{ mt: 1 }}
                    onChange={(e) => {
                      field.onChange(e);
                      setValue("memo", e.target.value);
                    }}
                  />
                )}
              />
            </Card>
          </Box>
        </Card>
      </Grid>
      <Stack
        spacing={1}
        alignItems="flex-bottom"
        sx={{ mt: 1, borderRadius: 1 }}
      >
        <Box display={"flex"} sx={{ justifyContent: "center", m: 5 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            onClick={handleButtonClick}
          >
            Process Invoice
          </LoadingButton>
        </Box>
      </Stack>
    </FormProvider>
  );
}
